import React, { useState, useRef, useEffect } from "react";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import toast from "react-hot-toast";
import { Box, Button, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const Gallery = ({
  photos,
  customer_id,
  dashboard,
  model,
  onUpload,
  onDelete,
  onlyFirst,
}) => {
  const at = localStorage.getItem("accessToken");

  const [activePhoto, setActivePhoto] = useState(
    photos[0] ?? { path: "http://db.awapartners.com/nopic.jpg" }
  );
  const [isPhotoExpanded, setIsPhotoExpanded] = useState(false);
  const photoInputRef = useRef(null);

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies;

  const handlePhotoClick = (photo) => {
    setActivePhoto(photo);
  };

  const handleExpandClose = () => {
    setIsPhotoExpanded(false);
  };

  const togglePhotoExpand = () => {
    setIsPhotoExpanded((prevState) => !prevState);
  };

  const deletePhoto = (id) => () => {
    onDelete(id);
  };
  const uploadFile = async (event) => {
    const newPhotos = event.currentTarget.files;
    const formData = new FormData();
    Array.from(newPhotos).forEach((photo, i) => {
      formData.append(`photos${i}`, photo);
      formData.append(`id`, customer_id);
    });
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/${model}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + at,
          },
        }
      );
      onUpload?.(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (photos.length > 0) {
      setActivePhoto(photos[0]);
    } else {
      setActivePhoto({ path: "http://db.awapartners.com/nopic.jpg" });
    }
  }, [photos]);

  console.log({ photos });

  return (
    <div>
      {policy_actions.includes("edit") && !dashboard && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <input
            id="photos"
            name="photos"
            onChange={uploadFile}
            multiple
            hidden
            ref={photoInputRef}
            type="file"
          />
          <Button>
            <DownloadIcon onClick={() => photoInputRef.current.click()} />
          </Button>
        </Box>
      )}
      {/* Отображение текущей активной фотографии в большом размере */}
      {isPhotoExpanded && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
          onClick={handleExpandClose}
        >
          <img
            src={activePhoto.path}
            alt="Expanded Photo"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              cursor: "pointer",
            }}
            onClick={togglePhotoExpand}
          />
        </Box>
      )}
      {photos.length == 0 && dashboard ? null : (
        <Box sx={{ marginBottom: "20px", position: "relative" }}>
          <img
            src={activePhoto.path}
            alt="Active Photo"
            style={{ maxWidth: "100%", height: "auto", cursor: "pointer" }}
            onClick={togglePhotoExpand}
          />
          {policy_actions.includes("edit") &&
            photos.length > 0 &&
            !dashboard && (
              <IconButton
                onClick={deletePhoto(activePhoto.id)}
                style={{
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                  color: "#5664d2",
                }}
              >
                <Close />
              </IconButton>
            )}
        </Box>
      )}

      {!onlyFirst && (
        <Grid container spacing={2}>
          {photos &&
            photos
              .filter((p) => p.id != activePhoto.id)
              .map((photo) => (
                <Grid item xs={6} md={3} key={photo.id}>
                  <Box position="relative">
                    <img
                      src={photo.path}
                      alt={`Photo ${photo.id}`}
                      onClick={() => handlePhotoClick(photo)}
                      style={{ cursor: "pointer", width: "100%" }}
                    />
                    {policy_actions.includes("edit") && !dashboard && (
                      <IconButton
                        onClick={deletePhoto(photo.id)}
                        style={{
                          position: "absolute",
                          top: "-4px",
                          right: "-4px",
                          color: "#5664d2",
                        }}
                      >
                        <Close />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              ))}
        </Grid>
      )}
    </div>
  );
};

export default Gallery;
