"use strict";


const SVGUploadT = class SVGUploadT {

	static Data = "M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z";

};


export default SVGUploadT;
