"use strict";


import UISVGAbstractT from "./UISVGAbstractT.mjs";


const UISVGProducerAbstractT = class UISVGProducerAbstractT {
	
	constructor(
		
	){
		
	}
	
	Produce(
		...Props
	){
		
		throw new Error( "Abstract" );
		
	}
	
};


export default UISVGProducerAbstractT;