import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { useTranslation } from "react-i18next";
import $ from "jquery";
import { Autocomplete, TextField } from "@material-ui/core";
import { getNameFromObject } from "../components/form/FormWithValidation";

export default forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [optionsArray, setOptionsArray] = useState([]);

  const inputRef = useRef(null);
  async function getOptions() {
    let data_array = props.data_array ?? [];

    setOptionsArray(
      data_array
        .map((data) => {
          return {
            value: data.id,
            label:
              props.data_array_model === "suppliers"
                ? data.dodavatel_fullname
                : getNameFromObject(data, props.data_array_model),
          };
        })
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    );
  }

  function getVal(e) {
    let val = props.data[props.column.colId];
    if (val == null) val = "0";
    setValue(val);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: async () => {
        setValue(props.value);
        await getOptions();
        getVal();

        inputRef.current.focus();
        inputRef.current.click();
        $("#mycstselect svg").click();
      },

      isPopup: () => {
        return true;
      },
    };
  });

  const freeSoloModels = ["SomeModel"];

  let val;
  if (!freeSoloModels.includes(props.data_array_model)) {
    val =
      optionsArray.find(
        (option) => option?.value?.toString() === value?.toString()
      ) ?? "";
  } else {
    val = value?.toString() ?? "";
  }

  return (
    <Autocomplete
      ref={inputRef}
      options={optionsArray}
      fullWidth
      sx={{ minWidth: "300px" }}
      value={val}
      onChange={(_, pair) => {
        if (!pair) {
          let confirmed = window.confirm(t("Clear value?"));
          if (!confirmed) return;
          setValue(""); // Устанавливаем пустую строку
        } else {
          setValue(pair?.value.toString() ?? ""); // Устанавливаем значение строки
        }
      }}
      freeSolo={freeSoloModels.includes(props.data_array_model)}
      autoSelect={freeSoloModels.includes(props.data_array_model)}
      renderInput={(params) => (
        <TextField {...params} label={props.colDef.headerName} />
      )}
    />
  );
});
