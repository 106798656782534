"use strict";


const DBAbstractT = class DBAbstractT {
	
	constructor( 
		
	){
		
	}
	
	Get( 
		Key 
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
	
	}
	
};


export default DBAbstractT;