import { useState } from "react";
import toast from "react-hot-toast";
import {
  Avatar,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import { Send } from "@material-ui/icons";

const AddComment = ({ customer, parent_id, onAdd, grd }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [message, setMessage] = useState("");
  const [personal, setPersonal] = useState(false);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handlePersonalChange = (event) => {
    setPersonal(event.target.checked);
  };

  const handleSend = async () => {
    try {
      if (message) {
        const res = await HttpService.post(
          UrlService.apiDomain() + "api/customer_comments/create",
          {
            data: {
              customer_id: customer,
              title: null,
              message,
              personal,
              parent_id: parent_id ?? null,
            },
          }
        );
        if (res.status != 200) {
          toast.error(t("Něco se pokazilo!"));
          return;
        }
        setMessage("");
        toast.success(t("Poznámka přidána"));
        grd();
        onAdd?.();
      }
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  const handleKeyUp = async (event) => {
    try {
      if (event.key === "Enter" && message) {
        const res = await HttpService.post(
          UrlService.apiDomain() + "api/customer_comments/create",
          {
            data: {
              customer_id: customer,
              title: null,
              message,
              personal,
              parent_id: parent_id ?? null,
            },
          }
        );
        if (res.status != 200) {
          toast.error(t("Něco se pokazilo!"));
          return;
        }
        setMessage("");
        toast.success(t("Poznámka přidána"));
        grd();
        onAdd?.();
      }
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        gap: 5,
        p: 5,
      }}
    >
      <Avatar
        src={user.avatar}
        sx={{ mr: 2, width: 70, height: 70, borderRadius: "50%" }}
      />
      <TextField
        autoFocus
        multiline
        rows="3"
        fullWidth
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        placeholder={t("Napište zprávu...")}
        // size="medium"
        value={message}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                disabled={!message}
                onClick={handleSend}
              >
                <Send />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={personal}
            onChange={handlePersonalChange}
          />
        }
        label={t("Soukromá poznámka")}
      />{" "}
    </Box>
  );
};

export default AddComment;
