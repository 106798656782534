import { useRef, useState, useEffect } from "react";
import Pusher from "pusher-js";
import toast from "react-hot-toast";
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  Modal,
  Dialog,
} from "@material-ui/core";
import BellIcon from "../../icons/Bell";
import ChatAltIcon from "../../icons/ChatAlt";
import CreditCardIcon from "../../icons/CreditCard";
import ShoppingCartIcon from "../../icons/ShoppingCart";
import HailIcon from "@mui/icons-material/Hail";
import TrashIcon from "../../icons/Trash";
import ArchiveIcon from "../../icons/Archive";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import NotificationsArchiv from "./NotificationsArchiv";
import KanbanCardModal from "./vacancies/KanbanCardModal";
import { CalendarEventForm } from "./calendar";
import { useDispatch, useSelector } from "../../store";
import { getBoard, updateCard } from "../../slices/vacancies";
import { getEvents } from "../../slices/calendar";
import useAuth from "../../hooks/useAuth";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { useTranslation } from "react-i18next";

const iconsMap = {
  item_shipped: ShoppingCartIcon,
  customer_start_requirements: ChatAltIcon,
  order_placed: CreditCardIcon,
  vacancy: HailIcon,
  employees: ChatAltIcon,
};

const NotificationsPopover = () => {
  const { t } = useTranslation();
  const at = localStorage.getItem("accessToken");
  const pusher = new Pusher(
    process.env.NODE_ENV === "production"
      ? "63354407ad35d73af702"
      : "4e7067b9345489da794a",
    {
      cluster: "eu",
      useTLS: true,
      channelAuthorization: {
        endpoint: UrlService.apiDomain() + "api/pusher/auth",
        headers: {
          Authorization: "Bearer " + at,
        },
      },
      // Дополнительные настройки
    }
  );

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [card, setCard] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const { user } = useAuth();
  const { cards } = useSelector((state) => state.vacancies);
  const { events } = useSelector((state) => state.calendar);
  const [event, setEvent] = useState(null);
  const { isLoading } = useContext(DataContext);
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (notifications.length) {
      dispatch(getBoard());
      dispatch(getEvents());
    }
  }, [dispatch, notifications]);

  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleEventClose = () => {
    setOpenEvent(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCardClose = () => {
    setOpenCard(false);
  };

  const handleDelete = (notificationId) => async () => {
    try {
      const response = await HttpService.delete(
        UrlService.apiDomain() + `api/delete-notification/${notificationId}`
      );
      if (response.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      setNotifications(notifications.filter((n) => n.id != notificationId));
      toast.success("Oznámení smazáno");
    } catch (error) {
      console.log(error);
    }
  };
  const handleAllDelete = async () => {
    try {
      const response = await HttpService.delete(
        UrlService.apiDomain() + `api/delete-notifications`
      );
      if (response.status !== 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      setNotifications([]);
      toast.success("Oznámení smazáno");
    } catch (error) {
      console.log(error);
    }
  };

  const handleLinkOpen = ({ type, item_id, customer_id }) => {
    if (type === "vacancy") {
      setCard(cards.byId[item_id]);
      setCustomerId(customer_id);
      setOpenCard(true);
    } else if (type === "employees") {
      setEvent(events.find((event) => event.id == item_id));
      setOpenEvent(true);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + "api/old-notifications"
      );
      if (response.status === 200) {
        const unreadNotifications = response.data;
        setNotifications(unreadNotifications);
      } else {
        console.error("Failed to fetch unread notifications");
      }
    } catch (error) {
      console.error("Failed to fetch unread notifications", error);
    }
  };

  useEffect(() => {
    fetchUnreadNotifications();
  }, []);

  useEffect(() => {
    // Подписка на канал "notifications"
    const channel = pusher.subscribe(`private-notification.${user.id}`);
    const channelVacancy = pusher.subscribe(`private-vacancy.${user.id}`);
    const channelEmployees = pusher.subscribe(`private-employees.${user.id}`);
    const channelCustomersRequests = pusher.subscribe(
      `private-customer_start_requirements.${user.id}`
    );
    // Слушатель события "new-notification"
    channel.bind("new-notification", (data) => {
      const newNotification = data;
      // Обновление состояния с уведомлениями
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      // Вывод сообщения об уведомлении
      toast.success(newNotification?.title);
    });
    channelVacancy.bind("delete-user", (data) => {
      const newNotification = data;
      // Обновление состояния с уведомлениями
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      // Вывод сообщения об уведомлении
      toast.success(newNotification?.title);
    });
    channelVacancy.bind("overdue-vacancy", (data) => {
      const newNotification = data;
      // Обновление состояния с уведомлениями
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      // Вывод сообщения об уведомлении
      toast.success(newNotification?.title);
    });
    channelVacancy.bind("approve-user", (data) => {
      const newNotification = data;
      // Обновление состояния с уведомлениями
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      // Вывод сообщения об уведомлении
      toast.success(newNotification?.title);
    });
    channelVacancy.bind("archived-vacancy", (data) => {
      const newNotification = data;
      // Обновление состояния с уведомлениями
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      // Вывод сообщения об уведомлении
      toast.success(newNotification?.title);
    });
    channelVacancy.bind("add-user", (data) => {
      const newNotification = data;
      // Обновление состояния с уведомлениями
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      // Вывод сообщения об уведомлении
      toast.success(newNotification?.title);
    });
    channelVacancy.bind("add-vacancy", (data) => {
      const newNotification = data;
      // Обновление состояния с уведомлениями
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      // Вывод сообщения об уведомлении
      toast.success(newNotification?.title);
    });
    channelEmployees.bind("add-employee", (data) => {
      const newNotification = data;
      // Обновление состояния с уведомлениями
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      // Вывод сообщения об уведомлении
      toast.success(newNotification?.title);
    });
    channelCustomersRequests.bind("activated", (data) => {
      const newNotification = data;
      // Обновление состояния с уведомлениями
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      // Вывод сообщения об уведомлении
      toast.success(newNotification?.title);
    });

    // Отписка при размонтировании компонента
    return () => {
      channel.unbind("new-notification");
      pusher.unsubscribe("notifications");
    };
  }, []);

  return (
    <>
      <Tooltip title={t("Oznámení")}>
        <span>
          <IconButton
            disabled={isLoading}
            color="inherit"
            ref={anchorRef}
            onClick={handleOpen}
          >
            <Badge color="error" badgeContent={notifications.length}>
              <BellIcon fontSize="small" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      {card && (
        <KanbanCardModal
          setCard={setCard}
          card={card}
          onClose={handleCardClose}
          open={openCard}
          column={customerId}
        />
      )}
      {event && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleEventClose}
          open={openEvent}
        >
          <CalendarEventForm
            event={event}
            onAddComplete={handleEventClose}
            onCancel={handleEventClose}
            onDeleteComplete={handleEventClose}
            onEditComplete={handleEventClose}
            // range={selectedRange}
          />
        </Dialog>
      )}
      <Modal onClose={handleModalClose} open={openModal}>
        <div>
          <NotificationsArchiv handleOptionsClose={handleModalClose} />
        </div>
      </Modal>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}
      >
        <Box
          sx={{
            p: 2,
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography color="textPrimary" variant="h6">
            {t("Oznámení")}
          </Typography>
          <IconButton color={"primary"} onClick={handleModalOpen}>
            <ArchiveIcon fontSize="small" />
          </IconButton>
        </Box>
        {notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              {t("Nejsou žádná oznámení")}
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.type];

                return (
                  <ListItem divider key={notification.id}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: "primary.main",
                          color: "primary.contrastText",
                        }}
                      >
                        <Icon fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Link
                          color="textPrimary"
                          sx={{ cursor: "pointer" }}
                          underline="none"
                          variant="subtitle2"
                          component="button"
                          // variant="body2"
                          onClick={() => {
                            handleLinkOpen(notification);
                          }}
                        >
                          {notification.title}
                        </Link>
                      }
                      secondary={notification.description}
                    />
                    <IconButton onClick={handleDelete(notification.id)}>
                      <TrashIcon fontSize="small" />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Button
                onClick={handleAllDelete}
                color="primary"
                size="small"
                variant="text"
              >
                Označit vše jako přečtené
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
