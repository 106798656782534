"use strict";


import UIButtonAbstractT from "./UIButtonAbstractT.mjs";


const UIButtonProducerAbstractT = class UIButtonProducerAbstractT {
	
	constructor(
		
	){
		
	}
	
	Produce(
		...Props
	){
		
		throw new Error( "Abstract" );
		
	}
	
};


export default UIButtonProducerAbstractT;