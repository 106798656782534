export function getHash(item){
    const json = JSON.stringify(item);
    if(!json) return 0;
    let hash = 0, i, chr;
    if (json.length !== 0) {
        for (i = 0; i < json.length; i++) {
            chr = json.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
    }
    return hash;
}