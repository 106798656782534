import InputWithValidationOld from "../../../inputs/text/InputWithValidationOld";

// export const AccountNumberRegex = /^[1-9]\d{3,}\/\d{4}$/;
export const AccountNumberRegex = /^[1-9]\d*(-\d+)?\/\d{4}$/;
export default function InputAccountNumber(props) {
  return (
    <InputWithValidationOld
      regex={AccountNumberRegex}
      onChange={props.onChange}
      name="account_number"
      label={props.label}
      value={props.value}
      processValue={(val) => val.toUpperCase()}
    />
  );
}
