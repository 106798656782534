import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Alert,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";

const ApplyProposition = ({
  selected,
  getRowData,
  customer_id,
  getMainData,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const closeAllDialogs = () => {
    handleClose();
    setOpenConfirm(false);
  };

  const sendData = async () => {
    handleClose();
    setDisabledButton(true);
    try {
      const response = await HttpService.post(
        `${UrlService.apiDomain()}api/customer_start_requirements/edit`,
        {
          data: {
            id: selected[0].customer_start_requirement_id,
            resolver_employee_id: selected[0].proposer_employee_id,
            is_done: true,
          },
        }
      );
      if (!response.data.success) {
        alert(response.data.message);
        setDisabledButton(false);
        return;
      } else {
        setOpenConfirm(true);
        getMainData(customer_id);
        getRowData(
          "customer_start_requirements",
          null,
          { type: "owner", customer_id },
          true
        );
        setDisabledButton(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Potvrďte uložení")}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Zrušení")}
          </Button>
          <Button color="primary" onClick={sendData}>
            {t("Potvrdit")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirm} onClose={closeAllDialogs}>
        <DialogTitle>{t("Potvrzení")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("Záznam obnoven!")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAllDialogs} color="primary">
            {t("Zavřít")}
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        onClick={handleOpen}
        disabled={selected.length != 1 || disabledButton}
        color="primary"
        type="button"
        variant="contained"
        sx={{ height: "100%", px: 2, mx: 2 }}
      >
        {t("Uplatnit návrh")}
      </Button>
    </>
  );
};

export default ApplyProposition;
