"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UISelectReactT from "../../../Select/UISelectReactT.mjs";


const UIGridEditorSelectReactT = class UIGridEditorSelectReactT extends React.Component {
	
	#Component = null;
	
	#Ref = null;
	
	constructor(
		Props
	){
		
		console.log( "UIGridEditorSelectReactT.constructor", Props );
		
		
		super( Props );
		
		
		this.#Ref = null;
		
		
		this.#Component = React.createElement(
			UISelectReactT,
			{
				...Props,

				ref: this.SetRef.bind( this )
			}
		);
		
	}
	
	SetRef( Ref ){
		
		this.#Ref = Ref;
		
	}
	
	getValue( ){
		
		console.log( "getValue", this.#Ref.ValueGet( ) );
		
		
		return this.#Ref.ValueGet( );
		
	}
	
	ValueSet(
		Value
	){
		
		if( Value !== null ){
		
			if( typeof( Value ) !== "bigint" ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		console.log( this.#Ref );
		
		if( this.#Ref !== null ){
		
			this.#Ref.ValueSet( ( Value !== null ) ? Value : null );
		
		} else {
			
			throw new Error( "Not mounted" );
			
		}
		
	}
	
	render( ){
		
		console.log( "UIGridEditorSelectReactT.render" );
		
		
		return this.#Component;
		
	}
	
};


export default UIGridEditorSelectReactT;