import { useState, useEffect } from "react";

import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const NotificationsArchiv = ({ handleOptionsClose }) => {
  const { t } = useTranslation();

  const customComparator = (valueA, valueB) => {
    if (valueA === null || valueB === null) {
      return valueA === valueB ? 0 : valueA === null ? -1 : 1;
    }
    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else {
      // return default value for non-string and non-number values
      return 0;
    }
  };

  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  const coldefs = [
    {
      headerName: t("Název"),
      field: "name",
    },
    {
      headerName: t("Popis"),
      field: "description",
    },
    {
      headerName: t("Přijaté"),
      field: "created_at",
    },
    {
      headerName: t("Datum archivace"),
      field: "deleted_at",
    },
  ];

  const { settings } = useSettings();
  const [rowdata, setRowdata] = useState([]);
  const [columndefs, setColumndefs] = useState(coldefs);
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    editable: false,
    resizable: true,
    suppressMovableColumns: true,
    menuTabs: ["filterMenuTab", "columnsMenuTab"],
    sortable: true,
    filter: true,
    suppressContextMenu: true,
    comparator: customComparator,
  };
  const frameworkComponents = {};

  const grd = () => {
    HttpService.get(UrlService.apiDomain() + `api/notifications-archiv`)
      .then((res) => {
        setRowdata(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    grd();
  }, []);

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);
  };

  useEffect(() => {
    setloctext(lt);
    setColumndefs(coldefs);
  }, [t("cs")]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "60%",
    //  border: "2px solid #000",
  };

  return (
    <div style={style}>
      {/* <Box
        sx={{
          backgroundColor: "background.default",
          height: "100%",
          py: 8,
        }}
      > */}
      <Container
        maxWidth={settings.compact ? "xl" : false}
        style={{
          width: "100%",
          height: "100%",
          background: "#f4f5f7",
          borderRadius: "20px",
          paddingBottom: 100,
        }}
      >
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h5">
              {t("Archiv oznámení")}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  title={t("Excel")}
                  onClick={() => gridApi.exportDataAsExcel()}
                >
                  Excel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleOptionsClose}>
                  {t("Zavřít")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{ mt: 3 }}
          style={{ width: "100%", height: "calc(100% - 100px)" }}
          id="myGrid"
          className="ag-theme-material"
        >
          <AgGridReact
            modules={AllCommunityModules}
            rowData={rowdata}
            columnDefs={columndefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            enableCellChangeFlash={true}
            rowSelection={"multiple"}
            suppressContextMenu={true}
            localeText={loctext}
            popupParent={document.querySelector("body")}
            frameworkComponents={frameworkComponents}
          />
        </Box>
      </Container>
      {/* </Box> */}
    </div>
  );
};

export default NotificationsArchiv;
