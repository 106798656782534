"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";

import MaterialUIIcons from "@material-ui/icons";


const UITextReactT = class UITextReactT extends React.Component {
	
	constructor( 
		Props
	){
		
		console.log( "UITextReactT.constructor", Props );
		
	
		super( Props );
		
	
		this.state = {
			Required: ( Props.Required ? Props.Required : false ),
			Label: ( Props.Label ? Props.Label : "" ),
			Name: ( Props.Name ? Props.Name : "" ),
			Value: ( Props.Value ? Props.Value : "" ),
			SX: ( Props.SX ? Props.SX : { } ),
			Type: ( Props.Type ? Props.Type : "text" ),
			Disabled: ( Props.Disabled ? Props.Disabled : false ),
			onChange: ( Props.onChange ? Props.onChange : null ),
			AllowedRegex: ( Props.AllowedRegex ? new RegExp( Props.AllowedRegex, "" ) : null ),
			Regex: ( Props.Regex ? new RegExp( Props.Regex, "" ) : null ),
			OnCloseClick: ( Props.OnCloseClick ? Props.OnCloseClick : null )
		};
		
		this.state.IsValid = this.Validation( this.state.Value );
		
	}
	
	Validation( 
		Value
	){
		
		console.log( "UITextReactT.Validation", Value );

        if( Value === "" ) {
			
            if( this.state.Required === true ) {
				
                return false;
            
			} else {

                return true;
				
            }
			
        } else {
		
			if( this.state.Regex === null ) {

				return true;
				
			} else {
				
				return this.state.Regex.test( Value );
			
			}
		
		}
		
	}
	
	OnChangeCb( 
		Ev 
	){
		
		console.log( Ev );
		
		this.PropSet( "Value", Ev.target.value );
		
	}
	
	componentDidMount(
	
	){
		
		console.log( "UITextReactT.componentDidMount", this.state );
		
	}
	
	componentDidUpdate(
		PropsPrev,
		StatePrev
	){
		
		console.log( "UITextReactT.componentDidUpdate", this.state );
			
	}

				   
				   
	componentWillUnmount(
	
	){
		
		console.log( "UIPageContentReactT.componentWillUnmount", this.state );
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIBooleanReactT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		if( Name === "Value" ){
			
			if( ( Value !== "" ) && ( this.state.AllowedRegex !== null ) ){
			
				if( this.state.AllowedRegex.test( Value ) === false ){ 
				
					return;
					
				}
				
			}
			
			this.setState( {
				Value: Value,
				IsValid: this.Validation( Value )
			} );
			
			return;
			
		}
		
		
		this.setState( {
			[ Name ]: Value
		} );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIBooleanReactT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.state[ Name ];
		
	}
	
	OnClickClear( ){
												
		this.setState( { Value: "", IsValid: this.Validation( "" ) } );
												
	}
	
	OnClickClose( ){
												
		if( this.state.OnCloseClick !== null ){
		
			this.state.OnCloseClick( );
		
		}
		
	}
	
	render( ){
		
		console.log( "UITextReactT.render", this.state );
		
		
		return React.createElement(
			MaterialUI.TextField, 
			{
				label: ( ( this.state.Required === true ) ? ( this.state.Label + "*" ) : ( this.state.Label ) ),
				name: this.state.Name,
				InputLabelProps: { shrink: ( this.state.Value !== "" ) },
				sx: this.state.SX,
				variant: ( ( this.state.Disabled ) ? "filled" : "outlined" ), 
				type: this.state.Type,
				value: this.state.Value,
				error: ( ( this.state.IsValid === false ) && ( this.state.Disabled === false ) ), 
				disabled: this.state.Disabled,
				fullWidth: true, 
				onChange: this.OnChangeCb.bind( this ),
				InputProps: {
							
					endAdornment: React.createElement(
						React.Fragment,
						{
							
						},
						[
							React.createElement(
								MaterialUI.IconButton,
								{
									title: "Clear",
									onClick: this.OnClickClear.bind( this )
								},
								[
									
									React.createElement(
										MaterialUIIcons.Backspace,
										{
											
										},
										[
										]
									)
									
								]
							),
							
							React.createElement(
								MaterialUI.IconButton,
								{
									title: "Close",
									onClick: this.OnClickClose.bind( this )
								},
								[
									
									React.createElement(
										MaterialUIIcons.Clear,
										{
											
										},
										[
										]
									)
									
								]
							)
						
						]
					)
					
				}
			},
			[
				
			]
		);
		
	}
	
};


export default UITextReactT;