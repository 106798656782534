"use strict";


import UIPageContentTopBarAbstractT from "./UIPageContentTopBarAbstractT.mjs";


const UIPageContentTopBarProducerAbstractT = class UIPageContentTopBarProducerAbstractT {
	
	constructor(
		
	){
		
	}
	
	Produce(
		...Props
	){
		
		throw new Error( "Abstract" );
		
	}
	
};


export default UIPageContentTopBarProducerAbstractT;