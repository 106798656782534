import React from "react";

const isIncludedComponent = (element) =>
  element && (element.type.name == "" || element.type.name);

export default function addPropsToGrid(children, props) {
  if (!children) return null;

  const applyProps = (child) => {
    if (isIncludedComponent(child)) {
      return React.cloneElement(child, { ...props, key: child.type.name });
    }
    return child;
  };

  if (Array.isArray(children)) {
    return children.map(applyProps);
  } else {
    return applyProps(children);
  }
}
