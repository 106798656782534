"use strict";


const UIBooleanAbstractT = class UIBooleanAbstractT {
	
	constructor(
	
	) { 
	  
	}

	PropSet(
		Name,
		Value
	){
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	PropGet(
		Name
	){
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		throw new Error( "Abstract" );
		
	}

};


export default UIBooleanAbstractT;
