import {Box, Tab} from "@material-ui/core";
import {useState} from "react";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import addPropsToChildren from "../../utils/addPropsToChildren";

export default function TabsWrapper({tabs, ...props}) {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <TabContext value={value} sx={{width: "100%"}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList onChange={handleChange} variant="scrollable"
                         scrollButtons="auto">
                    {tabs.map((tab, index) => (
                        <Tab fullWidth key={index} value={`${index + 1}`} label={tab.label} {...tab.props}/>
                    ))}
                </TabList>
            </Box>
            {tabs.map((tab, index) => {
                let content = addPropsToChildren(tab.content, props);
                return (<TabPanel key={index} value={`${index + 1}`} sx={{p: 0}}>
                    {content}
                </TabPanel>)}
            )}
        </TabContext>
    );
}