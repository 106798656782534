"use strict";


import HTTPHeaderT from "../../../../../Type/HTTP/Header/HTTPHeaderT.mjs";

import HTTPHeadersT from "../../../../../Type/HTTP/Header/HTTPHeadersT.mjs";


const HTTPClientAbstractT = class HTTPClientAbstractT {
	
	constructor(

	){
		
	}
	
	Request(
		Method,
		URL,
		Headers,
		Data,
		
		SuccessCb,
		ErrorCb
	){
		
		if( typeof( Method ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( URL ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( Headers instanceof HTTPHeadersT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( Data instanceof Uint8Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
};


export default HTTPClientAbstractT;