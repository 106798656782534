"use strict";


const SVGCheckT = class SVGCheckT {

	static Data = "M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z";

};


export default SVGCheckT;
