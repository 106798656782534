"use strict";


import JSONBNSerializerErrorT from "../../../Type/JSONBN/Serializer/JSONBNSerializerErrorT.mjs";


const JSONBNSerializerT = class JSONBNSerializerT {
	
	#DigitMin = '\0';
		
	#DigitMax = '\0';
		
	#DigitMinus = '\0';
	
	#DigitDot = '\0';
	
	#DigitColon = '\0';
	
	#DigitComma = '\0';
	
	#DigitQuoteDouble = '\0';
	
	#DigitSlash = '\0';
	
	#DigitBackSlash = '\0';
	
	#DigitWhiteSpace = '\0';
	
	
	#DigitBackSpace = '\0';
		
	#DigitLineFeed = '\0';
	
	#DigitFormFeed = '\0';
	
	#DigitCarriageReturn = '\0';
	
	#DigitHorizontalTab = '\0';
	
	
	#DigitBracketFigureOpen = '\0';
	
	#DigitBracketFigureClose = '\0';
	
	#DigitBracketSquareOpen = '\0';
	
	#DigitBracketSquareClose = '\0';
	
	
	#DigitLetterLowerA = '\0';
	
	#DigitLetterLowerB = '\0';
	
	#DigitLetterLowerE = '\0';
	
	#DigitLetterLowerF = '\0';
	
	#DigitLetterLowerL = '\0';
	
	#DigitLetterLowerN = '\0';
	
	#DigitLetterLowerR = '\0';
	
	#DigitLetterLowerS = '\0';
	
	#DigitLetterLowerT = '\0';
	
	#DigitLetterLowerU = '\0';
	
	
	#DigitLetterUpperA = '\0';
	
	#DigitLetterUpperE = '\0';
	
	#DigitLetterUpperF = '\0';
	
	
	#DigitWhitespaces = null;
	
	#DigitExponentPrefixes = null;
	
	
	#ConstantNull = null;
		
	#ConstantBooleanFalse = null;
	
	#ConstantBooleanTrue = null;
	
	#ConstantEscapedBackSlash = null;
	
	#ConstantEscapedBackSpace = null;
	
	#ConstantEscapedCarriageReturn = null;
	
	#ConstantEscapedFormFeed = null;
	
	#ConstantEscapedHorizontalTab = null;
	
	#ConstantEscapedLineFeed = null;
	
	#ConstantEscapedQuoteDouble = null;
	
	#ConstantEscapedSlash = null;
	
	#ConstantEscapedUnicode = null;
	
	
	#Buffer = null;

	constructor(
	
	){
		
		this.#DigitMin = '0';
		
		this.#DigitMax = '9';
		
		this.#DigitMinus = '-';
		
		this.#DigitDot = '.';
		
		this.#DigitColon = ':';
		
		this.#DigitComma = ',';
	
		this.#DigitQuoteDouble = '"';
		
		this.#DigitSlash = '/';
		
		this.#DigitBackSlash = '\\';
		
		this.#DigitWhiteSpace = ' ';
		
		
		this.#DigitBackSpace = '\b';
		
		this.#DigitLineFeed = '\n';
		
		this.#DigitFormFeed = '\f';
		
		this.#DigitCarriageReturn = '\r';
		
		this.#DigitHorizontalTab = '\t';
		
		
		this.#DigitBracketFigureOpen = '{';
	
		this.#DigitBracketFigureClose = '}';
	
		this.#DigitBracketSquareOpen = '[';
	
		this.#DigitBracketSquareClose = ']';

		
		this.#DigitLetterLowerA = 'a';
	
		this.#DigitLetterLowerB = 'b';
		
		this.#DigitLetterLowerE = 'e';
		
		this.#DigitLetterLowerF = 'f';
		
		this.#DigitLetterLowerL = 'l';
		
		this.#DigitLetterLowerN = 'n';
		
		this.#DigitLetterLowerR = 'r';
		
		this.#DigitLetterLowerS = 's';
		
		this.#DigitLetterLowerT = 't';
		
		this.#DigitLetterLowerU = 'u';
		
		
		this.#DigitLetterUpperA = 'A';
		
		this.#DigitLetterUpperE = 'E';
		
		this.#DigitLetterUpperF = 'F';
		
		
		this.#ConstantNull = "null";
		
		this.#ConstantBooleanFalse = "false";
		
		this.#ConstantBooleanTrue = "true";
		
		this.#ConstantEscapedBackSlash = "\\\\";
		
		this.#ConstantEscapedBackSpace = "\\b";
		
		this.#ConstantEscapedCarriageReturn = "\\r";
		
		this.#ConstantEscapedFormFeed = "\\f";
		
		this.#ConstantEscapedHorizontalTab = "\\t";
		
		this.#ConstantEscapedLineFeed = "\\n";
		
		this.#ConstantEscapedQuoteDouble = "\\\"";
		
		this.#ConstantEscapedSlash = "\\/";
		
		this.#ConstantEscapedUnicode = "\\u";
		
		
		this.#DigitWhitespaces = [ 
			this.#DigitWhiteSpace, 
			this.#DigitCarriageReturn, 
			this.#DigitLineFeed, 
			this.#DigitHorizontalTab
		];
		
		this.#DigitExponentPrefixes = [ 
			this.#DigitLetterLowerE, 
			this.#DigitLetterUpperE
		];
		
		
		this.#Buffer = [ ];
	
	}
	
	#BufferWrite( 
		Data 
	){
	    
	    this.#Buffer.push( Data );
	    
	}
	
	#BufferDrain( 
	
	){
		
		let Result = this.#Buffer.join( "" );
		
		this.#Buffer.splice( 0, this.#Buffer.length );
		
		return Result;
		
	}
	
	/*#AddWhitespaces( ){
		
		while( this.#IsNextIn( this.#DigitWhitespaces ) === true ){
			
			this.#SkipToNext( );
			
		}
		
	}*/
	
	#EnsureClean(
	
	){

		this.#Buffer.splice( 0, this.#Buffer.length );
		
	}
	
	#EnsureDouble(
		JSONValue
	){
		
		if( typeof( JSONValue ) !== "number" ){
			
			throw new JSONBNSerializerErrorT( "Invalid value type" );
			
		}
		
	}
	
	#SerializeDouble(
		JSONValue
	){
		
		this.#EnsureDouble( JSONValue );
		
		
		this.#BufferWrite( JSONValue.toString( ) );
		
	}
	
	#EnsureInteger(
		JSONValue
	){
		
		if( typeof( JSONValue ) !== "bigint" ){
			
			throw new JSONBNSerializerErrorT( "Invalid value type" );
			
		}
		
	}
	
	#SerializeInteger( 
		JSONValue
	){
		
		this.#EnsureInteger( JSONValue );
		
		
		this.#BufferWrite( JSONValue.toString( ) );
		
	}
	
	#EnsureNull(
		JSONValue
	){
		
		if( JSONValue !== null ){
			
			throw new JSONBNSerializerErrorT( "Invalid value type" );
			
		}
		
	}
	
	#SerializeNull(
		JSONValue
	){
		
		this.#EnsureNull( JSONValue );
		
		
		this.#BufferWrite( this.#ConstantNull );

	}
	
	#EnsureBoolean(
		JSONValue
	){
		
		if( typeof( JSONValue ) !== "boolean" ){
			
			throw new JSONBNSerializerErrorT( "Invalid value type" );
			
		}
		
	}
	
	#SerializeBoolean(
		JSONValue
	){
		
		this.#EnsureBoolean( JSONValue );
		
		
		if( JSONValue === true ){
		
			this.#BufferWrite( this.#ConstantBooleanTrue );
		
		} else {
			
			this.#BufferWrite( this.#ConstantBooleanFalse );
			
		}
		
	}
	
	#SerializeChar(
		Char
	){
		
		if( Char === this.#DigitBackSlash ){
			
			this.#BufferWrite( this.#ConstantEscapedBackSlash );
			
		} else if( Char === this.#DigitQuoteDouble ){
			
			this.#BufferWrite( this.#ConstantEscapedQuoteDouble );
			
		} else if( Char === this.#DigitSlash ){
			
			this.#BufferWrite( this.#ConstantEscapedSlash );
			
		} else if( Char === this.#DigitBackSpace ){
			
			this.#BufferWrite( this.#ConstantEscapedBackSpace );
			
		} else if( Char === this.#DigitFormFeed ){
			
			this.#BufferWrite( this.#ConstantEscapedFormFeed );
			
		} else if( Char === this.#DigitFormFeed ){
			
			this.#BufferWrite( this.#ConstantEscapedFormFeed );
			
		} else if( Char === this.#DigitLineFeed ){
			
			this.#BufferWrite( this.#ConstantEscapedLineFeed );
			
		} else if( Char === this.#DigitCarriageReturn ){
			
			this.#BufferWrite( this.#ConstantEscapedCarriageReturn );
			
		} else if( Char === this.#DigitHorizontalTab ){
			
			this.#BufferWrite( this.#ConstantEscapedHorizontalTab );
			
		} else if( ( Char.charCodeAt( 0 ) >= 0x00 ) && ( Char.charCodeAt( 0 ) <= 0x1F ) ){
			
			let Value = this.#ConstantEscapedUnicode + ( Char.charCodeAt( 0 ).toString( 16 ).padStart( 4, 0 ) );

			this.#BufferWrite( Value );
			
		} else {
			
			this.#BufferWrite( Char );
			
		}
		
	}
	
	#EnsureString(
		JSONValue
	){
		
		if( typeof( JSONValue ) !== "string" ){
			
			throw new JSONBNSerializerErrorT( "Invalid value type" );
			
		}
	
	}
	
	#SerializeString(
		JSONValue
	){
		
		this.#EnsureString( JSONValue );
		
		this.#BufferWrite( this.#DigitQuoteDouble );
		
		for( 
			let I = 0;
			I < JSONValue.length;
			I++
		){
			
			this.#SerializeChar( JSONValue.charAt( I ) );
			
		}
		
		
		this.#BufferWrite( this.#DigitQuoteDouble );
		
	}
	
	#EnsureArray(
		JSONValue
	){
		
		if( ( JSONValue instanceof Array ) === false ){
			
			throw new JSONBNSerializerErrorT( "Invalid value type" );
			
		}
		
	}
	
	#SerializeArray(
		JSONValue
	){
		
		this.#EnsureArray( JSONValue );
		
		//TODO AddWhitespaces = this.#SkipWhitespaces( );
		
		this.#BufferWrite( this.#DigitBracketSquareOpen );
		
		for( 
			let I = 0;
			I < JSONValue.length;
			I++
		){
			
			let Element = JSONValue[ I ];
			
			this.#SerializeElement( Element );
			
			if( ( I + 1 ) !== JSONValue.length ){
				
				this.#BufferWrite( this.#DigitComma );
				
			}
			
		}
		
	
		this.#BufferWrite( this.#DigitBracketSquareClose );
		
	}
	
	#SerializeKey(
		JSONValue
	){
		
		this.#SerializeString( 
			JSONValue
		);
		
	}
	
	#SerializeValue( 
		JSONValue
	){
		
		this.#SerializeElement( 
			JSONValue
		);
		
	}
	
	#SerializePair( 
		Key,
		Value
	){
		
		this.#SerializeKey( Key );
		
		this.#BufferWrite( this.#DigitColon );
		
		this.#SerializeValue( Value );
		
	}
	
	#EnsureObject(
		JSONValue
	){
		
		if( ( JSONValue instanceof Object ) === false ){
			
			throw new JSONBNSerializerErrorT( "Invalid value type" );
			
		}
		
	}
	
	#SerializeObject(
		JSONValue
	){
		
		
		this.#EnsureObject( JSONValue );
		
		//TODO AddWhitespaces = this.#SkipWhitespaces( );
		
		this.#BufferWrite( this.#DigitBracketFigureOpen );
		
		
		let Result = { };
		
		let Keys = Object.keys( JSONValue );
	
		
		for( 
			let I = 0;
			I < Keys.length;
			I++
		) {
			
			let Key = Keys[ I ];
			
			let Value = JSONValue[ Key ];
			
			this.#SerializePair( Key, Value );
			
			if( ( I + 1 ) !== Keys.length ){
				
				this.#BufferWrite( this.#DigitComma );
				
			}
			
		}
		
		
		this.#BufferWrite( this.#DigitBracketFigureClose );
	
	}

	#SerializeElement(
		JSONValue
	){
		
		// AddWhitespace this.#SkipWhitespaces( ); 
		
		
		let Result = null;
		
		
		if( typeof( JSONValue ) === "object" ){
			
			if( JSONValue === null ){
				
				this.#SerializeNull( JSONValue );
				
			} else if( ( JSONValue instanceof Array ) === true ){
				
				this.#SerializeArray( JSONValue );
				
			} else {
				
				this.#SerializeObject( JSONValue );
				
			}
			
		} else if( typeof( JSONValue ) === "string" ){
			
			this.#SerializeString( JSONValue );
			
		} else if( typeof( JSONValue ) === "number" ){
			
			this.#SerializeDouble( JSONValue );
			
		} else if( typeof( JSONValue ) === "bigint" ){
			
			this.#SerializeInteger( JSONValue );
			
		} else if( typeof( JSONValue ) === "boolean" ){
			
			this.#SerializeBoolean( JSONValue );
			
		} else {
			
			throw new JSONBNSerializerErrorT( "Invalid element type" );
			
		}
		
		
		//Add whitespaces this.#SkipWhitespaces( );
		
		
		return Result;
	
	}
	
	Serialize(
		Data
	){
		
		this.#EnsureClean( );
		
		
		this.#SerializeElement( Data );
		
		let Result = this.#BufferDrain( );
		
		
		this.#EnsureClean( );
		
		
		return Result;
		
	}

};


export default JSONBNSerializerT;