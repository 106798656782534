import React, { Component } from "react";
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import { useTranslation } from "react-i18next";
import XIcon from "../icons/X";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import deleteDocumentTag from "../api/document/deleteDocumentTag";

const DeleteRenderer = (props) => {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    let deletedRow = props.node.data;

    if (props.interceptDelete) {
      props.interceptDelete(props, handleClose);
      return;
    }

    if (props.gridType === "document_tags") {
      deleteDocumentTag(
        props.data.id,
        () => {
          console.log("Success");
          props.node.gridApi.updateRowData({ remove: [deletedRow] }); // It will update the row
          setOpen(false);
          if (typeof props.afterUpdate === "function") props.afterUpdate();
        },
        (error) => {
          alert(error);
        }
      );
      return;
    }

    var url =
      UrlService.apiDomain() + "api/" + props.gridType + "/" + props.data.id;

    if (props.gridType === "columns_options")
      url =
        UrlService.apiDomain() +
        "api/" +
        props.gridType +
        "/" +
        props.colid +
        "/" +
        props.data.id;

    if (props.gridType === "delete/files")
      url =
        UrlService.apiDomain() + "api/" + props.gridType + "/" + props.data.id;

    HttpService.delete(url)
      .then((res) => {
        if (res.data.alert != null && res.data.alert != "") {
          alert(t(res.data.alert));
          return false;
        }
      
        if (props.setRowData) {
          props.setRowData((prev) =>
            prev.filter((row) => row.id !== props.data.id)
          );
        } else {
          props.node.gridApi.updateRowData({ remove: [deletedRow] }); // It will update the row
        }
        setOpen(false);

        if (typeof props.afterUpdate === "function") props.afterUpdate();
        if (typeof props.onDelete === "function") props.onDelete(props.data.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Button
        className="btn btn-default btn-sm delete-ingrid-but"
        onClick={handleClickOpen}
      >
        <XIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Potvrdit odstranění")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              "Jste si jisti, že chcete odstranit tuto položku a všechny položky s ní spojené?"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Ne")}
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {t("Ano")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteRenderer;
