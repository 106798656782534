"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIButtonT from "../UIButtonT.mjs";

import UISVGT from "../../SVG/UISVGT.mjs";

import UIInputT from "../../Input/UIInputT.mjs";

import UILabelT from "../../Label/UILabelT.mjs";

import SVGUploadT from "../../../../Type/SVG/SVGUploadT.mjs";

import UIButtonFileUploadAbstractT from "../../../../Interface/UI/Button/FileUpload/_/UIButtonFileUploadAbstractT.mjs";


const UIButtonFileUploadT = class UIButtonFileUploadT extends UIButtonT {
	
	#FileInputRef = null;
	
	#OnChange = null;
	
	constructor(
		Props
	){
		
		console.log( "UIButtonFileUploadT.constructor", Props );
		
		
		let FileInputRef = { Ref: null };
		
		
		let Children = [
			new UIInputT( {
				key: Props.key + "_" + "upload_input",
				OnChange: function( Ev ){
					Props.OnChange( Ev.target.files )
				},
				Hidden: true,
				Type: "file",
				IsMultiple: Props.IsMultiple
			} ),
			new UISVGT( {
				key: Props.key + "_" + "upload_svg",
				SX: { },
				Data: SVGUploadT.Data,
				ViewBox: "0 0 24 24"
			} ),
			new UILabelT( {
				key: Props.key + "_" + "upload_label",
				SX: ( ( Props.SX ) ? Props.SX : { FullWidth: true } ),
				Color: ( ( Props.Color ) ? Props.Color : "textPrimary" ),
				Children: [ 
					"Nahrát" + " " + Props.Label + ( Props.Required ? "*" : "" )
				]
			} )
		];
		
		
		super( { 
			...Props, 
			Children: Children
		} );

		this.#FileInputRef = FileInputRef;
		
		this.#OnChange = Props.OnChange;
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIButtonFileUploadT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super.PropSet( Name, Value );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIButtonFileUploadT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return super.PropGet( Name );
		
	}
	
	Click( ){
		
		this.PropGet( "Children" )[ 0 ].Click( );
		
	}
	
	Clear( ){
		
		this.PropGet( "Children" )[ 0 ].ValueSet( null );
		
		this.#OnChange( [ ] );
		
	}
	
	Component( 
	
	){
		
		return super.Component( );
		
	}
	
};


export default UIButtonFileUploadT;