"use strict";


const TranslationGridCST = class TranslationGridCST {
	
	static Data( ){
		
		return {
			// Set Filter
			selectAll: "(Vybrat vše)",
			selectAllSearchResults: "(Vybrat všechny výsledky hledání)",
			searchOoo: "Vyhledat...",
			blanks: "(Prázdno)",
			noMatches: "Žádná shoda",

			// Number Filter & Text Filter
			filterOoo: "Filtrovat...",
			equals: "Rovná se",
			notEqual: "Nerovná se",
			empty: "Vyberte",

			// Number Filter
			lessThan: "Menší než",
			greaterThan: "Větší než",
			lessThanOrEqual: "Menší nebo roven",
			greaterThanOrEqual: "Větší nebo roven",
			inRange: "V rozsahu",
			inRangeStart: "Do",
			inRangeEnd: "Od",

			// Text Filter
			contains: "Obsahuje",
			notContains: "Neobsahuj",
			startsWith: "Začíná",
			endsWith: "Končí",

			// Date Filter
			dateFormatOoo: "dd.mm.yyyy",

			// Filter Conditions
			andCondition: "A",
			orCondition: "NEBO",

			// Filter Buttons
			applyFilter: "Uložit",
			resetFilter: "Zrušit",
			clearFilter: "Vymazat",
			cancelFilter: "Storno",

			// Filter Titles
			textFilter: "Textový filtr",
			numberFilter: "Čiselný filtr",
			dateFilter: "Filtr datumu",
			setFilter: "Nastavit filtr",

			// Side Bar
			columns: "Kolonky",
			filters: "Filtry",

			// columns tool panel
			pivotMode: "Pivot Mode",
			groups: "Row Groups",
			rowGroupColumnsEmptyMessage: "Drag here to set row groups",
			values: "Values",
			valueColumnsEmptyMessage: "Drag here to aggregate",
			pivots: "Column Labels",
			pivotColumnsEmptyMessage: "Drag here to set column labels",

			// Header of the Default Group Column
			group: "Sekce",

			// Other
			loadingOoo: "Načítám data...",
			noRowsToShow: "Žádná data",
			enabled: "Zapnuto",

			// Menu
			pinColumn: "Zafixovat kolonku",
			pinLeft: "Zafixovat vlevo",
			pinRight: "Zafixovat vpravo",
			noPin: "Nefixovat",
			valueAggregation: "Value Aggregation",
			autosizeThiscolumn: "Autošířka kolonky",
			autosizeAllColumns: "Autošířka všech kolonek",
			groupBy: "Group by",
			ungroupBy: "Un-Group by",
			resetColumns: "Resetovat kolonky",
			expandAll: "Expand All",
			collapseAll: "Close All",
			copy: "Kopírovat",
			ctrlC: "Ctrl+C",
			copyWithHeaders: "Kopírovat se záhlavím",
			paste: "Vložit",
			ctrlV: "Ctrl+V",
			export: "Exportovat",
			csvExport: "CSV Export",
			excelExport: "Excel Export (.xlsx)",
			excelXmlExport: "Excel Export (.xml)",

			// Enterprise Menu Aggregation and Status Bar
			sum: "Sum",
			min: "Min",
			max: "Max",
			none: "None",
			count: "Count",
			avg: "Average",
			filteredRows: "Filtrované",
			selectedRows: "Vybrané",
			totalRows: "Celkem řádků",
			totalAndFilteredRows: "Řádků",
			more: "Více",
			to: "do",
			of: "z",
			page: "Stránka",
			nextPage: "Další stránka",
			lastPage: "Poslední stránka",
			firstPage: "První stránka",
			previousPage: "Předchozí stránka",

			// Enterprise Menu (Charts)
			pivotChartAndPivotMode: "Pivot Chart & Pivot Mode",
			pivotChart: "Pivot Chart",
			chartRange: "Chart Range",

			columnChart: "Column",
			groupedColumn: "Grouped",
			stackedColumn: "Stacked",
			normalizedColumn: "100% Stacked",

			barChart: "Bar",
			groupedBar: "Grouped",
			stackedBar: "Stacked",
			normalizedBar: "100% Stacked",

			pieChart: "Pie",
			pie: "Pie",
			doughnut: "Doughnut",

			line: "Line",

			xyChart: "X Y (Scatter)",
			scatter: "Scatter",
			bubble: "Bubble",

			areaChart: "Area",
			area: "Area",
			stackedArea: "Stacked",
			normalizedArea: "100% Stacked",

			histogramChart: "Histogram",

			// Charts
			pivotChartTitle: "Pivot Chart",
			rangeChartTitle: "Range Chart",
			settings: "Settings",
			data: "Data",
			format: "Format",
			categories: "Categories",
			defaultCategory: "(None)",
			series: "Series",
			xyValues: "X Y Values",
			paired: "Paired Mode",
			axis: "Axis",
			navigator: "Navigator",
			color: "Color",
			thickness: "Thickness",
			xType: "X Type",
			automatic: "Automatic",
			category: "Category",
			number: "Number",
			time: "Time",
			xRotation: "X Rotation",
			yRotation: "Y Rotation",
			ticks: "Ticks",
			width: "Width",
			height: "Height",
			length: "Length",
			padding: "Padding",
			spacing: "Spacing",
			chart: "Chart",
			title: "Title",
			titlePlaceholder: "Chart title - double click to edit",
			background: "Background",
			font: "Font",
			top: "Top",
			right: "Right",
			bottom: "Bottom",
			left: "Left",
			labels: "Labels",
			size: "Size",
			minSize: "Minimum Size",
			maxSize: "Maximum Size",
			legend: "Legend",
			position: "Position",
			markerSize: "Marker Size",
			markerStroke: "Marker Stroke",
			markerPadding: "Marker Padding",
			itemSpacing: "Item Spacing",
			itemPaddingX: "Item Padding X",
			itemPaddingY: "Item Padding Y",
			layoutHorizontalSpacing: "Horizontal Spacing",
			layoutVerticalSpacing: "Vertical Spacing",
			strokeWidth: "Stroke Width",
			offset: "Offset",
			offsets: "Offsets",
			tooltips: "Tooltips",
			callout: "Callout",
			markers: "Markers",
			shadow: "Shadow",
			blur: "Blur",
			xOffset: "X Offset",
			yOffset: "Y Offset",
			lineWidth: "Line Width",
			normal: "Normal",
			bold: "Bold",
			italic: "Italic",
			boldItalic: "Bold Italic",
			predefined: "Predefined",
			fillOpacity: "Fill Opacity",
			strokeOpacity: "Line Opacity",
			histogramBinCount: "Bin count",
			columnGroup: "Column",
			barGroup: "Bar",
			pieGroup: "Pie",
			lineGroup: "Line",
			scatterGroup: "X Y (Scatter)",
			areaGroup: "Area",
			histogramGroup: "Histogram",
			groupedColumnTooltip: "Grouped",
			stackedColumnTooltip: "Stacked",
			normalizedColumnTooltip: "100% Stacked",
			groupedBarTooltip: "Grouped",
			stackedBarTooltip: "Stacked",
			normalizedBarTooltip: "100% Stacked",
			pieTooltip: "Pie",
			doughnutTooltip: "Doughnut",
			lineTooltip: "Line",
			groupedAreaTooltip: "Area",
			stackedAreaTooltip: "Stacked",
			normalizedAreaTooltip: "100% Stacked",
			scatterTooltip: "Scatter",
			bubbleTooltip: "Bubble",
			histogramTooltip: "Histogram",
			noDataToChart: "No data available to be charted.",
			pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
			chartSettingsToolbarTooltip: "Menu",
			chartLinkToolbarTooltip: "Linked to Grid",
			chartUnlinkToolbarTooltip: "Unlinked from Grid",
			chartDownloadToolbarTooltip: "Download Chart",

			// ARIA
			ariaHidden: "hidden",
			ariaVisible: "visible",
			ariaChecked: "checked",
			ariaUnchecked: "unchecked",
			ariaIndeterminate: "indeterminate",
			ariaColumnSelectAll: "Toggle Select All Columns",
			ariaInputEditor: "Input Editor",
			ariaDateFilterInput: "Date Filter Input",
			ariaFilterInput: "Filter Input",
			ariaFilterColumnsInput: "Filter Columns Input",
			ariaFilterValue: "Filter Value",
			ariaFilterFromValue: "Filter from value",
			ariaFilterToValue: "Filter to value",
			ariaFilteringOperator: "Filtering Operator",
			ariaColumnToggleVisibility: "column toggle visibility",
			ariaColumnGroupToggleVisibility: "column group toggle visibility",
			ariaRowSelect: "Press SPACE to select this row",
			ariaRowDeselect: "Press SPACE to deselect this row",
			ariaRowToggleSelection: "Press Space to toggle row selection",
			ariaRowSelectAll: "Press Space to toggle all rows selection",
			ariaSearch: "Search",
			ariaSearchFilterValues: "Search filter values"
		};
		
	}
		
};


export default TranslationGridCST;
