import {
  Autocomplete,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import { memo, useEffect, useState } from "react";

export default memo(
  ({
    keySelector,
    valueSelector,
    onChange,
    label,
    data_array,
    data_array_model,
    name,
    required,
    disabled,
    inputSx,
    value,
  }) => {
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
      let data = data_array;
      setOptions(
        data.map((item) => {
          return { value: valueSelector(item), label: keySelector(item) };
        })
      );
    }, [data_array, data_array_model, keySelector, valueSelector]);
    useEffect(() => {
      if (value) {
        let data = data_array;
        let values = value.toString().split("//");
        console.log({ values });
        const filteredData = data.filter((item) =>
          values.includes(item.id.toString())
        );
        const result = filteredData.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        setSelected(result);
      }
    }, [value]);

    useEffect(() => {
      if (options?.length === 1) {
        setSelected([options[0]]);
      }
    }, [options]);

    useEffect(() => {
      let newValue = selected?.map((item) => item.value)?.join("//") ?? null;
      onChange(name, newValue, !required || selected?.length > 0);
    }, [name, onChange, required, selected]);

    if (!data_array || !options) return <LinearProgress />;

    const selectAllOption = { value: "selectAll", label: "Vybrat vše" };
    const updatedOptions = [selectAllOption, ...options];

    return (
      <Autocomplete
        name={name}
        disabled={disabled}
        options={updatedOptions}
        onChange={(event, newValue) => {
          if (newValue.some((item) => item.value === "selectAll")) {
            setSelected(options);
          } else setSelected(newValue);
        }}
        fullWidth
        multiple
        value={selected ?? []}
        sx={inputSx}
        renderInput={(params) => (
          <TextField
            {...params}
            label={required ? label + "*" : label}
            inputProps={{ ...params.inputProps, maxLength: 30 }}
            error={required && (!selected || selected?.length === 0)}
          />
        )}
      />
    );
  },
  (prev, next) => {
    return (
      prev.disabled === next.disabled &&
      prev.selected === next.selected &&
      prev.data_array === next.data_array
    );
  }
);
