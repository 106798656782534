"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIPageContentTopBarReactT from "./UIPageContentTopBarReactT.mjs";

import UIPageContentTopBarAbstractT from "../../../../../Interface/UI/Page/Content/TopBar/_/UIPageContentTopBarAbstractT.mjs";


const UIPageContentTopBarT = class UIPageContentTopBarT extends UIPageContentTopBarAbstractT {
	
	#Component = null;
	
	#Ref = null;
	
	#ReadyCb = null;
	
	constructor(
		props,
		
		ReadyCb
	){
		
		if( typeof( ReadyCb ) !== "function" ){
		
			throw new Error( "Argument" );
		
		}
		
		
		super( );
		
		
		this.#ReadyCb = ReadyCb;
		
		
		this.#Component = React.createElement( 
			UIPageContentTopBarReactT, 
			{
				...props,
				ref: this.RefSet.bind( this )
			}
		);
		
	}
	
	RefSet( Ref ){
		
		console.log( "UIPageContentT.RefSet", Ref );
		
		
		this.#Ref = Ref;
		
		
		if( this.#Ref !== null ){
		
			this.#ReadyCb( );
		
		}
		
	}
	
	ChildrenSet(
		Children
	){
		
		let ChildrenReact = [ ];
		
		for(
			let I = 0;
			I < Children.length;
			I++
		){
			
			let XS = Children[ I ].XS;
			
			let Components = Children[ I ].Components;
			
			let key = Children[ I ].key;
			
			let ComponentsChildren = [ ];
			
			for(
				let I2 = 0;
				I2 < Components.length;
				I2++
			){
				
				ComponentsChildren.push( Components[ I2 ].Component( ) );
				
			}
			
			ChildrenReact.push( {
				
				key: key,
				XS: XS,
				Children: ComponentsChildren
				
			} );
			
		}
		
		this.#Ref.ChildrenSet( ChildrenReact );
		
	}
	
	Component( ){
		
		return this.#Component;
		
	}
	
};


export default UIPageContentTopBarT;