import { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import ChatAltIcon from "../../../icons/ChatAlt";
import DocumentTextIcon from "../../../icons/DocumentText";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "../../../store";
import KanbanCardModal from "./KanbanCardModal";
import CopyVacancy from "./CopyVacancy";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const cardSelector = (state, cardId) => {
  const { cards, members } = state.vacancies;
  const card = cards.byId[cardId];

  return {
    ...card,
    members: card.memberIds.map((memberId) => members.byId[memberId]),
  };
};

const KanbanCard = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { cardId, dragging, column, ...other } = props;
  const card = useSelector((state) => cardSelector(state, cardId));
  const [open, setOpen] = useState(false);
  const [approvedPeopleCount, setApprovedPeopleCount] = useState(0);

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies;

  const isDodavatel = policy_actions.includes("dodavatel_view");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { checklists } = card;

  useEffect(() => {
    setApprovedPeopleCount(
      checklists[0].checkItems.filter((item) => item.state === "complete")
        .length
    );
  }, [card]);

  const totalCheckItems = card.employeeNeeded;
  const completedCheckItems = card.checklists[0].checkItems.filter(
    (checkItem) => checkItem.state === "complete"
  ).length;
  const completePercentage =
    totalCheckItems === 0 ? 0 : (completedCheckItems / totalCheckItems) * 100;

  const setProgressColor = () => {
    let color;
    if (completePercentage < 25) {
      color = "primary";
    }
    if (completePercentage > 25 && completePercentage < 100) {
      color = "warning";
    }
    if (completePercentage == 100) {
      color = "success";
    }
    return color;
  };
  const setBoarderColor = () => {
    let color = "primary";
    if (card.priority === "low") {
      color = "#5cb85c";
    }
    if (card.priority === "medium") {
      color = "#f0ad4e";
    }
    if (card.priority === "high") {
      color = "#cf3939";
    }
    return color;
  };

  return (
    <Box
      ref={ref}
      sx={{
        outline: "none",
        py: 1,
      }}
      {...other}
    >
      <Card
        onClick={handleOpen}
        sx={{
          backgroundColor: "background.paper",
          border: "1px solid #5664d2",
          transition: "0.3s ease-in-out",
          "&:hover": {
            borderColor: setBoarderColor(),
            transform: "scale(1.05)",
            backgroundColor: "background.default",
            cursor: "pointer",
          },
        }}
        variant={dragging ? "elevation" : "outlined"}
      >
        {card.cover && <CardMedia image={card.cover} sx={{ height: 200 }} />}
        <CardContent>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: 1,
              mb: 1,
            }}
          >
            <Typography color="textPrimary" variant="h6">
              {card.name}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                minWidth: "25px",
                height: "25px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* В зависимости от выбранного приоритета будет отображаться соответствующая иконка */}
              {card.priority === "low" && (
                <img
                  src="/static/icons/low.svg"
                  alt="Low Priority"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              )}
              {card.priority === "medium" && (
                <img
                  src="/static/icons/medium.svg"
                  alt="Medium Priority"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              )}
              {card.priority === "high" && (
                <img
                  src="/static/icons/hight.svg"
                  alt="High Priority"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              )}
              {/* {!card.priority && (
                <img
                  src="/static/icons/no_priority.svg"
                  alt="No Priority"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              )} */}
            </Box>
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: 1,
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                mt: 2,
                "& svg:not(:first-of-type)": {
                  ml: 2,
                },
              }}
            >
              {completePercentage > 0 && (
                <CircularProgress
                  size={25}
                  variant="determinate"
                  value={completePercentage}
                  color={setProgressColor()}
                />
              )}
              {card.attachments.length > 0 && (
                <>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ ml: 1 }}
                  >
                    {card.attachments.length}
                  </Typography>
                  <DocumentTextIcon fontSize="small" />
                </>
              )}
              {card.comments.length > 0 && (
                <>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ ml: 1 }}
                  >
                    {card.comments.length}
                  </Typography>
                  <ChatAltIcon fontSize="small" />
                </>
              )}
              {policy_actions.includes("copy") && !isDodavatel && (
                <CopyVacancy
                  description={card.description}
                  name={card.name}
                  amount={card.employeeNeeded}
                  profession={card.profession}
                  customer_id={card.columnId}
                  salary={card.salary}
                  bonus={card.bonus}
                  shift={card.shift}
                  citizenship={card.citizenship}
                  sex={card.sex}
                  age={card.age}
                  languages={card.languages}
                />
              )}
            </Box>
            <Box sx={{}}>
              <Typography color="textPrimary" variant="subtitle2">
                {t("Nástup")}: {format(new Date(card.due), " dd.LL")}
              </Typography>
              <Typography color="textPrimary" variant="subtitle2">
                {t("Pohovor")}: {format(new Date(card.interveiwDay), " dd.LL")}
              </Typography>
              <Typography color="textPrimary" variant="subtitle2">
                {t("Lidí")}: {approvedPeopleCount}/{card.employeeNeeded} (
                {card.checklists[0]?.checkItems?.length})
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <KanbanCardModal
        card={card}
        column={column}
        onClose={handleClose}
        open={open}
      />
    </Box>
  );
});

KanbanCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  dragging: PropTypes.bool,
  index: PropTypes.number,
  column: PropTypes.object.isRequired,
  style: PropTypes.object,
};

KanbanCard.defaultProps = {
  dragging: false,
};

export default KanbanCard;
