import UrlService from "../services/UrlService";

export default function scanPassport(file, handleResult, handleError) {
  const formData = new FormData();
  formData.append("file", file);
  fetch(UrlService.apiDomain() + "api/passport_scan", {
    method: "POST",
    body: formData,
  })
    .then((r) => r.json())
    .then((response) => {
      console.log("Response: ", response);

      if (response.ocr_error) {
        handleError(response.ocr_error);
        return;
      }

      let data = [];
      for (const [key, value] of Object.entries(response.ocr_result)) {
        data[key] = value;
      }
      if (response.number) {
        data["passport_number"] = response.number;
      }
      if (response.date_of_issue) {
        data["passport_issue"] = response.date_of_issue;
      }
      if (response.expiration_date) {
        data["passport_expiry"] = response.expiration_date;
      }

      let passport_path = response.passport_path;
      let photo_url = response.photo_url;

      console.log("Ocr raw result: ", response.raw);

      handleResult(data, passport_path, photo_url);
    })
    .catch((error) => {
      // Обработка ошибки при выполнении запроса
      handleError(error.message);
    });
}
