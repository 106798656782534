import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography, Popover, } from '@material-ui/core';
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import { useTranslation } from 'react-i18next';
import MUIRichTextEditor from "mui-rte";
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import { convertFromHTML, ContentState, convertToRaw, convertFromRaw } from 'draft-js'
export default forwardRef((props, ref) => {

    const { t } = useTranslation();
    //const inputRef = useRef();
    const column=props.column.colId;
    const orig_value=( props.data[column] ) ? props.data[column] : "";
    const [value, setValue] = useState(orig_value);
    const [rawValue, setRawValue] = useState('');
    const [open, setOpen] = useState(true);

    //var optionsArray = [];
    console.log(open);
    var locale = 'cs';
    if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');



    function getVal(e) {


            return e;


    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {

                return value;
            },
            afterGuiAttached: async() => {

                setValue(orig_value);

            },

            isPopup: () => {
                return true;
            }
        };
    });

    console.log(props);
    console.log(value);

    const contentHTML = convertFromHTML(value);
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
    const content = JSON.stringify(convertToRaw(state));

 const save=(data)=>{
     setValue(stateToHTML(convertFromRaw(JSON.parse(data))));

 }
    return (

        <Box
            sx={{
                paddingBottom:4,
                backgroundColor: 'background.paper',
                minHeight: '100%',
                p: 3,
                width:700
            }}
        >
            <Box
                sx={{
                    marginBottom:4,
                }}
                onKeyDown={(e) => {

                    if (e.key === "Enter") {
                        e.stopPropagation();
                    }
                }}
            >
            <MUIRichTextEditor


                label={t('Sem napište text...')}
                defaultValue={content}
                onSave={save}
                onChange={(event)=>{
                  //  setValue(stateToHTML(event.getCurrentContent()));
                   //+
                   //  setRawValue(event)
                }}
                inlineToolbar={true}
            />
        </Box>
        </Box>

    )


})
