import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Grid,
  Typography,
  Select,
  MenuItem,
  LinearProgress,
  Button,
  Dialog,
} from "@material-ui/core";
import useFilter from "../../../hooks/useFilter";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.js";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import LocaleRu from "../../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";
import { LicenseManager } from "ag-grid-enterprise";
import JsxRenderer from "../../../aggrid/JsxRenderer";
import useSettings from "../../../hooks/useSettings";
import AddDownpaymentRequest from "./AddDownpaymentRequest";

LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);
const DownpaymentsRequests = (props) => {
  const { t } = useTranslation();
  const filter = useFilter();
  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");

  const customComparator = (valueA, valueB) => {
    if (valueA === null || valueB === null) {
      return valueA === valueB ? 0 : valueA === null ? -1 : 1;
    }
    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else {
      // return default value for non-string and non-number values
      return 0;
    }
  };
  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).downpayments;

  const monthNames = [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec",
  ];
  const month_list = [];

  for (let i = 1; i <= 12; i++) {
    month_list.push(
      <MenuItem key={i} value={i}>
        {t(monthNames[i - 1])}
      </MenuItem>
    );
  }

  const monthes = [];
  for (let i = 1; i <= 12; i++) {
    monthes.push(i);
  }

  const year_list = [];

  for (let i = -5; i <= 1; i++) {
    var d = new Date();
    const y = d.getYear() + i + 1900;
    year_list.push(
      <MenuItem key={y} value={y}>
        {y}
      </MenuItem>
    );
  }

  const valuesList = [
    <MenuItem key={"all"} value={"all"}>
      {t("Vše")}
    </MenuItem>,
    <MenuItem key={"active"} value={"active"}>
      {t("Aktivní")}
    </MenuItem>,
    <MenuItem key={"archived"} value={"archived"}>
      {t("Archiv")}
    </MenuItem>,
  ];
  const { settings } = useSettings();

  const [rowdata, setRowdata] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState(null);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getYear() + 1900);
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("active");
  const [filteredData, setFilteredData] = useState([]);
  const [sending, setSending] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let filterYear = filter.getYearFilter();
    let filterMonth = filter.getMonthFilter() && filter.getMonthFilter() - 1;

    if (filterYear) setYear(filterYear);
    if (filterMonth) setMonth(filterMonth);
  }, [filter]);

  useEffect(() => {
    switch (selectedFilter) {
      case "all":
        setFilteredData(rowdata);
        break;
      case "active":
        setFilteredData(rowdata.filter((item) => !item.approved_at));
        break;
      case "archived":
        setFilteredData(rowdata.filter((item) => item.approved_at));
    }
  }, [selectedFilter, rowdata]);

  const colDefs = [
    {
      headerName: "#",
      valueGetter: (params) => {
        return params.node.rowIndex + 1;
      },
      lockPosition: "left",
      pinned: "left",
      maxWidth: 120,
      sortable: false,
      resizable: false,
      suppressMenu: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: t("Uživatel"),
      field: "customer_name",
    },
    {
      headerName: t("Hodiny koordinatora"),
      field: "hours",
    },
    {
      headerName: t("Jméno"),
      field: "name",
    },
    {
      headerName: t("Příjmení"),
      field: "surname",
    },
    {
      headerName: t("Povolená záloha"),
      field: "availableSum",
    },
    {
      headerName: t("Zálohy tento měsíc"),
      field: "allPayedDownpayments",
    },
    {
      headerName: t("Záloha"),
      field: "amount",
      editable: (params) => {
        return !params.data.approved_at;
      },
    },
    {
      headerName: t("Měsíc"),
      field: "month",
      editable: (params) => {
        return (
          policy_actions.includes("employee_report_edit") &&
          !params.data.approved_at
        );
      },

      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: monthes,
      },
    },
    {
      headerName: t("Rok"),
      field: "year",
    },
    {
      headerName: t("Schválil"),
      field: "approved_by",
    },
    {
      headerName: t("Schváleno"),
      field: "approved_at",
    },
    {
      headerName: t("Vytvořeno"),
      field: "created",
    },
    {
      headerName: t("Upraveno"),
      field: "updated_at",
    },
  ];

  if (policy_actions.includes("employee_report_delete")) {
    colDefs.push({
      headerName: t("Odstranit"),
      cellRenderer: "DeleteRenderer",
      minWidth: 90,
      cellRendererParams: {
        gridType: "delete-downpayment-request",
        setRowData: setRowdata,
      },
      suppressMenu: true,
    });
  }

  const defaultColDef = {
    flex: 1,
    minWidth: 80,
    editable: false,
    resizable: true,
    sortable: true,
    menuTabs: ["filterMenuTab", "columnsMenuTab"],
    filter: true,
    comparator: customComparator,
  };

  const frameworkComponents = {
    JsxRenderer: JsxRenderer,
    DeleteRenderer: DeleteRenderer,
  };

  const gtr = () => {
    setLoading(true);
    HttpService.get(
      UrlService.apiDomain() +
        `api/get-downpayment-requests/${year}/${month}/${
          props.employeeId ?? null
        }`
    )
      .then((res) => {
        setRowdata(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    gtr();
  }, [month, year]);

  useEffect(() => {
    setloctext(lt);
    setColumnDefs(colDefs);
  }, [t("cs")]);

  const onCellValueChanged = (event) => {
    event.data.field = event.colDef.field;
    HttpService.post(UrlService.apiDomain() + "api/edit-downpayment-request", {
      data: event.data,
    })
      .then((res) => {
        if (res.status != 200) {
          alert(
            t("Chyba serveru, prosím zkuste pozdějí") +
              ". " +
              t(res.data.message)
          );
          event.node.data[event.data.field] = event.oldValue;
          event.node.setData(event.node.data);
        } else if (res.data.result == "failed") {
          alert(t(res.data.message));
          event.node.data[event.data.field] = event.oldValue;
          event.node.setData(event.node.data);
        } else {
          console.log("Successfully updated");
          event.node.setData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleApprove = async () => {
    setSending(true);
    const data = selectedRows.map((item) => item.id);
    try {
      const response = await HttpService.post(
        `${UrlService.apiDomain()}api/approve-downpayment-request`,
        data
      );
      if (response.status !== 200) {
        let words = response.data.message.split(" ");
        var name = words.slice(0, 2).join(" ");
        var restOfText = words.slice(2).join(" ");

        alert(`${name} ${t(restOfText)}`);
        setSending(false);
        return;
      } else {
        setSending(false);
        gtr();
      }
    } catch (error) {
      setSending(false);
      console.log(error);
    }
  };

  const getRowStyle = (params) => {
    if (params.data.approved_at) {
      return { background: "#dcfcdc" };
    }
  };

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);
  };

  return (
    <>
      <Helmet>
        <title>Požadavky zálohy | AWA Partners</title>
      </Helmet>
      <Dialog onClose={handleClose} open={open}>
        <AddDownpaymentRequest
          handleClosePopover={handleClose}
          getRowdata={gtr}
          month={month}
          year={year}
        />
      </Dialog>
      <Box
        sx={{
          backgroundColor: "background.default",
          height: "100%",
          py: 4,
        }}
      >
        <Container
          maxWidth={settings.compact ? "xl" : false}
          style={props.style ?? { width: "100%", height: "100%" }}
        >
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                <Typography sx={{ m: 1 }} color="textPrimary" variant="h5">
                  {t("Požadavky zálohy")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              {policy_actions.includes("employee_report_create") && (
                <Button
                  sx={{ mx: 1 }}
                  color="primary"
                  variant="contained"
                  onClick={handleOpen}
                >
                  {t("Požádat o zálohu")}
                </Button>
              )}
              {policy_actions.includes("employee_report_approve") && (
                <Button
                  disabled={!selectedRows.length || sending}
                  sx={{ mx: 1 }}
                  color="primary"
                  variant="contained"
                  onClick={handleApprove}
                >
                  {t("Schválit")}
                </Button>
              )}
              <Select
                sx={{ m: 1 }}
                size="small"
                onChange={(event, value) => {
                  setSelectedFilter(value.props.value);
                }}
                value={selectedFilter}
                id="archive_select"
              >
                {valuesList}
              </Select>
              <Select
                sx={{ m: 1 }}
                size="small"
                onChange={(event, value) => {
                  setMonth(value.props.value);
                }}
                value={month}
                id="month_select"
              >
                {month_list}
              </Select>
              <Select
                sx={{ m: 1 }}
                size="small"
                onChange={(event, value) => {
                  setYear(value.props.value);
                }}
                value={year}
                id="year_select"
              >
                {year_list}
              </Select>
              <Button
                color="primary"
                variant="contained"
                title={t("Excel")}
                onClick={() => gridApi.exportDataAsExcel()}
              >
                Excel
              </Button>
            </Grid>
          </Grid>
          <Box
            sx={{ mt: 3 }}
            style={{ width: "100%", height: "calc(100% - 100px)" }}
            id="myGrid"
            className="ag-theme-material"
          >
            {loading && <LinearProgress />}
            <AgGridReact
              rowData={filteredData}
              getRowStyle={getRowStyle}
              modules={AllCommunityModules}
              rowSelection={"multiple"}
              columnDefs={columnDefs}
              onCellValueChanged={onCellValueChanged}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onSelectionChanged={() =>
                setSelectedRows(gridApi.getSelectedRows())
              }
              frameworkComponents={frameworkComponents}
              enableCellChangeFlash={true}
              suppressContextMenu={true}
              localeText={loctext}
              popupParent={document.querySelector("body")}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default DownpaymentsRequests;
