import ModalWrapper from "../modal/ModalWrapper";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import {Button, TextField} from "@material-ui/core";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import React, {useState} from "react";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import {useTranslation} from "react-i18next";

const localeMap = {
    ru: ruLocale,
    cs: csLocale,
};

export default function DateModal({isOpen, onSubmit, onClose}){
    const {t} = useTranslation();
    const [locale] = useState(t('cs'));
    const [date, setDate] = React.useState(null);

    return <ModalWrapper isOpen={isOpen} onClose={onClose} size={'xs'}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
            <MobileDatePicker
                value={date}
                label={'Date'}
                fullWidth
                mask="__.__.____"
                openTo="year"
                clearable
                onChange={(val) => {
                    setDate(val);
                }}
                renderInput={(params) => {
                    if (date == null) {
                        params.value = 'teď';
                        params.inputProps.value = 'teď';
                    }
                    return <TextField
                        fullWidth
                        variant={"outlined"}
                        {...params}
                    />
                }}
            />
        </LocalizationProvider>
        <Button fullWidth size={'small'} sx={{mt:1}} variant='contained' onClick={() => {
            let formattedDate = date ? `${date.getYear() + 1900}-${date.getMonth() + 1}-${date.getDate()}` : null;
            onSubmit(formattedDate)
        }}>Save</Button>
    </ModalWrapper>
}