import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import toast from "react-hot-toast";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import {
  Grid,
  TextField,
  FormControl,
  Alert,
  Button,
  Box,
  Container,
  Divider,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import Map from "./map/index.js";
import GoogleAutocompleteForMap from "./map/GoogleAutocompleteForMap.js";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { useJsApiLoader } from "@react-google-maps/api";
import { geocode, RequestType } from "react-geocode";

const libraries = ["places"];
const GOOGLE_MAPS_API_KEY = "AIzaSyCYuKAtPxFF9PSdonVF-CUo_29BIRjOCfg";

const MainForm = ({
  data,
  customerLegalData,
  onClose,
  onAdd,
  getData,
  filials,
  grd,
  setCustomerId,
}) => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const { data: contextData, updateData } = useContext(DataContext);
  const userId = JSON.parse(localStorage.getItem("my_employee")).id;

  const policyActions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).customers;

  const canEditOwner = policyActions.includes("edit_owner");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const thp_users = contextData.employees_sale_managers
    // .filter((item) => !item.ukonceni_pp)
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  const countries = contextData.countries;

  const phases = contextData.customer_states;

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isExist, setIsExist] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 50.0875, lng: 14.421 });
  const [addressComponents, setAddressComponents] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState("error");
  const [selectedFilial, setSelectedFilial] = useState(data?.id);

  const getPlaceIdFromAddress = async (address) => {
    try {
      const response = await geocode(RequestType.ADDRESS, address, {
        key: "AIzaSyCYuKAtPxFF9PSdonVF-CUo_29BIRjOCfg",
        language: "cs",
      });
      return response.results[0].place_id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getAdressPart = (part) => {
    let keys;
    if (part === "post_code") {
      keys = ["postal_code"];
    } else if (part === "city") {
      keys = ["locality", "postal_town", "sublocality"];
    } else if (part === "building") {
      keys = ["premise"];
    } else if (part === "street") {
      keys = ["route"];
    } else if (part === "municipality") {
      keys = ["administrative_area_level_2", "administrative_area_level_1"];
    }

    let result = "";
    addressComponents.forEach((comp) => {
      if (keys.some((key) => comp.types.includes(key))) {
        result = comp.long_name;
      }
    });
    return result;
  };

  const getAddressFromPlaceId = async (id) => {
    try {
      const response = await geocode(RequestType.PLACE_ID, id, {
        key: "AIzaSyCYuKAtPxFF9PSdonVF-CUo_29BIRjOCfg",
        language: "cs",
      });
      return response.results[0].formatted_address;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const formik = useFormik({
    initialValues: {
      company_name: customerLegalData?.name ?? "",
      name: data?.name ?? "",
      oid: customerLegalData?.oid ?? "",
      tin: customerLegalData?.tin ?? "",
      owner_id: data ? data?.owner_id ?? "" : userId,
      employee_ids: data?.employee_ids ?? [],
      address_street: customerLegalData?.address_street ?? "",
      address_zip: customerLegalData?.address_zip ?? "",
      address_district: customerLegalData?.address_district ?? "",
      address_number: customerLegalData?.address_orientation_number ?? "",
      // file_number: "",
      address_country: customerLegalData?.address_country ?? "43",
      //   tags: [],
      web: data?.web ?? "",
      phase: data?.phase ?? "1",
      active: data?.active > 0 ? true : false,
      contact_name: "",
      contact_phone: "",
      main_contact_id: data?.main_contact_id ?? "",
      place_id: "",
      photos: [],
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required(t("Povinné")),
      name: Yup.string(),
      oid: Yup.string().required(t("Povinné")),
      place_id: Yup.string().required(t("Povinné")),
      owner_id: Yup.string().required(t("Povinné")),
      main_contact_id: Yup.string(),
      tin: Yup.string(),
      address_street: Yup.string(),
      address_zip: Yup.string(),
      address_district: Yup.string(),
      address_number: Yup.string(),
      // file_number: Yup.string(),
      address_country: Yup.string(),
      //   tags: Yup.array(),
      web: Yup.string(),
      phase: Yup.string(),
      employee_ids: Yup.array(),
      active: Yup.boolean(),
      contact_name:
        data || selectedFilial
          ? Yup.string()
          : Yup.string().required(t("Povinné")),
      contact_phone:
        data || selectedFilial
          ? Yup.string()
          : Yup.string().required(t("Povinné")),
      photos: Yup.array(),
    }),
    onSubmit: async (values) => {
      const filialData = {
        name: values.name,
        owner_id: values.owner_id.toString(),
        employee_ids: values.employee_ids,
        address_street: getAdressPart("street"),
        address_zip: getAdressPart("post_code"),
        address_district: getAdressPart("city"),
        address_number: getAdressPart("building"),
        address_municipality: getAdressPart("municipality"),
        address_country: values.address_country,
        web: values.web,
        phase: values.phase,
        active: values.active,
        main_contact_id: values.main_contact_id,
        photos: values.photos,
        place_id: await getPlaceIdFromAddress(values.place_id),
        id: data?.id ?? "",
      };

      const legalData = {
        name: values.company_name,
        oid: values.oid,
        tin: values.tin,
        photos: values.photos,
        address_country: values.address_country,
        address_street: values.address_street,
        address_zip: values.address_zip,
        address_district: values.address_district,
        address_number: values.address_number,
      };

      if (data) {
        try {
          const response = await HttpService.post(
            `${UrlService.apiDomain()}api/customers/edit`,
            { data: filialData, data_customer_legal: legalData }
          );
          if (response.data.success == false) {
            if (response.data.message) {
              setAlertMessage(response.data.message);
              setAlertSeverity("error");
              setShowAlert(true);
            }

            toast.error(t("Něco se pokazilo!"));
            return;
          } else {
            getData("customers", true);
            toast.success(t("Záznam obnoven"));
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          if (!values.name) {
            filialData.name = `${legalData.name} ${getAdressPart("city")}`;
          }
          const response = await HttpService.post(
            `${UrlService.apiDomain()}api/customers/create`,
            { data: filialData, data_customer_legal: legalData }
          );
          if (response.data.success == false) {
            if (response.data.message) {
              setAlertMessage(response.data.message);
              setAlertSeverity("error");
              setShowAlert(true);
            }

            toast.error(t("Něco se pokazilo!"));
            return;
          } else {
            toast.success(t("Záznam obnoven"));
            updateData();
            !formik.values.main_contact_id && addContact(response.data.data.id);
            onClose();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const contacts =
    data || formik.values.main_contact_id
      ? contextData.customer_contacts
          .filter(
            (cont) =>
              cont.customer == data?.id || cont.customer == selectedFilial
          )
          .filter((item) => !item.deleted)
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      : [];

  useEffect(() => {
    if (data?.place_id) {
      getAddressFromPlaceId(data.place_id)
        .then((formattedAddress) => {
          formik.setFieldValue("place_id", formattedAddress);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [data?.place_id]);

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const handleSearch = async () => {
    // if (!formik.values.oid) {
    //   setAlertMessage("Nejprve vyplňte IČO");
    //   setShowAlert(true);
    //   return;
    // }
    try {
      const response = await HttpService.get(
        `${UrlService.apiDomain()}api/customer_registry_records/get/by_oid/${
          formik.values.oid
        }`
      );
      if (response.data.success == false) {
        if (response.data.message) {
          setAlertMessage(response.data.message);
          setAlertSeverity("error");
          setShowAlert(true);
        }

        toast.error(t("Něco se pokazilo!"));
        return;
      } else if (response.data.data == null) {
        setAlertMessage("Pro toto číslo nejsou žádná data v registru");
        setAlertSeverity("info");
        setShowAlert(true);
      } else {
        const data = response.data.data;
        formik.setFieldValue(`address_zip`, data.address_zip ?? "");
        formik.setFieldValue(`address_district`, data.address_district ?? "");
        formik.setFieldValue(`company_name`, data.name ?? "");
        formik.setFieldValue(
          `address_street`,
          `${data.address_street ?? ""} ${data.address_number ?? ""}`
        );
        // formik.setFieldValue("name", data.name);
        // if (!formik.values.name.trim()) {
        //   formik.setFieldValue("name", data.name);
        // }
        toast.success(t("Záznam obnoven"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleIcoCheck = async () => {
    try {
      let place_id = await getPlaceIdFromAddress(formik.values.place_id);
      const response = await HttpService.get(
        `${UrlService.apiDomain()}api/customers/get/by_country_and_oid_and_place_id/${
          formik.values.address_country
        }/${formik.values.oid}/${place_id}`
      );
      if (response.data.success == false) {
        if (response.data.message) {
          setAlertMessage(response.data.message);
          setAlertSeverity("error");
          setShowAlert(true);
          return;
        }

        toast.error(t("Něco se pokazilo!"));
        return;
      } else if (response.data.data == null) {
        setIsExist(true);
        handleCheckCompanyAtBase();
      } else {
        // let ownerId = response.data.data.owner_id;
        // let ownerName = response.data.data_employees[ownerId]?.name ?? "";
        // let ownerSurname = response.data.data_employees[ownerId]?.surname ?? "";
        // setAlertMessage(
        //   `${t("Takový uživatel je již v databázi registrován")} ${
        //     ownerName ? "za " + ownerName + " " + ownerSurname : ""
        //   }`
        // );
        // setAlertSeverity("error");
        // setShowAlert(true);
        setIsExist(true);
        const data = response.data.data;
        setSelectedFilial(data.id);
        const legalData = response.data.data_customer_legal;
        formik.setFieldValue(`address_zip`, legalData.address_zip ?? "");
        formik.setFieldValue(`tin`, legalData.tin ?? "");
        formik.setFieldValue(
          `address_district`,
          legalData.address_district ?? ""
        );
        formik.setFieldValue(`company_name`, legalData.name ?? "");
        formik.setFieldValue(`name`, data.name ?? "");
        formik.setFieldValue(
          `address_street`,
          `${legalData.address_street ?? ""}`
        );
        formik.setFieldValue(
          `address_number`,
          `${legalData.address_number ?? ""}`
        );
        formik.setFieldValue(`phase`, `${data.phase ?? ""}`);
        formik.setFieldValue(`employee_ids`, data.employee_ids ?? []);
        formik.setFieldValue(
          `main_contact_id`,
          `${data.main_contact_id ?? ""}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckCompanyAtBase = async () => {
    try {
      const response = await HttpService.get(
        `${UrlService.apiDomain()}api/customer_legals/get/by_country_and_oid/${
          formik.values.address_country
        }/${formik.values.oid}`
      );
      if (response.data.success == false) {
        if (response.data.message) {
          setAlertMessage(response.data.message);
          setAlertSeverity("error");
          setShowAlert(true);
        }

        toast.error(t("Něco se pokazilo!"));
        return;
      } else if (response.data.data == null) {
        handleSearch();
      } else {
        const data = response.data.data;
        formik.setFieldValue(`address_zip`, data.address_zip ?? "");
        formik.setFieldValue(`tin`, data.tin ?? "");
        formik.setFieldValue(`address_district`, data.address_district ?? "");
        formik.setFieldValue(`company_name`, data.name ?? "");
        formik.setFieldValue(`address_street`, `${data.address_street ?? ""}`);
        formik.setFieldValue(`address_number`, `${data.address_number ?? ""}`);
        // formik.setFieldValue("name", data.name);
        // if (!formik.values.name.trim()) {
        //   formik.setFieldValue("name", data.name);
        // }
        toast.success(t("Záznam obnoven"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addContact = async (customer) => {
    const data = {
      customer,
      name: formik.values.contact_name,
      phone: formik.values.contact_phone,
      tags: [],
    };
    try {
      const response = await HttpService.post(
        `${UrlService.apiDomain()}api/customer_contacts/create`,
        { data: data }
      );
      if (response.status != 200) {
        if (response.data.message) {
          setAlertMessage(response.data.message);
          setAlertSeverity("error");
          setShowAlert(true);
        }

        toast.error(t("Něco se pokazilo!"));
        return;
      } else {
        setMainContact(response.data.data.id, customer);
        //  toast.success(t("Záznam obnoven"));
        //  handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setMainContact = async (contact_id, customer) => {
    const data = {
      main_contact_id: contact_id,
      id: customer,
    };
    try {
      const response = await HttpService.post(
        `${UrlService.apiDomain()}api/customers/edit`,
        { data: data }
      );
      if (response.status != 200) {
        if (response.data.message) {
          setAlertMessage(response.data.message);
          setAlertSeverity("error");
          setShowAlert(true);
        }

        toast.error(t("Něco se pokazilo!"));
        return;
      } else {
        onAdd(response.data.data);
        //  toast.success(t("Záznam obnoven"));
        //  handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      setIsExist(true);
    }
  }, [data]);

  useEffect(() => {
    if (formik.values.place_id) {
      geocode(RequestType.ADDRESS, formik.values.place_id, {
        key: "AIzaSyCYuKAtPxFF9PSdonVF-CUo_29BIRjOCfg",
        language: "cs",
      })
        .then((response) => {
          let coord = response.results[0].geometry.location;
          setAddressComponents(response.results[0].address_components);
          setMapCenter({ ...coord });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [formik.values.place_id]);

  const handleFilialChange = (e) => {
    setCustomerId(e.target.value);
    setSelectedFilial(e.target.value);
    grd(e.target.value);
  };

  useEffect(() => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      name: data?.name ?? "",
      employee_ids: data?.employee_ids ?? [],
      address_street: customerLegalData?.address_street ?? "",
      address_zip: customerLegalData?.address_zip ?? "",
      address_district: customerLegalData?.address_district ?? "",
      address_number: customerLegalData?.address_number ?? "",
      owner_id: data ? data?.owner_id ?? "" : userId,
      web: data?.web ?? "",
      phase: data?.phase ?? "1",
      active: data?.active > 0 ? true : false,
      main_contact_id: data?.main_contact_id ?? "",
    }));
  }, [data, customerLegalData]);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
      }}
    >
      <Container>
        <Box sx={{ p: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <Divider
              sx={{
                mb: "25px",
                color: "#5664d2",
                fontSize: "18px",
                fontWeight: 500,
              }}
              variant={"fullWidth"}
              textAlign="center"
            >
              {t("Právnická osoba")}
            </Divider>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus={data ? false : true}
                  name="oid"
                  label={t("IČO")}
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oid}
                  error={!!(formik.touched.oid && formik.errors.oid)}
                  helperText={formik.touched.oid && formik.errors.oid}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton
                  //         onClick={!data ? handleIcoCheck : handleSearch}
                  //       >
                  //         <SearchIcon />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // onKeyDown={(e) => {
                  //   if (e.key === "Enter") {
                  //     e.preventDefault(); // Чтобы предотвратить стандартное поведение Enter (submit формы)
                  //     !data ? handleIcoCheck() : handleSearch();
                  //   }
                  // }}
                />
              </Grid>
              {isExist && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={!isExist}
                    name="company_name"
                    label={t("Název společnosti")}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company_name}
                    error={
                      !!(
                        formik.touched.company_name &&
                        formik.errors.company_name
                      )
                    }
                    helperText={
                      formik.touched.company_name && formik.errors.company_name
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={!isExist ? 6 : 4}>
                <FormControl fullWidth>
                  <Autocomplete
                    id={`address_country`}
                    name={`address_country`}
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      formik.setFieldValue(`address_country`, value?.id);
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      countries.find(
                        (item) => item.id == formik.values.address_country
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Země")}
                        error={
                          !!(
                            formik.touched.address_country &&
                            formik.errors.address_country
                          )
                        }
                        helperText={
                          formik.touched.address_country &&
                          formik.errors.address_country
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {isExist && (
                <>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled={!isExist}
                      name="tin"
                      label={t("DIČ")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.tin}
                      error={!!(formik.touched.tin && formik.errors.tin)}
                      helperText={formik.touched.tin && formik.errors.tin}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled={!isExist}
                      name="web"
                      label={t("WEB")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.web}
                      error={!!(formik.touched.web && formik.errors.web)}
                      helperText={formik.touched.web && formik.errors.web}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      disabled={!isExist}
                      name="address_street"
                      label={t("Ulice")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address_street}
                      error={
                        !!(
                          formik.touched.address_street &&
                          formik.errors.address_street
                        )
                      }
                      helperText={
                        formik.touched.address_street &&
                        formik.errors.address_street
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      disabled={!isExist}
                      name="address_number"
                      label={t("Číslo domu")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address_number}
                      error={
                        !!(
                          formik.touched.address_number &&
                          formik.errors.address_number
                        )
                      }
                      helperText={
                        formik.touched.address_number &&
                        formik.errors.address_number
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      disabled={!isExist}
                      name="address_zip"
                      label={t("PSČ")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address_zip}
                      error={
                        !!(
                          formik.touched.address_zip &&
                          formik.errors.address_zip
                        )
                      }
                      helperText={
                        formik.touched.address_zip && formik.errors.address_zip
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      disabled={!isExist}
                      name="address_district"
                      label={t("Město")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address_district}
                      error={
                        !!(
                          formik.touched.address_district &&
                          formik.errors.address_district
                        )
                      }
                      helperText={
                        formik.touched.address_district &&
                        formik.errors.address_district
                      }
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12}>
                <Divider
                  sx={{
                    mb: "25px",
                    color: "#5664d2",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                  variant={"fullWidth"}
                  textAlign="center"
                >
                  {t("Pobočka")}
                </Divider>
              </Grid>
              {isExist && (
                <Grid item xs={12} sm={filials?.length > 1 ? 4 : 6}>
                  <TextField
                    disabled={!isExist}
                    name="name"
                    label={t("Název")}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={!!(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={!isExist ? 12 : filials?.length > 1 ? 4 : 6}
              >
                <GoogleAutocompleteForMap
                  // disabled={isExist}
                  isLoaded={isLoaded}
                  name="place_id"
                  onChange={(val) => {
                    formik.setFieldValue("place_id", val);
                  }}
                  value={formik.values.place_id}
                  label={t("Adresa výkonu práce")}
                  onBlur={formik.handleBlur}
                  error={!!(formik.touched.place_id && formik.errors.place_id)}
                  helperText={formik.touched.place_id && formik.errors.place_id}
                />
              </Grid>
              {isExist && filials?.length > 1 && (
                <Grid item xs={12} sm={4}>
                  <Select
                    fullWidth
                    id="filials"
                    name="filials"
                    value={selectedFilial}
                    onChange={handleFilialChange}
                  >
                    {filials.map((filial) => (
                      <MenuItem key={filial.id} value={filial.id}>
                        {t(filial.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
            </Grid>
            {showAlert && (
              <Alert
                sx={{ my: 2 }}
                severity={alertSeverity}
                onClose={handleAlertClose}
                open={showAlert}
              >
                {alertMessage}
              </Alert>
            )}
            <Map сenter={mapCenter} isLoaded={isLoaded} />
            {isExist && (
              <>
                <Divider
                  sx={{
                    my: "25px",
                    color: "#5664d2",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                  variant={"fullWidth"}
                  textAlign="center"
                >
                  {t("Lidí")}
                </Divider>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disabled={!canEditOwner}
                        id={`owner_id`}
                        name={`owner_id`}
                        options={thp_users}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                          formik.setFieldValue(`owner_id`, value?.id);
                        }}
                        onBlur={formik.handleBlur}
                        value={
                          thp_users.find(
                            (item) => item.id == formik.values.owner_id
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Přiřazený manažer")}
                            error={
                              !!(
                                formik.touched.owner_id &&
                                formik.errors.owner_id
                              )
                            }
                            helperText={
                              formik.touched.owner_id && formik.errors.owner_id
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disabled={!isExist}
                        multiple
                        id={`employee_ids`}
                        name={`employee_ids`}
                        options={thp_users}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, values) => {
                          formik.setFieldValue(
                            `employee_ids`,
                            values.map((value) => value.id.toString())
                          );
                        }}
                        onBlur={formik.handleBlur}
                        value={
                          thp_users.filter((item) =>
                            formik.values.employee_ids.includes(
                              item.id.toString()
                            )
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Spolupracující manažeři")}
                            error={
                              !!(
                                formik.touched.employee_ids &&
                                formik.errors.employee_ids
                              )
                            }
                            helperText={
                              formik.touched.employee_ids &&
                              formik.errors.employee_ids
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {(data || formik.values.main_contact_id) && (
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id={`main_contact_id`}
                          name={`main_contact_id`}
                          options={contacts}
                          getOptionLabel={(option) =>
                            `${option.name}, ${option.phone}`
                          }
                          onChange={(e, value) => {
                            formik.setFieldValue(`main_contact_id`, value?.id);
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            contacts.find(
                              (item) => item.id == formik.values.main_contact_id
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Hlavní kontakt")}
                              error={
                                !!(
                                  formik.touched.main_contact_id &&
                                  formik.errors.main_contact_id
                                )
                              }
                              helperText={
                                formik.touched.main_contact_id &&
                                formik.errors.main_contact_id
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                {!data && !formik.values.main_contact_id && (
                  <>
                    <Divider
                      sx={{
                        my: "25px",
                        color: "#5664d2",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                      variant={"fullWidth"}
                      textAlign="center"
                    >
                      {t("Hlavní kontakt")}
                    </Divider>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={!isExist}
                          name="contact_name"
                          label={t("Jméno / Název")}
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_name}
                          error={
                            !!(
                              formik.touched.contact_name &&
                              formik.errors.contact_name
                            )
                          }
                          helperText={
                            formik.touched.contact_name &&
                            formik.errors.contact_name
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={!isExist}
                          name="contact_phone"
                          label={t("Telefon")}
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_phone}
                          error={
                            !!(
                              formik.touched.contact_phone &&
                              formik.errors.contact_phone
                            )
                          }
                          helperText={
                            formik.touched.contact_phone &&
                            formik.errors.contact_phone
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Divider
                  sx={{
                    my: "25px",
                    color: "#5664d2",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                  variant={"fullWidth"}
                  textAlign="center"
                >
                  {t("Stav")}
                </Divider>
                <Grid item container spacing={2}>
                  {/* <Grid item xs={12} sm={4}>
                  <TextField
                    disabled={!isExist}
                    name="file_number"
                    label={t("Spisová značka")}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.file_number}
                    error={
                      !!(formik.touched.file_number && formik.errors.file_number)
                    }
                    helperText={
                      formik.touched.file_number && formik.errors.file_number
                    }
                  />
                </Grid> */}
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disabled={!canEditOwner}
                        id={`phase`}
                        name={`phase`}
                        options={phases}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                          formik.setFieldValue(`phase`, value?.id);
                        }}
                        onBlur={formik.handleBlur}
                        value={
                          phases.find(
                            (item) => item.id == formik.values.phase
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Obchodní fáze")}
                            error={
                              !!(formik.touched.phase && formik.errors.phase)
                            }
                            helperText={
                              formik.touched.phase && formik.errors.phase
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid alignSelf={"center"} item xs={12} sm={6}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FormControlLabel
                      disabled={isExist}
                      label={t("Aktivní")}
                      control={
                        <Checkbox
                          id="active"
                          name="active"
                          type="checkbox"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.active}
                        />
                      }
                    />
                  </Box>
                </Grid> */}
                </Grid>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                mt: 1,
              }}
            >
              {!isExist ? (
                <Button
                  disabled={
                    !formik.values.oid ||
                    !formik.values.address_country ||
                    !formik.values.place_id
                  }
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleIcoCheck}
                >
                  {t("Najít společnost")}
                </Button>
              ) : (
                <Button type="submit" variant="contained" color="primary">
                  {t("Uložit")}
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default MainForm;
