"use strict";


import JSONRPCRequestT from "../../../../Type/JSONRPC/JSONRPCRequestT.mjs";

import HTTPHeadersT from "../../../../Type/HTTP/Header/HTTPHeadersT.mjs";


const JSONRPCClientAbstractT = class JSONRPCClientAbstractT {
	
	constructor(

	){
		
	}
	
	Request(
		JSONRPCRequest,
		Headers,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( JSONRPCRequest instanceof JSONRPCRequestT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( Headers instanceof HTTPHeadersT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	Batch(
		JSONRPCRequestArray,
		Headers,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( JSONRPCRequestArray instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		for(
			let I = 0;
			I < JSONRPCRequestArray.length;
			I++
		){
			
			let JSONRPCRequest = JSONRPCRequestArray[ I ];
			
			if( ( JSONRPCRequest instanceof JSONRPCRequestT ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
		}
		
		if( ( Headers instanceof HTTPHeadersT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
};


export default JSONRPCClientAbstractT;