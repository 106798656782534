"use strict";


import DBAbstractT from "../DB/_/DBAbstractT.mjs";

import StorageAbstractT from "../../Procedure/Storage/_/StorageAbstractT.mjs";


const StorageT = class StorageT extends StorageAbstractT {
	
	#DB = null;
	
	constructor( 
		DB
	){
		
		if( ( DB instanceof DBAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super( );
		
		
		this.#DB = DB;
		
	}
	
	Get( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
	
		return this.#DB.Get(  
			Key
		);
	
	}
	
};


export default StorageT;