import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography,
  Checkbox,
} from "@material-ui/core";
import useAuth from "../../hooks/useAuth";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { ChangePaymentDetailsModal } from "./ChangePaymentDetailsModal";

import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";

const AccountPopover = () => {
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [emailChecked, setEmailChecked] = useState(!!user.email_send);
  const [smsChecked, setSmsChecked] = useState(!!user.sms_send);

  const user_roles_ids = user.role;
  const user_roles = JSON.parse(localStorage.getItem("roles")).filter((item) =>
    user_roles_ids.includes(item.id.toString())
  );

  const handleEmailCheckboxChange = async (event) => {
    setEmailChecked(event.target.checked);
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/send-notifications-status`,
        { id: user.id, send_email: event.target.checked ? 1 : 0 }
      );
      if (response.status != 200) {
        setEmailChecked(!event.target.checked);
        alert(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(process.env);

  const handleSmsCheckboxChange = async (event) => {
    setSmsChecked(event.target.checked);
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/send-notifications-status`,
        { id: user.id, send_sms: event.target.checked ? 1 : 0 }
      );
      if (response.status != 200) {
        setEmailChecked(!event.target.checked);
        alert(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      localStorage.clear();
      navigate("/");
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };

  let isDodavatel = !!user?.roles?.find((role) => {
    return parseInt(role.id) === 5;
  });

  //isDodavatel = true; //TODO delete

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user.email}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {user_roles.map((role) => (
            <Typography key={role.id} color="textPrimary" variant="subtitle2">
              {role.name}
            </Typography>
          ))}
        </Box>
        <Box sx={{ px: 2 }}>
          <ChangePasswordModal />
        </Box>

        {isDodavatel && (
          <Box sx={{ px: 2, mt: 2 }}>
            <ChangePaymentDetailsModal />
          </Box>
        )}

        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            {t("Odhlásit se")}
          </Button>
        </Box>
        <Box sx={{ px: 2 }}>
          <Checkbox
            checked={emailChecked}
            onChange={handleEmailCheckboxChange}
          />
          {t("E-mailová notifikace")}
        </Box>
        <Box sx={{ px: 2 }}>
          <Checkbox checked={smsChecked} onChange={handleSmsCheckboxChange} />
          {t("SMS notifikace")}
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
