"use strict";


const ViewPageAbstractT = class ViewPageAbstractT {

	constructor(
		
	){
		
	}
	
	//TODO
	
};


export default ViewPageAbstractT;