import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";
import useMounted from "../../../hooks/useMounted";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Google from "../../../icons/Google";

const LoginJWT = (props) => {
  const mounted = useMounted();
  const { login } = useAuth();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const GoogleAuthLink = function () {
    let RedirectURL = "https://db.awapartners.com";

    let ClientId =
      "399337867440-fn23hqam4sg82ftbvibaqq6ag3fpsldo.apps.googleusercontent.com";

    let LoginURL =
      "https://accounts.google.com/o/oauth2/auth?scope=" +
      encodeURIComponent(
        "https://www.googleapis.com/auth/userinfo.email" +
          " " +
          "https://www.googleapis.com/auth/userinfo.profile" +
          " " +
          "https://www.googleapis.com/auth/calendar"
      ) +
      "&redirect_uri=" +
      encodeURIComponent(RedirectURL) +
      "&response_type=code" +
      "&client_id=" +
      ClientId +
      "&access_type=offline";

    return LoginURL;
  };

  const handleGoogleAuth = () => {
    const authURL = GoogleAuthLink();
    window.location.href = authURL;
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .max(255)
          .required(t("email nebo telefon je povinný")),
        password: Yup.string().max(255).required(t("Heslo je povinné")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
            console.log("err", err);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            sx={{
              mb: 1,
            }}
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={t("Email nebo telefon")}
            margin="none"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            sx={{
              mt: 1,
            }}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={t("Heslo")}
            margin="none"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {t("Chybně zadané heslo nebo email")}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t("Přihlásit se")}
            </Button>

            <Button
              startIcon={<Google />}
              sx={{ mt: 2 }}
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              onClick={handleGoogleAuth}
              variant="outlined"
            >
              {t("Přihlaste se přes Google")}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
