"use strict";


const HTTPHeaderT = class HTTPHeaderT {
	
	#Key = null;
	
	#Value = null;
	
	
	constructor( 
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		this.#Key = Key;
		
		this.#Value = Value;
		
	}
	
	Key(
	
	){
		
		return this.#Key;
		
	}
	
	Value(
	
	){
		
		return this.#Value;
		
	}
	
};


export default HTTPHeaderT;