"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";

import MaterialUILab from "@material-ui/lab";

import MaterialUIIcons from "@material-ui/icons";

import AdapterDateFns from "@material-ui/lab/AdapterDateFns";


const UIDatetimeReactT = class UIDatetimeReactT extends React.Component {
	
	#IsValid = false;
	
	constructor( 
		Props
	){
		
		console.log( "UIDatetimeReactT.constructor", Props );
		
	
		super( Props );
		
	
		this.state = {
			Required: ( Props.Required ? Props.Required : false ),
			Label: ( Props.Label ? Props.Label : "" ),
			Name: ( Props.Name ? Props.Name : "" ),
			Value: Props.Value,
			Disabled: ( Props.Disabled ? Props.Disabled : false ),
			OnChange: ( Props.OnChange ? Props.OnChange : null ),
			OnCloseClick: ( Props.OnCloseClick ? Props.OnCloseClick : null )
		};
		
		
		this.Validation( Props.Value );
		
	}
	
	Validation( 
		Value
	){
		
		console.log( "UIDatetimeReactT.Validation", Value );


        if( Value === null ) {
			
            if( this.state.required === true ) {
				
                this.#IsValid = false;
            
			} else {

                this.#IsValid = true;
				
            }
			
        } else {
		
			if( ( Value instanceof Date ) === false ){
				
				this.#IsValid = false;
				
			} else if( Number.isNaN( Value.getTime( ) ) === true ){
				
				this.#IsValid = false;
				
			} else {
				
				this.#IsValid = true;
				
			}
		
		}
		
	}
	
	OnChangeCb(
		Value, 
	) {
		
		this.ValueSet( Value );
		
	}
	
	componentDidMount(
	
	){
		
		console.log( "UIDatetimeReactT.componentDidMount", this.state );
		
	}
	
	componentDidUpdate(
		PropsPrev,
		StatePrev
	){
		
		console.log( "UIDatetimeReactT.componentDidUpdate", this.state );
			
	}
				   
	componentWillUnmount(
	
	){
		
		console.log( "UIDatetimeReactT.componentWillUnmount", this.state );
		
	}
	
	ValueGet(
	
	){
		
		return this.state.Value;
	
	}
	
	ValueSet(
		Value
	){
		
		console.log( "UIDatetimeReactT.ValueSet", Value );
		
		
		this.Validation( Value );
		
		
		this.setState( { Value: Value } );
		
	}
	
	OnClickClear( ){
												
		this.setState( { Value: null } );
												
	}
	
	OnClickClose( ){
												
		if( this.state.OnCloseClick !== null ){
		
			this.state.OnCloseClick( );
		
		}
		
	}
	
	RenderInput( Params ){
							
		console.log( "UIDatetimeReactT.RenderInput", Params );
		
		return React.createElement(
			MaterialUI.TextField,
			{
				...Params,
				error: ( ( this.#IsValid === false ) && ( this.state.Disabled === false ) ), 
				label: ( ( this.state.Required === true ) ? ( this.state.Label + "*" ) : ( this.state.Label ) )
			},
			[
				
			]
		);
		
	}
	
	render( ){
		
		console.log( "UIDatetimeReactT.render", this.state, AdapterDateFns );
		
		
		return React.createElement(
			MaterialUILab.LocalizationProvider,
			{
				dateAdapter: AdapterDateFns.default
			},
			[
				
				React.createElement(
					MaterialUILab.DesktopDateTimePicker, 
					{
						value: this.state.Value,
						inputFormat: "dd.MM.yyyy HH:mm:ss",
						mask: "__.__.____ __:__:__",
						onChange: this.OnChangeCb.bind( this ),
						renderInput: this.RenderInput.bind( this ),
						InputAdornmentProps: {
							position: "start",
						},
						InputProps: {
							
							endAdornment: React.createElement(
								React.Fragment,
								{
									
								},
								[
									React.createElement(
										MaterialUI.IconButton,
										{
											title: "Clear",
											onClick: this.OnClickClear.bind( this )
										},
										[
											
											React.createElement(
												MaterialUIIcons.Backspace,
												{
													
												},
												[
												]
											)
											
										]
									),
									
									React.createElement(
										MaterialUI.IconButton,
										{
											title: "Close",
											onClick: this.OnClickClose.bind( this )
										},
										[
											
											React.createElement(
												MaterialUIIcons.Clear,
												{
													
												},
												[
												]
											)
											
										]
									)
								
								]
							)
							
						}
						
					},
					[
						
					]
				)
				
			]
		);
		
	}
	
};


export default UIDatetimeReactT;