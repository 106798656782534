"use strict";


const JSONBNParserErrorT = class JSONBNParserErrorT extends Error {
	
	constructor( Message ){
		
		super( Message );
		
	}
	
};


export default JSONBNParserErrorT;