"use strict";


import React from "react";

import AgGridReact from "ag-grid-react";


const UIGridReactT = class UIGridReactT extends React.Component {
	
	#RefGrid = null;
	
	#Element = null;
	
	#RowData = [ ];
	
	constructor(
		props
	) {
		
		super( props );
		
		this.state = {
			...props
		};
		
		this.#RefGrid = React.createRef( );
		
		
		
		this.#Element = React.createElement(
			AgGridReact.AgGridReact,
			{
				key: this.state.key,
				//Infrastructure
				
				ref: this.#RefGrid,
			
				modules: this.state.modules,
				frameworkComponents: this.state.frameworkComponents,
				
				//Interface
				
				popupParent: this.state.popupParent,
				
				//Procedures
				
				onFirstDataRendered: this.props.onFirstDataRendered,
				onFilterChanged: this.state.onFilterChanged,
				onGridReady: this.state.onGridReady,
				onDragStopped: this.state.onDragStopped,
				onColumnVisible: this.state.onColumnVisible,
				onCellValueChanged: this.state.onCellValueChanged,
				onCellClicked: this.state.onCellClicked,
				onSelectionChanged: this.state.onSelectionChanged,
				onSortChanged: this.state.onSortChanged,
				onGridColumnsChanged: this.state.onGridColumnsChanged,
				onRowDataChanged: this.state.onRowDataChanged,
				
				getRowClass: this.state.getRowClass,
				getRowStyle: this.state.getRowStyle,
				
				getContextMenuItems: this.state.getContextMenuItems,
				
				//Type
				
				localeText: this.state.localeText,
				
				defaultColDef: this.state.defaultColDef,
				columnDefs: this.state.columnDefs,
				
				rowClass: this.state.rowClass,
				rowStyle: this.state.rowStyle,
				
				rowSelection: this.state.rowSelection,
				getRowNodeId: this.state.getRowNodeId,
				style: this.state.style,
				suppressContextMenu: true,
				enableCellChangeFlash: true,
				autoGroupColumnDef: this.state.autoGroupColumnDef,
				className: this.state.className,
				autoSizeStrategy: this.state.autoSizeStrategy,
				reactiveCustomComponents: true //TODO for future
			},
			[ ]
		);
		
	}
	
	componentDidMount(
	
	) {
		
		console.log( "UIGridReactT.componentDidMount", this.state );
		
		
		//this.state.MountCb( );
		
	}

	componentDidUpdate( 
		PropsPrev, 
		StatePrev 
	) {
		
		console.log( "UIGridReactT.componentDidUpdate", this.state );
		
		if( this.state.columnDefs !== StatePrev.columnDefs ) {
			
			console.log( this.#RefGrid );
			
			this.#RefGrid.current.api.setColumnDefs( this.state.columnDefs );
			
		}
		
		if( this.state.rowData !== StatePrev.rowData ){
			
			this.#RefGrid.current.api.setRowData( this.state.rowData );
			
		}
		
		
		//this.state.UpdateCb( );
		
	}
	
	componentWillUnmount(
	
	){
		
		console.log( "UIGridReactT.componentWillUnmount", this.state );


		//this.state.UnmountCb( );
		
	}
	
	ColDefsGet(
	
	){
			
		return this.state.columnDefs;
			
	}
	
	ColDefsSet(
		ColDefs
	){
		
		this.setState( { columnDefs: ColDefs } );
		
	}
	
	RowDataGet(
		
	){
		
		return this.#RefGrid.current.api.getRowData( );
		
	}
	
	RowDataSet( 
		RowData
	){
		
		this.#RowData = RowData;
		
		this.#RefGrid.current.api.setRowData( RowData );
		
	}
	
	RowsSet(
		Rows
	){
		
		console.log( "UIGridReactT.RowsSet", Rows );
		

		this.#RefGrid.current.api.applyTransaction( {
			
			update: Rows
			
		} );
		
	}
	
	RowsAdd(
		Rows
	){
		
		console.log( "UIGridReactT.RowsAdd", Rows );
		
		
		this.#RefGrid.current.api.applyTransaction( {
			
			add: Rows 
			
		} );
		
	}
	
	RowsDelete(
		RowIds
	){
		
		console.log( "UIGridReactT.RowsDelete", RowIds );
		
		
		let Data = [ ];
		
		for(
			let I = 0;
			I < RowIds.length;
			I++
		){
		
			Data.push( { id: RowIds[ I ] } )
		
		}
		
		this.#RefGrid.current.api.applyTransaction( {
			
			remove: Data 
			
		} );
		
	}
	
	FilterModelGet(
		
	){
	
		return this.#RefGrid.current.api.getFilterModel( );
	
	}
	
	FilterModelSet(
		Model
	){
		
		console.log( Model );
		
		this.#RefGrid.current.api.setFilterModel( Model );
		
	}
	
	FilterChanged(
	
	){
	
		this.#RefGrid.current.api.onFilterChanged( );
	
	}
	
	AutoSize( ){
		
		let ColumnIds = this.#RefGrid.current.columnApi.getAllColumns( ).map( Column => Column.colId );
		
		this.#RefGrid.current.columnApi.autoSizeColumns( ColumnIds );
		
	}

	render( 
	
	) {
		
		console.log( "UIGridReactT.render", this.state );
		
		
		return this.#Element;
		
	}
	
};
	
export default UIGridReactT;