import ModalWithButton from "../modal/ModalWithButton";
import FormWithSubmit from "../../components/form/FormWithSubmit";
import apiPost from "../../api/apiPost";
import getColumnDefinitions from "../../services/getColumnDefinitions";
import { memo, useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TabsWrapper from "../tabs/tabsWrapper";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";
import { APIClient } from "../../Main.mjs";

export default function BasicAdd({
  onAdd,
  model,
  presetValues,
  defaultValues,
  handleChange,
  interceptAdd,
  title,
  buttonProps,
  noTemplates,
  parentModel,
  columnDefinitionsHandler,
  company,
  handleOnAdd,
  New = false
}) {
	
	console.log( { onAdd }, New );
  
	const handleAdd = async ( values ) => {
	  
		console.log( values );
	  
		if( interceptAdd ) {
			
			interceptAdd( presetValues ? values.concat( presetValues ) : values );
			
			return;
			
		}
		
		const sendData = presetValues ? values.concat( presetValues ) : values;

		console.log( sendData );

		const transformedData = {};
		
		let Files = [ ];

		sendData.forEach( ( item ) => {
			
			if( New === true ){
			
				if( item.key !== "files" ){
				
					transformedData[ item.key ] = item.value;
					
				} else {
					
					if( item.value !== "" ){
						
						Files = item.value;
						
					}
					
					transformedData[ item.key ] = [ ];
					
				}
			
			} else {
				
				transformedData[ item.key ] = item.value;
				
			}
			
		} );

		try {
			
			console.log( model, parentModel, Files, transformedData[ "files" ], 1, New );
			
			const res = await HttpService.post(
				UrlService.apiDomain() + `api/${model}/create`,
				{ data: transformedData }
			);
			
			if (!res.data.success) {
				alert(res.data.message);
				return;
			}
			
			if ( handleOnAdd ) {
				
				handleOnAdd( );
				
				return;
				
			}
			
			let ResultData = res.data.data;
			
			if( New === true ){
				
				console.log( Files );
				
				APIClient.Upload(
					81, //accounting_request_files
					ResultData.id,
					Files,
					
					function( JSONRPCResponse ){
						
						let FileIds = [ ];
						
						let ResultJSON = JSONRPCResponse.Result( );
						
						for(
							let I = 0;
							I < ResultJSON.length;
							I++
						){
							
							FileIds.push( Number( ResultJSON[ I ].id ) );
							
						}
						
						ResultData[ "files" ] = FileIds;
						
						console.log( ResultData );
						
						onAdd( ResultData );
						
					},
					function( Err ){
						
						alert( Err.message );
						
					}
				);
				
			} else {
				
				onAdd( ResultData );
			
			}
			
		} catch( error ) {
			
			console.log( error );
			
		}
	
	};

  const { data: contextData } = useContext(DataContext);

  const [columnDefinitions, setColumnDefinitions] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;

    if (!columnDefinitions) {
      getColumnDefinitions(t, model, contextData, true, (definitions) =>
        definitions.filter(
          (def) => !presetValues?.find((val) => def.name === val.key)
        ),
		null,
		New
      ).then((definitions) => {
        if (columnDefinitionsHandler) {
          definitions = columnDefinitionsHandler(definitions);
        }

        if (mounted) {
          setColumnDefinitions(definitions);
          setIsLoading(false);
        }
      });
    }
    return function cleanup() {
      mounted = false;
    };
  }, [presetValues, model, t, columnDefinitions]);

  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );

  if (!policy_action_tree[model].includes("add")) {
    return null;
  }

  if (isLoading) {
    return (
      <ModalWithButton title={"Add " + model}>
        <LinearProgress sx={{ minWidth: "200px" }} />
      </ModalWithButton>
    );
  }

  if (!columnDefinitions) {
    alert("No column definitions found for " + model);
  }

  let body;

  if (Object.keys(columnDefinitions).length > 1) {
    body = (
      <TabsWrapper
        tabs={Object.keys(columnDefinitions).map((key) => {
          return {
            label: t(key),
            content: (
              <FormWithSubmit
                company={company}
                parentModel={parentModel}
                model={model}
                sx={{ mt: 2 }}
                form={columnDefinitions[key]}
                onSubmit={handleAdd}
                defaultValues={defaultValues}
                handleChange={handleChange}
                submitText={t("Odeslat")}
                noTemplates={noTemplates}
              />
            ),
          };
        })}
      />
    );
  } else {
    body = (
      <FormWithSubmit
        company={company}
        parentModel={parentModel}
        form={columnDefinitions["default"]}
        onSubmit={handleAdd}
        handleChange={handleChange}
        defaultValues={defaultValues}
        submitText={t("Odeslat")}
        model={model}
        noTemplates={noTemplates}
      />
    );
  }

  return (
    <ModalWithButton
      buttonProps={buttonProps}
      title={title ?? t("Přidat")}
      size={"md"}
      onOpen={() => {
        setColumnDefinitions(null);
        setIsLoading(true);
      }}
    >
      {body}
    </ModalWithButton>
  );
}
