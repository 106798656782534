import InputWithValidationOld from "../../../inputs/text/InputWithValidationOld";
import { useEffect, useState } from "react";

export const NumRegEx = /^[0-9]{1,25}$/;

export default function InputNum(props) {
  const [value, setValue] = useState(props.value);
  const [disabled, setDisabled] = useState(props.disabled);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  return (
    <InputWithValidationOld
      regex={NumRegEx}
      emptyAllowed={!!props.name.endsWith("_2")}
      onChange={props.onChange}
      name={props.name}
      label={props.label}
      onlyValid={true}
      value={value}
      disabled={disabled}
    />
  );
}
