import { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Grid,
  TextField,
  FormControl,
  Button,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { format } from "date-fns";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const AddUbytovatelRest = ({ getRowdata, user_info, onClose }) => {
  const { t } = useTranslation();
  const at = localStorage.getItem("accessToken");

  let lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");
  const [locale, setLocale] = useState(lc);
  const { settings } = useSettings();
  const { data: contextData } = useContext(DataContext);
  let hostels = contextData.accommodations;
  let allRooms = contextData.rooms;
  let allInventoryItems = contextData.inventories;

  const [rooms, setRooms] = useState(
    user_info.ubytovna
      ? allRooms
          .filter((room) => room.accommodation_id == user_info.ubytovna)
          .map((room) => {
            return {
              id: room.id,
              name: room.name,
            };
          })
      : []
  );
  const [inventoryItems, setInventoryItems] = useState(
    allInventoryItems.map((item) => {
      return {
        id: item.id,
        name: item.name,
        price: item.price,
      };
    })
  );
  console.log({ user_info });

  const photoInputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      hostel_id: user_info.ubytovna || "",
      room_id: user_info.pokoj || "",
      inventory_id: "",
      datum: new Date(),
      datum_zadani: new Date(),
      suma: 0,
      comment: "",
      photos: [],
    },
    validationSchema: Yup.object({
      hostel_id: Yup.string().required("Povinné"),
      photos: Yup.array(),
      room_id: Yup.string(),
      inventory_id: Yup.string(),
      comment: Yup.string(),
      datum: Yup.date().required("Povinné"),
      datum_zadani: Yup.date().required("Povinné"),
      suma: Yup.number()
        .moreThan(0, "Musí být větší než 0")
        .required("Povinné"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("hostel_id", values.hostel_id);
      formData.append("room_id", values.room_id);
      formData.append("inventory_id", values.inventory_id);
      formData.append("datum", format(values.datum, "yyyy-MM-dd"));
      formData.append(
        "datum_zadani",
        format(values.datum_zadani, "yyyy-MM-dd")
      );
      formData.append("suma", values.suma);
      formData.append("comment", values.comment);
      Array.from(values.photos).forEach((photo, i) => {
        formData.append(`photos${i}`, photo);
      });

      try {
        const res = await HttpService.post(
          `${UrlService.apiDomain()}api/add-pokuta-ubytovatel/${user_info.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + at,
            },
          }
        );
        if (res.status !== 200) {
          alert(res.data.message);
        }
        getRowdata();
        formik.handleReset();
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleFileChange = (event) => {
    formik.setFieldValue("photos", [...event.currentTarget.files]);
  };

  const handleRoomsSearch = (id) => {
    setRooms(
      allRooms
        .filter((room) => room.accommodation_id == id)
        .map((room) => {
          return {
            id: room.id,
            name: room.name,
          };
        })
    );
  };

  const handleItemsSearch = (id) => {
    setInventoryItems(
      allInventoryItems
        .filter((item) => item.room_id == id)
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            price: item.price,
          };
        })
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 8,
      }}
    >
      <Container maxWidth={settings.compact ? "xl" : false}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h5">
              {t("Přidat")}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={onClose}>
              {t("Zavřít")}
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ p: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id={`hostel_id`}
                      name={`hostel_id`}
                      options={hostels}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        formik.setFieldValue(`hostel_id`, value?.id);
                        handleRoomsSearch(value?.id);
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        hostels.find(
                          (hostel) => hostel.id === formik.values.hostel_id
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Ubytovna")}
                          error={
                            !!(
                              formik.touched.hostel_id &&
                              formik.errors.hostel_id
                            )
                          }
                          helperText={
                            formik.touched.hostel_id && formik.errors.hostel_id
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id={`room_id`}
                      name={`room_id`}
                      options={rooms}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        formik.setFieldValue(`room_id`, value?.id);
                        handleItemsSearch(value?.id);
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        rooms.find(
                          (room) => room.id === formik.values.room_id
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Pokoj")}
                          error={
                            !!(formik.touched.room_id && formik.errors.room_id)
                          }
                          helperText={
                            formik.touched.room_id && formik.errors.room_id
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id={`inventory_id`}
                      name={`inventory_id`}
                      options={inventoryItems}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        formik.setFieldValue(`inventory_id`, value?.id);
                        formik.setFieldValue(`suma`, value?.price);
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        inventoryItems.find(
                          (item) => item.id === formik.values.inventory_id
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Předmět")}
                          error={
                            !!(
                              formik.touched.inventory_id &&
                              formik.errors.inventory_id
                            )
                          }
                          helperText={
                            formik.touched.inventory_id &&
                            formik.errors.inventory_id
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="suma"
                    label={t("Cena")}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.suma}
                    error={!!(formik.touched.suma && formik.errors.suma)}
                    helperText={formik.touched.suma && formik.errors.suma}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[locale]}
                  >
                    <MobileDatePicker
                      label={t("Datum škody")}
                      inputFormat="dd.MM.yyyy"
                      value={formik.values.datum}
                      onChange={(value) => {
                        formik.setFieldValue("datum", value);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[locale]}
                  >
                    <MobileDatePicker
                      label={t("Datum zaúčtování")}
                      inputFormat="dd.MM.yyyy"
                      value={formik.values.datum_zadani}
                      onChange={(value) => {
                        formik.setFieldValue("datum_zadani", value);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mb: 2 }}
                  name="comment"
                  label={t("Poznámka")}
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comment}
                  error={!!(formik.touched.comment && formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button type="submit" variant="contained" color="primary">
                  {t("Přidat")}
                </Button>
                <input
                  id="photos"
                  name="photos"
                  onChange={handleFileChange}
                  multiple
                  hidden
                  ref={photoInputRef}
                  type="file"
                />
                {formik.values.photos.length > 0 && (
                  <Box
                    sx={{ mr: 1 }}
                  >{`Přidány ${formik.values.photos.length} foto`}</Box>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => photoInputRef.current.click()}
                >
                  {t("Přidat foto")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
};
export default AddUbytovatelRest;
