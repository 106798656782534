import React, { useState, useCallback, useMemo } from "react";
import GridWrapper from "../../components/grid/gridWrapperNew";
import BasicAdd from "../../components/grid/basicAddNew";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import {
  TextField,
  Box,
  Dialog,
  Button,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import { useTranslation } from "react-i18next";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import CustomerCard from "../customers/CustomerCard";
import Details from "./Details.js";
import { isSameDay } from "date-fns";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

export default function CoordinatorReports({
  tab,
  handleRowData,
  customer_id,
  fromCard,
}) {
  const { t } = useTranslation();
  const params = useParams();

  let selectedCustomerId = customer_id || params.customer_id || null;

  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");

  const [locale, setLocale] = useState(lc);
  const [date, setDate] = useState(new Date());
  const [openCustomer, setOpenCustomer] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [field, setField] = useState("");
  const [fieldLable, setFieldLable] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const [largeTextOpen, setLargeTextOpen] = useState(false);
  const [largeText, setLargeText] = useState("");
  const [reportDate, setReportDate] = useState(null);

  const handleCloseDetails = useCallback(() => {
    setOpenDetails(false);
  }, []);

  const handleCloseCustomer = useCallback(() => {
    setOpenCustomer(false);
  }, []);

  const policyActions = useMemo(
    () =>
      JSON.parse(localStorage.getItem("policy_action_tree"))
        .dailycoordinator_reports,
    []
  );

  const allowedCustomers = JSON.parse(
    localStorage.getItem("policy_record_tree")
  ).customers;

  const hasFullPermission = policyActions.includes("overedit");
  const today = new Date();

  const disabled =
    (fromCard && !allowedCustomers.includes(selectedCustomerId)) || //new condition 12.06.2024
    (!hasFullPermission && !isSameDay(today, date));

  let moreFields = [
    "scheduled",
    "attended",
    "absent_names",
    "terminations",
    "changed_place",
    "new_hires",
    "new_orders",
    "storno_smlouva",
  ];

  let textAreaFields = ["recruitment_activity", "contract_info", "looking_new"];

  const interceptClick = useCallback((e) => {
    let field = e.colDef.field;

    if (moreFields.includes(field)) {
      setField(field);
      setReportId(e.data.id);
      setFieldLable(e.colDef.headerName);
      setCustomerId(e.data.customer_id);
      setOpenDetails(true);
    }
    if (field == "customer_id") {
      setCustomerId(e.data.customer_id);
      setOpenCustomer(true);
    }
    if (
      field === "recruitment_activity" ||
      field === "contract_info" ||
      field == "looking_new"
    ) {
      setReportDate(new Date(e.data.created_at));
      if (
        !hasFullPermission &&
        !isSameDay(today, new Date(e.data.created_at))
      ) {
        setLargeText(e.data[field]);
        setLargeTextOpen(true);
      }
      if (
        isSameDay(today, new Date(e.data.created_at)) &&
        !hasFullPermission &&
        !e.colDef.editable
      ) {
        setLargeText(e.data[field]);
        setLargeTextOpen(true);
      }
    }
  }, []);

  const handleCloseLargeText = () => {
    setLargeTextOpen(false);
  };

  const handleColumnDefs = (colDefs) => {
    return colDefs.map((columnDef) => {
      if (moreFields.includes(columnDef.field)) {
        columnDef.cellStyle = { color: "#5664d2" };
        columnDef.minWidth = 90;
      }
      if (columnDef.field === "customer_id") {
        columnDef.cellStyle = { color: "#5664d2" };
      }
      if (textAreaFields.includes(columnDef.field)) {
        columnDef.maxWidth = 300;
        if (hasFullPermission || isSameDay(today, reportDate)) {
          columnDef.cellEditor = "TextArea";
        }
      }
      return columnDef;
    });
  };

  const handleOpenInNew = () => {
    window.open(
      `/dashboard/daily_coordinator_reports/${customer_id}`,
      "_blank"
    );
  };

  const presetValues = [
    {
      key: "date",
      value: date,
    },
  ];

  if (fromCard || params.customer_id) {
    presetValues.push({
      key: "customer_id",
      value: selectedCustomerId,
    });
  }
  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={handleCloseLargeText}
        open={largeTextOpen}
        PaperProps={{ sx: { minHeight: "200px", p: 2 } }}
      >
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={handleCloseLargeText}>
            {t("Zavřít")}
          </Button>
        </Box>
        <Box>{largeText}</Box>
      </Dialog>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={handleCloseCustomer}
        open={openCustomer}
      >
        <CustomerCard id={customerId} onClose={handleCloseCustomer} />
      </Dialog>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={handleCloseDetails}
        open={openDetails}
      >
        <Details
          reportId={reportId}
          field={field}
          fieldLable={fieldLable}
          customer_id={customerId}
        />
      </Dialog>
      <GridWrapper
        style={tab ? { mt: 2 } : null}
        helmet="Dashboard: Feedbacks | AWA Partners"
        title="Denní report koordinátora"
        model="dailycoordinator_reports"
        filter={handleRowData}
        browseData={useMemo(
          () =>
            fromCard || params.customer_id
              ? { customer_id: selectedCustomerId }
              : !showAll
              ? { date: format(new Date(date), "yyyy-MM-dd") }
              : {},
          [date, customer_id, showAll]
        )}
        needTemplate={true}
        interceptClick={interceptClick}
        handleColumnDefs={handleColumnDefs}
      >
        {!fromCard && !params.customer_id && (
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <Checkbox
              color="secondary"
              checked={showAll}
              onChange={(event) => {
                setShowAll(event.target.checked);
              }}
            />
            <Typography>{t("Zobrazit vše")}</Typography>
          </Box>
        )}
        {!fromCard && !params.customer_id && (
          <Box sx={{ mr: 2 }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={localeMap[locale]}
            >
              <MobileDatePicker
                label={t("Datum")}
                mask="__.__.____"
                openTo="day"
                maxDate={new Date()}
                name="date"
                value={date}
                id="date"
                onChange={(event) => {
                  setDate(event);
                }}
                renderInput={(inputProps) => (
                  <TextField fullWidth variant="outlined" {...inputProps} />
                )}
              />
            </LocalizationProvider>
          </Box>
        )}
        <BasicAdd
          noTemplates={true}
          model={"dailycoordinator_reports"}
          presetValues={presetValues}
          buttonProps={{ disabled: disabled }}
        />
        {fromCard && (
          <Button
            color="primary"
            variant="contained"
            sx={{ ml: 2 }}
            onClick={handleOpenInNew}
          >
            <OpenInNew />
          </Button>
        )}
      </GridWrapper>
    </>
  );
}
