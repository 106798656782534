"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


const UIButtonReactT = class UIButtonReactT extends React.Component {
	
	constructor( Props ){
		
		console.log( "UIButtonReactT.constructor", Props );
		
		
		super( Props );
		
		
		this.state = {
			key: ( Props.key ? Props.key : null ),
			SX: ( ( Props.SX ) ? Props.SX : { ml: 2, px: 2 } ),
			Variant: ( ( Props.Variant ) ? Props.Variant : "contained" ),
			Color: ( ( Props.Color ) ? Props.Color : "primary" ),
			Disabled: ( ( Props.Disabled ) ? Props.Disabled : false ),
			OnClick: ( ( Props.OnClick ) ? Props.OnClick : null ),
			Children: ( ( Props.Children ) ? Props.Children : [ ] ),
			FullWidth: ( ( Props.FullWidth ) ? Props.FullWidth : false ),
			OnReady: ( ( Props.OnReady ) ? Props.OnReady : null )
		};
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIButtonReactT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.setState( {
			[ Name ]: Value
		} );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIButtonReactT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.state[ Name ];
		
	}
	
	render(
	
	){
		
		console.log( "UIButtonReactT.render", this.state );
		
		let Children = [ ];
		
		
		console.log( this.state.Children );
		
		for(
			let I = 0;
			I < this.state.Children.length;
			I++
		){
			
			Children.push( this.state.Children[ I ].Component( ) );
			
		}
		
		console.log( Children );
		
		
		return React.createElement(
			MaterialUI.Button,
			{
				key: this.state.key,
				sx: this.state.SX, 
				disabled: this.state.Disabled,
				variant: this.state.Variant,
				color: this.state.Color,
				onClick: this.state.OnClick,
				fullWidth: this.state.FullWidth
			},
			[
				...Children
			]
		);
		
	}
	
};


export default UIButtonReactT;