"use strict";


import HTTPClientAbstractT from "../../../../Interface/Network/HTTP/Client/_/HTTPClientAbstractT.mjs";

import HTTPFileClientAbstractT from "../../../../Procedure/HTTPFile/Client/_/HTTPFileClientAbstractT.mjs";

import JSONBNT from "../../../../Procedure/JSONBN/JSONBNT.mjs";

import HTTPHeadersT from "../../../../Type/HTTP/Header/HTTPHeadersT.mjs";

import JSONRPCRequestT from "../../../../Type/JSONRPC/JSONRPCRequestT.mjs";

import JSONRPCResponseT from "../../../../Type/JSONRPC/JSONRPCResponseT.mjs";



const HTTPClientFileT = class HTTPClientFileT extends HTTPFileClientAbstractT {
	
	#HTTPClient = null;
	
	#JSONBN = null;
	
	#URLUpload = null;
	
	#URLDownload = null;
	
	#TextEncoder = null;
	
	#TextDecoder = null;
	
	constructor(
		HTTPClient,
		URLUpload,
		URLDownload
	){
		
		if( ( HTTPClient instanceof HTTPClientAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( URLUpload ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( URLDownload ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super( );
		
		
		this.#HTTPClient = HTTPClient;
		
		this.#JSONBN = new JSONBNT( );
		
		this.#URLUpload = URLUpload;
		
		this.#URLDownload = URLDownload;
		
		this.#TextEncoder = new TextEncoder( );
		
		this.#TextDecoder = new TextDecoder( );
		
	}
	
	Upload(
		ModelId,
		Id,
		Headers,
		Files,
		
		SuccessCb,
		ErrorCb
	){

		let FD = new FormData( );
		
		for( 
			let I = 0;
			I < Files.length;
			I++
		){
			
			let File = Files[ I ];
			
			FD.append( "files" + I, File );
			
		}
		
		this.#HTTPClient.Upload(
			this.#URLUpload + "/" + ModelId + "/" + Id,
			Headers,
			
			FD,
			function( Status, StatusMessage, Headers, Body ){
				
				console.log( "JSONRPCClientT.Request", "HTTP", Status, StatusMessage, Headers, Body );
				
				
				if( Status !== 200 ){
					
					ErrorCb( new Error( "Error during request" ) );
					
					return;
					
				}
				
				
				let JSONRPCData = this.#JSONBN.Parse( this.#TextDecoder.decode( Body ) );
				
				if( ( JSONRPCData instanceof Object ) === false ){
					
					ErrorCb( new Error( "Invalid response format" ) );
					
					return;
					
				}
				
				
				let JSONRPCResponse = null;
				
				let Err = null;
				
				try {
						
					let IsOverdue = false;
						
					JSONRPCResponse = new JSONRPCResponseT(
						( JSONRPCData.result ) ? true : false,
						JSONRPCData.result,
						JSONRPCData.error,
						IsOverdue
					);
				
				} catch( IErr ){
					
					Err = IErr;
					
				}
				
				
				if( Err !== null ){
					
					ErrorCb( Err );
					
				} else {
				
					SuccessCb(
						JSONRPCResponse
					);
				
				}
				
			}.bind( this ),
			function( ErrorData ){
				
				console.log( ErrorData );
				
				ErrorCb( ErrorData );
				
			}.bind( this )
		);
	}
	
	Download( 
		ModelId,
		Id,
		Headers,
		
		SuccessCb,
		ErrorCb
	){

		this.#HTTPClient.Download(
			this.#URLDownload + "/" + ModelId + "/" + Id,
			Headers,
			
			function( Status, StatusMessage, Headers, Body ){
			
				if( Status != 200 ) {
					
					ErrorCb( new Error( StatusMessage ) );
					
					return;
				}
			
				SuccessCb( URL.createObjectURL( Body ) );
				
			},
			
			function( Err ){
				
				ErrorCb( Err );
				
			}
		);
		
	}
	
};


export default HTTPClientFileT;