import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography, Popover, } from '@material-ui/core';
//import { customerApi } from '../../__fakeApi__/customerApi';
//import { CustomerListTable } from '../../components/dashboard/customer';
import useMounted from '../../../hooks/useMounted';
import Add from './add.js';
import ChevronRightIcon from '../../../icons/ChevronRight';
import DownloadIcon from '../../../icons/Download';
import PlusIcon from '../../../icons/Plus';
import UploadIcon from '../../../icons/Upload';
import useSettings from '../../../hooks/useSettings';
import gtm from '../../../lib/gtm';
import HttpService from '../../../services/HttpService.js';
import UrlService from '../../../services/UrlService.js';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.js";
import DatePicker from "../../../aggrid/DatePicker.js";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.js";
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import LocaleRu from "../../../aggrid/locale.ru.js";
import { useTranslation } from 'react-i18next';

import MultiSelectSimple from "../../../aggrid/MultiSelectSimple.js";
import {LicenseManager} from "ag-grid-enterprise";
import RichTextEditor from "../../../aggrid/RichTextEditor.js";
LicenseManager.setLicenseKey("CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166");



const RestyTypes = (props) => {
    const { t } = useTranslation();


    const customComparator = (valueA, valueB) => {
        if (valueA!=null && valueB!=null)
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };

    var lt;
    if (t('cs')=='cs') lt=LocaleCs;
    if (t('cs')=='en') lt=LocaleEn;
    if (t('cs')=='ru') lt=LocaleRu;

    var coldefs=[
        {
            headerName: t('Název'),
            field: 'name',
            pinned: 'left'
        },
        {
            headerName: t('Cena'),
            field: 'cena',

        },

        {
            headerName: t('Typ'),
            field: 'typ',
            editable: true,
            valueSetter: function(params) {
                if (params.data && params.data.id) {
                    params.data['typ']=params.newValue;
                }
            },


            cellEditor: 'agRichSelectCellEditor',

            cellEditorParams: {values: JSON.parse(localStorage.getItem('resty_types_types')).map(e => e['name']),
                formatValue:function(params) {return t(params)}
            },
            valueSetter: function (params) {
                if (params.data && params.data.id) {
                    try {
                        params.data['typ'] = JSON.parse(localStorage.getItem('resty_types_types')).find(refData => refData['name'] == params.newValue).id;
                        return true;
                    } catch (err) {
                        console.log(err);
                        return true;
                    }
                }
            },
            valueGetter: function (params) {
                if (params.data && params.data.id) {

                    if (params.data['typ'] === null || params.data['typ'] === '') return '';
                    try {
                        return t(JSON.parse(localStorage.getItem('resty_types_types')).find(refData => String(refData.id) === String(params.data['typ']))['name']);
                    } catch (err) {
                        console.log(params);
                        console.log(params.data);


                        console.log(err);
                        return '';
                    }
                }
            }

        },


        {
            headerName: t('Visible'),
            field: 'visible',
            cellRenderer: 'CheckboxRenderer'
        },
        {headerName:t('Odstranit'),
            cellRenderer:"DeleteRenderer",
            minWidth: 90,
            cellRendererParams: {
                gridType: "resty_types"
            },
            suppressMenu: true,}



    ];

    const mounted = useMounted();

    const { settings } = useSettings();
    const [rowdata, setRowdata] = useState(null);
    const [open, setOpen] = useState(false);
    const [columndefs, setColumndefs] = useState(coldefs);
    const [gridApi, setgridApi] = useState({});
    const [loctext, setloctext] = useState(lt);
    const [gridColumnApi, setgridColumnApi] = useState({});








    const defaultColDef={
        flex: 1,
        minWidth: 170,
        editable: true,
        resizable: true,
        suppressMovableColumns:true,
        sortable: true,
        filter: true,
        suppressContextMenu:true,
    };

    const frameworkComponents={
        CheckboxRenderer: CheckboxRenderer,
        MultiSelectSimple: MultiSelectSimple,
        DeleteRenderer: DeleteRenderer,
        DatePicker: DatePicker

    };



    const grd=()=>{
        HttpService.get(UrlService.apiDomain()+'api/resty_types/'+props.id)
            .then(res => {

                setRowdata(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getRowdata = useCallback(async () => {
        grd();
    }, [mounted]);




    useEffect(() => {
        grd();
    }, [props.id]);



    const onCellCValueChanged = (event) => {

        event.data.field=event.colDef.field;
        HttpService.put(UrlService.apiDomain()+'api/resty_types/'+event.data.id, event.data)
            .then(res => {

                if (res.status!=200) {
                    alert(t('Chyba serveru, prosím zkuste pozdějí')+'. '+t(res.data.message));
                } else {

                    console.log('Successfully updated');


                    event.node.setData(res.data.entry);
                }
            })
            .catch((error) => {
                console.log(error);
            })


    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleAdd = (data) => {
        data.customer_id=props.id;
        HttpService.post(UrlService.apiDomain()+'api/resty_types', data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    handleClose();
                    let r = gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    gridApi.flashCells({rowNodes: [r.add[0]]});




                }

            })
            .catch((error) => {
                alert(error);
            });

    };

    const onGridReady = (params) => {
        setgridApi(params.api);
        setgridColumnApi(params.columnApi);

        //params.api.setDomLayout('autoHeight');
        //document.querySelector('#myGrid').style.height = '';

        var columnState = JSON.parse(localStorage.getItem('resty_types_column_state'));
        if (columnState) {
            params.columnApi.setColumnState(columnState);
        }
    };

    const onDragStopped = (params) => {

        var columnState = JSON.stringify(params.columnApi.getColumnState());
        localStorage.setItem('resty_types_column_state', columnState);
    }



    useEffect(() => {
        setloctext(lt);
        setColumndefs(coldefs);
    }, [t('cs')]);


    return (
        <>
        <Helmet>
            <title>Dashboard: Typy restu | AWA Partners</title>
        </Helmet>


        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 8
            }}
        >
            <Container maxWidth={settings.compact ? 'xl' : false}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('Typy restů')}
                        </Typography>


                    </Grid>

                    <Popover
                        anchorOrigin={{
                            horizontal: 'center',
                            vertical: 'top'
                        }}
                        keepMounted
                        onClose={handleClose}
                        open={open}
                        PaperProps={{
                            sx: { width: 540 }
                        }}
                    >
                        <Add onAdd={handleAdd} />
                    </Popover>
                    <Grid item>
                        <Box sx={{ m: -1 }}>
                            <Button
                                color="primary"
                                startIcon={<PlusIcon fontSize="small" />}
                                sx={{ m: 1 }}
                                variant="contained"
                                onClick={()=>{

                                    setOpen(true);
                                }}
                            >
                                {t('Přidat')}
                            </Button>
                        </Box>
                    </Grid>

                </Grid>
                <Box sx={{ mt: 3 }}>
                    <div style={{ width:'100%', overflow: 'auto', height: '500px'}} id="myGrid" className="ag-theme-material">
                        <AgGridReact
                            modules={AllCommunityModules}
                            rowData={rowdata}
                            columnDefs={columndefs}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            onDragStopped={onDragStopped}
                            onCellValueChanged={onCellCValueChanged}
                            frameworkComponents={frameworkComponents}
                            enableCellChangeFlash={true}
                            rowSelection={'multiple'}
                            suppressContextMenu={true}
                            localeText={loctext}
                            popupParent={document.querySelector('body')}
                        />
                    </div>
                </Box>
            </Container>
        </Box>
        </>
    );
};

export default RestyTypes;
