"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIButtonReactT from "../../../Button/UIButtonReactT.mjs";


const UIGridRendererButtonT = class UIGridRendererButtonT extends React.Component { //TODO Rename file
	
	#Ref = null;
	
	constructor( props ){
		
		console.log( "UIGridRendererButtonT.constructor", props );
		
		
		super( props );
		
		
		this.#Ref = React.createRef( );
		
		
		this.state = {
			SX: ( props.SX ) ? props.SX : { },
			Color: ( props.Color ) ? props.Color : "primary",
			Disabled: ( props.Disabled ) ? props.Disabled : false,
			Variant: ( ( props.Variant ) ? props.Variant : "contained" ),
			FullWidth: ( props.FullWidth ) ? props.FullWidth : false,
			OnClick: ( props.OnClick ) ? props.OnClick : null,
			Children: ( props.Children ) ? props.Children : [ ],
			data: ( props.data ) ? props.data : null,
			node : ( props.node ) ? props.node : null
		};
		
	}
	
	ChildGet( Index ){
		
		return this.state.Children[ Index ];
		
	}
	
	DisabledSet( Value ){
		
		console.log( "UIGridRendererButtonT.DisabledSet", Value );
		
		this.#Ref.current.DisabledSet( Value );
		
	}
	
	OnClick( ){
		
		this.state.OnClick( this, this.state.data, this.state.node );
		
	}
	
	render( ){
		
		console.log( "UIGridRendererButtonT.render", this.state );
		
		return React.createElement(
			UIButtonReactT,
			{
				ref: this.#Ref,
				SX: this.state.SX,
				Color: this.state.Color,
				Disabled: this.state.Disabled,
				FullWidth: this.state.FullWidth,
				Variant: this.state.Variant,
				OnClick: this.OnClick.bind( this ),
				Children: this.state.Children
			} /*,
			[
				...ChildrenElements
			]*/
		);
		
	}
	
};


export default UIGridRendererButtonT;