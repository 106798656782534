"use strict";


import UIBooleanAbstractT from "./UIBooleanAbstractT.mjs";


const UIBooleanProducerAbstractT = class UIBooleanProducerAbstractT {
	
	constructor(
	
	) {
		
	}

	Produce( 
		...Props 
	) {
		
		throw new Error( "Abstract" );
		
	}
	
};


export default UIBooleanProducerAbstractT;
