import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Children, cloneElement, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputAutoComplete from "../inputs/autoComplete/InputAutoComplete";
import DateInput from "../inputs/DateInput";
import DateTimeInput from "../inputs/DateTimeInput";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputMultiAutocomplete from "../inputs/autoComplete/InputMultiAutocomplete";
import { getLocalJson, setLocalJson } from "../../utils/localStorageUtills";
import { getHash } from "../../utils/getHash";
import { formatDateTime } from "../../utils/formatDate";
import { format } from "date-fns";
import { Delete } from "@material-ui/icons";
import useFilter from "../../hooks/useFilter";
import InputWithValidation from "../inputs/text/InputWithValidation";
import FileUpload from "../inputs/file/FileUpload";
import { getName } from "../../services/getColumnDefinitions";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";

export function getNameFromObject(val, model) {
  if (!val || val === "") {
    return "";
  }
  return val.name;
}

const getSelectors = (item) => {
  let keySelector, valueSelector, freeSolo;
  switch (item.data_array_model) {
    case "clients":
      keySelector = (i) => {
        return getName(i);
      };
      break;
    case "issued_invoices":
      keySelector = (i) => i?.invoice_number;
      break;
    case "additional_texts":
      keySelector = (i) => i?.text;
      break;
    default:
      keySelector = (i) => getName(i, item.data_array_model);
  }

  switch (item.data_array_model) {
    case "invoice_item_names":
      valueSelector = (i) => i.name;
      freeSolo = true;
      break;
    case "additional_texts":
      valueSelector = (i) => i.text;
      freeSolo = true;
      break;
    default:
      valueSelector = (i) => i.id;
      freeSolo = false;
  }
  return [keySelector, valueSelector, freeSolo];
};

export default memo(
  (props) => {
    const filter = useFilter();
    const { company } = props;
    const initializeData = () => {
      let dataInit = {};
      for (const item of props.form) {
        let defaultValue = "";
        if (item.defaultValue) {
          defaultValue = item.defaultValue;
        }
        if (props.defaultValues) {
          if (Array.isArray(props.defaultValues)) {
            let foundValue = props.defaultValues.find(
              (f) => f?.name ?? f.key === item.name
            )?.value;
            if (foundValue) {
              defaultValue = foundValue;
            }
          }
          if (typeof props.defaultValues === "object") {
            if (props.defaultValues[item.name]) {
              defaultValue = props.defaultValues[item.name];
            }
          }
        }
        if (!defaultValue) {
          // if (item.type === "select") {
          //   let data = item.data_array;
          //   if (data.length === 1) {
          //     let [, valueSelector, freeSolo] = getSelectors(item);
          //     if (!freeSolo) {
          //       defaultValue = valueSelector(data[0]);
          //     }
          //   }
          // }
          if (item.type === "select") {
            let filtered = filter.byAccessAndFilter(
              item.data_array,
              item.data_array_model
            );
            if (filtered.length === 1) {
              let [, valueSelector, freeSolo] = getSelectors(item);
              if (!freeSolo) {
                defaultValue = valueSelector(filtered[0]);
              }
            }
          }
          if (item.type === "boolean") {
            defaultValue = 0;
          }
          if (item.type === "date") {
            defaultValue = new Date();
            if (item.name === "to_date") {
              defaultValue.setFullYear(defaultValue.getFullYear() + 1);
            }
            if (item.name === "date_due") {
              let offsetInDays = 14;

              let result = new Date(defaultValue);
              result.setDate(defaultValue.getDate() + offsetInDays);
              defaultValue = result;
            }
            if (item.name === "date_of_payment" || item.name === "end_date") {
              defaultValue = null;
            } else {
              defaultValue = format(defaultValue, "yyyy-MM-dd");
            }
          }
        }
        if (item.type === "boolean" || (defaultValue && defaultValue !== "")) {
          dataInit[item.name] = { value: defaultValue, valid: true };
        } else {
          dataInit[item.name] = { value: defaultValue, valid: !item.required };
        }
      }
      /*for (const item of props.form) { // copy invoices
			let val = dataInit[item.name].value;
			if (props.handleChange && item.type === "select" && val !== "") {
				dataInit = props.handleChange(dataInit, item.name, val);
			}
	  }*/
      return dataInit;
    };

    const [data, setData] = useState(() => {
      let dataInit = initializeData();
      for (const item of props.form) {
        // copy invoices
        let val = dataInit[item.name].value;
        if (props.handleChange && item.type === "select" && val !== "") {
          dataInit = props.handleChange(dataInit, item.name, val);
        }
      }
      return dataInit;
    });

    const { t } = useTranslation();

    const [externalData, setExternalData] = useState(props.externalData);

    const [outerData, setOuterData] = useState(props.outerData);

    useEffect(() => {
      setExternalData(props.externalData);
    }, [props.externalData]);

    useEffect(() => {
      setOuterData(props.outerData);

      let D = data;

      for (let I in props.outerData) {
        D[I] = props.outerData[I];
      }

      setData(D);
    }, [props.outerData]);

    const [defaultValues, setDefaultValues] = useState(props.defaultValues);

    useEffect(() => {
      if (props.defaultValues) {
        if (Array.isArray(props.defaultValues)) {
          for (const defaultValue of props.defaultValues) {
            let name = defaultValue?.name ?? defaultValue?.key;
            let value = defaultValue?.value;
            if (!defaultValues || defaultValues[name]?.value !== value) {
              handleChange(name, value, true);
            }
          }
        }
        // if(typeof props.defaultValues === 'object') {
        //     for (const defaultValueKey in props.defaultValues) {
        //         if (defaultValues[defaultValueKey]?.value !== props.defaultValues[defaultValueKey]) {
        //             handleChange(defaultValueKey, props.defaultValues[defaultValueKey], true);
        //         }
        //     }
        // }
      }
      setDefaultValues(props.defaultValues);
    }, [props.defaultValues]);

    const [groups, setGroups] = useState([]);

    const setDataArray = (parent_model, model, name, data_array, company) => {
      if (
        parent_model === "issued_invoices" &&
        model === "invoice_items" &&
        name === "name"
      ) {
        console.log("data_array", data_array);
        if (company) {
          let data = data_array.filter((item) => item.invoice != 1);
          let filtered = data.filter((item) =>
            item.company_ids
              ? item.company_ids.split("//").includes(company.toString())
              : null
          );
          return filtered.length > 0 ? filtered : data;
        } else return data_array.filter((item) => item.invoice != 1);
      }
      if (
        parent_model === "received_invoices" &&
        model === "invoice_items" &&
        name === "name"
      ) {
        if (company) {
          let data = data_array.filter((item) => item.invoice != 2);
          let filtered = data.filter((item) =>
            item.company_ids
              ? item.company_ids.split("//").includes(company.toString())
              : null
          );
          return filtered.length > 0 ? filtered : data;
        } else return data_array.filter((item) => item.invoice != 2);
      } else if (
        parent_model === "received_invoices" &&
        name === "payment_method"
      ) {
        return data_array.filter((item) => item.id != 2);
      } else if (
        model === "dailycoordinator_reports" &&
        name === "customer_id"
      ) {
        console.log({ data_array });
        let allowed_options = JSON.parse(
          localStorage.getItem("policy_record_tree")
        ).customers;
        return data_array.filter(
          (i) => allowed_options.includes(i.id) && i.id != "1"
        );
      } else {
        return data_array;
      }
    };

    useEffect(() => {
      let rawData = [];
      let dataValid = true;
      for (const key in data) {
        let contains =
          groups
            .filter((group) => group.open)
            ?.find((group) => {
              return (
                group.fields.find((field) => field.name === key) !== undefined
              );
            }) !== undefined;
        if (!groups || !groups.length) {
          contains = true;
        }
        if (contains) {
          let item = data[key];
          if (!item.valid) {
            dataValid = false;
          }
          rawData.push({ key: key, value: item.value });
        }
      }
      props.onChange(rawData, dataValid);
    }, [data, groups]);

    const handleChange = (name, newValue, valid) => {
      if (!newValue) newValue = "";
      if (data[name]?.value === newValue) return;
      if (!changed && selectedDraft === now) setChanged(true);
      setData((prevState) => {
        let newData = {
          ...prevState,
          [name]: { value: newValue, valid: valid },
        };
        if (props.handleChange) {
          newData = props.handleChange(newData, name, newValue);
        }
        return newData;
      });
    };

    useEffect(() => {
      let groups = [];
      for (const item of props.form) {
        if (item.groups) {
          let split = item.groups.split(",");
          for (const group of split) {
            if (!groups.find((g) => g.key === group)) {
              groups.push({ key: group, fields: [] });
            }
            groups
              .find((g) => g.key === group)
              .fields.push(props.form.find((i) => i.name === item.name));
          }
        }
      }
      if (groups.length > 0) {
        for (const item of props.form) {
          if (!item.groups) {
            for (const group of groups) {
              group.fields.push(props.form.find((i) => i.name === item.name));
            }
          }
        }
      }
      for (const group of groups) {
        group.open = false;
      }
      setGroups(groups);
    }, [props.form]);

    const getInput = (item) => {
      if (props.hidden) {
        if (props.hidden.includes(item.name)) {
          return null;
        }
      }
      if (item.type === "text") {
        const getValue = () => {
          if (item.formula) {
            let result = 0;

            if (item.name === "vat_per_item") {
              result = eval(item.formula).toFixed(4);
            } else {
              result = eval(item.formula).toFixed(2);
            }

            if (result != data[item.name].value) {
              handleChange(item.name, result, true);
            }
            return result;
          }
          return data[item.name].value;
        };
        return (
          <Grid
            item
            xs={item.size}
            key={item.name}
            sx={item.name == "cost_to_pay" ? { display: "none" } : {}}
          >
            <InputWithValidation
              value={getValue()}
              valid={data[item.name].valid}
              name={item.name}
              label={item.label}
              onChange={handleChange}
              processValue={item.processValue}
              onlyValid={item.onlyValid}
              required={item.required}
              disabled={item.isDisabled && item.isDisabled(item, data)}
              regex={item.regex}
              allowedRegex={item.allowedRegex}
            />
          </Grid>
        );
      } else if (item.type === "select") {
        let [keySelector, valueSelector, freeSolo] = getSelectors(item);
        return (
          <Grid item xs={item.size} key={item.name}>
            <InputAutoComplete
              name={item.name}
              label={item.label}
              value={data[item.name].value}
              onChange={handleChange}
              required={item.required}
              disabled={item.isDisabled && item.isDisabled(item, data)}
              data_array={setDataArray(
                props.parentModel,
                props.model,
                item.name,
                item.data_array,
                company
              )}
              data_array_model={item.data_array_model}
              company_id={data["company_id"]?.value}
              valueSelector={valueSelector}
              keySelector={keySelector}
              freeSolo={freeSolo}
              allowedRegex={freeSolo ? item.allowedRegex : undefined}
            />
          </Grid>
        );
      } else if (item.type === "multiselect") {
        let [keySelector, valueSelector] = getSelectors(item);
        return (
          <Grid item xs={item.size} key={item.name}>
            <InputMultiAutocomplete
              value={data[item.name].value}
              name={item.name}
              label={item.label}
              onChange={handleChange}
              required={item.required}
              disabled={item.isDisabled && item.isDisabled(item, data)}
              data_array={item.data_array}
              data_array_model={item.data_array_model}
              valueSelector={valueSelector}
              keySelector={keySelector}
            />
          </Grid>
        );
      } else if (item.type === "file" || item.type === "multifile") {
        return (
          <Grid item xs={item.size} key={item.name}>
            <FileUpload
              multiple={item.type === "multifile"}
              name={item.name}
              label={item.label}
              onChange={handleChange}
              file={data[item.name].value}
              required={item.required}
            />
          </Grid>
        );
      } else if (item.type === "boolean") {
        return (
          <Grid item xs={item.size} key={item.name}>
            <FormControlLabel
              control={
                <Checkbox
                  name={item.name}
                  checked={data[item.name].value === 1}
                  disabled={item.isDisabled && item.isDisabled(item, data)}
                  onChange={(event) =>
                    handleChange(item.name, event.target.checked ? 1 : 0, true)
                  }
                />
              }
              label={item.required ? item.label + "*" : item.label}
            />
          </Grid>
        );
      } else if (item.type === "date") {
        return (
          <Grid item xs={item.size} key={item.name}>
            <DateInput
              onChange={handleChange}
              name={item.name}
              label={t(item.label)}
              value={data[item.name].value}
              required={item.required}
              disabled={item.isDisabled && item.isDisabled(item, data)}
            />
          </Grid>
        );
      } else if (item.type === "datetime") {
        return (
          <Grid item xs={item.size} key={item.name}>
            <DateTimeInput
              onChange={handleChange}
              name={item.name}
              label={t(item.label)}
              value={data[item.name].value}
              required={item.required}
              disabled={item.isDisabled && item.isDisabled(item, data)}
            />
          </Grid>
        );
      } else if (item.type === "custom") {
        return (
          <Grid item xs={item.size} key={item.name}>
            <item.component
              onChange={handleChange}
              name={item.name}
              label={t(item.label)}
              value={data[item.name]}
              disabled={item.isDisabled && item.isDisabled(item, data)}
            />
          </Grid>
        );
      }
      return <div>Unexpected item type: {item.type}</div>;
    };

    const [selectedDraft, setSelectedDraft] = useState(null);
    const [now] = useState(Date.now());
    const [drafts, setDrafts] = useState([]);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
      let stored = getLocalJson(`draft-${getHash(props.form)}`);
      if (stored) {
        for (const draft of stored) {
          draft.value = draft.time;
          draft.label = formatDateTime(draft.time);
        }
        setDrafts([
          ...stored,
          {
            time: now,
            value: now,
            label: t("Nový"),
            data: data, //data?.length > 0 ? data : initializeData(),
          },
        ]);
      } else {
        setDrafts([
          {
            time: now,
            value: now,
            label: t("Nový"),
            data: data, //data?.length > 0 ? data : initializeData(),
          },
        ]);
      }
      setSelectedDraft(now);
    }, [props.form]);

    useEffect(() => {
      if (!drafts || !selectedDraft) return;
      const delayDebounceFn = setTimeout(() => {
        let index = drafts.findIndex((draft) => draft.time === selectedDraft);
        let copy = [...drafts];
        if (copy[index] && copy[index].data !== data) {
          copy[index].data = data;
          setDrafts(copy);
        }
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }, [data, selectedDraft, drafts]);

    useEffect(() => {
      let withoutNow = drafts.filter((draft) => draft.time !== now);
      setLocalJson(
        `draft-${getHash(props.form)}`,
        changed ? drafts : withoutNow
      );
    }, [drafts, changed]);

    useEffect(() => {
      let draftData = drafts.find(
        (draft) => draft.time === selectedDraft
      )?.data;
      if (draftData) {
        setData(draftData);
      }
    }, [selectedDraft]);

    useEffect(() => {
      if (props.getFunctionToDeleteDraft) {
        const func = () => {
          let withoutSelected = drafts.filter(
            (draft) => draft.time !== selectedDraft
          );
          setDrafts(withoutSelected);
          setSelectedDraft(now);
        };
        props.getFunctionToDeleteDraft(func);
      }
    }, [selectedDraft, changed]);

    // const [templates, setTemplates] = useState(() => {
    // let loaded = getLocalJson(`templates-${getHash(props.form)}`) ?? [];
    //   loaded.push({
    //    label: t("Nový"),
    //     value: "default",
    //     data: initializeData(),
    //   });
    //   return loaded;
    // });
    // const [selectedTemplate, setSelectedTemplate] = useState("default");

    // useEffect(() => {
    //   let withoutDefault = templates?.filter(
    //     (template) => template.value !== "default"
    //   );
    //   setLocalJson(`templates-${getHash(props.form)}`, withoutDefault ?? []);
    // }, [templates]);

    // useEffect(() => {
    //   let template = templates.find(
    //     (template) => template.value === selectedTemplate
    //   );
    //   if (template) {
    //     setData(template.data);
    //   }
    // }, [selectedTemplate]);

    const [templates, setTemplates] = useState([
      {
        label: t("Nový"),
        value: "default",
        data: data, //initializeData(),
      },
    ]);
    const [selectedTemplate, setSelectedTemplate] = useState("default");

    useEffect(() => {
      HttpService.get(
        UrlService.apiDomain() + `api/form-templates/${props.model}`
      )
        .then((response) => {
          const loaded = response.data ?? [];
          loaded.push({
            label: t("Nový"),
            value: "default",
            data: data, //initializeData(),
          });
          setTemplates(loaded);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [props.model]);

    const saveTemplate = async (data) => {
      await HttpService.post(
        UrlService.apiDomain() + `api/templates/${props.model}/form`,
        data
      ).catch((error) => {
        console.log(error);
      });
    };

    const deleteTemplate = async (selectedTemplate) => {
      let template = templates.find(
        (template) => template.value === selectedTemplate
      );
      let id = template.id;

      await HttpService.delete(
        UrlService.apiDomain() + `api/form-templates/${id}`
      ).catch((error) => {
        console.log(error);
      });
    };

    useEffect(() => {
      let template = templates.find(
        (template) => template.value === selectedTemplate
      );
      if (template) {
        setData(template.data);
      }
    }, [selectedTemplate]);

    function addRemoveButtonToEndAdornment(endAdornment) {
      const children = Children.toArray(endAdornment.props.children);
      children.push(
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            deleteTemplate(selectedTemplate);
            setTemplates(
              templates.filter(
                (template) => template.value !== selectedTemplate
              )
            );
            setSelectedTemplate("default");
          }}
        >
          <Delete />
        </IconButton>
      );
      return cloneElement(endAdornment, {}, children);
    }

    if (groups && groups.length > 0) {
      return (
        <div
          style={{ maxHeight: "70vh", margin: 0, padding: 0, overflow: "auto" }}
        >
          {groups.map((group) => (
            <Container key={group.key + "_container"} sx={{ p: 0, mt: 1 }}>
              <Accordion
                onChange={(event, expanded) => {
                  setGroups((prevState) => {
                    let newState = [...prevState];
                    newState.find((g) => g.key === group.key).open = expanded;
                    return newState;
                  });
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{group.key}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid spacing={2} container key={group.key + "_form"}>
                    {group.fields.map(getInput)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Container>
          ))}
        </div>
      );
    }

    /*let dataInit = initializeData();
	for (const item of props.form) { // copy invoices
		let val = dataInit[item.name].value;
		if (props.handleChange && item.type === "select" && val !== "") {
			dataInit = props.handleChange(dataInit, item.name, val);
		}
	}*/

    return (
      <Grid container spacing={2}>
        {!props.noTemplates && (
          <>
            <Container sx={{ display: "flex", mt: 2 }}>
              {/* <Autocomplete
                            size={"small"}
                            sx={{width: '200px'}}
                            value={drafts ? drafts.find(d => d.time === selectedDraft) ?? '' : ''}
                            disableClearable
                            renderInput={
                                (params) => <TextField {...params} label="Koncept" variant="outlined"/>
                            } options={drafts ?? []}
                            onChange={(e, val) => {
                                if (val.time) {
                                    setSelectedDraft(val.time)
                                }
                            }}/> */}
              {now !== selectedDraft && (
                <Button
                  onClick={() => {
                    setDrafts(
                      drafts.filter((draft) => draft.time !== selectedDraft)
                    );
                    setSelectedDraft(now);
                  }}
                >
                  Delete current draft
                </Button>
              )}
              {now === selectedDraft && (
                <Button
                  onClick={() => {
                    let dataInit = initializeData();
                    for (const item of props.form) {
                      // copy invoices
                      let val = dataInit[item.name].value;
                      if (
                        props.handleChange &&
                        item.type === "select" &&
                        val !== ""
                      ) {
                        dataInit = props.handleChange(dataInit, item.name, val);
                      }
                    }
                    setData(dataInit);

                    setChanged(false);
                  }}
                >
                  {t("Resetovat změny")}
                </Button>
              )}
              <Autocomplete
                size={"small"}
                sx={{ width: "200px" }}
                value={
                  templates.find((t) => t.value === selectedTemplate) ?? ""
                }
                disableClearable
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Šablóna")}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment:
                        selectedTemplate !== "default"
                          ? addRemoveButtonToEndAdornment(
                              params.InputProps.endAdornment
                            )
                          : params.InputProps.endAdornment,
                    }}
                  />
                )}
                options={templates ?? []}
                onChange={(e, newVal) => {
                  if (typeof newVal === "string") {
                    let value = newVal.toLowerCase().trim().replace(" ", "_");
                    console.log("Value", value);
                    if (
                      newVal &&
                      !templates.find((template) => template.value === value)
                    ) {
                      setTemplates([
                        ...templates,
                        {
                          value: value,
                          label: newVal,
                          data: data,
                        },
                      ]);
                      saveTemplate({
                        value: value,
                        label: newVal,
                        data: data,
                      });
                    }
                    setSelectedTemplate(value);
                  } else {
                    setSelectedTemplate(newVal.value);
                  }
                }}
              />
            </Container>
            <Divider sx={{ my: 1 }} />
          </>
        )}
        {props.form.map((item) => getInput(item))}
      </Grid>
    );
  },
  (prev, next) => {
    return (
      prev.form === next.form &&
      prev.externalData === next.externalData &&
      prev.outerData === next.outerData &&
      prev.defaultValues === next.defaultValues &&
      prev.hidden === next.hidden
    );
  }
);
