import PropTypes from "prop-types";
import { format } from "date-fns";
import { Avatar, Box, Paper, Typography, IconButton } from "@material-ui/core";
import { useSelector } from "../../../store";
import TrashIcon from "../../../icons/Trash";
import { useDispatch } from "../../../store";
import toast from "react-hot-toast";
import { deleteComment } from "../../../slices/vacancies";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const memberSelector = (state, memberId) => {
  const { members } = state.vacancies;

  return members.byId[memberId];
};

const KanbanComment = (props) => {
  const { t } = useTranslation();

  const { createdAt, commentId, cardId, memberId, message, ...other } = props;
  const member = useSelector((state) => memberSelector(state, memberId));
  const { user } = useAuth();
  const visibleDeleteIcon = user.id == memberId ? "visible" : "hidden";
  const dispatch = useDispatch();
  const handleDelete = async () => {
    try {
      await dispatch(deleteComment({ commentId, cardId }));
      toast.success(t("Záznam smazán!"));
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        mb: 2,
      }}
      {...other}
    >
      {/* <Avatar src={member.avatar} /> */}
      <Box
        sx={{
          ml: 2,
          flexGrow: 1,
        }}
      >
        <Typography color="textPrimary" variant="subtitle2">
          {member.name}
        </Typography>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            width: 1,
          }}
        >
          <Paper
            sx={{
              width: 1,
              backgroundColor: "background.default",
              mt: 1,
              p: 2,
            }}
            variant="outlined"
          >
            <Typography color="textPrimary" variant="body2">
              {message}
            </Typography>
          </Paper>
          <IconButton
            onClick={handleDelete}
            sx={{ visibility: visibleDeleteIcon }}
          >
            <TrashIcon fontSize="small" />
          </IconButton>
        </Box>
        <Typography
          color="textSecondary"
          component="p"
          sx={{ mt: 1 }}
          variant="caption"
        >
          {format(createdAt, " dd.LL 'v' H:mm")}
        </Typography>
      </Box>
    </Box>
  );
};

KanbanComment.propTypes = {
  createdAt: PropTypes.number.isRequired,
  memberId: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  commentId: PropTypes.number.isRequired,
  cardId: PropTypes.number.isRequired,
};

export default KanbanComment;
