"use strict";


import UIPageContentTopBarFilterSwitcherT from "./UIPageContentTopBarFilterSwitcherT.mjs";

import UIPageContentTopBarFilterSwitcherProducerAbstractT from "../../../../../../Interface/UI/Page/Content/TopBar/FilterSwitcher/_/UIPageContentTopBarFilterSwitcherProducerAbstractT.mjs";


const UIPageContentTopBarFilterSwitcherProducerT = class UIPageContentTopBarFilterSwitcherProducerT extends UIPageContentTopBarFilterSwitcherProducerAbstractT {
	
	constructor(
		
	){
		
		console.log( "UIPageContentTopBarFilterSwitcherProducerT.constructor" );
		
	
		super( );
		
	}
	
	Produce(
		...Props
	){
		
		console.log( "UIPageContentTopBarFilterSwitcherProducerT.Produce", Props );
		
		
		return new UIPageContentTopBarFilterSwitcherT(
			...Props
		);
		
	}
	
};


export default UIPageContentTopBarFilterSwitcherProducerT;