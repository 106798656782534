import { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { useDispatch } from "../../../store";
import { updateEmployeeList } from "../../../slices/vacancies";
import useMounted from "../../../hooks/useMounted";
import useSettings from "../../../hooks/useSettings";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import CheckRenderer from "../../../aggrid/CheckRenderer.js";
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import LocaleRu from "../../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const EmployeesTable = ({ vacancyId, handleOptionsClose, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customComparator = (valueA, valueB) => {
    if (valueA === null || valueB === null) {
      return valueA === valueB ? 0 : valueA === null ? -1 : 1;
    }
    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else {
      // return default value for non-string and non-number values
      return 0;
    }
  };

  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  var coldefs = [
    {
      headerName: t("Přidat"),
      field: "add",
      cellRenderer: "CheckRenderer",
      editable: true,
    },
    {
      headerName: t("Jméno"),
      field: "name",
    },
    {
      headerName: t("Příjmení"),
      field: "surname",
    },
    {
      headerName: t("Datum narození"),
      field: "date_of_birth",
    },
    {
      headerName: t("Pohlaví"),
      field: "sex",
    },
    {
      headerName: t("Datum registrace"),
      field: "datum_registrace",
    },

    {
      headerName: t("Občanství"),
      field: "citizenship",
    },
    {
      headerName: t("Telefon"),
      field: "phone",
    },
  ];
  const mounted = useMounted();

  const { settings } = useSettings();
  const [rowdata, setRowdata] = useState([]);
  const [columndefs, setColumndefs] = useState(coldefs);
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});
  const [loading, setLoading] = useState(true);

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    editable: false,
    resizable: true,
    suppressMovableColumns: true,
    menuTabs: ["filterMenuTab", "columnsMenuTab"],
    sortable: true,
    filter: true,
    suppressContextMenu: true,
    comparator: customComparator,
  };

  const frameworkComponents = {
    CheckRenderer: CheckRenderer,
  };

  const grd = () => {
    HttpService.post(UrlService.apiDomain() + `api/vacancies-employees`, {
      type,
      vacancy_id: vacancyId,
    })
      .then((res) => {
        setRowdata(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    grd();
  }, [vacancyId, type]);

  const handleEmployeeAdd = async (arr) => {
    try {
      await dispatch(updateEmployeeList({ list: arr, cardId: vacancyId }));
      toast.success(t("Záznam obnoven!"));
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  const onCellCValueChanged = (event) => {
    event.data.field = event.colDef.field;
    const route =
      event.newValue == true
        ? "api/add-employee-to-vacancy"
        : "api/delete-employee-to-vacancy";
    HttpService.post(UrlService.apiDomain() + route, {
      employee_id: event.data.id,
      vacancy_id: vacancyId,
      state: event.newValue,
    })
      .then((res) => {
        if (res.data.result == "failed") {
          alert(t(res.data.message));
          event.node.data[event.data.field] = event.oldValue;
          event.node.setData(event.node.data);
        } else {
          console.log(res);
          handleEmployeeAdd(res.data.checklists[0].checkItems);
          console.log("Successfully updated");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);

    const columnState = JSON.parse(
      localStorage.getItem("vacancies_people_column_state")
    );
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  };

  const onDragStopped = (params) => {
    const columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem("vacancies_people_column_state", columnState);
  };

  useEffect(() => {
    setloctext(lt);
    setColumndefs(coldefs);
  }, [t("cs")]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    //  border: "2px solid #000",
  };

  return (
    <div style={style}>
      {/* <Box
        sx={{
          backgroundColor: "background.default",
          height: "100%",
          py: 8,
        }}
      > */}
      <Container
        maxWidth={settings.compact ? "xl" : false}
        style={{
          width: "100%",
          height: "100%",
          background: "#f4f5f7",
          borderRadius: "20px",
          paddingBottom: 100,
        }}
      >
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h5">
              {type === "profession"
                ? t("Lidé ve zvolené profesi")
                : t("Lidé podle preferovaného uživatele")}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  title={t("Excel")}
                  onClick={() => gridApi.exportDataAsExcel()}
                >
                  Excel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleOptionsClose}>
                  {t("Zavřít")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loading && <LinearProgress />}
        <Box
          sx={{ mt: 3 }}
          style={{ width: "100%", height: "calc(100% - 100px)" }}
          id="myGrid"
          className="ag-theme-material"
        >
          <AgGridReact
            modules={AllCommunityModules}
            rowData={rowdata}
            columnDefs={columndefs}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellCValueChanged}
            onGridReady={onGridReady}
            onDragStopped={onDragStopped}
            enableCellChangeFlash={true}
            rowSelection={"multiple"}
            suppressContextMenu={true}
            localeText={loctext}
            popupParent={document.querySelector("body")}
            frameworkComponents={frameworkComponents}
          />
        </Box>
      </Container>
      {/* </Box> */}
    </div>
  );
};

export default EmployeesTable;
