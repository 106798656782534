"use strict";


import EnvMechAbstractT from "./_/EnvMechAbstractT.mjs";

import EnvAbstractT from "../../Procedure/Env/_/EnvAbstractT.mjs";


const EnvT = class EnvT extends EnvAbstractT {

	#EnvMech = null;

	constructor( 
		EnvMech
	){
		
		if( ( EnvMech instanceof EnvMechAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super( );
		
		
		this.#EnvMech = EnvMech;

	}
	
	URL( ){
		
		return this.#EnvMech.URL( );
		
	}

};


export default EnvT;