import UrlService from "../services/UrlService";


const at = localStorage.getItem("accessToken");

export default function unSpouse(id, handleResult, handleError) {
    fetch(UrlService.apiDomain() + "api/spouse/unspouse/" + id, {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + at,
        },
    })
        .then(r => r.json())
        .then(response => {
            if (!response.success) {
                handleError(response.error);
                return;
            }
            handleResult(response.success);
        })
}