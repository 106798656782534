"use strict";


const JSONRPCResponseT = class JSONRPCResponseT {
	
	#IsSuccess = false;
	
	#Result = null;
	
	#Error = null;
	
	#IsOverdue = null;
	
	constructor( 
		IsSuccess,
		Result,
		Error,
		IsOverdue
	){
		
		if( typeof( IsSuccess ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		if( IsSuccess === true ){
		
			if( typeof( Result ) === "undefined" ){
				
				throw new Error( "Argument" );
				
			}
			
			if( typeof( Error ) !== "undefined" ){
				
				throw new Error( "Argument" );
				
			}
		
		} else {
		
			if( typeof( Result ) !== "undefined" ){
				
				throw new Error( "Argument" );
				
			}
			
			if( typeof( Error ) === "undefined" ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		if( typeof( IsOverdue ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#IsSuccess = IsSuccess;
		
		this.#Result = Result;
		
		this.#Error = Error;
		
		this.#IsOverdue = IsOverdue;
		
	}
	
	IsSuccess(
	
	){
	
		return this.#IsSuccess;
	
	}
	
	Result(
	
	){
	
		return this.#Result;
	
	}
	
	Error(
	
	){
	
		return this.#Error;
	
	}
	
	IsOverdue(
	
	){
	
		return this.#IsOverdue;
	
	}
	
};


export default JSONRPCResponseT;