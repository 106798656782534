import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Divider,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Autocomplete,
  FormControl,
} from "@material-ui/core";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";
import toast from "react-hot-toast";
const SendInfo = ({ data, onClose, onlyInfo }) => {
  const { t } = useTranslation();

  const [generalInfoChecked, setGeneralInfoChecked] = useState(true);
  const [photoGalleryChecked, setPhotoGalleryChecked] = useState(true);
  const [detailedInfoChecked, setDetailedInfoChecked] = useState(false);
  const [people, setPeople] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [phoneNumbersError, setPhoneNumbersError] = useState("");
  const [isValid, setIsValid] = useState(false);

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).customers;

  useEffect(() => {
    const isEmployeesValid = selectedEmployee.length > 0;
    const numbers = phoneNumbers.split(",");
    const minDigits = 5;
    const maxDigits = 15;

    const isPhoneNumbersValid = numbers.every((number) => {
      const digitsOnly = number.replace(/\D/g, "");
      return digitsOnly.length >= minDigits && digitsOnly.length <= maxDigits;
    });

    setIsValid(isEmployeesValid || isPhoneNumbersValid);
  }, [selectedEmployee, phoneNumbers]);

  const handleGeneralInfoChange = (event) => {
    setGeneralInfoChecked(event.target.checked);
  };

  const handlePhotoGalleryChange = (event) => {
    setPhotoGalleryChecked(event.target.checked);
  };

  const handleDetailedInfoChange = (event) => {
    setDetailedInfoChecked(event.target.checked);
  };

  const handlePhoneNumbersChange = (event) => {
    const value = event.target.value;
    setPhoneNumbers(value);
    setPhoneNumbersError("");

    const phoneNumberRegex = /^(\+?\d{1,3}[\s,]*)+$/;
    if (!phoneNumberRegex.test(value)) {
      setPhoneNumbersError(
        "Zadejte telefonní čísla v mezinárodním formátu oddělená čárkami."
      );
    } else {
      const numbers = value.split(",");
      const minDigits = 5;
      const maxDigits = 15;

      for (const number of numbers) {
        const digitsOnly = number.replace(/\D/g, "");
        if (digitsOnly.length < minDigits || digitsOnly.length > maxDigits) {
          setPhoneNumbersError("Každé číslo musí obsahovat 5 až 15 číslic.");
          return;
        }
      }

      setPhoneNumbersError("");
    }
  };

  const handleSearch = (value) => {
    if (value.length > 2 && value.length < 5) {
      HttpService.get(
        UrlService.apiDomain() + `api/getAvailableUsers?search=${value}`
      )
        .then((res) => {
          setPeople([...people, ...res.data]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const sendData = async () => {
    const peopleNumbers = selectedEmployee.map((item) => item.phone);
    const positions = data.map((item) => item.id);
    const randomPhones = phoneNumbers.split(",");

    const numbers = [...peopleNumbers, ...randomPhones];
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + "api/send-vacancy-info",
        {
          onlyInfo,
          positions,
          numbers,
          general: generalInfoChecked,
          gallery: photoGalleryChecked,
          details: detailedInfoChecked,
        }
      );
      if (res.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      onClose();
      toast.success("Zpráva odeslána");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  return (
    <Paper
      elevation={12}
      sx={{
        maxWidth: 620,
        mx: "auto",
        p: 2,
      }}
    >
      <Typography color="textPrimary" variant="h6">
        {t("Odeslat")}
      </Typography>
      <Divider sx={{ marginBottom: 3 }} />
      <Grid container spacing={3}>
        {!onlyInfo && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={generalInfoChecked}
                  onChange={handleGeneralInfoChange}
                />
              }
              label="Obecné informace"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={photoGalleryChecked}
                  onChange={handlePhotoGalleryChange}
                />
              }
              label="Fotogalerie"
            />

            {policy_actions.includes("send_full_info") && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={detailedInfoChecked}
                    onChange={handleDetailedInfoChange}
                  />
                }
                label="Podrobné informace"
              />
            )}
          </Grid>
        )}
        <Divider sx={{ marginBottom: 3 }} />
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Autocomplete
              getOptionDisabled={(option) => option.phone == null}
              multiple={true}
              name={`employee`}
              options={people}
              getOptionLabel={(option) =>
                `${option.name} ${option.surname}, ${option?.date_of_birth}, ${option?.code}`
              }
              onChange={(e, value) => {
                setSelectedEmployee(value);
              }}
              value={selectedEmployee}
              onInputChange={(e, value) => handleSearch(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Zadejte první tři písmena příjmení"}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Telefonní čísla (mezinárodní formát oddělená čárkami)"
            fullWidth
            value={phoneNumbers}
            onChange={handlePhoneNumbersChange}
            error={!!phoneNumbersError}
            helperText={phoneNumbersError}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!isValid}
            color="primary"
            fullWidth
            variant="contained"
            onClick={sendData}
          >
            {t("Odeslat")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SendInfo;
