import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import useSettings from "../../hooks/useSettings";
import {
  Box,
  Container,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import SimpleLoader from "../../components/SimpleLoader.js";
import { Close } from "@material-ui/icons";
import MainForm from "./MainForm.js";
import Contacts from "./Contacts.js";
import Interactions from "./Interactions.js";
import Comments from "./comments/index.js";
import CustomerProfile from "../../components/dashboard/vacancies/CustomerProfile.js";
import Positions from "../../pages/settings/positions/index.js";
import RestyTypes from "../../pages/settings/resty_types";
import PlaceAdresses from "../settings/place_addresses/index.js";
import Vacancies from "./Vacancies.js";
import CustomerRequirements from "./CustomerRequirements.js";
import CustomerVacancies from "../../components/dashboard/vacancies/CustomerVacancies.js";
import CustomerEmployees from "./CustomerEmployees.js";
import CoordinatorReports from "../coordinator_reports/index.js";
import CustomerFiles from "./CustomerFiles.js";
import MeetingRecords from "../meeting_records";

const CustomerCard = ({ id, onClose, onAdd, getData, rowData, openOn }) => {
  const { t } = useTranslation();
  const userId = JSON.parse(localStorage.getItem("my_employee")).id;
  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useState(openOn || "main");
  const [customerData, setCustomerData] = useState(null);
  const [customerLegalData, setCustomerLegalData] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({});
  const [customerId, setCustomerId] = useState(id);
  const [loading, setLoading] = useState(id && true);
  const [filials, setFilials] = useState(null);
  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );

  const isDodavatel = policy_action_tree.vacancies.includes("dodavatel_view");

  let customer_legal_id = customerData?.customer_legal_id;

  useEffect(() => {
    if (customer_legal_id) {
      setFilials(
        rowData
          ? rowData.filter(
              (item) => item.customer_legal_id == customer_legal_id
            )
          : []
      );
    }
  }, [customer_legal_id, rowData]);

  const tabs = isDodavatel
    ? [
        {
          label: t("Obecné informace"),
          value: "description",
        },
      ]
    : [
        {
          label: t("Hlavní"),
          value: "main",
        },
      ];

  if (customerId && policy_action_tree.customer_contacts.includes("browse")) {
    tabs.push({
      label: t("Kontakty"),
      value: "contacts",
    });
  }

  if (
    customerId &&
    (policy_action_tree.customer_start_requirements.includes("browse_all") ||
      (policy_action_tree.customer_start_requirements.includes("browse") &&
        (userId == customerData?.owner_id ||
          customerData?.employees?.includes(userId))))
  ) {
    tabs.push({
      label: t("Požadavky na rozjezd"),
      value: "requirements",
    });
  }

  if (customerId && policy_action_tree.customers.includes("description")) {
    tabs.push({
      label: t("Obecné informace"),
      value: "description",
    });
  }
  if (customerId && policy_action_tree.customers.includes("description")) {
    tabs.push({
      label: t("Podrobné informace"),
      value: "info",
    });
  }
  if (customerId && policy_action_tree.customers.includes("positions_send")) {
    tabs.push({
      label: t("Pozice - Odeslat"),
      value: "positions_send",
    });
  }
  if (
    customerId &&
    policy_action_tree.customer_interactions.includes("browse")
  ) {
    tabs.push({
      label: t("Interakce"),
      value: "interactions",
    });
  }
  if (customerId && policy_action_tree.customer_comments.includes("browse")) {
    tabs.push({
      label: t("Poznámky"),
      value: "comments",
    });
  }
  if (id && policy_action_tree.customers.includes("files")) {
    tabs.push({
      label: t("Přílohy"),
      value: "files",
    });
  }
  if (customerId && policy_action_tree.settings.includes("positions")) {
    tabs.push({
      label: t("Pozice"),
      value: "positions",
    });
  }
  if (customerId && policy_action_tree.settings.includes("resty_types")) {
    tabs.push({
      label: t("Resty"),
      value: "rests",
    });
  }
  if (customerId && policy_action_tree.place_addresses.includes("browse")) {
    tabs.push({
      label: t("Adresy místa"),
      value: "place_addresses",
    });
  }
  if (customerId && policy_action_tree.customers.includes("browse")) {
    tabs.push({
      label: t("Požadavky lidi"),
      value: "vacancies",
    });
  }
  if (
    customerId &&
    policy_action_tree.customers.includes("customer_employees")
  ) {
    tabs.push({
      label: t("Zaměstnanci"),
      value: "customer_employees",
    });
  }
  if (
    customerId &&
    policy_action_tree.dailycoordinator_reports.includes("browse")
  ) {
    tabs.push({
      label: t("Denní reporty"),
      value: "daily_reports",
    });
  }
  if (customerId && policy_action_tree.meeting_records.includes("browse")) {
    tabs.push({
      label: t("Zapisy z porad"),
      value: "meeting_records",
    });
  }

  const grd = (customer_id) => {
    if (customer_id) {
      HttpService.get(
        UrlService.apiDomain() + "api/customers/get/one/" + customer_id
      )
        .then((res) => {
          setCustomerData(res.data.data);
          setCustomerLegalData(res.data.data_customer_legal);
          setCustomerInfo({
            id: customer_id,
            description_primary:
              res.data.data.description_primary ??
              res.data.data_customer_legal.description_primary,
            description_secondary:
              res.data.data.description_secondary ??
              res.data.data_customer_legal.description_secondary,
            logo: res.data.data.logo ?? res.data.data_customer_legal.logo,
            name: res.data.data.name,
            photos:
              res.data.data &&
              res.data.data.photos &&
              res.data.data.photos.length > 0
                ? res.data.data.photos
                : res.data.data_customer_legal &&
                  res.data.data_customer_legal.photos &&
                  res.data.data_customer_legal.photos.length > 0
                ? res.data.data_customer_legal.photos
                : [],
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    grd(customerId);
    // getCustomerInfo();
  }, [customerId]);

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 1,
      }}
    >
      <Container maxWidth={settings.compact ? "xl" : false}>
        <Box
          sx={{
            mt: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">
            {customerData
              ? `${t("Upravit firmu")} ${customerData?.name}`
              : t("Vytvořit firmu")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Tabs
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Divider />
        {loading ? (
          <SimpleLoader />
        ) : (
          <Box sx={{ mt: 0 }}>
            {currentTab === "main" && (
              <MainForm
                setCustomerId={setCustomerId}
                filials={filials}
                grd={grd}
                data={customerData}
                customerLegalData={customerLegalData}
                onClose={onClose}
                onAdd={onAdd}
                getData={getData}
              />
            )}
            {currentTab === "contacts" && (
              <Contacts
                id={customerId}
                main={customerData && customerData.main_contact}
              />
            )}
            {currentTab === "requirements" && (
              <CustomerRequirements
                customer_id={customerId}
                getMainData={grd}
              />
            )}
            {currentTab === "description" && (
              <CustomerProfile
                customer={customerInfo}
                setCustomerData={setCustomerInfo}
                isDodavatel={isDodavatel}
              />
            )}
            {currentTab === "info" && (
              <CustomerProfile
                info={true}
                customer={customerInfo}
                setCustomerData={setCustomerInfo}
              />
            )}
            {currentTab === "files" && (
              <CustomerFiles customer_id={customerId} />
            )}
            {currentTab === "positions_send" && (
              <CustomerVacancies customer_id={customerId} />
            )}
            {currentTab === "interactions" && (
              <Interactions customer={customerId} />
            )}
            {currentTab === "comments" && <Comments customer={customerId} />}
            {currentTab === "vacancies" && <Vacancies customer={customerId} />}
            {currentTab === "positions" && <Positions id={customerId} />}
            {currentTab === "rests" && <RestyTypes id={customerId} />}
            {currentTab === "place_addresses" && (
              <PlaceAdresses customer={customerId} />
            )}
            {currentTab === "customer_employees" && (
              <CustomerEmployees customer_id={customerId} />
            )}
            {currentTab === "daily_reports" && (
              <CoordinatorReports customer_id={customerId} fromCard={true} />
            )}
            {currentTab === "meeting_records" && (
              <MeetingRecords customer_id={customerId} fromCard={true} />
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default CustomerCard;
