"use strict";


import HTTPHeaderT from "../../../../Type/HTTP/Header/HTTPHeaderT.mjs";

import HTTPHeadersT from "../../../../Type/HTTP/Header/HTTPHeadersT.mjs";

import HTTPClientAbstractT from "../../../../Interface/Network/HTTP/Client/_/HTTPClientAbstractT.mjs";


const HTTPClientXMLHTTPRequestT = class HTTPClientXMLHTTPRequestT extends HTTPClientAbstractT {
	
	constructor(
		
	){
		
		super( );
		
	}
	
	Request(
		Method,
		URL,
		Headers,
		Data,
		
		SuccessCb,
		ErrorCb
	){
		
		if( typeof( Method ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( URL ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( Headers instanceof HTTPHeadersT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( Data instanceof Uint8Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		let HTTPRequest = new XMLHttpRequest( );
		
		HTTPRequest.open( Method, URL );
		
		HTTPRequest.responseType = "arraybuffer";
		
		for(
			let Key of Headers.Keys( )
		){
			
			let Header = Headers.Get( Key );
			
			HTTPRequest.setRequestHeader( Header.Key( ), Header.Value( ) );
			
		}
		
		
		HTTPRequest.onload = function( Ev ){
			
			console.log( "HTTPClientT", "OnLoad" );
			
			let Status = HTTPRequest.status;
			
			let StatusMessage = HTTPRequest.statusText;
			
			
			let Headers = new HTTPHeadersT( [
				
			] );
			
			const HeadersArray = ( HTTPRequest.getAllResponseHeaders( ) ).trim( ).split( /[\r\n]+/ );
			
			for( 
				let I = 0; 
				I < HeadersArray.length;
				I++ 
			){
				
				let Line = HeadersArray[ I ];
				
				const Parts = Line.split(": ");
				
				const Key = Parts.shift( );
				
				const Value = Parts.join( ": " );
			
				Headers.Set( new HTTPHeaderT( Key, Value ) );
			
			}
			
			
			let Body = new Uint8Array( HTTPRequest.response );
			

			SuccessCb( Status, StatusMessage, Headers, Body );
			
		};
		
		HTTPRequest.onerror = function( Err ) {

			ErrorCb( Err );

		};
		
		console.log( "HTTPClientT", "Request" );
		
		HTTPRequest.send( Data );
		
	}
	
	Upload(
		URL,
		Headers,
		Data,
		
		SuccessCb,
		ErrorCb
	){
		
		if( typeof( URL ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( Headers instanceof HTTPHeadersT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( Data instanceof FormData ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		let HTTPRequest = new XMLHttpRequest( );
		
		HTTPRequest.open( "POST", URL );
		
		HTTPRequest.responseType = "arraybuffer";
		
		for(
			let Key of Headers.Keys( )
		){
			
			let Header = Headers.Get( Key );
			
			HTTPRequest.setRequestHeader( Header.Key( ), Header.Value( ) );
			
		}
		
		
		HTTPRequest.onload = function( Ev ){
			
			console.log( "HTTPClientT", "OnLoad" );
			
			let Status = HTTPRequest.status;
			
			let StatusMessage = HTTPRequest.statusText;
			
			
			let Headers = new HTTPHeadersT( [
				
			] );
			
			const HeadersArray = ( HTTPRequest.getAllResponseHeaders( ) ).trim( ).split( /[\r\n]+/ );
			
			for( 
				let I = 0; 
				I < HeadersArray.length;
				I++ 
			){
				
				let Line = HeadersArray[ I ];
				
				const Parts = Line.split(": ");
				
				const Key = Parts.shift( );
				
				const Value = Parts.join( ": " );
			
				Headers.Set( new HTTPHeaderT( Key, Value ) );
			
			}
			
			
			let Body = new Uint8Array( HTTPRequest.response );
			

			SuccessCb( Status, StatusMessage, Headers, Body );
			
		};
		
		HTTPRequest.onerror = function( Err ) {

			ErrorCb( Err );

		};
		
		console.log( "HTTPClientT", "Request" );
		
		HTTPRequest.send( Data );
		
	}
	
	Download(
		URL,
		Headers,
		
		SuccessCb,
		ErrorCb
	){
		
		if( typeof( URL ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( Headers instanceof HTTPHeadersT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		let HTTPRequest = new XMLHttpRequest( );
		
		HTTPRequest.open( "GET", URL );
		
		HTTPRequest.responseType = "blob";
		
		for(
			let Key of Headers.Keys( )
		){
			
			let Header = Headers.Get( Key );
			
			HTTPRequest.setRequestHeader( Header.Key( ), Header.Value( ) );
			
		}
		
		
		HTTPRequest.onload = function( Ev ){
			
			console.log( "HTTPClientT", "OnLoad" );
			
			let Status = HTTPRequest.status;
			
			let StatusMessage = HTTPRequest.statusText;
			
			
			let Headers = new HTTPHeadersT( [
				
			] );
			
			const HeadersArray = ( HTTPRequest.getAllResponseHeaders( ) ).trim( ).split( /[\r\n]+/ );
			
			for( 
				let I = 0; 
				I < HeadersArray.length;
				I++ 
			){
				
				let Line = HeadersArray[ I ];
				
				const Parts = Line.split(": ");
				
				const Key = Parts.shift( );
				
				const Value = Parts.join( ": " );
			
				Headers.Set( new HTTPHeaderT( Key, Value ) );
			
			}
			

			SuccessCb( Status, StatusMessage, Headers, HTTPRequest.response );
			
		};
		
		HTTPRequest.onerror = function( Err ) {

			ErrorCb( Err );

		};
		
		console.log( "HTTPClientT", "Request" );
		
		HTTPRequest.send( );
		
	}
	
};


export default HTTPClientXMLHTTPRequestT;