import React, { Component } from "react";
import { Button } from "@material-ui/core";

import PropTypes from "prop-types";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import { useTranslation } from "react-i18next";

const OptionsRenderer = (props) => {
  const { t } = useTranslation();
  const handleClick = (event) => {
    if (props.type != "notifications") {
      props.openOptions(props.data?.id, props.data);
    } else {
      props.openOptions(props.data.id, props.data.rules);
    }
  };

  if (
    props.type == "forms" ||
    props.type == "rooms" ||
    props.type == "positions" ||
    props.type == "resty_types" ||
    props.type == "notifications" ||
    (props.node && props.node.data && props.node.data.data_array === "custom")
  ) {
    return (
      <button
        className="btn btn-default btn-sm edit-ingrid-but"
        onClick={(event) => {
          handleClick(event);
        }}
      >
        <FormatListNumberedIcon />
      </button>
    );
  } else if (
    props.type === "table_cell_button" &&
    props.value !== null &&
    props.value !== undefined
  ) {
    return (
      <Button
        variant="outlined"
        onClick={(event) => {
          handleClick(event);
        }}
      >
        {t(props.value)}
      </Button>
    );
  } else {
    return "";
  }
};

export default OptionsRenderer;
