"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIBooleanReactT from "../../../Boolean/UIBooleanReactT.mjs";


const UIGridRendererBooleanT = class UIGridRendererBooleanT extends React.Component {
	
	#Ref = null;
	
	constructor( Props ){
		
		console.log( "UIGridRendererBooleanT.constructor", Props );
		
		
		super( Props );
		
		
		this.#Ref = React.createRef( );
		
		
		this.state = {
			Value: Props.value ? Props.value : false,
			Disabled: Props.Disabled ? Props.Disabled : false,
			OnChange: Props.OnChange ? Props.OnChange : null,
			Label: Props.Label ? Props.Label : "",
			Color: Props.Color ? Props.Color : "primary",
			Node: Props.node,
			ColDef: Props.colDef
		};
		
	}
	
	DisabledGet(
	
	){
		
		console.log( "UIGridRendererBooleanT.DisabledGet" );
		
	
		return this.#Ref.current.DisabledGet( );
	
	}
	
	DisabledSet( 
		Disabled 
	){
		
		console.log( "UIGridRendererBooleanT.DisabledSet", Disabled );
		
		
		this.#Ref.current.DisabledSet( Disabled );
		
	}
	
	ValueGet(
	
	){
		
		console.log( "UIGridRendererBooleanT.ValueGet" );
		
	
		return this.#Ref.current.ValueGet( );
	
	}
	
	ValueSet( 
		Value 
	){
		
		console.log( "UIGridRendererBooleanT.ValueSet", Value );
		
		
		this.#Ref.current.ValueSet( Value );
		
	}
	
	OnChange(
		Ev
	){
		
		console.log( "UIGridRendererBooleanT.OnChange", Ev.currentTarget.checked, this.state.Node, this.state.Value, this.state.colDef );
		
		
		let Node = this.state.Node;
		
		Node.setDataValue( this.state.ColDef.field, Ev.currentTarget.checked ); //.data[ this.state.ColDef.field ] = Ev.currentTarget.checked;
		
		//this.state.OnChange( this.state.Node, this.state.Value );
		
	}
	
	render( 
	
	){
		
		console.log( "UIGridRendererBooleanT.render", this.state );
		
		
		return React.createElement(
			UIBooleanReactT,
			{
				Value: this.state.Value,
				Disabled: this.state.Disabled,
				OnChange: this.OnChange.bind( this ),
				Label: this.state.Label,
				Color: this.state.Color
			},
			[
				
			]
		);
		
	}
	
};


export default UIGridRendererBooleanT;