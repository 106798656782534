"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


const UIBooleanReactT = class UIBooleanReactT extends React.Component {

	constructor( Props ) {
		
		console.log( "UIBooleanReactT.constructor", Props );
		

		super( Props );
		

		this.state = {
			
			Value: Props.Value ? Props.Value : false,
			Disabled: Props.Disabled ? Props.Disabled : false,
			OnChange: Props.OnChange ? Props.OnChange : null,
			Label: Props.Label ? Props.Label : "",
			Color: Props.Color ? Props.Color : "primary"

		};

	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIBooleanReactT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.setState( {
			[ Name ]: Value
		} );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIBooleanReactT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.state[ Name ];
		
	}
	
	OnChange(
		Ev
	){
		
		console.log( "UIBooleanReactT.DisabledGet", Ev );
			
			
		return this.state.OnChange( Ev );
		
	}

	render( 
	
	) {
		
		return React.createElement(
			MaterialUI.Checkbox, 
			{
				checked: this.state.Value,
				disabled: this.state.Disabled,
				onChange: this.OnChange.bind( this ),
				color: this.state.Color,
				inputProps: { 
					"aria-label": this.state.Label 
				}
			} 
		);
		
	}
	
};

export default UIBooleanReactT;
