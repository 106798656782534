import { GoogleMap, Marker } from "@react-google-maps/api";
import { useCallback, useRef } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControlFalse: false,
  clickableIcons: false,
  keyboardShortcuts: false,
  scrollweel: false,
  disableDoubleClickZoom: false,
  fullscreenControl: false,
};

const Map = ({ сenter, isLoaded }) => {
  const { t } = useTranslation();

  const mapRef = useRef(null);

  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(function callback(map) {
    mapRef.current = null;
  }, []);

  return isLoaded ? (
    <Box sx={{ w: 1, height: "300px", py: 3 }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={сenter}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={defaultOptions}
      >
        <Marker position={сenter} />
      </GoogleMap>
    </Box>
  ) : (
    <div>{t("Načítám mapu...")}</div>
  );
};

export default Map;
