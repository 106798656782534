import { experimentalStyled } from "@material-ui/core/styles";

const LogoRoot = experimentalStyled("svg")``;

const LogoWhite = (props) => (
  <LogoRoot height="70" version="1.1" viewBox="0 0 70 70" width="70" {...props}>
    <title>AWA Partners</title>

    {/* <g transform="matrix(0.35 0 0 0.35 0 0)">
      <g transform="matrix(1 0 0 1 26 26)">
      </g>
      <path transform="matrix(1 0 0 1 26 26)"  d="M38 58.394447L57.737034 88L18.262966 88L38 58.394447zM110 58.394447L129.73703 88L90.26296 88L110 58.394447zM38 65.606L25.737 84L50.262 84L38 65.606zM110 65.606L97.737 84L122.262 84L110 65.606zM74 0C 114.86907 0 148 33.130928 148 74C 148 114.86907 114.86907 148 74 148C 33.130928 148 0 114.86907 0 74C 0 33.130928 33.130928 0 74 0zM74 4C 35.34007 4 4 35.34007 4 74C 4 112.659935 35.34007 144 74 144C 112.659935 144 144 112.659935 144 74C 144 35.34007 112.659935 4 74 4zM101.97407 60L81.97407 90L73.97404 77.99945L65.97407 90L46.5 60L101.97407 60zM65.167 64L53.974033 64L65.97407 82.78845L71.57004 74.39345L65.167 64zM94.49903 64L83.307 64L76.37804 74.39445L81.97407 82.78845L94.49903 64zM78.49903 64L69.97404 64L73.97404 70.78845L78.49903 64z" stroke="none" fill="#ffff" fillRule="nonzero" />
    </g> */}

    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="50.000000pt"
      height="50.000000pt"
      viewBox="0 0 320.000000 132.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,132.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M423 1032 c-103 -37 -172 -102 -218 -206 -25 -56 -25 -61 -25 -321
l0 -265 70 0 69 0 3 133 3 132 220 0 220 0 3 -133 3 -133 72 3 72 3 3 205 c1
112 -2 235 -7 272 -19 135 -95 244 -209 296 -76 35 -203 41 -279 14z m167
-133 c93 -21 161 -95 175 -191 l7 -48 -227 0 -227 0 7 48 c16 109 94 182 215
201 3 0 25 -4 50 -10z"
        />
        <path
          d="M1052 793 c4 -297 12 -330 95 -423 59 -66 114 -97 203 -116 78 -16
165 -7 222 22 24 13 32 13 56 0 57 -29 144 -38 222 -22 89 19 144 50 203 116
83 93 91 126 95 423 l3 257 -75 0 -76 0 0 -232 c0 -128 -4 -249 -10 -268 -13
-47 -63 -106 -112 -131 -44 -23 -132 -36 -141 -22 -3 5 6 33 21 63 21 43 27
70 30 144 3 80 0 96 -20 131 -73 126 -263 126 -336 0 -20 -35 -23 -51 -20
-131 3 -74 9 -101 30 -144 15 -30 24 -58 21 -63 -9 -14 -97 -1 -141 22 -49 25
-99 84 -112 131 -6 19 -10 140 -10 268 l0 232 -76 0 -75 0 3 -257z m607 -119
c56 -71 -10 -231 -80 -194 -56 31 -79 142 -38 194 16 20 29 26 59 26 30 0 43
-6 59 -26z"
        />
        <path
          d="M2543 1036 c-104 -34 -187 -111 -229 -214 -23 -55 -24 -68 -24 -319
l0 -263 70 0 69 0 3 133 3 132 220 0 220 0 3 -132 3 -133 69 0 70 0 0 269 0
268 -35 71 c-65 129 -168 195 -315 199 -52 2 -102 -3 -127 -11z m215 -158 c66
-31 120 -116 122 -190 l0 -28 -225 0 c-136 0 -225 4 -225 10 0 35 25 113 45
140 68 92 176 118 283 68z"
        />
      </g>
    </svg>
  </LogoRoot>
);

export default LogoWhite;
