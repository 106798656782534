import { useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { Avatar, Box, TextField } from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";
import { addComment } from "../../../slices/vacancies";
import { useDispatch } from "../../../store";
import { useTranslation } from "react-i18next";

const KanbanCommentAdd = (props) => {
  const { t } = useTranslation();
  const { cardId, ...other } = props;
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyUp = async (event) => {
    try {
      if (event.key === "Enter" && message) {
        await dispatch(addComment({ cardId, message }));
        setMessage("");
        toast.success(t("Poznámka přidána"));
      }
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
      }}
      {...other}
    >
      {/* <Avatar src={user.avatar} sx={{ mr: 2 }} /> */}
      <TextField
        fullWidth
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        placeholder={t("Napište zprávu...")}
        size="small"
        value={message}
        variant="outlined"
      />
    </Box>
  );
};

KanbanCommentAdd.propTypes = {
  cardId: PropTypes.number.isRequired,
};

export default KanbanCommentAdd;
