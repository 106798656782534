import axios from "axios";


class HttpService {
    async get(url) {
        const at = localStorage.getItem("accessToken");
        const options = {
          headers: {
            Authorization: "Bearer " + at,
          },
        };
        try {
            return await axios.get(url, options);
        } catch (error) {
            console.error("Not able to fetch data", error);
        }
    }

    async getblob( URI ) {
		
        const AccessToken = localStorage.getItem( "accessToken" );
		
        const Options = {
			responseType: "blob"
        };
		
		if( AccessToken !== null ){
			
			Options.headers = {
				
				Authorization: "Bearer " + AccessToken
				
			};
			
		}
		
        try {
			
            return await axios.get( URI, Options );
			
        } catch( Err ) {
			
            console.error( "Not able to fetch data", Err );
			
        }
		
	}
  
    async delete(url) {
        const at = localStorage.getItem("accessToken");
        const options = {
          headers: {
            Authorization: "Bearer " + at,
          },
        };
        try {
            return await axios.delete(url, options);
        } catch (error) {
            console.error("Not able to fetch data", error);
        }
    }

    async postblob(url, data, options = null) {
        const at = localStorage.getItem("accessToken");
        const postOptions = {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + at,
          },
        };
        const finalOptions = Object.assign(postOptions, options);
        try {
            return await axios.post(url, data, finalOptions);
        } catch (error) {
            console.error("Not able to fetch data", error);
            return error.response !== undefined ? error.response : error;
        }
    }

    async post(url, data, options = null) {
        const at = localStorage.getItem("accessToken");
        const postOptions = {
          headers: {
            Authorization: "Bearer " + at,
          },
        };
        const finalOptions = Object.assign(postOptions, options);
        try {
            return await axios.post(url, data, finalOptions);
        } catch (error) {
            console.error("Not able to fetch data", error);
            return error.response !== undefined ? error.response : error;
        }
    }

    async put(url, data, options = null) {
        const at = localStorage.getItem("accessToken");
        const postOptions = {
          headers: {
            Authorization: "Bearer " + at,
          },
        };
        const finalOptions = Object.assign(postOptions, options);
        try {
            return await axios.put(url, data, finalOptions);
        } catch (error) {
            console.error("Not able to fetch data", error);
            return error.response !== undefined ? error.response : error;
        }
    }

}

export default new HttpService();
