import { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Avatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  IconButton,
  Popover,
} from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";
import useSettings from "../../../hooks/useSettings";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import toast from "react-hot-toast";
import Gallery from "./Gallery";
import { useTranslation } from "react-i18next";
import { Send } from "@material-ui/icons";
import { stateToHTML } from "draft-js-export-html";
import {
  convertFromHTML,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { Edit } from "@material-ui/icons";
import SendInfo from "../../../pages/settings/positions/SendInfo.js";

const CustomerProfile = ({ customer, info, setCustomerData, isDodavatel }) => {
  const { t } = useTranslation();
  const at = localStorage.getItem("accessToken");

  const languageOptions = {
    ru: {
      icon: "/static/icons/ru_flag.svg",
      label: "Русский",
    },
    cz: {
      icon: "/static/icons/cs_flag.svg",
      label: "Čeština",
    },
  };

  const { id, name } = customer;
  const { settings } = useSettings();
  const [ruEditable, setRuEditable] = useState(false);
  const [czEditable, setCzEditable] = useState(false);
  const [allPhotos, setAllPhotos] = useState(customer.photos);
  const [preparedFiles, setPrepareFiles] = useState([]);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [logo, setLogo] = useState(customer.logo);
  const [selectedOption, setSelectedOption] = useState(languageOptions.cz);
  const [openSend, setOpenSend] = useState(false);

  const handleOpenSend = () => {
    setOpenSend(true);
  };

  const handleCloseSend = () => {
    setOpenSend(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fileInputRef = useRef(null);

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  let ruData = customer.description_primary?.ru ?? "";
  let czData = customer.description_primary?.cz ?? "";

  if (info) {
    ruData = customer.description_secondary?.ru ?? "";
    czData = customer.description_secondary?.cz ?? "";
  }

  const ruContentHTML = convertFromHTML(ruData);
  const czContentHTML = convertFromHTML(czData);

  const ruState = ContentState.createFromBlockArray(
    ruContentHTML.contentBlocks,
    ruContentHTML.entityMap
  );
  const czState = ContentState.createFromBlockArray(
    czContentHTML.contentBlocks,
    czContentHTML.entityMap
  );

  const ruContent = JSON.stringify(convertToRaw(ruState));
  const czContent = JSON.stringify(convertToRaw(czState));

  const controls = [
    "title",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "highlight",
    "undo",
    "redo",
    "numberList",
    "bulletList",
    "quote",
    "code",
    "clear",
    "save",
  ];

  const saveDesc = async (data, lang) => {
    let field = info ? "description_secondary" : "description_primary";
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + `api/customers/edit`,
        {
          data: {
            id,
            [field]: {
              ...customer[field],
              [lang]: data,
            },
          },
          data_customer_legal: {},
        }
      );
      if (res.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      if (info) {
        setCustomerData((prev) => ({
          ...prev,
          description_secondary: {
            ...prev.description_secondary,
            [lang]: data,
          },
        }));
      } else {
        setCustomerData((prev) => ({
          ...prev,
          description_primary: {
            ...prev.description_primary,
            [lang]: data,
          },
        }));
      }
      toast.success("Popis aktualizován");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  const handleChangeLanguage = (language) => {
    const currentLang = languageOptions[language];
    setSelectedOption(currentLang);
    setOpen(false);
  };

  const onUpload = async (data) => {
    const newPhotos = data.map((item) => item.id.toString());
    const all_photos = [...allPhotos, ...newPhotos];
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + `api/customers/edit`,
        {
          data: {
            id,
            photos: all_photos,
          },
        }
      );
      if (res.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }

      setAllPhotos(all_photos);

      toast.success("Popis aktualizován");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  const downloadFile = async (id) => {
    try {
      const res = await HttpService.getblob(
        UrlService.apiDomain() + `api/customer_images/download/${id}`
      );
      if (res.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      console.log({ res });
      const imageUrl = URL.createObjectURL(res.data);
      return imageUrl;
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  const fetchPreparedFiles = async () => {
    try {
      const preparedFilesPromises = allPhotos.map(async (photo) => {
        const path = await downloadFile(photo);
        return { id: photo, path };
      });
      const prepared = await Promise.all(preparedFilesPromises);
      setPrepareFiles(prepared);
    } catch (error) {
      console.error(error);
    }
  };

  const onDeletePhoto = async (imageId) => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + `api/customers/edit`,
        {
          data: {
            id,
            photos: allPhotos.filter((item) => item != imageId),
          },
        }
      );
      if (res.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      setPrepareFiles((prev) => prev.filter((photo) => photo.id != imageId));
      toast.success("Popis aktualizován");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  const uploadLogo = async (event) => {
    if (isDodavatel) {
      alert(t("Nemáte oprávnění nahrávat fotografie"));
      return;
    }
    const newPhotos = [event.target.files[0]];
    const formData = new FormData();
    Array.from(newPhotos).forEach((photo, i) => {
      formData.append(`photos${i}`, photo);
      formData.append(`id`, id);
    });
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/customer_images/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + at,
          },
        }
      );
      await setLogoToCustomer(response.data.data[0].id);
      setLogo(response.data.data[0].id);
    } catch (error) {
      console.log(error);
    }
  };

  const setLogoToCustomer = async (imageId) => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + `api/customers/edit`,
        {
          data: {
            id,
            logo: imageId.toString(),
          },
        }
      );
      if (res.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      toast.success("Popis aktualizován");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  useEffect(() => {
    fetchPreparedFiles();
  }, [allPhotos]);

  useEffect(async () => {
    logo && setLogoUrl(await downloadFile(logo));
  }, [logo]);

  return (
    <>
      <Popover
        open={openSend}
        anchorEl={document.querySelector("body")}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        onClose={handleCloseSend}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SendInfo data={[]} onlyInfo={true} onClose={handleCloseSend} />
      </Popover>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: "flex",
                  height: 20,
                  width: 20,
                  "& img": {
                    width: "100%",
                  },
                }}
              >
                <img
                  alt={languageOptions[language].label}
                  src={languageOptions[language].icon}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {languageOptions[language].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={info ? null : 6}>
              <Box
                sx={{
                  width: 1,
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 1,
                    display: "inline-flex",
                    // justifyContent: "space-between",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    src={logoUrl}
                    alt="Company Logo"
                    onClick={handleImageUpload}
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={uploadLogo}
                  />
                  <Typography color="textPrimary" variant="h5">
                    {name}
                  </Typography>
                </Box>
                {!info && !isDodavatel && (
                  <Button
                    color="primary"
                    startIcon={<Send fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={handleOpenSend}
                  >
                    {t("Odeslat")}
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  width: 1,
                  minHeight: "800px",
                  display: "flex",
                  flexDirection: info ? "row" : "column",
                }}
              >
                <Box
                  sx={{
                    width: 1,
                    minHeight: 1,
                    overflow: "auto",
                    flexGrow: 1,
                    position: "relative",
                  }}
                >
                  {/* <img
                    src={languageOptions.cz.icon}
                    alt="Czech flag"
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "20px",
                      height: "20px",
                    }}
                  /> */}
                  <IconButton onClick={handleOpen} ref={anchorRef}>
                    <Box
                      sx={{
                        display: "flex",
                        height: 20,
                        width: 20,
                        "& img": {
                          width: "100%",
                        },
                      }}
                    >
                      {selectedOption && (
                        <img
                          alt={selectedOption.label}
                          src={selectedOption.icon}
                        />
                      )}
                    </Box>
                  </IconButton>

                  {selectedOption.label == "Čeština" ? (
                    <>
                      <MUIRichTextEditor
                        controls={controls}
                        label="Sem napište text..."
                        defaultValue={czContent}
                        onSave={(data) => {
                          saveDesc(
                            stateToHTML(convertFromRaw(JSON.parse(data))),
                            "cz"
                          );
                        }}
                        toolbar={czEditable}
                        readOnly={!czEditable}
                      />
                      {!isDodavatel && (
                        <Button
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                          }}
                          color="primary"
                          sx={{ m: 1 }}
                          variant="contained"
                          onClick={() => setCzEditable(!czEditable)}
                          title={"Uprava"}
                        >
                          <Edit />
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <MUIRichTextEditor
                        controls={controls}
                        label="Напишите описание..."
                        defaultValue={ruContent}
                        onSave={(data) => {
                          saveDesc(
                            stateToHTML(convertFromRaw(JSON.parse(data))),
                            "ru"
                          );
                        }}
                        toolbar={ruEditable}
                        readOnly={!ruEditable}
                      />
                      {!isDodavatel && (
                        <Button
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                          }}
                          color="primary"
                          sx={{ m: 1 }}
                          variant="contained"
                          onClick={() => setRuEditable(!ruEditable)}
                          title={"Uprava"}
                        >
                          <Edit />
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
            {!info && (
              <Grid item xs={12} md={6}>
                <Gallery
                  photos={preparedFiles}
                  model={"customer_images"}
                  onUpload={onUpload}
                  customer_id={customer.id}
                  onDelete={onDeletePhoto}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CustomerProfile;
