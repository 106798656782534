import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, IconButton, TextField, Tooltip } from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import HttpService from '../../../services/HttpService.js';
import UrlService from '../../../services/UrlService.js';
const ChatMessageAdd = (props) => {
    const { t } = useTranslation();
  const { disabled, onSend, ...other } = props;
  const fileInputRef = useRef(null);
  const { user } = useAuth();
  const [body, setBody] = useState('');

  const handleAttach = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    setBody(event.target.value);
  };

  const handleSend = () => {
    if (!body) {
      return;
    }



      const fileInput = document.querySelector('#commfiles') ;
      var files=fileInput.files;

      if( files.length>0) {

          let files_ok = true;
          let allowed_exts = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'doc', 'docx', 'pdf', 'xls', 'xlsx'];


          Array.from(files).forEach(e => {
              if (!allowed_exts.includes(e.name.split('.')[e.name.split('.').length - 1].toLowerCase())) files_ok = false;
          });


          if (!files_ok) {
              alert(t('Jsou povolené pouze obrázky, pdf, word a excel'));
              return false;
          }

      }

          var form_data = new FormData();

  form_data.append('body',body);


          if (typeof files[0]!=='undefined') form_data.append('file1', files[0]);
          if (typeof files[1]!=='undefined') form_data.append('file2', files[1]);
          if (typeof files[2]!=='undefined') form_data.append('file3', files[2]);
          if (typeof files[3]!=='undefined') form_data.append('file4', files[3]);
          if (typeof files[4]!=='undefined') form_data.append('file5', files[4]);



      HttpService.post(UrlService.apiDomain()+'api/communication/'+props.node.id, form_data)
          .then(res => {

              if (res.data.result=='failed') {
                  alert(res.data.message);
              } else {
                  fileInput.value='';
                  props.onSend(res.data.entry);
                  console.log('Successfully updated');

              }

          })
          .catch((error) => {
              alert(error);
          });


    setBody('');
  };

  const handleKeyUp = (event) => {
    if (event.code === 'ENTER') {
      handleSend();
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexShrink: 0,
        px: 2,
        py: 1
      }}
      {...other}
    >

      <TextField
        disabled={disabled}
        fullWidth
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        placeholder={t('Odeslat zprávu')}
        value={body}
        size="small"
        variant="outlined"
      />
      <Tooltip title={t('Odeslat')}>
        <span>
          <IconButton
            color="primary"
            disabled={!body || disabled}
            onClick={handleSend}
          >
            <SendIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
      <Box
        sx={{
          alignItems: 'center',
          display: {
            xs: 'none',
            sm: 'flex'
          }
        }}
      >
        <Divider
          orientation="vertical"
          sx={{ height: 24 }}
        />

        <Tooltip title={t('Přiložit soubor')}>
          <span>
            <IconButton
              disabled={disabled}
              edge="end"
              onClick={handleAttach}
            >
              <AttachFileIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <input
          id="commfiles"
hidden
          multiple={true}
        ref={fileInputRef}
        type="file"
      />
    </Box>
  );
};

ChatMessageAdd.propTypes = {
  disabled: PropTypes.bool,
  onSend: PropTypes.func
};

ChatMessageAdd.defaultProps = {
  disabled: false
};

export default ChatMessageAdd;
