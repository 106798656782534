"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UITextReactT from "../../../Text/UITextReactT.mjs";


const UIGridEditorTextReactT = class UIGridEditorTextReactT extends React.Component {
	
	#Element = null;
	
	#Ref = null;
	
	#API = null;
	
	#ValueStart = null;
	
	constructor(
		Props
	){
		
		console.log( "UIGridEditorTextReactT.constructor", Props );
		
		
		super( Props );
		
		
		this.#Ref = React.createRef( );
		
		
		this.#API = Props.api;
		
		
		this.#ValueStart = Props.value;
		
		
		this.#Element = React.createElement(
			UITextReactT,
			{
				...Props,
				Value: Props.value, 
				ref: this.RefSet.bind( this ),
				OnCloseClick: this.OnCloseClick.bind( this )
			}
		);
		
	}
	
	RefSet( Ref ){
		
		console.log( "UIGridEditorTextReactT.RefSet", Ref );
		
		
		this.#Ref = Ref;
		
	}
	
	OnCloseClick( ){
		
		console.log( "UIGridEditorDateReactT.OnCloseClick" );
		
		
		this.#API.stopEditing( true );
		
	}
	
	getValue( ){
		
		console.log( "UIGridEditorTextReactT.getValue", this.#Ref.PropGet( "IsValid" ), this.#Ref.PropGet( "Value" ) );
		
		if( this.#Ref !== null ){
			
			if( this.#Ref.PropGet( "IsValid" ) === false ){
				
				return this.#ValueStart;
				
			}
		
			return this.#Ref.PropGet( "Value" );
		
		} else {
			
			throw new Error( "Not mounted" );
			
		}
		
	}
	
	render( ){
		
		console.log( "UIGridEditorTextReactT.render" );
		
		
		return this.#Element;
		
	}
	
};

export default UIGridEditorTextReactT;