import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import scanPassport from "../../api/scanPassport";
import { Navigate, useLocation } from "react-router-dom";
import useSearch from "../../hooks/useSearch";
import searchEmployee from "../../api/searchEmployee";
import SearchDialog from "./SearchDialog";
import { Backspace, Upload } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function SearchEmployee() {
  const { t } = useTranslation();
  const searchContext = useSearch();
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [passportLoading, setPassportLoading] = useState(false);

  const passportInputRef = useRef(null);

  const location = useLocation();

  const uploadPassport = (e) => {
    setPassportLoading(true);
    const files = Array.from(e.target.files);
    scanPassport(
      files[0],
      (result, passport, photo) => {
        console.log("PAS");
        console.log(result);
        console.log(passport);
        searchEmployee(
          {
            name: result.name,
            surname: result.surname,
            birthday: result.date_of_birth,
          },
          (employee) => {
            searchContext.setFoundEmployee(employee);
            setRedirect(true);
            setPassportLoading(false);
          },
          (error) => {
            alert(t(error));
            setPassportLoading(false);
          },
          null
        );
      },
      (error) => {
        alert(t("Chyba čtení pasu"));
        setPassportLoading(false);
      }
    );
  };
  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).employees;

  if (
    redirect &&
    location.pathname !== "/dashboard/employees" &&
    !searchContext.getLoaded()
  )
    return <Navigate to={"/dashboard/employees"} />;
  let found = searchContext.getFoundEmployee();
  return (
    <>
      <input
        id={"passportInput"}
        onChange={uploadPassport}
        hidden
        ref={passportInputRef}
        name="file"
        type="file"
      />
      <Button
        variant={"outlined"}
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "50px",
          mr: "5px",
          color: "white",
          borderColor: "white",
          minWidth: "100px",
        }}
        onClick={() => {
          if (found) {
            searchContext.clearFoundEmployee();
          } else {
            setOpen(true);
          }
        }}
      >
        {!found && (
          <span style={{ color: "white", width: "150px", textAlign: "left" }}>
            {t("Vyhledat")}
          </span>
        )}
        {!found && <SearchIcon />}
        {found && (
          <span style={{ color: "white", width: "150px", textAlign: "left" }}>
            {t("Zrušit vyhledávaní")}
          </span>
        )}
        {found && <Backspace />}
      </Button>
      {policy_actions.includes("passport_search") && (
        <Button
          variant={"outlined"}
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "50px",
            mr: "30px",
            color: "white",
            borderColor: "white",
          }}
          onClick={() => {
            if (!passportLoading) {
              passportInputRef.current.click();
            }
          }}
        >
          {!passportLoading && <Upload />}
          {passportLoading && <CircularProgress sx={{ color: "white" }} />}
        </Button>
      )}
      <SearchDialog
        passportSearch={false}
        isOpen={open}
        onClose={() => setOpen(false)}
        month={searchContext.month}
        year={searchContext.year}
        customer={searchContext.customer}
        onFound={(found) => {
          searchContext.setFoundEmployee(found);
          setRedirect(true);
        }}
        name={searchContext.name}
        surname={searchContext.surname}
        birthday={searchContext.birthday}
        getName={(name) => searchContext.setName(name)}
        getSurname={(surname) => searchContext.setSurname(surname)}
        getBirthday={(birthday) => searchContext.setBirthday(birthday)}
      />
    </>
  );
}
