import UrlService from "../services/UrlService";


const at = localStorage.getItem("accessToken");

export default function connectSpouse(id1, id2, handleResult, handleError) {
    fetch(UrlService.apiDomain() + `api/spouse/connect/${id1}/${id2}`, {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + at,
        },
    })
        .then(r => r.json())
        .then(response => {
            if (!response.success) {
                handleError(response.error);
                return;
            }
            handleResult(response.success);
        })
}