import FormWithValidation from "./FormWithValidation";
import {useEffect, useState} from "react";
import {Autocomplete, Button, Container, Divider, TextField, Typography} from "@material-ui/core";
import {getLocalJson, setLocalJson} from "../../utils/localStorageUtills";
import {getHash} from "../../utils/getHash";

export default function FormWithSubmit({
  noTemplates,
  onClose,
  onSubmit,
  submitText,
  form,
  sx,
  defaultValues,
  handleChange,
  model,
  parentModel,
  company,
  hidden
}) {
  const [data, setData] = useState([]);
  const [valid, setValid] = useState(false);
  const [deleteDraft, setDeleteDraft] = useState(null);
  const handleSubmit = () => {
    if (deleteDraft) {
      deleteDraft();
    }
    onClose();
    onSubmit(data);
  };

  return (
    <>
      <Container sx={sx}>
        <FormWithValidation
          hidden={hidden}
          company={company}
          parentModel={parentModel}
          model={model}
          noTemplates={noTemplates}
          getFunctionToDeleteDraft={(func) => {
            setDeleteDraft(() => func);
          }}
          form={form}
          defaultValues={defaultValues}
          handleChange={handleChange}
          onChange={(d, v) => {
            setData(d);
            setValid(v);
          }}
        />
      </Container>
      <Divider sx={{ my: 1 }} />
      <Button
        fullWidth
        variant="contained"
        disabled={!valid}
        onClick={handleSubmit}
      >
        {submitText}
      </Button>
    </>
  );
}