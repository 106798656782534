import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Autocomplete,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import getYourCompanyDetails from "../../../api/getYourCompanyDetails";
import updateYourCompanyDetails from "../../../api/updateYourCompanyDetails";
import { IbanRegex } from "./Transfer/InputIBAN";
import { SwiftRegex } from "./Transfer/InputBicSwift";
import { AccountNumberRegex } from "./Transfer/InputAccountNumber";
import {
  PaymentTextRegex,
  PaymentSecondaryTextRegex,
} from "./Transfer/InputText";
import { FullInputRegex } from "./Transfer/InputFullText";
import CountrySelect from "./Transfer/CountrySelect";
import { NumRegEx } from "./Transfer/InputNum";

import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";

export default function PaymentDetailsModalNew(props) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(props.isEdit ? true : false);
  const [updating, setUpdating] = useState(false);

  function startsWithCapitalLetter(str) {
    const regex = /^[A-Z]/;
    return regex.test(str);
  }

  const countries = [
    { code: "AD", label: "Andorra", phone: "376" },
    {
      code: "AE",
      label: "United Arab Emirates",
      phone: "971",
    },
    { code: "AF", label: "Afghanistan", phone: "93" },
    {
      code: "AG",
      label: "Antigua and Barbuda",
      phone: "1-268",
    },
    { code: "AI", label: "Anguilla", phone: "1-264" },
    { code: "AL", label: "Albania", phone: "355" },
    { code: "AM", label: "Armenia", phone: "374" },
    { code: "AO", label: "Angola", phone: "244" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54" },
    { code: "AS", label: "American Samoa", phone: "1-684" },
    { code: "AT", label: "Austria", phone: "43" },
    {
      code: "AU",
      label: "Australia",
      phone: "61",
      suggested: true,
    },
    { code: "AW", label: "Aruba", phone: "297" },
    { code: "AX", label: "Alland Islands", phone: "358" },
    { code: "AZ", label: "Azerbaijan", phone: "994" },
    {
      code: "BA",
      label: "Bosnia and Herzegovina",
      phone: "387",
    },
    { code: "BB", label: "Barbados", phone: "1-246" },
    { code: "BD", label: "Bangladesh", phone: "880" },
    { code: "BE", label: "Belgium", phone: "32" },
    { code: "BF", label: "Burkina Faso", phone: "226" },
    { code: "BG", label: "Bulgaria", phone: "359" },
    { code: "BH", label: "Bahrain", phone: "973" },
    { code: "BI", label: "Burundi", phone: "257" },
    { code: "BJ", label: "Benin", phone: "229" },
    { code: "BL", label: "Saint Barthelemy", phone: "590" },
    { code: "BM", label: "Bermuda", phone: "1-441" },
    { code: "BN", label: "Brunei Darussalam", phone: "673" },
    { code: "BO", label: "Bolivia", phone: "591" },
    { code: "BR", label: "Brazil", phone: "55" },
    { code: "BS", label: "Bahamas", phone: "1-242" },
    { code: "BT", label: "Bhutan", phone: "975" },
    { code: "BV", label: "Bouvet Island", phone: "47" },
    { code: "BW", label: "Botswana", phone: "267" },
    { code: "BY", label: "Belarus", phone: "375" },
    { code: "BZ", label: "Belize", phone: "501" },
    {
      code: "CA",
      label: "Canada",
      phone: "1",
      suggested: true,
    },
    {
      code: "CC",
      label: "Cocos (Keeling) Islands",
      phone: "61",
    },
    {
      code: "CD",
      label: "Congo, Democratic Republic of the",
      phone: "243",
    },
    {
      code: "CF",
      label: "Central African Republic",
      phone: "236",
    },
    {
      code: "CG",
      label: "Congo, Republic of the",
      phone: "242",
    },
    { code: "CH", label: "Switzerland", phone: "41" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225" },
    { code: "CK", label: "Cook Islands", phone: "682" },
    { code: "CL", label: "Chile", phone: "56" },
    { code: "CM", label: "Cameroon", phone: "237" },
    { code: "CN", label: "China", phone: "86" },
    { code: "CO", label: "Colombia", phone: "57" },
    { code: "CR", label: "Costa Rica", phone: "506" },
    { code: "CU", label: "Cuba", phone: "53" },
    { code: "CV", label: "Cape Verde", phone: "238" },
    { code: "CW", label: "Curacao", phone: "599" },
    { code: "CX", label: "Christmas Island", phone: "61" },
    { code: "CY", label: "Cyprus", phone: "357" },
    { code: "CZ", label: "Czech Republic", phone: "420" },
    {
      code: "DE",
      label: "Germany",
      phone: "49",
      suggested: true,
    },
    { code: "DJ", label: "Djibouti", phone: "253" },
    { code: "DK", label: "Denmark", phone: "45" },
    { code: "DM", label: "Dominica", phone: "1-767" },
    {
      code: "DO",
      label: "Dominican Republic",
      phone: "1-809",
    },
    { code: "DZ", label: "Algeria", phone: "213" },
    { code: "EC", label: "Ecuador", phone: "593" },
    { code: "EE", label: "Estonia", phone: "372" },
    { code: "EG", label: "Egypt", phone: "20" },
    { code: "EH", label: "Western Sahara", phone: "212" },
    { code: "ER", label: "Eritrea", phone: "291" },
    { code: "ES", label: "Spain", phone: "34" },
    { code: "ET", label: "Ethiopia", phone: "251" },
    { code: "FI", label: "Finland", phone: "358" },
    { code: "FJ", label: "Fiji", phone: "679" },
    {
      code: "FK",
      label: "Falkland Islands (Malvinas)",
      phone: "500",
    },
    {
      code: "FM",
      label: "Micronesia, Federated States of",
      phone: "691",
    },
    { code: "FO", label: "Faroe Islands", phone: "298" },
    {
      code: "FR",
      label: "France",
      phone: "33",
      suggested: true,
    },
    { code: "GA", label: "Gabon", phone: "241" },
    { code: "GB", label: "United Kingdom", phone: "44" },
    { code: "GD", label: "Grenada", phone: "1-473" },
    { code: "GE", label: "Georgia", phone: "995" },
    { code: "GF", label: "French Guiana", phone: "594" },
    { code: "GG", label: "Guernsey", phone: "44" },
    { code: "GH", label: "Ghana", phone: "233" },
    { code: "GI", label: "Gibraltar", phone: "350" },
    { code: "GL", label: "Greenland", phone: "299" },
    { code: "GM", label: "Gambia", phone: "220" },
    { code: "GN", label: "Guinea", phone: "224" },
    { code: "GP", label: "Guadeloupe", phone: "590" },
    { code: "GQ", label: "Equatorial Guinea", phone: "240" },
    { code: "GR", label: "Greece", phone: "30" },
    {
      code: "GS",
      label: "South Georgia and the South Sandwich Islands",
      phone: "500",
    },
    { code: "GT", label: "Guatemala", phone: "502" },
    { code: "GU", label: "Guam", phone: "1-671" },
    { code: "GW", label: "Guinea-Bissau", phone: "245" },
    { code: "GY", label: "Guyana", phone: "592" },
    { code: "HK", label: "Hong Kong", phone: "852" },
    {
      code: "HM",
      label: "Heard Island and McDonald Islands",
      phone: "672",
    },
    { code: "HN", label: "Honduras", phone: "504" },
    { code: "HR", label: "Croatia", phone: "385" },
    { code: "HT", label: "Haiti", phone: "509" },
    { code: "HU", label: "Hungary", phone: "36" },
    { code: "ID", label: "Indonesia", phone: "62" },
    { code: "IE", label: "Ireland", phone: "353" },
    { code: "IL", label: "Israel", phone: "972" },
    { code: "IM", label: "Isle of Man", phone: "44" },
    { code: "IN", label: "India", phone: "91" },
    {
      code: "IO",
      label: "British Indian Ocean Territory",
      phone: "246",
    },
    { code: "IQ", label: "Iraq", phone: "964" },
    {
      code: "IR",
      label: "Iran, Islamic Republic of",
      phone: "98",
    },
    { code: "IS", label: "Iceland", phone: "354" },
    { code: "IT", label: "Italy", phone: "39" },
    { code: "JE", label: "Jersey", phone: "44" },
    { code: "JM", label: "Jamaica", phone: "1-876" },
    { code: "JO", label: "Jordan", phone: "962" },
    {
      code: "JP",
      label: "Japan",
      phone: "81",
      suggested: true,
    },
    { code: "KE", label: "Kenya", phone: "254" },
    { code: "KG", label: "Kyrgyzstan", phone: "996" },
    { code: "KH", label: "Cambodia", phone: "855" },
    { code: "KI", label: "Kiribati", phone: "686" },
    { code: "KM", label: "Comoros", phone: "269" },
    {
      code: "KN",
      label: "Saint Kitts and Nevis",
      phone: "1-869",
    },
    {
      code: "KP",
      label: "Korea, Democratic People's Republic of",
      phone: "850",
    },
    { code: "KR", label: "Korea, Republic of", phone: "82" },
    { code: "KW", label: "Kuwait", phone: "965" },
    { code: "KY", label: "Cayman Islands", phone: "1-345" },
    { code: "KZ", label: "Kazakhstan", phone: "7" },
    {
      code: "LA",
      label: "Lao People's Democratic Republic",
      phone: "856",
    },
    { code: "LB", label: "Lebanon", phone: "961" },
    { code: "LC", label: "Saint Lucia", phone: "1-758" },
    { code: "LI", label: "Liechtenstein", phone: "423" },
    { code: "LK", label: "Sri Lanka", phone: "94" },
    { code: "LR", label: "Liberia", phone: "231" },
    { code: "LS", label: "Lesotho", phone: "266" },
    { code: "LT", label: "Lithuania", phone: "370" },
    { code: "LU", label: "Luxembourg", phone: "352" },
    { code: "LV", label: "Latvia", phone: "371" },
    { code: "LY", label: "Libya", phone: "218" },
    { code: "MA", label: "Morocco", phone: "212" },
    { code: "MC", label: "Monaco", phone: "377" },
    {
      code: "MD",
      label: "Moldova, Republic of",
      phone: "373",
    },
    { code: "ME", label: "Montenegro", phone: "382" },
    {
      code: "MF",
      label: "Saint Martin (French part)",
      phone: "590",
    },
    { code: "MG", label: "Madagascar", phone: "261" },
    { code: "MH", label: "Marshall Islands", phone: "692" },
    {
      code: "MK",
      label: "Macedonia, the Former Yugoslav Republic of",
      phone: "389",
    },
    { code: "ML", label: "Mali", phone: "223" },
    { code: "MM", label: "Myanmar", phone: "95" },
    { code: "MN", label: "Mongolia", phone: "976" },
    { code: "MO", label: "Macao", phone: "853" },
    {
      code: "MP",
      label: "Northern Mariana Islands",
      phone: "1-670",
    },
    { code: "MQ", label: "Martinique", phone: "596" },
    { code: "MR", label: "Mauritania", phone: "222" },
    { code: "MS", label: "Montserrat", phone: "1-664" },
    { code: "MT", label: "Malta", phone: "356" },
    { code: "MU", label: "Mauritius", phone: "230" },
    { code: "MV", label: "Maldives", phone: "960" },
    { code: "MW", label: "Malawi", phone: "265" },
    { code: "MX", label: "Mexico", phone: "52" },
    { code: "MY", label: "Malaysia", phone: "60" },
    { code: "MZ", label: "Mozambique", phone: "258" },
    { code: "NA", label: "Namibia", phone: "264" },
    { code: "NC", label: "New Caledonia", phone: "687" },
    { code: "NE", label: "Niger", phone: "227" },
    { code: "NF", label: "Norfolk Island", phone: "672" },
    { code: "NG", label: "Nigeria", phone: "234" },
    { code: "NI", label: "Nicaragua", phone: "505" },
    { code: "NL", label: "Netherlands", phone: "31" },
    { code: "NO", label: "Norway", phone: "47" },
    { code: "NP", label: "Nepal", phone: "977" },
    { code: "NR", label: "Nauru", phone: "674" },
    { code: "NU", label: "Niue", phone: "683" },
    { code: "NZ", label: "New Zealand", phone: "64" },
    { code: "OM", label: "Oman", phone: "968" },
    { code: "PA", label: "Panama", phone: "507" },
    { code: "PE", label: "Peru", phone: "51" },
    { code: "PF", label: "French Polynesia", phone: "689" },
    { code: "PG", label: "Papua New Guinea", phone: "675" },
    { code: "PH", label: "Philippines", phone: "63" },
    { code: "PK", label: "Pakistan", phone: "92" },
    { code: "PL", label: "Poland", phone: "48" },
    {
      code: "PM",
      label: "Saint Pierre and Miquelon",
      phone: "508",
    },
    { code: "PN", label: "Pitcairn", phone: "870" },
    { code: "PR", label: "Puerto Rico", phone: "1" },
    {
      code: "PS",
      label: "Palestine, State of",
      phone: "970",
    },
    { code: "PT", label: "Portugal", phone: "351" },
    { code: "PW", label: "Palau", phone: "680" },
    { code: "PY", label: "Paraguay", phone: "595" },
    { code: "QA", label: "Qatar", phone: "974" },
    { code: "RE", label: "Reunion", phone: "262" },
    { code: "RO", label: "Romania", phone: "40" },
    { code: "RS", label: "Serbia", phone: "381" },
    { code: "RU", label: "Russian Federation", phone: "7" },
    { code: "RW", label: "Rwanda", phone: "250" },
    { code: "SA", label: "Saudi Arabia", phone: "966" },
    { code: "SB", label: "Solomon Islands", phone: "677" },
    { code: "SC", label: "Seychelles", phone: "248" },
    { code: "SD", label: "Sudan", phone: "249" },
    { code: "SE", label: "Sweden", phone: "46" },
    { code: "SG", label: "Singapore", phone: "65" },
    { code: "SH", label: "Saint Helena", phone: "290" },
    { code: "SI", label: "Slovenia", phone: "386" },
    {
      code: "SJ",
      label: "Svalbard and Jan Mayen",
      phone: "47",
    },
    { code: "SK", label: "Slovakia", phone: "421" },
    { code: "SL", label: "Sierra Leone", phone: "232" },
    { code: "SM", label: "San Marino", phone: "378" },
    { code: "SN", label: "Senegal", phone: "221" },
    { code: "SO", label: "Somalia", phone: "252" },
    { code: "SR", label: "Suriname", phone: "597" },
    { code: "SS", label: "South Sudan", phone: "211" },
    {
      code: "ST",
      label: "Sao Tome and Principe",
      phone: "239",
    },
    { code: "SV", label: "El Salvador", phone: "503" },
    {
      code: "SX",
      label: "Sint Maarten (Dutch part)",
      phone: "1-721",
    },
    {
      code: "SY",
      label: "Syrian Arab Republic",
      phone: "963",
    },
    { code: "SZ", label: "Swaziland", phone: "268" },
    {
      code: "TC",
      label: "Turks and Caicos Islands",
      phone: "1-649",
    },
    { code: "TD", label: "Chad", phone: "235" },
    {
      code: "TF",
      label: "French Southern Territories",
      phone: "262",
    },
    { code: "TG", label: "Togo", phone: "228" },
    { code: "TH", label: "Thailand", phone: "66" },
    { code: "TJ", label: "Tajikistan", phone: "992" },
    { code: "TK", label: "Tokelau", phone: "690" },
    { code: "TL", label: "Timor-Leste", phone: "670" },
    { code: "TM", label: "Turkmenistan", phone: "993" },
    { code: "TN", label: "Tunisia", phone: "216" },
    { code: "TO", label: "Tonga", phone: "676" },
    { code: "TR", label: "Turkey", phone: "90" },
    {
      code: "TT",
      label: "Trinidad and Tobago",
      phone: "1-868",
    },
    { code: "TV", label: "Tuvalu", phone: "688" },
    {
      code: "TW",
      label: "Taiwan, Province of China",
      phone: "886",
    },
    {
      code: "TZ",
      label: "United Republic of Tanzania",
      phone: "255",
    },
    { code: "UA", label: "Ukraine", phone: "380" },
    { code: "UG", label: "Uganda", phone: "256" },
    {
      code: "US",
      label: "United States",
      phone: "1",
      suggested: true,
    },
    { code: "UY", label: "Uruguay", phone: "598" },
    { code: "UZ", label: "Uzbekistan", phone: "998" },
    {
      code: "VA",
      label: "Holy See (Vatican City State)",
      phone: "379",
    },
    {
      code: "VC",
      label: "Saint Vincent and the Grenadines",
      phone: "1-784",
    },
    { code: "VE", label: "Venezuela", phone: "58" },
    {
      code: "VG",
      label: "British Virgin Islands",
      phone: "1-284",
    },
    {
      code: "VI",
      label: "US Virgin Islands",
      phone: "1-340",
    },
    { code: "VN", label: "Vietnam", phone: "84" },
    { code: "VU", label: "Vanuatu", phone: "678" },
    { code: "WF", label: "Wallis and Futuna", phone: "681" },
    { code: "WS", label: "Samoa", phone: "685" },
    { code: "XK", label: "Kosovo", phone: "383" },
    { code: "YE", label: "Yemen", phone: "967" },
    { code: "YT", label: "Mayotte", phone: "262" },
    { code: "ZA", label: "South Africa", phone: "27" },
    { code: "ZM", label: "Zambia", phone: "260" },
    { code: "ZW", label: "Zimbabwe", phone: "263" },
  ];

  const formik = useFormik({
    initialValues: {
      type: "individual",
      payment_type: "cash",
      account_number: "",
      address: "",
      ic: "",
      dic: "",
      company_name: "",
      iban: "",
      bic_swift: "",
      bank_country: "",
      bank_address_1: "",
      bank_address_2: "",
      bank_name_1: "",
      bank_name_2: "",
      beneficiary_address_1: "",
      beneficiary_address_2: "",
      beneficiary_name_1: "",
      beneficiary_name_2: "",
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required(t("Povinné")),
      payment_type: Yup.string().required(t("Povinné")),
      account_number: Yup.string()
        .matches(AccountNumberRegex, t("Špatný formát"))
        .when("payment_type", {
          is: "transferCz",
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      address: Yup.string()
        .matches(FullInputRegex, t("Špatný formát"))
        .when(["type", "payment_type"], {
          is: (type, payment_type) =>
            (type === "self_employed" || type === "legal_entity") &&
            (payment_type === "transferCz" || payment_type === "transfer"),
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      ic: Yup.string()
        .matches(NumRegEx, t("Špatný formát"))
        .when(["type", "payment_type"], {
          is: (type, payment_type) =>
            (type === "self_employed" || type === "legal_entity") &&
            (payment_type === "transferCz" || payment_type === "transfer"),
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      dic: Yup.string()
        .matches(NumRegEx, t("Špatný formát"))
        .when(["type", "payment_type"], {
          is: (type, payment_type) =>
            (type === "self_employed" || type === "legal_entity") &&
            (payment_type === "transferCz" || payment_type === "transfer"),
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      company_name: Yup.string()
        .matches(PaymentTextRegex, t("Zadejte text velkými písmeny"))
        .when(["type", "payment_type"], {
          is: (type, payment_type) =>
            type === "legal_entity" &&
            (payment_type === "transferCz" || payment_type === "transfer"),
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      iban: Yup.string()
        .matches(IbanRegex, t("Špatný formát"))
        .when("payment_type", {
          is: "transfer",
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      bic_swift: Yup.string()
        .matches(SwiftRegex, t("Špatný formát"))
        .when("payment_type", {
          is: "transfer",
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      bank_country: Yup.string().when("payment_type", {
        is: "transfer",
        then: Yup.string().required(t("Povinné")),
        otherwise: Yup.string(),
      }),
      bank_address_1: Yup.string()
        .matches(PaymentTextRegex, t("Zadejte text velkými písmeny"))
        .when("payment_type", {
          is: "transfer",
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      bank_address_2: Yup.string().matches(
        PaymentSecondaryTextRegex,
        t("Zadejte text velkými písmeny")
      ),
      bank_name_1: Yup.string()
        .matches(PaymentTextRegex, t("Zadejte text velkými písmeny"))
        .when("payment_type", {
          is: "transfer",
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      bank_name_2: Yup.string().matches(
        PaymentSecondaryTextRegex,
        t("Zadejte text velkými písmeny")
      ),
      beneficiary_address_1: Yup.string()
        .matches(PaymentTextRegex, t("Zadejte text velkými písmeny"))
        .when("payment_type", {
          is: "transfer",
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      beneficiary_address_2: Yup.string().matches(
        PaymentSecondaryTextRegex,
        t("Zadejte text velkými písmeny")
      ),
      beneficiary_name_1: Yup.string()
        .matches(PaymentTextRegex, t("Zadejte text velkými písmeny"))
        .when("payment_type", {
          is: (value) => ["transferCz", "transfer"].includes(value),
          then: Yup.string().required(t("Povinné")),
          otherwise: Yup.string(),
        }),
      beneficiary_name_2: Yup.string().matches(
        PaymentSecondaryTextRegex,
        t("Zadejte text velkými písmeny")
      ),
    }),
    onSubmit: async (values) => {
      setUpdating(true);
      try {
        const response = await HttpService.post(
          `${UrlService.apiDomain()}api/companies/your/details`,
          values
        );
        if (response.status != 200) {
          if (response.data.message) {
            alert(response.data.message);
          }
          setUpdating(false);
          toast.error(t("Něco se pokazilo!"));
          return;
        } else {
          if (props.on_update_success) {
            props.on_update_success();
          }
          setUpdating(false);
          toast.success(t("Záznam obnoven"));
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (props.isOpen) {
      getData();
    }
  }, [props.isOpen]);

  const getData = () => {
    setLoading(true);
    getYourCompanyDetails(
      (data) => {
        let isValidIban = startsWithCapitalLetter(data.iban);
        let payment_type =
          data.payment_type == "transfer" && data.bank_country == "CZ"
            ? "transferCz"
            : data.payment_type;
        formik.setValues({
          type: data?.type,
          payment_type: payment_type,
          account_number: !isValidIban ? data.iban : "",
          address: data?.address ?? "",
          ic: data?.ic ?? "",
          dic: data?.dic ?? "",
          company_name: data?.company_name ?? "",
          iban: isValidIban ? data.iban : "",
          bic_swift: data?.bic_swift ?? "",
          bank_country: data?.bank_country ?? "",
          bank_address_1: data?.bank_address_1 ?? "",
          bank_address_2: data?.bank_address_2 ?? "",
          bank_name_1: data?.bank_name_1 ?? "",
          bank_name_2: data?.bank_name_2 ?? "",
          beneficiary_address_1: data?.beneficiary_address_1 ?? "",
          beneficiary_address_2: data?.beneficiary_address_2 ?? "",
          beneficiary_name_1: data?.beneficiary_name_1 ?? "",
          beneficiary_name_2: data?.beneficiary_name_2 ?? "",
        });
        setLoading(false);
      },
      (error) => {
        alert(error);
        props?.onClose();
      }
    );
  };

  if (loading) {
    return (
      <Dialog
        onClose={props.onClose}
        open={props.isOpen}
        sx={{
          minWidth: 400,
          mx: "auto",
          p: 4,
          width: "50vw",
        }}
      >
        <Paper elevation={12} sx={{ width: "100%", p: 3 }}>
          <CircularProgress />
        </Paper>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={props.onClose}
        open={props.isOpen}
        sx={{
          minWidth: 400,
          mx: "auto",
          p: 4,
          width: "50vw",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Paper elevation={12} sx={{ width: "100%", p: 3 }}>
            <Typography color="textPrimary" variant="h6">
              {t("Platební a firemní údaje")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="radio-label-1">{t("Typ osoby")}</FormLabel>
                  <RadioGroup
                    name="type"
                    aria-labelledby="radio-label-1"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    row
                  >
                    <FormControlLabel
                      value="individual"
                      control={<Radio />}
                      label={t("Fyzická osoba")}
                    />
                    <FormControlLabel
                      value="self_employed"
                      control={<Radio />}
                      label={t("OSVČ")}
                    />
                    <FormControlLabel
                      value="legal_entity"
                      control={<Radio />}
                      label={t("Právnická osoba")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="radio-label-2">{t("Typ platby")}</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-label-2"
                    name="payment_type"
                    value={formik.values.payment_type}
                    onChange={formik.handleChange}
                    row
                  >
                    <FormControlLabel
                      value="transfer"
                      control={<Radio />}
                      label={t("Převodem (mimo ČR)")}
                    />
                    <FormControlLabel
                      value="transferCz"
                      control={<Radio />}
                      label={t("Převodem (ČR)")}
                    />
                    <FormControlLabel
                      disabled={formik.values.type !== "individual"}
                      value="cash"
                      control={<Radio />}
                      label={t("Hotovost (pouze v ČŘ)")}
                    />
                    <FormControlLabel
                      disabled={formik.values.type === "legal_entity"}
                      value="card"
                      control={<Radio />}
                      label={t("Fido karta")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {(formik.values.type == "self_employed" ||
              formik.values.type == "legal_entity") && (
              <Grid container spacing={3} sx={{ py: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    onChange={formik.handleChange}
                    name="ic"
                    label={t("IČ")}
                    value={formik.values.ic}
                    onBlur={formik.handleBlur}
                    error={!!(formik.touched.ic && formik.errors.ic)}
                    helperText={formik.touched.ic && formik.errors.ic}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    onChange={formik.handleChange}
                    name="dic"
                    label={t("DIČ")}
                    value={formik.values.dic}
                    onBlur={formik.handleBlur}
                    error={!!(formik.touched.dic && formik.errors.dic)}
                    helperText={formik.touched.dic && formik.errors.dic}
                  />
                </Grid>
                {formik.values.type == "legal_entity" && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      onChange={formik.handleChange}
                      name="company_name"
                      label={t("Název společnosti")}
                      value={formik.values.company_name}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.company_name &&
                          formik.errors.company_name
                        )
                      }
                      helperText={
                        formik.touched.company_name &&
                        formik.errors.company_name
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    onChange={formik.handleChange}
                    name="address"
                    label={t("Adresa")}
                    value={formik.values.address}
                    onBlur={formik.handleBlur}
                    error={!!(formik.touched.address && formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
              </Grid>
            )}
            {formik.values.payment_type == "transferCz" && (
              <>
                <Divider orientation="horizontal">
                  {t("Bankovní spojení")}
                </Divider>
                <Grid container spacing={3} sx={{ py: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      onChange={formik.handleChange}
                      name="account_number"
                      label={t("Číslo účtu")}
                      value={formik.values.account_number}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.account_number &&
                          formik.errors.account_number
                        )
                      }
                      helperText={
                        formik.touched.account_number &&
                        formik.errors.account_number
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      onChange={formik.handleChange}
                      name="beneficiary_name_1"
                      label={t("Název příjemce 1")}
                      value={formik.values.beneficiary_name_1}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.beneficiary_name_1 &&
                          formik.errors.beneficiary_name_1
                        )
                      }
                      helperText={
                        formik.touched.beneficiary_name_1 &&
                        formik.errors.beneficiary_name_1
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!formik.values.beneficiary_name_1}
                      fullWidth
                      onChange={formik.handleChange}
                      name="beneficiary_name_2"
                      label={t("Název příjemce 2")}
                      value={formik.values.beneficiary_name_2}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.beneficiary_name_2 &&
                          formik.errors.beneficiary_name_2
                        )
                      }
                      helperText={
                        formik.touched.beneficiary_name_2 &&
                        formik.errors.beneficiary_name_2
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {formik.values.payment_type == "transfer" && (
              <>
                <Divider orientation="horizontal">
                  {t("Bankovní spojení")}
                </Divider>
                <Grid container spacing={3} sx={{ py: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      onChange={formik.handleChange}
                      name="iban"
                      label={t("IBAN")}
                      value={formik.values.iban}
                      onBlur={formik.handleBlur}
                      error={!!(formik.touched.iban && formik.errors.iban)}
                      helperText={formik.touched.iban && formik.errors.iban}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      onChange={formik.handleChange}
                      name="bic_swift"
                      label={t("BIC/SWIFT")}
                      value={formik.values.bic_swift}
                      onBlur={formik.handleBlur}
                      error={
                        !!(formik.touched.bic_swift && formik.errors.bic_swift)
                      }
                      helperText={
                        formik.touched.bic_swift && formik.errors.bic_swift
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="country-select"
                      name="bank_country"
                      fullWidth
                      options={countries}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        formik.setFieldValue(`bank_country`, value?.code);
                      }}
                      value={
                        countries.find(
                          (country) =>
                            country.code === formik.values.bank_country
                        ) || null
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            !!(
                              formik.touched.bank_country &&
                              formik.errors.bank_country
                            )
                          }
                          helperText={
                            formik.touched.bank_country &&
                            formik.errors.bank_country
                          }
                          label={t("Vyberte zemi")}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      onChange={formik.handleChange}
                      name="bank_address_1"
                      label={t("Adresa banky 1")}
                      value={formik.values.bank_address_1}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.bank_address_1 &&
                          formik.errors.bank_address_1
                        )
                      }
                      helperText={
                        formik.touched.bank_address_1 &&
                        formik.errors.bank_address_1
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!formik.values.bank_address_1}
                      fullWidth
                      onChange={formik.handleChange}
                      name="bank_address_2"
                      label={t("Adresa banky 2")}
                      value={formik.values.bank_address_2}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.bank_address_2 &&
                          formik.errors.bank_address_2
                        )
                      }
                      helperText={
                        formik.touched.bank_address_2 &&
                        formik.errors.bank_address_2
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      onChange={formik.handleChange}
                      name="bank_name_1"
                      label={t("Název banky 1")}
                      value={formik.values.bank_name_1}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.bank_name_1 &&
                          formik.errors.bank_name_1
                        )
                      }
                      helperText={
                        formik.touched.bank_name_1 && formik.errors.bank_name_1
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!formik.values.bank_name_1}
                      fullWidth
                      onChange={formik.handleChange}
                      name="bank_name_2"
                      label={t("Název banky 2")}
                      value={formik.values.bank_name_2}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.bank_name_2 &&
                          formik.errors.bank_name_2
                        )
                      }
                      helperText={
                        formik.touched.bank_name_2 && formik.errors.bank_name_2
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      onChange={formik.handleChange}
                      name="beneficiary_address_1"
                      label={t("Adresa příjemce 1")}
                      value={formik.values.beneficiary_address_1}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.beneficiary_address_1 &&
                          formik.errors.beneficiary_address_1
                        )
                      }
                      helperText={
                        formik.touched.beneficiary_address_1 &&
                        formik.errors.beneficiary_address_1
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!formik.values.beneficiary_address_1}
                      fullWidth
                      onChange={formik.handleChange}
                      name="beneficiary_address_2"
                      label={t("Adresa příjemce 2")}
                      value={formik.values.beneficiary_address_2}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.beneficiary_address_2 &&
                          formik.errors.beneficiary_address_2
                        )
                      }
                      helperText={
                        formik.touched.beneficiary_address_2 &&
                        formik.errors.beneficiary_address_2
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      onChange={formik.handleChange}
                      name="beneficiary_name_1"
                      label={t("Název příjemce 1")}
                      value={formik.values.beneficiary_name_1}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.beneficiary_name_1 &&
                          formik.errors.beneficiary_name_1
                        )
                      }
                      helperText={
                        formik.touched.beneficiary_name_1 &&
                        formik.errors.beneficiary_name_1
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!formik.values.beneficiary_name_1}
                      fullWidth
                      onChange={formik.handleChange}
                      name="beneficiary_name_2"
                      label={t("Název příjemce 2")}
                      value={formik.values.beneficiary_name_2}
                      onBlur={formik.handleBlur}
                      error={
                        !!(
                          formik.touched.beneficiary_name_2 &&
                          formik.errors.beneficiary_name_2
                        )
                      }
                      helperText={
                        formik.touched.beneficiary_name_2 &&
                        formik.errors.beneficiary_name_2
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Divider sx={{ my: 1 }} />
            {updating && <LinearProgress />}
            {props.isEdit && (
              <Button
                type="submit"
                // disabled={formik.vaild}
                fullWidth
                variant="contained"
                sx={{ my: 1 }}
              >
                {t("Uložit")}
              </Button>
            )}
          </Paper>
        </form>
      </Dialog>
    );
  }
}
