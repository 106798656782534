import {
  Dialog,
  DialogTitle,
  DialogContent,
  Popover,
  MenuItem,
  Typography,
  Box,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../contexts/DataContext";

const RewardDeteails = ({
  isOpen,
  id,
  onClose,
  employeeReward,
  rewardType,
}) => {
  const { t } = useTranslation();
  const { data: contextData, isLoading } = useContext(DataContext);

  const anchorRef = useRef(null);
  const rewards = contextData.reward_rules;
  const language = localStorage.getItem("language");
  const reward =
    rewardType == "supplier"
      ? rewards.find((rew) => rew.id == id)
      : employeeReward;

  const languageOptions = {
    ru: {
      icon: "/static/icons/ru_flag.svg",
      label: "Русский",
    },
    cz: {
      icon: "/static/icons/cs_flag.svg",
      label: "Čeština",
    },
  };

  const [selectedOption, setSelectedOption] = useState(
    language == "cs" ? languageOptions.cz : languageOptions.ru
  );
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = (language) => {
    const currentLang = languageOptions[language];
    setSelectedOption(currentLang);
    setOpen(false);
  };
  const description =
    selectedOption.label == "Čeština"
      ? reward.description.cz
      : reward.description.ru;

  console.log({ reward });

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ display: "flex" }}>
          <DialogTitle id="alert-dialog-title">{t("Popis odmeny")}</DialogTitle>
          <IconButton onClick={handleOpen} ref={anchorRef}>
            <Box
              sx={{
                display: "flex",
                height: 20,
                width: 20,
                "& img": {
                  width: "100%",
                },
              }}
            >
              {selectedOption && (
                <img alt={selectedOption.label} src={selectedOption.icon} />
              )}
            </Box>
          </IconButton>
        </Box>
        <DialogContent>
          {description ? (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            "Tady zatím prázdno"
          )}
        </DialogContent>
        <Dialog
          keepMounted
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: { width: 240 },
          }}
        >
          {Object.keys(languageOptions).map((language) => (
            <MenuItem
              onClick={() => handleChangeLanguage(language)}
              key={language}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    display: "flex",
                    height: 20,
                    width: 20,
                    "& img": {
                      width: "100%",
                    },
                  }}
                >
                  <img
                    alt={languageOptions[language].label}
                    src={languageOptions[language].icon}
                  />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    {languageOptions[language].label}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
        </Dialog>
      </Dialog>
    </>
  );
};

export default RewardDeteails;
