import React, { Component } from 'react';
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

import Download from '../icons/Download';

const DeleteRenderer = (props) => {


    const { t } = useTranslation();

    const downloadAttachment=(X)=>{
        HttpService.postblob(UrlService.apiDomain() + 'api/attachments/download_attachment', {attachment:X, model:props.model, id:props.node.data.id})
            .then((response) => {
                // Create blob link to download

                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                let tmp=X.split('/');
                let filename=tmp[tmp.length-1];
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    return (
        <>
        <Button
            className="btn btn-default btn-sm delete-ingrid-but"
            onClick={function() {downloadAttachment(props.data[props.field])}}
        > <Download  />
        </Button>

        </>
    );

}

export default DeleteRenderer;
