"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIDateReactT from "../../../Date/UIDateReactT.mjs";


const UIGridEditorDateReactT = class UIGridEditorDateReactT extends React.Component {
	
	#Element = null;
	
	#Ref = null;
	
	#API = null;
	
	#ValueStart = null;
	
	constructor(
		Props
	){
		
		console.log( "UIGridEditorDateReactT.constructor", Props );
		
		
		super( Props );
		
		
		this.#Ref = null;
		
		
		this.#API = Props.api;
		
		
		this.#ValueStart = Props.value;
		
		
		this.#Element = React.createElement(
			UIDateReactT,
			{
				Value: Props.value ? new Date( Props.value ) : null, 
				Label: Props.Label ? Props.Label : "",
				
				ref: this.RefSet.bind( this ),
				OnCloseClick: this.OnCloseClick.bind( this )
			}
		);
		
	}
	
	OnCloseClick( ){
		
		console.log( "UIGridEditorDateReactT.OnCloseClick" );
		
		
		this.#API.stopEditing( true );
		
	}
	
	RefSet( Ref ){
		
		console.log( "UIGridEditorDateReactT.RefSet", Ref );
		
		
		this.#Ref = Ref;
		
	}
	
	getValue( 
	
	){
		
		console.log( "UIGridEditorDateReactT.getValue" );
		
		
		if( this.#Ref !== null ){
			
			let ValueDate = this.#Ref.ValueGet( );
			
			if( ValueDate === null ){
				
				return null;
				
			} else {
				
				if( Number.isNaN( ValueDate.getTime( ) ) === true ){
					
					return this.#ValueStart;
				
				} else {
					
					const Day = ( ValueDate.getDate( ) ).toString( );
					
					const Month = ( ValueDate.getMonth( ) + 1 ).toString( );

					const Year = ( ValueDate.getFullYear( ) ).toString( );
					
					return Year.padStart( 4, "0" ) + "-" + Month.padStart( 2, "0" ) + "-" + Day.padStart( 2, "0" );
				
				}
				
			}
		
		} else {
			
			throw new Error( "Not mounted" );
			
		}
		
	}
	
	render( ){
		
		console.log( "UIGridEditorDateReactT.render" );
		
		
		return this.#Element;
		
	}
	
};

export default UIGridEditorDateReactT;