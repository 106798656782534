import ModalWithButton from "../modal/ModalWithButton";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";
import { ChangeCircle } from "@material-ui/icons";

export function ResetPasswordModal() {
  const { t } = useTranslation();

  return (
    <>
      <ModalWithButton
        title={t("Zapomenuté heslo")}
        buttonProps={{ fullWidth: true, variant: "outlined" }}
      >
        <ResetPassword />
      </ModalWithButton>
    </>
  );
}

function ResetPassword({ onClose }) {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [state, setState] = useState("email");
  const [senderType, setSenderType] = useState("email");

  const [timeout, setTimeout] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeout > 0) {
        setTimeout(timeout - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeout]);

  if (state === "email") {
    let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    let phoneValid = phone?.length > 0;
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          {senderType === "email" && (
            <TextField
              sx={{ mt: 0 }}
              label={t("Email")}
              fullWidth
              type={"email"}
              error={!emailValid}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          )}
          {senderType === "sms" && (
            <TextField
              sx={{ mt: 0 }}
              label={t("Telefon")}
              fullWidth
              type={"phone"}
              error={!phoneValid}
              value={phone}
              onChange={(event) => {
                let val = event.target.value;
                setPhone(val?.replace(/\D/g, ""));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Button
            variant={"contained"}
            onClick={() => {
              if (senderType === "email") {
                setSenderType("sms");
              } else {
                setSenderType("email");
              }
            }}
          >
            <ChangeCircle></ChangeCircle>
            {senderType === "email" ? "SMS" : "EMAIL"}
          </Button>
        </div>
        <Button
          sx={{ mt: 2 }}
          fullWidth
          variant={"contained"}
          disabled={senderType === "email" ? !emailValid : !phoneValid}
          onClick={() => {
            let formData = new FormData();
            formData.append("type", senderType);
            if (senderType === "email") {
              formData.append("email", email);
            } else {
              formData.append("phone", phone);
            }
            HttpService.post(
              UrlService.apiDomain() + "api/password/token/generate",
              formData
            ).then((response) => {
              if (!response.data.error) {
                setTimeout(90);
                setState("code");
              } else {
                alert(t(response.data.error));
              }
            });
          }}
        >
          {t("Odeslat obnovovací klíč")}
        </Button>
      </>
    );
  } else if (state === "code") {
    let resetTokenValid = resetToken.match(/^[\dA-Z]{6}$/);
    return (
      <>
        <TextField
          sx={{ mt: 1 }}
          label={t("Klíč pro obnovení")}
          fullWidth
          type={"text"}
          error={!resetTokenValid}
          value={resetToken}
          onChange={(event) => {
            let newVal = event.target.value;
            if (newVal?.length <= 6) {
              setResetToken(newVal?.toUpperCase());
            }
          }}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            sx={{ mt: 2, mr: 1 }}
            fullWidth
            variant={"contained"}
            disabled={timeout > 0}
            onClick={() => {
              setState("email");
            }}
          >
            {t("Odeslat znovu") + (timeout > 0 ? ` (${timeout})` : "")}
          </Button>
          <Button
            sx={{ mt: 2 }}
            fullWidth
            variant={"contained"}
            disabled={!resetTokenValid}
            onClick={() => {
              let formData = new FormData();
              formData.append("type", senderType);
              if (senderType === "email") {
                formData.append("email", email);
              } else {
                formData.append("phone", phone);
              }
              formData.append("token", resetToken);
              HttpService.post(
                UrlService.apiDomain() + "api/password/token/test",
                formData
              ).then((response) => {
                if (!response.data.error) {
                  setState("password");
                } else {
                  alert(t(response.data.error));
                }
              });
            }}
          >
            {t("Pokračovat")}
          </Button>
        </div>
      </>
    );
  }

  let newPasswordValid = newPassword.length >= 8;
  let newPasswordRepeatValid = newPasswordRepeat === newPassword;

  return (
    <>
      <TextField
        sx={{ mt: 2 }}
        label={t("New password")}
        fullWidth
        type={"password"}
        error={!newPasswordValid}
        value={newPassword}
        onChange={(event) => {
          setNewPassword(event.target.value);
        }}
      />
      <TextField
        sx={{ mt: 2 }}
        label={t("Repeat password")}
        fullWidth
        type={"password"}
        disabled={!newPasswordValid}
        error={!newPasswordRepeatValid && newPasswordValid}
        value={newPasswordRepeat}
        onChange={(event) => {
          setNewPasswordRepeat(event.target.value);
        }}
      />
      <Button
        sx={{ mt: 2 }}
        fullWidth
        variant="contained"
        disabled={!(newPasswordRepeatValid && newPasswordValid)}
        onClick={() => {
          if (newPasswordRepeatValid && newPasswordValid) {
            let formData = new FormData();
            formData.append("type", senderType);
            if (senderType === "email") {
              formData.append("email", email);
            } else {
              formData.append("phone", phone);
            }
            formData.append("token", resetToken);
            formData.append("new_password", newPassword);
            HttpService.post(
              UrlService.apiDomain() + "api/password/reset",
              formData
            ).then((response) => {
              if (!response.data.error) {
                alert(t("Operace provedena úspěšně"));
                onClose();
              } else {
                alert(t(response.data.error));
              }
            });
          }
        }}
      >
        {t("Změnit heslo")}
      </Button>
    </>
  );
}
