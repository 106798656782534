import { useState } from "react";
import { Box, Dialog, Button, IconButton, Typography } from "@material-ui/core";
import SubjectIcon from "@mui/icons-material/Subject";
import { useTranslation } from "react-i18next";
import { GetApp } from "@material-ui/icons";

const FullText = ({ text, name, date }) => {
  const { t } = useTranslation();
  const [openFullText, setOpenFullText] = useState(false);

  const handleOpen = () => {
    setOpenFullText(true);
  };
  const handleClose = () => {
    setOpenFullText(false);
  };

  const handleDownload = () => {
    try {
      const docContent = `
            <html xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office"
            xmlns:w="urn:schemas-microsoft-com:office:word"
            xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
            xmlns="http://www.w3.org/TR/REC-html40">
            <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
              <title>${name}</title>
              <style>
                body { font-family: Arial, sans-serif; }
              </style>
            </head>
            <body>
            <h1>${name}</h1>
            <p>${date}</p>
              ${text}
            </body>
            </html>`;

      const blob = new Blob(["\ufeff", docContent], {
        type: "application/msword",
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}.doc`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error creating DOC file:", error);
    }
  };

  return (
    <>
      <Dialog
        open={openFullText}
        maxWidth="xl"
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: "10px",
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            minWidth: "50%",
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            width: 1,
            justifyContent: "space-between",
            alignItems: "center",
            flexShrink: 0,
          }}
        >
          <Typography variant="h5">{name}</Typography>
          <Box>
            <IconButton onClick={handleDownload}>
              <GetApp />
            </IconButton>
            <Button onClick={handleClose} color="primary">
              {t("Zavřít")}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            marginTop: "10px",
            p: 2,
          }}
        >
          {text}
        </Box>
      </Dialog>
      <IconButton
        color="primary"
        sx={{ m: 1 }}
        variant="contained"
        onClick={handleOpen}
        title={"Celý text"}
      >
        <SubjectIcon />
      </IconButton>
    </>
  );
};

export default FullText;
