"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


const UIModalReactT = class UIModalReactT extends React.Component {
	
	constructor(
		Props
	){
		
		console.log( "UIModalReactT.constructor", Props );
		
		
		super( Props );
		
		
		this.state = {
			ModalDialogOnOpenCb: ( ( Props.ModalDialogOnOpenCb ) ? Props.ModalDialogOnOpenCb : null ),
			ModalDialogMaxWidth: ( Props.ModalDialogMaxWidth ? Props.ModalDialogMaxWidth : "sm" ),
			ModalDialogOnCloseCb: ( ( Props.ModalDialogOnCloseCb ) ? Props.ModalDialogOnCloseCb : null ),
			ModalDialogIsOpen: false,
			ModalDialogStyle: ( ( Props.ModalDialogStyle ) ? Props.ModalDialogStyle : { } ),
			ModalPaperElevation: ( ( Props.ModalPaperElevation ) ? Props.ModalPaperElevation : 12 ),
			ModalPaperSX: ( ( Props.ModalPaperSX ) ? Props.ModalPaperSX : { p: 2 } ),
			ModalPaperStyle: ( Props.ModalPaperStyle ? Props.ModalPaperStyle : { } ),
			ModalBoxStyle: ( Props.ModalBoxStyle ? Props.ModalBoxStyle : { } ),
			ModalBoxContainerStyle: ( Props.ModalBoxContainerStyle ? Props.ModalBoxContainerStyle : { } ),
			ModalBoxContainerTopBarStyle: ( Props.ModalBoxContainerTopBarStyle ? Props.ModalBoxContainerTopBarStyle : { } ),
			ModalBoxContainerGridStyle: ( Props.ModalBoxContainerGridStyle ? Props.ModalBoxContainerGridStyle : { } ),
			
			TopBar: ( ( Props.TopBar ) ? Props.TopBar : null ),
			Grid: ( ( Props.Grid ) ? Props.Grid : null )
		};
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIButtonReactT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		if( Name === "ModalDialogIsOpen" ){
			
			if( ( Value === true ) && ( this.state[ Name ] === false ) ){
			
				this.state.ModalDialogOnOpenCb( );
		
			}
		
		}
		
		
		this.setState( {
			[ Name ]: Value
		} );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIButtonReactT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.state[ Name ];
		
	}
	
	render(
	
	){
		
		return React.createElement(
			MaterialUI.Dialog,
			{
				fullWidth: true,
				maxWidth: this.state.ModalDialogMaxWidth, 
				onClose: this.state.ModalDialogOnCloseCb, 
				open: this.state.ModalDialogIsOpen,
				PaperProps:{ 
					style: this.state.ModalDialogStyle
				}
			},
			[
				React.createElement(
					MaterialUI.Paper, 
					{
						key: "paper",
						elevation: this.state.ModalPaperElevation, 
						sx: this.state.ModalPaperSX,
						style: this.state.ModalPaperStyle
					},
				
					[ 
						React.createElement(
							MaterialUI.Box,
							{
								key: this.state.key + "_" + "box",
								style: this.state.ModalBoxStyle
							},
							[
								React.createElement(
									MaterialUI.Container,
									{
										key: "box" + "_" + "container",
										
										style: this.state.ModalBoxContainerStyle
									},
									[
									
										React.createElement(
											MaterialUI.Box,
											{
												key: "box" + "_" + "container" + "_" + "box" + "_" + "top_bar",
												
												style: this.state.ModalBoxContainerTopBarStyle
											},
											[
												this.state.TopBar.Component( )
											]
										),
							
										React.createElement(
											MaterialUI.Box,
											{
												key: "box" + "_" + "container" + "_" + "box" + "_" + "grid",
												
												style: this.state.ModalBoxContainerGridStyle
											},
											[
												this.state.Grid.Component( )
											]
										)
											
									]
								)
							]
						)
					]
					
				)
				
			]
		);
		
	}
	
};


export default UIModalReactT;