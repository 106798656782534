import CustomerComment from "./CustomerComment";
import AddComment from "./AddComment";
import { useState, useEffect } from "react";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import useSettings from "../../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@material-ui/core";
import SimpleLoader from "../../../components/SimpleLoader.js";
import toast from "react-hot-toast";
import { compareDesc } from "date-fns";

const Comments = ({ customer }) => {
  const { t } = useTranslation();

  const [allComments, setAllComments] = useState([]);
  const [allCommentators, setAllCommentators] = useState({});
  const [loading, setLoading] = useState(customer && false);

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).customer_comments;


  const grd = () => {
    if (customer) {
      HttpService.get(
        UrlService.apiDomain() + "api/customer_comments/get/all/" + customer
      )
        .then((res) => {
          setAllComments(Object.values(res.data.data));
          setAllCommentators(res.data.data_employees);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    grd();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await HttpService.delete(
        `${UrlService.apiDomain()}api/customer_comments/delete/${id}`
      );
      if (response.status != 200) {
        if (response.data.message) {
          alert(response.data.message);
        }
        toast.error(t("Něco se pokazilo!"));
        return;
      } else {
        toast.success(t("Záznam obnoven"));

        setAllComments((prev) => {
          const newData = prev.filter((item) => item.id != id);
          const comment = prev.find((item) => item.id == id);
          comment.message = "Komentář smazán autorem";
          return [...newData, comment];
        }); //TO DO
      }
    } catch (error) {
      console.log(error);
    }
  };

  const parentComments = allComments.filter((com) => !com.parent_id);

  const sortedComments = parentComments.sort((comment1, comment2) => {
    const createdAt1 = new Date(comment1.created_at);
    const createdAt2 = new Date(comment2.created_at);
    return compareDesc(createdAt1, createdAt2);
  });
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 1,
      }}
    >
      {loading ? (
        <SimpleLoader />
      ) : (
        <>
          {policy_actions.includes("add") && (
            <AddComment customer={customer} grd={grd} />
          )}
          {sortedComments.map((comment) => {
            const children_ids = comment.children_ids;

            const childComments = allComments.filter((comment) =>
              children_ids.includes(comment.id.toString())
            );
            const sortedChild = childComments.sort((comment1, comment2) => {
              const createdAt1 = new Date(comment1.created_at);
              const createdAt2 = new Date(comment2.created_at);
              return compareDesc(createdAt1, createdAt2);
            });
            return (
              <CustomerComment
                allComments={allComments}
                key={comment.id}
                comment={comment}
                onDelete={handleDelete}
                allCommentators={allCommentators}
                childComments={sortedChild}
                grd={grd}
                customer={customer}
              />
            );
          })}
        </>
      )}
    </Box>
  );
};
export default Comments;
