import React, {useState, useRef, forwardRef, useImperativeHandle, useEffect} from "react";

import {useTranslation} from 'react-i18next';
import {
    Autocomplete, Button,
    Grid,
    LinearProgress,
    Paper,
    TextField
} from "@material-ui/core";
import getSpouseSelect from "../api/getSpouseSelect";
import unSpouse from "../api/unSpouse";
import connectSpouse from "../api/connectSpouse";
import SearchIcon from '@material-ui/icons/Search';

export default forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [value, setValue] = useState(props.data.spouse_id);
    const [options, setOptions] = useState(undefined);
    const [filter, setFilter] = useState(true);
    const [filterName, setFilterName] = useState("");
    const [filterSurname, setFilterSurname] = useState("");

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            isPopup: () => {
                return true;
            }
        };
    });

    const handleChange = (event, newValue) => {
        let currentId = props.data.id;
        if (newValue) {
            connectSpouse(currentId, newValue.id,
                (result) => {
                    setValue(newValue.id);
                },
                (error) => {
                    console.log(error);
                });
        } else {
            unSpouse(currentId,
                (result) => {
                    setValue(undefined);
                },
                (error) => {
                    console.log(error);
                });
        }
    };

    const handleRequest = () => {
        setFilter(false);
        getSpouseSelect(props.data.id, filterName, filterSurname,
            (result) => {
                let withLabel = result.map(item => {
                    return {...item, label: `${item.name} ${item.surname} ${item.date_of_birth}`}
                });
                setOptions(withLabel);
            },
            (error) => {
                console.log(error);
            }
        );
    }


    if (filter) {
        return (
            <Paper elevation={2} sx={{p: '10px'}}>
                <Grid container size={12} spacing={3}>
                    <Grid item size={6}>
                        <TextField label="Vyhledat jméno" fullWidth
                                   onChange={(event) =>
                                       setFilterName(event.target.value)}/>
                    </Grid>
                    <Grid item size={6}>
                        <TextField label="Vyhledat přijmení" fullWidth
                                   onChange={(event) =>
                                       setFilterSurname(event.target.value)}/>
                    </Grid>
                    <Grid item size={1}>
                        <Button variant="contained"
                                fullWidth
                                sx={{height: "100%", width: "100%"}}
                                onClick={handleRequest}><SearchIcon/></Button>
                    </Grid>
                </Grid>
            </Paper>)
    }

    if (!options) {
        return (
            <Paper elevation={2} sx={{p: '10px'}} fullWidth>
                <LinearProgress fullWidth/>
            </Paper>
        )
    }

    return (
        <Paper elevation={2} sx={{p: '10px'}} fullWidth>
            <Autocomplete
                options={options}
                fullWidth
                autoComplete
                value={options.find(item => item.id === value)}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} label={t("Manželé")}/>}
            />
        </Paper>
    )
})
