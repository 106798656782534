import { useState, useEffect } from "react";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import GridWrapper from "../../components/grid/gridWrapperNew";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "@material-ui/core";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const CustomerEmployees = ({ customer_id }) => {
  const { t } = useTranslation();
  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");

  const [locale, setLocale] = useState(lc);
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState([]);

  const columnDefs = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      lockPosition: "left",
      pinned: "left",
      maxWidth: 150,
      sortable: false,
      resizable: false,
      suppressMenu: true,
    },
    {
      headerName: t("Příjmení"),
      field: "surname",
      cellStyle: { color: "#5664d2" },
      width: "50%",
    },
    {
      headerName: t("Jméno"),
      field: "name",
      width: "50%",
    },
  ];

  const getData = async () => {
    const data = {
      data: {
        customer_id,
        date,
      },
    };
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + "api/customer_employees",
        data
      );
      if (!response.data.success) {
        alert(response.data.message);
        return;
      }
      setData(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getData();
  }, [date]);

  return (
    <GridWrapper
      needResize={false}
      data={data}
      colDefs={columnDefs}
      title={t("Zaměstnanci")}
    >
      <Box sx={{ mr: 2 }}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <MobileDatePicker
            label={t("Datum")}
            mask="__.__.____"
            openTo="day"
            maxDate={new Date()}
            name="date"
            value={date}
            id="date"
            onChange={(event) => {
              setDate(event);
            }}
            renderInput={(inputProps) => (
              <TextField fullWidth variant="outlined" {...inputProps} />
            )}
          />
        </LocalizationProvider>
      </Box>
    </GridWrapper>
  );
};
export default CustomerEmployees;
