import {TextField} from "@material-ui/core";
import {memo, useEffect, useState} from "react";

export default memo(({
                         value: val, disabled,
                         regex, name, label, onlyValid, required,
                         processValue, onChange, inputSx, allowedRegex
                     }) => {
    const [valid, setValid] = useState(null);
    const [value, setValue] = useState(val);

    useEffect(() => {
        if(value !== val) {
            validate(val);
            setValue(val);
        }
    }, [val]);

    const validate = (valueToValidate) => {

        if (!valueToValidate) {
            if(required) {
                setValid(false);
                return false;
            } else {
                setValid(true);
                return true;
            }
        }
        if (!regex) {
            setValid(true);
            return true;
        }
        let result = regex.test(valueToValidate);
        setValid(result);
        return result;
    }

    if(valid === null){
        validate(value);
    }

    const handleChange = (event) => {
        if(allowedRegex && !allowedRegex.test(event.target.value)) return;
        setValue(event.target.value);
        let newValue;
        if (processValue) {
            newValue = processValue(event.target.value);
        } else {
            newValue = event.target.value;
        }
        if (validate(newValue) || newValue === "" || !onlyValid) {
            let result = validate(newValue);
            onChange(name, newValue, result);
        } else if (newValue) {
            setValid(true);
        }
    }

    return (
        <TextField label={required ? label + '*' : label} name={name} value={value ?? ''}
                   InputLabelProps={{ shrink: Boolean(value) || value === 0}}
                   sx={inputSx}
                   variant={disabled ? "filled" : "outlined"} type={name === "password" ? "password" : "text"}
                   error={!valid && !disabled} disabled={disabled}
                   fullWidth onChange={handleChange}/>
    );
}, (prev,next) => {
    return prev.value === next.value;
})