"use strict";


import UIButtonT from "./UIButtonT.mjs";

import UIButtonProducerAbstractT from "../../../Interface/UI/Button/_/UIButtonProducerAbstractT.mjs";


const UIButtonProducerT = class UIButtonProducerT extends UIButtonProducerAbstractT {
	
	constructor(
		
	){
		
		console.log( "UIButtonProducerT.constructor" );
		
	
		super( );
		
	}
	
	Produce(
		...Props
	){
		
		console.log( "UIButtonProducerT.Produce", Props );
		
		
		return new UIButtonT(
			...Props
		);
		
	}
	
};


export default UIButtonProducerT;