import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "../../icons/Search";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import NewRecord from "./NewRecord";
import Player from "./Player.js";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import SimpleLoader from "../../components/SimpleLoader";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const MeetingRecords = ({ customer_id, fromCard }) => {
  const policy_actions = JSON.parse(localStorage.getItem("policy_action_tree"))[
    "meeting_records"
  ];

  const canCreate = policy_actions.includes("add");

  const { t } = useTranslation();
  const { isLoading } = useContext(DataContext);
  const [loading, setLoading] = useState(true);

  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date("2024-01-01"));

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);

  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");
  const [locale, setLocale] = useState(lc);

  const handleSearch = () => {
    const browseData = {
      data: {
        search,
        dateFrom,
        dateTo,
        customer_id,
      },
    };
    setLoading(true);
    getData(browseData);
  };

  const getData = async (
    browseData = { data: { dateFrom, dateTo, customer_id } }
  ) => {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/meeting_records/get/all`,
        browseData
      );
      if (!response.data.success) {
        alert(response.data.message);
        setLoading(false);
        return;
      }
      setData(Object.values(response.data.data));
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handlePlay = async (fileName) => {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/meeting_records/get_audio`,
        {
          data: {
            audio_file: fileName,
          },
        }
      );
      if (!response.data.success) {
        alert(response.data.message);
        return;
      }
      const link = response.data.url;
      window.open(link, "_blank");
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = (id) => {
    setData((prev) => prev.filter((item) => item.id != id));
  };

  useEffect(() => {
    getData();
  }, [dateFrom, dateTo]);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        height: "100%",
        py: 4,
      }}
    >
      <Container style={{ maxWidth: "100%", height: "100%" }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={3}>
            <Typography color="textPrimary" variant="h5">
              {t("Zapisy z porad")}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={localeMap[locale]}
                >
                  <MobileDatePicker
                    label={t("Od")}
                    mask="__.__.____"
                    openTo="year"
                    name="date"
                    required={true}
                    value={dateFrom}
                    id="date"
                    onChange={(event) => {
                      setDateFrom(event);
                    }}
                    renderInput={(inputProps) => (
                      <TextField fullWidth variant="outlined" {...inputProps} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={localeMap[locale]}
                >
                  <MobileDatePicker
                    label={t("Do")}
                    mask="__.__.____"
                    openTo="year"
                    name="date"
                    required={true}
                    value={dateTo}
                    id="date"
                    onChange={(event) => {
                      setDateTo(event);
                    }}
                    renderInput={(inputProps) => (
                      <TextField fullWidth variant="outlined" {...inputProps} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  sx={{ width: "400px", flexGrow: 1 }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={t("Vyhledat...")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        disabled={!search}
                        aria-label="toggle password visibility"
                        onClick={handleSearch}
                      >
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isLoading || loading ? (
          <SimpleLoader />
        ) : (
          <>
            <Grid
              container
              sx={{ height: "100%", py: 2 }}
              spacing={3}
              justifyContent="space-between"
            >
              <Grid
                item
                xs={12}
                sm={canCreate && !fromCard ? 8 : 12}
                lg={canCreate && !fromCard ? 8 : 12}
                sx={{
                  height: "100%",
                }}
              >
                <Box sx={{ height: "100%", width: "100%", overflow: "auto" }}>
                  {data.map((file) => (
                    <Player
                      key={file.id}
                      file={file}
                      onPlay={() => handlePlay(file.audio_file)}
                      onDelete={handleDelete}
                    />
                  ))}
                </Box>
              </Grid>
              {canCreate && !fromCard && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  sx={{
                    height: "100%",
                  }}
                >
                  <NewRecord onStop={getData} />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Container>
    </Box>
  );
};

export default MeetingRecords;
