import { useState, useEffect, useCallback, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  Popover,
} from "@material-ui/core";
//import { customerApi } from '../../__fakeApi__/customerApi';
//import { CustomerListTable } from '../../components/dashboard/customer';
import useMounted from "../../../hooks/useMounted";
import Add from "./add.js";
import SendInfo from "./SendInfo.js";
import ChevronRightIcon from "../../../icons/ChevronRight";
import DownloadIcon from "../../../icons/Download";
import PlusIcon from "../../../icons/Plus";
import UploadIcon from "../../../icons/Upload";
import useSettings from "../../../hooks/useSettings";
import gtm from "../../../lib/gtm";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.js";
import DatePicker from "../../../aggrid/DatePicker.js";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.js";
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import LocaleRu from "../../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";
import { Send } from "@material-ui/icons";
import MultiSelectSimple from "../../../aggrid/MultiSelectSimple.js";
import { LicenseManager } from "ag-grid-enterprise";
import RichTextEditor from "../../../aggrid/RichTextEditor.js";
LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const Positions = (props) => {
  const { t } = useTranslation();

  const customComparator = (valueA, valueB) => {
    if (valueA != null && valueB != null)
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let vacancies = props.vacancies ? true : false;

  const additionalColumns = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      lockPosition: "left",
      pinned: "left",
      maxWidth: 150,
      sortable: false,
      resizable: false,
      suppressMenu: true,
      checkboxSelection: true,
    },
    {
      headerName: t("Název"),
      field: "name",
      pinned: "left",
    },
    {
      headerName: t("Veřejný název RU"),
      field: "short_name_ru",
    },
    {
      headerName: t("Veřejný název CZ"),
      field: "short_name_cz",
    },
    {
      headerName: t("Popis CZ"),
      field: "descCz",
      cellEditor: "RichTextEditor",
      editable: true,
      valueGetter: function (params) {
        if (params.data["descCz"] === null || params.data["descCz"] === "")
          return "";
        if (params.data && params.data.id) {
          return params.data.descCz.replace(/<[^>]+>/g, "");
        }
      },
    },
    {
      headerName: t("Popis RU"),
      field: "descRu",
      cellEditor: "RichTextEditor",
      editable: true,
      valueGetter: function (params) {
        if (params.data["descRu"] === null || params.data["descRu"] === "")
          return "";
        if (params.data && params.data.id) {
          return params.data.descRu.replace(/<[^>]+>/g, "");
        }
      },
    },
  ];

  const mainColumns = [
    {
      headerName: t("Popis - smlouva"),
      field: "popis",
    },

    {
      headerName: t("Zobrazit na náboru"),
      field: "nabor",
      cellRenderer: "CheckboxRenderer",
    },
    {
      headerName: t("CZ-ISCO smlouva"),
      field: "kod",
    },
    {
      headerName: t("CZ-ISCO pokyn"),
      field: "kod_pokyn",
    },

    {
      headerName: t("NACE"),
      field: "nace",
    },

    {
      headerName: t("Vzdělání"),
      field: "vzdelani",
    },
    {
      headerName: t("Mzda smlouva"),
      field: "mzda_smlouva",
    },
    {
      headerName: t("Druh práce - smlouva"),
      field: "dp_smlouva",
    },
    {
      headerName: t("Druh práce - pokyn"),
      field: "dp_pokyn",
    },

    {
      headerName: t("Popis HU"),
      field: "popis_hu",
    },
    {
      headerName: t("Popis PL"),
      field: "popis_pl",
    },
    {
      headerName: t("Popis ROU"),
      field: "popis_rou",
    },
    {
      headerName: t("Popis BGR"),
      field: "popis_bgr",
    },

    {
      headerName: t("DP smlouva HU"),
      field: "dp_smlouva_hu",
    },
    {
      headerName: t("DP smlouva PL"),
      field: "dp_smlouva_pl",
    },
    {
      headerName: t("DP smlouva ROU"),
      field: "dp_smlouva_rou",
    },
    {
      headerName: t("DP smlouva BGR"),
      field: "dp_smlouva_bgr",
    },

    {
      headerName: t("Tarif"),
      field: "tarif",
    },
    {
      headerName: t("Odstranit"),
      cellRenderer: "DeleteRenderer",
      minWidth: 90,
      cellRendererParams: {
        gridType: "positions",
      },
      suppressMenu: true,
    },
  ];

  const coldefs = useMemo(() => {
    if (props.vacancies) {
      return additionalColumns;
    } else {
      return [...mainColumns, ...additionalColumns];
    }
  }, [props.vacancies]);

  const mounted = useMounted();

  const { settings } = useSettings();
  const [rowdata, setRowdata] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [columndefs, setColumndefs] = useState(coldefs);
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const defaultColDef = {
    flex: 1,
    minWidth: 170,
    editable: true,
    resizable: true,
    suppressMovableColumns: true,
    sortable: true,
    filter: true,
    suppressContextMenu: true,
  };

  const frameworkComponents = {
    CheckboxRenderer: CheckboxRenderer,
    MultiSelectSimple: MultiSelectSimple,
    DeleteRenderer: DeleteRenderer,
    DatePicker: DatePicker,
    RichTextEditor: RichTextEditor,
  };

  const grd = () => {
    HttpService.get(
      UrlService.apiDomain() + "api/positions/" + props.id + "/" + vacancies
    )
      .then((res) => {
        setRowdata(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRowdata = useCallback(async () => {
    grd();
  }, [mounted]);

  useEffect(() => {
    grd();
  }, [props.id]);

  const onCellCValueChanged = (event) => {
    event.data.field = event.colDef.field;
    HttpService.put(
      UrlService.apiDomain() + "api/positions/" + event.data.id,
      event.data
    )
      .then((res) => {
        if (res.status != 200) {
          alert(
            t("Chyba serveru, prosím zkuste pozdějí") +
              ". " +
              t(res.data.message)
          );
        } else {
          console.log("Successfully updated");
          // props.setCustomerData && props.setCustomerData(res.data.data);
          event.node.setData(res.data.entry);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sendClose = () => {
    setOpenSend(false);
  };

  const handleOpenSend = () => {
    setOpenSend(true);
  };

  const handleAdd = (data) => {
    data.customer_id = props.id;
    HttpService.post(UrlService.apiDomain() + "api/positions", data)
      .then((res) => {
        if (res.data.result == "failed") {
          alert(res.data.message);
        } else {
          console.log("Successfully updated");
          handleClose();
          let r = gridApi.updateRowData({
            add: [res.data.entry],
          });

          gridApi.flashCells({ rowNodes: [r.add[0]] });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);

    //params.api.setDomLayout('autoHeight');
    //document.querySelector('#myGrid').style.height = '';

    var columnState = JSON.parse(
      localStorage.getItem("positions_column_state")
    );
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  };

  const onDragStopped = (params) => {
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem("positions_column_state", columnState);
  };

  useEffect(() => {
    setloctext(lt);
    setColumndefs(coldefs);
  }, [t("cs")]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Positions | AWA Partners</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("Pozice")}
              </Typography>
            </Grid>
            <Popover
              open={openSend}
              anchorEl={document.querySelector("body")}
              anchorOrigin={{
                horizontal: "center",
                vertical: "top",
              }}
              onClose={sendClose}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <SendInfo data={selectedRows} onClose={sendClose} />
            </Popover>

            <Popover
              anchorOrigin={{
                horizontal: "center",
                vertical: "top",
              }}
              keepMounted
              onClose={handleClose}
              open={open}
              PaperProps={{
                sx: { width: 540 },
              }}
            >
              <Add onAdd={handleAdd} />
            </Popover>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {props.vacancies ? (
                  <Button
                    color="primary"
                    disabled={!selectedRows.length}
                    startIcon={<Send fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={handleOpenSend}
                  >
                    {t("Odeslat")}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {t("Přidat")}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <div
              style={{ width: "100%", overflow: "auto", height: "350px" }}
              id="myGrid"
              className="ag-theme-material"
            >
              <AgGridReact
                modules={AllCommunityModules}
                rowData={rowdata}
                columnDefs={columndefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onDragStopped={onDragStopped}
                onCellValueChanged={onCellCValueChanged}
                frameworkComponents={frameworkComponents}
                enableCellChangeFlash={true}
                onSelectionChanged={() =>
                  setSelectedRows(gridApi.getSelectedRows())
                }
                rowSelection={"multiple"}
                suppressContextMenu={true}
                localeText={loctext}
                popupParent={document.querySelector("body")}
              />
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Positions;
