import UrlService from "../../services/UrlService";

const at = localStorage.getItem("accessToken");

export default function deleteDocumentTag(tag_id, handleResult, handleError) {
    fetch(UrlService.apiDomain() + `api/documents/tags/${tag_id}`, {
        method: 'delete',
        headers: {
            Authorization: "Bearer " + at,
        },
    })
        .then(r => r.json())
        .then(response => {
            if (!response.success) {
                handleError(response.error);
                return;
            }
            handleResult(response);
        })
}