"use strict";


import UISVGT from "./UISVGT.mjs";

import UISVGProducerAbstractT from "../../../Interface/UI/SVG/_/UISVGProducerAbstractT.mjs";


const UISVGProducerT = class UISVGProducerT extends UISVGProducerAbstractT {
	
	constructor(
		
	){
		
		console.log( "UISVGProducerT.constructor" );
		
		
		super( );
		
	}
	
	Produce(
		...Props
	){
		
		console.log( "UISVGProducerT.Produce", Props );
		
		
		return new UISVGT(
			...Props
		);
		
	}
	
};


export default UISVGProducerT;