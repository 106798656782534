import InputWithValidationOld from "../../../inputs/text/InputWithValidationOld";

export const SwiftRegex = /^([A-Z]{6})([A-Z0-9]{2})([A-Z0-9]{3})?$/;

export default function InputBicSwift(props) {
    return (
        <InputWithValidationOld regex={SwiftRegex}
                                onChange={props.onChange}
                                name="bic_swift"
                                label="BIC/SWIFT"
                                value={props.value}
                                processValue={(val) => val.toUpperCase()}/>
    );
}