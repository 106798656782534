import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import useSettings from "../../../hooks/useSettings";
import { stateToHTML } from "draft-js-export-html";
import { useState, useEffect } from "react";
import MUIRichTextEditor from "mui-rte";
import Positions from "../../../pages/settings/positions/index.js";
import toast from "react-hot-toast";
import {
  convertFromHTML,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import SimpleLoader from "../../SimpleLoader.js";

const CustomerVacancies = ({ customer_id }) => {
  const { settings } = useSettings();
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + "api/positions/browse_for_sharing",
        {
          data: {
            customer_id,
          },
        }
      );
      if (!res.data.success) {
        alert(res.data.message);
        setLoading(false);
        return;
      }
      setLoading(false);
      setVacancies(Object.values(res.data.data));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getDescription = (id, lang) => {
    const vacancy = vacancies.find((i) => i.id == id);
    let data = lang === "ru" ? vacancy.descRu : vacancy.descCz;
    const ContentHTML = convertFromHTML(data ?? "");
    const State = ContentState.createFromBlockArray(
      ContentHTML.contentBlocks,
      ContentHTML.entityMap
    );
    return JSON.stringify(convertToRaw(State));
  };

  const saveDesc = async (data, lang, id) => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + "api/edit-vacancy-desc",
        {
          id,
          data,
          language: lang,
        }
      );
      if (res.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      toast.success("Popis aktualizován");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  const languageOptions = {
    ru: {
      icon: "/static/icons/ru_flag.svg",
    },
    cz: {
      icon: "/static/icons/cs_flag.svg",
    },
  };

  if (loading) {
    return <SimpleLoader />;
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          pb: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Box
            sx={{
              width: 1,
            }}
          >
            <Positions
              id={customer_id}
              vacancies={true}
              // setCustomerData={setCustomerData}
            />
          </Box>
          <Grid container justifyContent="space-between" spacing={3}>
            {vacancies
              .filter((vac) => vac.descRu != null || vac.descCz != null)
              .map((vacancy) => {
                let descRu = getDescription(vacancy.id, "ru");
                let descCz = getDescription(vacancy.id, "cz");

                console.log({ descRu });
                return (
                  <Grid item xs={12} key={vacancy.id}>
                    <Box
                      sx={{
                        width: 1,
                        display: "inline-flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography color="textPrimary" variant="h5">
                        {vacancy.short_name
                          ? `${vacancy.name} - ${vacancy.short_name}`
                          : vacancy.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: 1,
                        // minHeight: "200px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box
                        sx={{
                          width: 0.5,
                          minHeight: 0.5,
                          overflow: "auto",
                          position: "relative",
                        }}
                      >
                        <img
                          src={languageOptions.cz.icon}
                          alt="Czech flag"
                          style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <MUIRichTextEditor
                          label="Napište popis do tabulky výše"
                          defaultValue={descCz}
                          onSave={(data) => {
                            saveDesc(
                              stateToHTML(convertFromRaw(JSON.parse(data))),
                              "cz",
                              vacancy.id
                            );
                          }}
                          readOnly={true}
                          toolbar={false}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: 0.5,
                          minHeight: 0.5,
                          overflow: "auto",
                          position: "relative",
                        }}
                      >
                        <img
                          src={languageOptions.ru.icon}
                          alt="Czech flag"
                          style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <MUIRichTextEditor
                          label="Напишите описание в таблице выше"
                          defaultValue={descRu}
                          onSave={(data) => {
                            saveDesc(
                              stateToHTML(convertFromRaw(JSON.parse(data))),
                              "ru",
                              vacancy.id
                            );
                          }}
                          readOnly={true}
                          toolbar={false}
                        />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default CustomerVacancies;
