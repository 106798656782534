export function addToDataArray(model, item) {
    const data = JSON.parse(localStorage.getItem(model));
    if(!data) return;
    data.push(item);
    localStorage.setItem(model, JSON.stringify(data));
}

export function updateDataInArray(model, item) {
    const data = JSON.parse(localStorage.getItem(model));
    if(!data) return;
    const index = data.findIndex(i => i.id === item.id);
    if(index === -1) return;
    data[index] = item;
    localStorage.setItem(model, JSON.stringify(data));
}

export function getLocalJson(name) {
    let json = localStorage.getItem(name);
    return json ? JSON.parse(json) : null;
}

export function setLocalJson(name, value) {
    localStorage.setItem(name, JSON.stringify(value));
}

