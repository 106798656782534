import {useEffect, useState} from "react";
import {Button, Grid, LinearProgress} from "@material-ui/core";
import FileUpload from "./FileUpload";
import {Check} from "@material-ui/icons";
import httpPost from "../../../api/base/httpPost";

export default function FileUploadWithSubmit({label, model, field, id, onAdd, withoutConfirmation}) {
    const [file, setFile] = useState(undefined);

    const handleSubmit = () => {
        if (file) {
            httpPost(
              [
                {
                  key: "file[]",
                  value: file,
                },
                {
                  key: "model",
                  value: model,
                },
                {
                  key: "field",
                  value: field,
                },
                {
                  key: "id",
                  value: id,
                },
              ],
              "attachments/add",
              (result) => {
                setFile(undefined);
                if (onAdd) onAdd(result.attachments);
                alert("Soubor úspěšně nahrán");
              }
            );
        }
    };

    useEffect(() => {
        if(withoutConfirmation && file) {
            handleSubmit()
        }
    }, [withoutConfirmation, file])

    if (withoutConfirmation) {
        if (file) {
            return <div></div>;
        }
        return (<Grid item sx={{display: 'flex', m: '0'}}>
            <FileUpload file={file} multiple name={field} label={label} onChange={(name, f) => {
                if (file !== f) {
                    setFile(f);
                }
            }}/>
            {file !== undefined && <Button
                fullWidth
                sx={{ml: 1}}
                color="primary"
                type="button"
                variant="outlined"
                onClick={handleSubmit}
            ><Check/> Odeslat
            </Button>}
        </Grid>)

    }

    return (<Grid item sx={{display: 'flex', m: '0'}}>
        <FileUpload file={file} multiple name={field} label={label} onChange={(name, f) => {
            if (file !== f) {
                setFile(f);
            }
        }}/>
        {file !== undefined && <Button
            fullWidth
            sx={{ml: 1}}
            color="primary"
            type="button"
            variant="outlined"
            onClick={handleSubmit}
        ><Check/> Odeslat
        </Button>}
    </Grid>)
}