import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import apiGet from "../../api/apiGet";
import GridWrapper from "../../components/grid/gridWrapper";
import DownloadAttachment from "../../aggrid/downloadAttachment";
import FileUploadWithSubmit from "../../components/inputs/file/FileUploadWithSubmit";
import DeleteRenderer from "../../aggrid/DeleteRenderer";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { format } from "date-fns";


const CustomerFiles = ({ customer_id }) => {
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState([]);
  const {
    data: { thp_users },
  } = useContext(DataContext);

  let hasPermission = JSON.parse(localStorage.getItem("policy_column_tree"))[
    "customers"
  ].update;

  useEffect(() => {
    apiGet(`customers/${customer_id}/attachments/files`).then((response) => {
      if (response.data && response.data.length > 0) {
        setAttachments(response.data);
      } else {
        console.log("No attachments found");
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: t("Soubor"),
      editable: false,
      field: "attachment",
      valueGetter: ({ data: { attachment } }) => {
        let parts = attachment.split("/");
        let result = parts[parts.length - 1];

        return result;
      },
    },
    {
      headerName: t("Nahrál"),
      editable: false,
      field: "created_by",
      valueGetter: ({ data: { user } }) => {
        return thp_users.find((u) => u.id == user)?.name;
      },
    },
    {
      headerName: t("Nahráno"),
      editable: false,
      field: "created_at",
      valueGetter: ({ data: { created_at } }) => {
        return format(new Date(created_at), "dd.MM.yyyy");
      },
    },
    {
      headerName: t("Akce"),
      editable: false,
      field: "id",
      cellRenderer: "downloadAttachment",
    },
  ];

  if (hasPermission) {
    columnDefs.push({
      headerName: t("Odstranit"),
      editable: false,
      cellRendererParams: {
        gridType: "delete/files",
      },
      cellRenderer: "DeleteRenderer",
      suppressMenu: true,
      editable: false,
      resizable: false,
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
    });
  }
  return (
    <GridWrapper
      withoutExcel={true}
      style={{ mt: 2 }}
      title={t("Přílohy")}
      data={attachments}
      frameworkComponents={{
        downloadAttachment: DownloadAttachment,
        DeleteRenderer: DeleteRenderer,
      }}
      colDefs={columnDefs}
      needResize={false}
    >
      {hasPermission && (
        <FileUploadWithSubmit
          model={"customers"}
          id={customer_id}
          field={"files"}
        />
      )}
    </GridWrapper>
  );
};

export default CustomerFiles;
