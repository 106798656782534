import ModalWrapper from "../modal/ModalWrapper";
import { useEffect, useState } from "react";
import ModalWithButton from "../modal/ModalWithButton";
import FormWithSubmit from "../form/FormWithSubmit";
import { LinearProgress } from "@material-ui/core";
import { format } from "date-fns";
import GridWrapper from "./index";
import httpPost from "../../api/base/httpPost";
import httpGet from "../../api/base/httpGet";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

export default function Notes({
  open,
  id,
  model,
  onClose,
  getData,
  browseData,
}) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [rows, setRows] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!open || !id || !model) return;
    let mounted = true;
    setReady(false);
    httpGet(`${model}/${id}/notes`, (response) => {
      if (mounted) {
        setRows(response?.data ?? []);
        setReady(true);
      }
    });

    return () => {
      mounted = false;
    };
  }, [id, model, open]);

  if (!ready) {
    return (
      <ModalWrapper isOpen={open} title={t("Přidat poznámku")}>
        <LinearProgress fullWidth sx={{ minWidth: "200px" }} />
      </ModalWrapper>
    );
  }

  const form = (
    <FormWithSubmit
      noTemplates={true}
      submitText={t("Přidat poznámku")}
      onSubmit={(data) => {
        httpPost(data, `${model}/${id}/notes`, (result) => {
          console.log("Success", result);
          setRows(rows.concat(result.data));
          browseData && getData?.("customer_reports", null, browseData, true);
        });
      }}
      form={[
        {
          name: "text",
          label: t("Text"),
          editable: true,
          size: 12,
          required: 1,
          type: "text",
        },
      ]}
    />
  );

  let isAdmin = !!user?.roles?.find((role) => {
    return parseInt(role.id) === 2 || parseInt(role.id) === 8;
  });

  return (
    <ModalWrapper
      isOpen={open}
      onClose={onClose}
      size={rows.length === 0 ? "xs" : "md"}
    >
      {rows.length > 0 && (
        <GridWrapper
          title={t("Poznámky")}
          model={"notes"}
          updateUrl={"notes/update"}
          data={rows}
          colDefs={[
            {
              field: "text",
              headerName: t("Text"),
              editable: (params) => {
                return isAdmin || params.data.author_id === user.id;
              },
            },
            { field: "author", headerName: t("Author") },
            {
              field: "author_role",
              headerName: t("Author role"),
              valueFormatter: (params) => {
                return (
                  JSON.parse(localStorage.getItem("roles"))?.find(
                    (role) => parseInt(role.id) === parseInt(params.value)
                  )?.name ?? ""
                );
              },
            },
            {
              field: "created_at",
              headerName: t("Vytvořeno"),
              valueFormatter: (params) => {
                return params.value && params.value !== "0000-00-00"
                  ? format(new Date(params.value), "dd.MM.yyyy")
                  : "";
              },
              filter: "agDateColumnFilter",
              filterParams: {
                comparator: (aDate, b) => {
                  if (aDate && b) {
                    let bDate = new Date(b);
                    bDate.setHours(0, 0, 0, 0);
                    return aDate.getTime() - bDate.getTime();
                  }
                  if (aDate) {
                    return 1;
                  }
                  return -1;
                },
              },
              comparator: (a, b) => {
                if (a && b) {
                  let aDate = new Date(a);
                  let bDate = new Date(b);
                  bDate.setHours(0, 0, 0, 0);
                  return aDate.getTime() - bDate.getTime();
                }
                if (a) {
                  return 1;
                }
                return -1;
              },
            },
            {
              type: "delete",
              disabled: (params) => {
                return !isAdmin && params.data.author_id !== user.id;
              },
              onDelete: (id) => {
                httpGet(`notes/delete/${id}`, () => {
                  setRows(rows.filter((row) => row.id !== id));
                  browseData &&
                    getData?.("customer_reports", null, browseData, true);
                });
              },
            },
          ]}
          handleChange={(newRows) => {
            setRows(newRows);
          }}
        >
          <ModalWithButton title={t("Nová poznámka")}>{form}</ModalWithButton>
        </GridWrapper>
      )}
      {rows.length === 0 && (
        <ModalWithButton
          title={t("Nová poznámka")}
          buttonProps={{ fullWidth: true }}
        >
          {form}
        </ModalWithButton>
      )}
    </ModalWrapper>
  );
}
