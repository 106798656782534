
import { getBoard } from "../../slices/vacancies";
import SimpleLoader from "../../components/SimpleLoader";
import { useDispatch, useSelector } from "../../store";
import { useEffect } from "react";
import VacanciesList from "./VacanciesList";

const Vacancies = ({ customer }) => {
  const dispatch = useDispatch();
  const { isLoaded } = useSelector((state) => state.vacancies);
  useEffect(() => {
    dispatch(getBoard());
  }, [dispatch]);
  return isLoaded ? (
    <VacanciesList columnId={customer.toString()} />
  ) : (
    <SimpleLoader />
  );
};

export default Vacancies;
