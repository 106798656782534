import { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
//import { customerApi } from '../../__fakeApi__/customerApi';
//import { CustomerListTable } from '../../components/dashboard/customer';
import useMounted from "../../hooks/useMounted";
import useSettings from "../../hooks/useSettings";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import CheckboxRenderer from "../../aggrid/CheckboxRenderer.js";
import { DataContext } from "../../contexts/DataContext";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";
import { compareAsc, format } from "date-fns";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const maskMap = {
  ru: "__.__.____",
  cs: "__.__.____",
};

const GenerateDocuments = (props) => {
  const { t } = useTranslation();

  const { data: contextData } = useContext(DataContext);

  const customComparator = (valueA, valueB) => {
    if (valueA != null && valueB != null)
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  var coldefs = [
    {
      headerName: t("Název"),
      field: "name",
      checkboxSelection: true,
    },
    {
      headerName: "tag",
      field: "tag",
      rowGroup: true,
      checkboxSelection: true,
      hide: true,
    },
    {
      headerName: "Kopií",
      field: "pages",
      valueGetter: function (params) {
        return Math.max(params.data?.pages ?? 0, 1);
      },
    },
  ];

  const mounted = useMounted();
  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");
  const [locale, setLocale] = useState(lc);
  const [date, setDate] = useState(new Date());
  const { settings } = useSettings();
  const [rowdata, setRowdata] = useState(null);
  const [open, setOpen] = useState(false);
  const [columndefs, setColumndefs] = useState(coldefs);
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});
  const [loading, setLoading] = useState("none");
  const [twoSided, setTwoSided] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [assignments, setAssignments] = useState([]);
  const [employments, setEmployments] = useState([]);
  const [assignment, setAssignment] = useState(null);
  const [employment, setEmployment] = useState(null);

  useEffect(() => {
    setEmployment(employments.find((emp) => emp.actual)?.id);
  }, [employments]);
  useEffect(() => {
    setAssignment(assignments.find((ass) => ass.actual)?.id);
  }, [assignments]);

  const customers = contextData.all_customers;
  const work_places = JSON.parse(localStorage.getItem("all_work_places"));
  const agencies = JSON.parse(localStorage.getItem("agencies"));

  const assignmentsList = assignments.map((ass) => {
    return (
      <MenuItem key={ass.id} value={ass.id}>
        {`${customers.find((cus) => cus.id == ass.uzivatel)?.name}: ${
          work_places.find((place) => place.id == ass.misto_vykonu)?.name || ""
        } (${
          ass.docasne_prideleni_od
            ? format(new Date(ass.docasne_prideleni_od), "dd.MM.yyyy")
            : t("Ne")
        }-${
          ass.docasne_prideleni_do
            ? format(new Date(ass.docasne_prideleni_do), "dd.MM.yyyy")
            : t("Ne")
        })`}
      </MenuItem>
    );
  });
  const employmentsList = employments.map((emp) => {
    return (
      <MenuItem key={emp.id} value={emp.id}>
        {`${agencies.find((ag) => ag.id == emp.agentura)?.name} (${
          emp.smlouva_od
            ? format(new Date(emp.smlouva_od), "dd.MM.yyyy")
            : t("Ne")
        }-${
          emp.smlouva_do
            ? format(new Date(emp.smlouva_do), "dd.MM.yyyy")
            : t("Ne")
        })`}
      </MenuItem>
    );
  });

  const defaultColDef = {
    flex: 1,
    minWidth: 170,
    editable: true,
    resizable: true,
    suppressMovableColumns: true,
    sortable: true,
    filter: true,
    suppressContextMenu: true,
  };

  const frameworkComponents = {
    CheckboxRenderer: CheckboxRenderer,
  };

  const getRowdata = () => {
    if (!assignment || !employment) {
      setRowdata([]);

      return;
    }

    var send_sc = props.sc;
    if (send_sc == "") send_sc = "none";
    HttpService.post(
      UrlService.apiDomain() +
        "api/generate_documents_list/" +
        props.type +
        "/" +
        send_sc,
      {
        employee_id: props.id,
        assignment_id: assignment ? assignment : null,
        employment_id: employment ? employment : null,
      }
    )
      .then((res) => {
        let final_data = [];
        let storage = JSON.parse(localStorage.getItem("document_tags"));
        for (const item of res.data) {
          if (!item.tags) {
            final_data.push({ ...item, tag: "other" });
          } else {
            let tags = item.tags.split("//");
            for (const tag_id of tags) {
              let tag = storage.find((x) => x.id === parseInt(tag_id));
              final_data.push({ ...item, tag: tag.name });
            }
          }
        }
        setRowdata(final_data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssignments = () => {
    if (props.id) {
      HttpService.get(
        UrlService.apiDomain() + "api/employee_models/assignments/" + props.id
      )
        .then((res) => {
          setAssignments(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getEmployments = () => {
    if (props.id) {
      HttpService.get(
        UrlService.apiDomain() + "api/employee_models/employments/" + props.id
      )
        .then((res) => {
          setEmployments(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    //getRowdata();
    getAssignments();
    getEmployments();
  }, [props.id]);

  useEffect(() => {
    getRowdata();
  }, [assignment, employment]);

  console.log({ assignment });
  console.log({ employment });

  const generate_documents = (sign) => {
    var signit = false;
    if (sign) signit = true;
    setLoading("block");

    var send_sc = props.sc;
    if (send_sc == "") send_sc = "none";
    var rows = [];
    gridApi.getSelectedRows().forEach((e) => {
      if (!e.pages) {
        e.pages = 1;
      } else {
        e.pages = parseInt(e.pages);
      }
      if (rows.findIndex((x) => x.id === e.id) === -1)
        rows.push({ ...e, tag: "" });
    });

    if (signit) {
      HttpService.post(
        `${UrlService.apiDomain()}api/generate_documents/${
          props.type
        }/${send_sc}/${twoSided}/${signit}`,
        {
          rows: rows,
          date: format(date, "dd.MM.yyyy"),
          assignment,
          employment,
        }
      )
        .then((response) => {
          setLoading("none");
          alert(response.data.message);
          /*
                    if (!response.data.success) {
                        console.log(response);
                        setLoading('none');
                        alert("Chyba: " + response.data.message);
                        return;
                    }
                    window.open(response.data.url, '_blank');
                    setLoading('none');
                    */
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      HttpService.postblob(
        `${UrlService.apiDomain()}api/generate_documents/${
          props.type
        }/${send_sc}/${twoSided}/${signit}`,
        {
          rows: rows,
          date: format(date, "dd.MM.yyyy"),
          assignment,
          employment,
        }
      )
        .then((response) => {
          if (response.status !== 200 && response.status !== 2) {
            setLoading("none");
            alert("Chyba serveru");
            return;
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          var filename = "print.pdf";
          link.setAttribute("download", filename);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          setLoading("none");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);
    var columnState = JSON.parse(
      localStorage.getItem("generate_documents_column_state")
    );
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  };

  const onDragStopped = (params) => {
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem("generate_documents_column_state", columnState);
  };

  useEffect(() => {
    setloctext(lt);
    setColumndefs(coldefs);
  }, [t("cs")]);

  function onSelectionChanged(event) {
    let selectedNode = event.node;
    if (selectedNode.field === "tag") {
      for (const children of selectedNode.allLeafChildren) {
        children.setSelected(selectedNode.selected);
      }
    }
    setSelectedCount(gridApi.getSelectedRows().length);
  }

  const handleTwoSidedChanged = (e) => {
    setTwoSided(e.target.checked);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Generate documents | AWA Partners</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography color="textPrimary" variant="h5">
                  {t("Generovat dokumenty")}
                </Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <Select
                    sx={{ m: 1 }}
                    size="small"
                    onChange={(e) => {
                      setAssignment(e.target.value);
                    }}
                    value={assignment}
                    id="assignment_select"
                  >
                    {assignmentsList}
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    sx={{ m: 1 }}
                    size="small"
                    onChange={(e) => {
                      setEmployment(e.target.value);
                    }}
                    value={employment}
                    id="employment_select"
                  >
                    {employmentsList}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Box sx={{ m: -1, mt: 0 }}>
                    <FormControlLabel
                      control={<Checkbox onChange={handleTwoSidedChanged} />}
                      label={t("Oboustranny tisk")}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ m: -1 }}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={localeMap[locale]}
                    >
                      <MobileDatePicker
                        label={t("Datum")}
                        mask="__.__.____"
                        openTo="year"
                        name="date"
                        required={true}
                        value={date}
                        id="date"
                        onChange={(event) => {
                          setDate(event);
                        }}
                        renderInput={(inputProps) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            {...inputProps}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ m: -1 }}>
                    <Button
                      color="primary"
                      startIcon={<NoteAddIcon fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                      disabled={selectedCount === 0}
                      onClick={() => {
                        generate_documents();
                      }}
                    >
                      {t("Generovat dokumenty")} {selectedCount}
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ m: -1 }}>
                    <Button
                      color="primary"
                      startIcon={<NoteAddIcon fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                      disabled={selectedCount === 0}
                      onClick={() => {
                        generate_documents(true);
                      }}
                    >
                      {t("Generovat k podpisu dokumenty")} {selectedCount}
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ m: -1 }}>
                    <CircularProgress sx={{ display: loading }} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <div
              style={{ width: "100%", overflow: "auto", height: "500px" }}
              id="myGrid"
              className="ag-theme-material"
            >
              <AgGridReact
                modules={AllCommunityModules}
                rowData={rowdata}
                columnDefs={columndefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onDragStopped={onDragStopped}
                onRowSelected={onSelectionChanged}
                groupDisplayType={"groupRows"}
                frameworkComponents={frameworkComponents}
                enableCellChangeFlash={true}
                rowSelection={"multiple"}
                suppressContextMenu={true}
                localeText={loctext}
                popupParent={document.querySelector("body")}
              />
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default GenerateDocuments;
