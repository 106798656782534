"use strict";


import UIGridAbstractT from "./UIGridAbstractT.mjs";


const UIGridProducerAbstractT = class UIGridProducerAbstractT {
	
	constructor(
		
	){
		
	}
	
	Produce(
		...Props
	){
		
		throw new Error( "Abstract" );
		
	}
	
};


export default UIGridProducerAbstractT;