import UrlService from "../services/UrlService";

export default function updateYourCompanyDetails(
  newDetails,
  handleResult,
  handleError
) {
  let formData = new FormData();
  const at = localStorage.getItem("accessToken");
  if (newDetails.payment_type !== "transfer") {
    newDetails = {
      ...newDetails,
      iban: "",
      bic_swift: "",
      bank_country: "",
      bank_address_1: "",
      bank_address_2: "",
      bank_name_1: "",
      bank_name_2: "",
      beneficiary_address_1: "",
      beneficiary_address_2: "",
      beneficiary_name_1: "",
      beneficiary_name_2: "",
    };
  }

  for (let key in newDetails) {
    formData.append(key, newDetails[key]);
  }

  fetch(UrlService.apiDomain() + "api/companies/your/details", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + at,
    },
    body: formData,
  })
    .then((r) => r.json())
    .then((response) => {
      if (!response.success) {
        handleError(response.error);
        return;
      }
      handleResult(response.company);
    });
}
