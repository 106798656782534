"use strict";


const UISVGAbstractT = class UISVGAbstractT {
	
	constructor( ){
		
	}
	
	//TODO
	
};


export default UISVGAbstractT;