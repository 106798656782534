import { format } from "date-fns";
import { Avatar, Box, Paper, Typography, IconButton } from "@material-ui/core";
import TrashIcon from "../../../icons/Trash";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import AddComment from "./AddComment";
import { useState } from "react";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import { Close } from "@material-ui/icons";

const ChildComment = ({
  onDelete,
  comment,
  allCommentators,
  grd,
  parent_id,
  onAdd,
  customer,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { created_at, created_by, message, id, deleted, personal } = comment;
  const creator = allCommentators[created_by];
  const [openAdd, setOpenAdd] = useState(false);
  const visibleDeleteIcon = user.id == created_by ? "visible" : "hidden";

  if (personal && user.id != created_by) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          pl: 10,
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{ mr: 2, width: 70, height: 70, borderRadius: "50%" }}
        />
        <Box
          sx={{
            ml: 2,
            flexGrow: 1,
          }}
        >
          <Typography color="textPrimary" variant="subtitle2">
            {`${creator?.name} ${creator?.surname}`}
          </Typography>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: 1,
            }}
          >
            <Paper
              sx={{
                width: 1,
                backgroundColor: "background.default",
                mt: 1,
                p: 2,
              }}
              variant="outlined"
            >
              <Typography color="textPrimary" variant="body2">
                {deleted ? "Komentář smazán autorem" : message}
              </Typography>
            </Paper>
            <Box sx={{ display: "flex", gap: 3 }}>
              {!openAdd && (
                <IconButton
                  color="primary"
                  title={t("Odpovědět")}
                  onClick={() => setOpenAdd(true)}
                >
                  <SubdirectoryArrowLeftIcon />
                </IconButton>
              )}
              {openAdd && (
                <IconButton
                  title={t("Zrušit")}
                  onClick={() => setOpenAdd(false)}
                >
                  <Close />
                </IconButton>
              )}
              {!deleted && (
                <IconButton
                  title={t("Smazat")}
                  onClick={() => onDelete(id)}
                  sx={{ visibility: visibleDeleteIcon }}
                >
                  <TrashIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Box>
          <Typography
            color="textSecondary"
            component="p"
            sx={{ mt: 1 }}
            variant="caption"
          >
            {format(new Date(created_at), " dd.LL 'v' H:mm")}
          </Typography>
        </Box>
      </Box>
      {openAdd && (
        <AddComment
          customer={customer}
          grd={grd}
          parent_id={parent_id}
          onAdd={onAdd}
        />
      )}
    </>
  );
};

export default ChildComment;
