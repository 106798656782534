import { useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import TrashIcon from "../../../icons/Trash";
import {
  deleteCheckItem,
  updateCheckItem,
  addComment,
} from "../../../slices/vacancies";
import EmployeeCard from "../../employee_card";
import { useDispatch } from "../../../store";
import useAuth from "../../../hooks/useAuth";
import { format } from "date-fns";

const CircleAvatar = experimentalStyled("div")({
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: "#ccc",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: 8,
  color: "#fff",
  fontWeight: "bold",
});

const KanbanCheckItemRoot = experimentalStyled("div")(
  ({ theme, disabled }) => ({
    alignItems: "flex-start",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    padding: theme.spacing(1),
    "@media (hover: none)": {
      // "& button": {
      //   visibility: !disabled ? "hidden" : "visible",
      // },
    },
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      "& button": {
        visibility: "visible",
      },
    },
  })
);

const KanbanCheckItem = (props) => {
  const {
    card,
    cardId,
    checkItem,
    checklistId,
    editing,
    onEditCancel,
    onEditComplete,
    onEditInit,
    ...other
  } = props;
  const dispatch = useDispatch();

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies;
  const { user } = useAuth();
  const [deleteReason, setDeleteReason] = useState("Nekontaktní");
  const [open, setOpen] = useState(false);
  const [openCommunication, setOpenCommunication] = useState(false);
  const disabled =
    user.id == card?.created_by || policy_actions.includes("edit");

  const isDodavatel = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies.includes("dodavatel_view");

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleStateChange = async (event) => {
    try {
      const state = event.target.checked ? "complete" : "incomplete";

      await dispatch(
        updateCheckItem({
          cardId,
          checklistId,
          checkItem: checkItem.id,
          state: { state },
        })
      );
      toast.success("Záznam obnoven");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  const handleDelete = async () => {
    try {
      dispatch(
        deleteCheckItem({
          cardId,
          checklistId,
          checkItemId: checkItem.id,
          deleteReason,
        })
      );
      dispatch(
        addComment({
          cardId,
          message: `Kandidát ${checkItem.name} byl odstraněn z důvodu ${deleteReason}`,
        })
      );
      handleClose();
      toast.success("Osoba je odstraněna!");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  const renderAvatar = () => {
    const initials =
      checkItem.createdBy &&
      checkItem.createdBy
        .split(" ")
        .map((name) => name.charAt(0))
        .join("");
    return <CircleAvatar>{initials}</CircleAvatar>;
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={() => {
          setOpenCommunication(false);
        }}
        open={openCommunication}
      >
        <EmployeeCard
          // month={month}
          // year={year}
          // uzivatel={uzivatel}
          // sc={sc}
          id={checkItem.employee_id}
          child_model={"profile"}
          type="employees"
          // node={communicationNode}
          onClose={() => {
            setOpenCommunication(false);
          }}
        />
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Uveďte důvod smazání</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Select
              id="demo-simple-select"
              value={deleteReason}
              onChange={(event) => {
                setDeleteReason(event.target.value);
              }}
            >
              <MenuItem value={"Nevhodný"}>Nevhodný</MenuItem>
              <MenuItem value={"Nekontaktní"}>Nekontaktní</MenuItem>
              <MenuItem value={"Neprošel pohovor"}>Neprošel pohovor</MenuItem>
              <MenuItem value={"Nedorazil na pohovor"}>
                Nedorazil na pohovor
              </MenuItem>
              <MenuItem value={"Nebyl kontaktován náborem"}>
                Nebyl kontaktován náborem
              </MenuItem>
              <MenuItem value={"Zrušený pohovor"}>Zrušený pohovor</MenuItem>
              <MenuItem value={"Přiřazen na jiný požadavek"}>
                Přiřazen na jiný požadavek
              </MenuItem>
              <MenuItem value={"Neplatný dokumenty"}>
                Neplatný dokumenty
              </MenuItem>
              <MenuItem value={"Nedorazil na nastup"}>
                Nedorazil na nastup
              </MenuItem>
              <MenuItem value={"Nemá zájem o prací"}>
                Nemá zájem o prací
              </MenuItem>
              <MenuItem value={"Nesplnil podmínky požadavku"}>
                Nesplnil podmínky požadavku
              </MenuItem>
              <MenuItem value={"Podepsaná smlouva"}>Podepsaná smlouva</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {"Zrušení"}
          </Button>
          <Button color="primary" autoFocus onClick={handleDelete}>
            {"Potvrdit"}
          </Button>
        </DialogActions>
      </Dialog>
      <KanbanCheckItemRoot disabled={disabled} {...other}>
        {checkItem.createdBy && (
          <Box>
            <Tooltip title={checkItem.createdBy}>{renderAvatar()}</Tooltip>
          </Box>
        )}
        <Checkbox
          disabled={isDodavatel}
          checked={checkItem.state === "complete"}
          color="primary"
          onChange={handleStateChange}
          sx={{
            // ml: -1,
            mr: 1,
          }}
        />

        <Box
          sx={{
            alignItems: "center",
            gap: 3,
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Typography
            component="div"
            onClick={() => {
              !isDodavatel && setOpenCommunication(true);
            }}
            color="textPrimary"
            sx={{
              cursor: isDodavatel ? "default" : "pointer",
              flexGrow: 1,
              minHeight: 32,
            }}
            variant="body1"
          >
            {checkItem.created_at
              ? `${format(new Date(checkItem.created_at), "dd.MM.yyyy")}, `
              : ""}
            {checkItem.name} {checkItem.code ?? ""}
          </Typography>

          {checkItem.phone && (
            <a
              href={`tel:${checkItem.phone}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="body1"
                color="primary"
                style={{ cursor: "pointer" }}
              >
                {checkItem.phone}
              </Typography>
            </a>
          )}
          <IconButton
            disabled={isDodavatel}
            onClick={handleOpen}
            sx={{ visibility: "hidden" }}
          >
            <TrashIcon fontSize="small" />
          </IconButton>
        </Box>
      </KanbanCheckItemRoot>
    </>
  );
};

KanbanCheckItem.propTypes = {
  cardId: PropTypes.number.isRequired,
  checkItem: PropTypes.object.isRequired,
  checklistId: PropTypes.number.isRequired,
  editing: PropTypes.bool,
  onEditCancel: PropTypes.func,
  onEditComplete: PropTypes.func,
  onEditInit: PropTypes.func,
  sx: PropTypes.object,
};

KanbanCheckItem.defaultProps = {
  editing: false,
};

export default KanbanCheckItem;
