import { useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  Popover,
  TextField,
} from "@material-ui/core";

import useMounted from "../../hooks/useMounted";

import useSettings from "../../hooks/useSettings";

import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ConfirmEmail from "./confirm_email.js";
import DeleteRenderer from "../../aggrid/DeleteRenderer.js";
import downloadFile from "../../aggrid/downloadFile.js";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";

import { LicenseManager } from "ag-grid-enterprise";
import {
  Download,
  Grading,
  PendingActions,
  PlayCircle,
} from "@material-ui/icons";
import JsxRenderer from "../../aggrid/JsxRenderer";
import httpPost from "../../api/base/httpPost";
import FileUploadWithSubmit from "../inputs/file/FileUploadWithSubmit";
import CircularProgress from "@material-ui/core/CircularProgress";
LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const GeneratedDocuments = (props) => {
  const { t } = useTranslation();
  const [template, setTemplate] = useState("");
  const customComparator = (valueA, valueB) => {
    if (valueA != null && valueB != null)
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  let lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).employees;

  let coldefs = [
    {
      headerName: t("Stáhnout"),
      field: "attachment",
      cellRenderer: "downloadFile",
      cellRendererParams: { model: "employees", field: "attachment" },
    },
    {
      headerName: t("Název"),
      field: "attachment",
      valueFormatter: function (params) {
        if (params.value == null || params.value == "") return params.value;
        const array1 = params.value.split("/");
        return array1[array1.length - 1];
      },
      filterParams: {
        valueFormatter: function (params) {
          if (params.value == null || params.value == "") return params.value;
          const array1 = params.value.split("/");
          return array1[array1.length - 1];
        },
      },
    },
    {
      headerName: t("Typ dokumentu"),
      field: "type",
      cellRenderer: "JsxRenderer",
      cellRendererParams: {
        jsx: (params) => {
          switch (params.value) {
            case "generated":
              return "Vygenerovaný";
            case "uploaded":
              return (
                <>
                  Nahraný{" "}
                  {policy_actions.includes("docusign") &&
                    props.signed && (
                      <Button
                        color="primary"
                        id="fil_attach"
                        className="btn btn-default btn-sm delete-ingrid-but"
                        fullWidth
                        onClick={() => {
                          httpPost(
                            [],
                            `document/${params.data.id}/digisign_uploaded`,
                            (data) => {
                              window.open(data.url, "_blank");
                            },
                            (message, response) => {
                              alert(message);
                              console.error(response);
                            }
                          );
                        }}
                      >
                        Podepsat <PlayCircle />
                      </Button>
                    )}
                </>
              );
            case "passport":
              return "Pas";
            case "form":
              return "Dotaznik";
            case "insurance":
              return "Pojištěni";
            case "account_number":
              return "Číslo účtu";
            default:
              return params.value;
          }
        },
      },
    },
    // {
    //   headerName: t("Typ souboru"),
    //   field: "attachment",
    //   valueFormatter: function (params) {
    //     if (params.value == null || params.value == "") return params.value;
    //     const array1 = params.value.split(".");
    //     return array1[array1.length - 1];
    //   },
    // },
    /*
        {
            headerName: t('User'),
            field: 'user',
        },
        */
    {
      headerName: t("Vytvořeno"),
      field: "created_at",
      valueGetter: function (params) {
        if (params.data.created_at == null || params.data.created_at == "")
          return "";
        const d = new Date(params.data.created_at);
        return (
          ("0" + d.getDate()).slice(-2) +
          "." +
          ("0" + (d.getMonth() + 1)).slice(-2) +
          "." +
          d.getFullYear() +
          " " +
          ("0" + d.getHours()).slice(-2) +
          ":" +
          ("0" + d.getMinutes()).slice(-2)
        );
      },
      sort: "desc",
      comparator: (a, b) => {
        const a1 = a.split(" ");
        const b1 = b.split(" ");
        const aDate = a1[0].split(".");
        const bDate = b1[0].split(".");
        const aTime = a1[1].split(":");
        const bTime = b1[1].split(":");
        const aFinal = new Date(
          aDate[2],
          aDate[1],
          aDate[0],
          aTime[0],
          aTime[1]
        );
        const bFinal = new Date(
          bDate[2],
          bDate[1],
          bDate[0],
          bTime[0],
          bTime[1]
        );
        return aFinal.getTime() - bFinal.getTime();
      },
    },
  ];

  if (props.signed) {
    coldefs.push({
      editable: false,
      headerName: t("Digisign"),
      field: "docusign_status",
      cellRenderer: "JsxRenderer",
      cellRendererParams: {
        jsx: (params) => {
          if (params.data.type === "K podpisu") {
            return (
              <Button
                color="primary"
                id="fil_attach"
                className="btn btn-default btn-sm delete-ingrid-but"
                fullWidth
                onClick={() => {
                  httpPost(
                    [],
                    `document/${params.data.id}/docusign`,
                    (data) => {
                      window.open(data.url, "_blank");
                    },
                    (message, response) => {
                      alert(message);
                      console.error(response);
                    }
                  );
                }}
              >
                Podepsat <PlayCircle />
              </Button>
            );
          }

          return <></>;
        },
      },
    });
  }

  if (!policy_actions.includes("docusign")) {
    coldefs = coldefs.filter(function (el) {
      return el.field !== "docusign_status";
    });
  }

  if (policy_actions.includes("delete")) {
    coldefs.push({
      headerName: t("Odstranit"),
      cellRenderer: "DeleteRenderer",
      minWidth: 90,
      cellRendererParams: {
        gridType: "attachments",
      },
      suppressMenu: true,
    });
  }
  const mounted = useMounted();

  const { settings } = useSettings();
  const [rowdata, setRowdata] = useState(null);
  const [confirmEmailOpen, setConfirmEmailOpen] = useState(false);
  const [columndefs, setColumndefs] = useState(coldefs);
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});
  const [countToSign, setCountToSign] = useState(0);
  const [loading, setLoading] = useState("none");

  const defaultColDef = {
    flex: 1,
    minWidth: 170,
    editable: false,
    resizable: true,
    suppressMovableColumns: true,
    sortable: true,
    filter: true,
    suppressContextMenu: true,
  };

  const frameworkComponents = {
    DeleteRenderer: DeleteRenderer,
    downloadFile: downloadFile,
    JsxRenderer: JsxRenderer,
  };

  const getRowdata = useCallback(async () => {
    var urll = "api/employee_attachments/";
    if (props.signed) urll = "api/employee_attachments_signed/";
    HttpService.get(UrlService.apiDomain() + urll + props.id)
      .then((res) => {
        let data = res.data;
        if (data.success) {
          let cnt = 0;

          data.attachments.forEach((a) => {
            if (a.type == "K podpisu") cnt++;
          });

          setCountToSign(cnt);

          setRowdata(data.attachments);
          if (
            data.unauthorized &&
            policy_actions.includes("docusign")
          ) {
            let login = window.confirm(
              "You need to login to see docusign signatures status"
            );
            if (login) {
              httpPost([], `docusign/connect`, (response) => {
                let url = response.url;
                window.open(url, "_blank");
              });
            }
          }
        } else {
          alert(data.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [mounted]);

  useEffect(() => {
    getRowdata();
  }, [getRowdata]);

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);

    //params.api.setDomLayout('autoHeight');
    //document.querySelector('#myGrid').style.height = '';

    const columnState = JSON.parse(
      localStorage.getItem("attachments_column_state")
    );
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  };

  const onDragStopped = (params) => {
    const columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem("attachments_column_state", columnState);
  };

  useEffect(() => {
    setloctext(lt);
    setColumndefs(coldefs);
  }, [t("cs")]);

  const handleConfirmEmailClose = () => {
    setConfirmEmailOpen(false);
  };

  let getRowStyle = function (params) {
    if (params.data.type === "Vypršel") {
      return { backgroundColor: "#ffe5e5" };
    } else {
      return { backgroundColor: "#FFFFFF" };
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: props.from_dashboard ? 2 : 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {props.signed ? t("Podpisy") : t("Dokumenty")}
              </Typography>
            </Grid>

            {!props.signed && (
              <>
                <Grid item>
                  <TextField
                    fullWidth
                    label={t("Šablóna")}
                    select
                    SelectProps={{ native: true }}
                    required
                    value={template}
                    onChange={(event) => {
                      setTemplate(event.target.value);
                    }}
                    variant="outlined"
                  >
                    <option key="0" value=""></option>
                    {JSON.parse(localStorage.getItem("documents")).map((c) => (
                      <>
                        {c.name && c.name.indexOf("Digisign") != -1 && (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        )}
                      </>
                    ))}
                  </TextField>
                </Grid>

                <Grid item>
                  <FileUploadWithSubmit
                    withoutConfirmation={true}
                    field={"attachments[]"}
                    label={""}
                    template={template}
                    model={"employees"}
                    id={props.id}
                    onAdd={(result) => {
                      setRowdata([...rowdata, ...result]);
                    }}
                  />
                </Grid>
              </>
            )}

            {props.signed && (
              <>
                <Grid item>
                  <Box sx={{ m: -1 }}>
                    <Button
                      color="primary"
                      startIcon={<NoteAddIcon fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                      disabled={countToSign === 0}
                      onClick={() => {
                        setConfirmEmailOpen(true);
                      }}
                    >
                      {t("Podepsat dokumenty") + ` (${countToSign})`}
                    </Button>
                  </Box>
                </Grid>

                <Grid item>
                  <Box sx={{ m: -1 }}>
                    <CircularProgress sx={{ display: loading }} />
                  </Box>
                </Grid>

                <Popover
                  anchorOrigin={{
                    horizontal: "center",
                    vertical: "top",
                  }}
                  keepMounted
                  onClose={handleConfirmEmailClose}
                  open={confirmEmailOpen}
                  PaperProps={{
                    sx: { width: 540 },
                  }}
                >
                  {props.overview ? (
                    <ConfirmEmail
                      email={props.email}
                      email_verified={props.email_verified}
                      id={props.id}
                      handleClose={handleConfirmEmailClose}
                      customer_id={props.profileData?.uzivatel}
                      employeeReward={props.profileData?.worker_reward}
                    />
                  ) : (
                    <ConfirmEmail
                      email={props.profileData?.email}
                      email_verified={props.profileData?.email_verified}
                      id={props.id}
                      handleClose={handleConfirmEmailClose}
                      customer_id={props.profileData?.uzivatel}
                      employeeReward={props.profileData?.worker_reward}
                    />
                  )}
                </Popover>
              </>
            )}
          </Grid>
          <Box sx={{ mt: 3 }}>
            <div
              style={{
                width: "100%",
                overflow: "auto",
                height: props.from_dashboard ? "20vh" : "500px",
              }}
              id="myGrid"
              className="ag-theme-material"
            >
              <AgGridReact
                getRowStyle={getRowStyle}
                modules={AllCommunityModules}
                rowData={rowdata}
                columnDefs={columndefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onDragStopped={onDragStopped}
                frameworkComponents={frameworkComponents}
                enableCellChangeFlash={true}
                rowSelection={"multiple"}
                suppressContextMenu={true}
                localeText={loctext}
                popupParent={document.querySelector("body")}
              />
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default GeneratedDocuments;
