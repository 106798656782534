let apiDomain = "http://127.0.0.1:8000/";
// let apiDomain = "http://zevgan.loc/";
if (process.env.NODE_ENV === "production") {
    apiDomain = process.env.REACT_APP_PRODAPIURL;
    if (window.location.href.indexOf('test')!==-1) apiDomain = process.env.REACT_APP_TESTAPIURL;
} else {
    apiDomain = process.env.REACT_APP_LOCALAPIURL;
}

const UrlService = class UrlService {

    static apiDomain( ) {
		
        return apiDomain;
		
    }
	
    static loginUrl( ) {
		
        return apiDomain + "api/login";
		
    }
	
	static loginLinkLongUrl( ){
		
		return apiDomain + "api/login_link_long";
		
	}
	
	static loginLinkShortUrl( ){
		
		return apiDomain + "api/login_link_short";
	}
	
	static loginLinkGoogleUrl( ){
		
		return apiDomain + "api/login_by_google";
		
	}

};


export default UrlService;