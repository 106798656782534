import BasicAdd from "../../components/grid/basicAddNew";
import GridWrapper from "../../components/grid/gridWrapperNew";
import { useState } from "react";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CustomerRequirmentsPropositions from "./CustomerRequirmentsPropositions";
import { useTranslation } from "react-i18next";

export default function CustomerRequirements({
  tab,
  presetValues,
  handleRowData,
  customer_id,
  getMainData,
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);

  function handleColumnDefs(colDefs) {
    return [
      ...colDefs,
      {
        headerName: t("Doporučení"),
        field: "id",
        pinned: "left",
        width: "100px",
        cellRendererFramework: (params) => {
          return (
            <Button
              onClick={() => {
                setId(params.value);
                setIsOpen(true);
              }}
            >
              <EditIcon />
            </Button>
          );
        },
      },
    ];
  }
  return (
    <>
      <GridWrapper
        style={tab ? { mt: 2 } : null}
        helmet="Dashboard: Customer requirements| AWA Partners"
        title="Požadavky na rozjezd"
        model="customer_start_requirements"
        filter={handleRowData}
        browseData={{ type: "owner", customer_id: customer_id }}
        hidden={["customer_id"]}
        handleColumnDefs={handleColumnDefs}
      >
        <CustomerRequirmentsPropositions
          open={isOpen}
          onClose={() => setIsOpen(false)}
          id={id}
          customer_id={customer_id}
          getMainData={getMainData}
        />
        <BasicAdd
          noTemplates={true}
          model={"customer_start_requirements"}
          presetValues={[{ key: "customer_id", value: customer_id }]}
        />
      </GridWrapper>
    </>
  );
}
