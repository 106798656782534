"use strict";


const JSONRPCRequestT = class JSONRPCRequestT {
	
	#Method = null;
	
	#Params = null;
	
	#IsNotification = false;
	
	constructor( 
		Method,
		Params,
		IsNotification = false
	){
		
		if( typeof( Method ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( Params ) === "undefined" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( IsNotification ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#Method = Method;
		
		this.#Params = Params;
		
		this.#IsNotification = IsNotification;
		
	}
	
	Method(
	
	){
	
		return this.#Method;
	
	}
	
	Params(
	
	){
	
		return this.#Params;
	
	}
	
	IsNotification(
	
	){
		
		return this.#IsNotification;
		
	}
	
};


export default JSONRPCRequestT;