import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "../icons/Menu";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";
import LanguagePopover from "./dashboard/LanguagePopover";
import useAuth from "../hooks/useAuth";
import AccountPopover from "./dashboard/AccountPopover";
import Support from "./support";

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;
  const { t } = useTranslation();

  const auth = useAuth();

  const user = auth.user;

  let user_info = "";
  let role_name = "";

  if (localStorage.getItem("my_user") != null && user != null) {
    let roles = JSON.parse(localStorage.getItem("roles"));
    role_name = roles.find((i) => i.id == user?.role)?.name;
    user_info = `${user.name} ${user.surname} ${role_name}`;
  }

  let IsOnMainScreen = true;

  const URLParts = window.location.pathname.split("/");

  if (URLParts[1] !== "") {
    IsOnMainScreen = false;
  }

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        color: "text.secondary",
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                md: "inline",
                xs: "none",
              },
              height: 40,
              width: 40,
            }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: "center",
            display: {
              md: "flex",
              xs: "none",
            },
          }}
        >
          <Support mainNavbar={true} />
          <LanguagePopover />
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2,
            }}
          />

          {auth.isAuthenticated === false && IsOnMainScreen === false && (
            <Button
              color="primary"
              component="a"
              href="/"
              size="small"
              variant="contained"
              sx={{ marginRight: auth.isAuthenticated === true ? 2 : 2 }}
            >
              {t("Přihlásit se")}
            </Button>
          )}

          {auth.isAuthenticated === false && IsOnMainScreen === true && (
            <Button
              color="primary"
              component="a"
              href="/registrace_dodavatele"
              size="small"
              variant="contained"
              sx={{ marginRight: auth.isAuthenticated === true ? 2 : 2 }}
            >
              {t("Registrace dodavatele")}
            </Button>
          )}

          {auth.isAuthenticated === true && (
            <>
              {user && (
                <>
                  <div>{user_info}</div>
                  <Box sx={{ mx: 2 }}>
                    <AccountPopover />
                  </Box>
                </>
              )}

              <Button
                color="primary"
                component="a"
                href="/dashboard"
                size="small"
                variant="contained"
              >
                {t("Můj účet")}
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
