"use strict";


import JSONRPCClientAbstractT from "../../Procedure/JSONRPC/Client/_/JSONRPCClientAbstractT.mjs";

import HTTPFileClientAbstractT from "../../Procedure/HTTPFile/Client/_/HTTPFileClientAbstractT.mjs";

import StorageAbstractT from "../../Procedure/Storage/_/StorageAbstractT.mjs";

import JSONRPCRequestT from "../../Type/JSONRPC/JSONRPCRequestT.mjs";

import HTTPHeaderT from "../../Type/HTTP/Header/HTTPHeaderT.mjs";

import HTTPHeadersT from "../../Type/HTTP/Header/HTTPHeadersT.mjs";


const APIClientT = class APIClientT {
	
	#JSONRPCClient = null;
	
	#HTTPFileClient = null;
	
	#Storage = null;
	
	constructor(
		JSONRPCClient,
		HTTPFileClient,
		Storage
	){
		
		if( ( JSONRPCClient instanceof JSONRPCClientAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( HTTPFileClient instanceof HTTPFileClientAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( Storage instanceof StorageAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#JSONRPCClient = JSONRPCClient;
		
		this.#HTTPFileClient = HTTPFileClient;
		
		this.#Storage = Storage;
		
	}
	
	Request(
		JSONRPCRequest,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( JSONRPCRequest instanceof JSONRPCRequestT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
			
		let HTTPHeaders = [
			new HTTPHeaderT( "Content-Type", "application/json" )
		];


		let BearerToken = this.#Storage.Get( "auth.token" );


		if( BearerToken !== null ){
			
			HTTPHeaders.push( 
				new HTTPHeaderT( "Authorization", "Bearer " + BearerToken )
			);
			
		}

		
		
		this.#JSONRPCClient.Request(
			JSONRPCRequest,
			new HTTPHeadersT( 
				HTTPHeaders 
			),
		
			SuccessCb,
			ErrorCb
		);
		
	}
	
	Batch(
		JSONRPCRequestArray,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( JSONRPCRequestArray instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		for(
			let I = 0;
			I < JSONRPCRequestArray.length;
			I++
		){
			
			let JSONRPCRequest = JSONRPCRequestArray[ I ];
			
			if( ( JSONRPCRequest instanceof JSONRPCRequestT ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		let HTTPHeaders = [
			new HTTPHeaderT( "Content-Type", "application/json" )
		];


		let BearerToken = this.#Storage.Get( "auth.token" );


		if( BearerToken !== null ){
			
			HTTPHeaders.push( 
				new HTTPHeaderT( "Authorization", "Bearer " + BearerToken )
			);
			
		}

		
		this.#JSONRPCClient.Batch(
			JSONRPCRequestArray,
			new HTTPHeadersT( 
				HTTPHeaders 
			),
		
			SuccessCb,
			ErrorCb
		);
		
	}
	
	Upload( 
		ModelId,
		Id,
		Files,
		
		SuccessCb,
		ErrorCb
	){
		
		let HTTPHeaders = [
			
		];


		let BearerToken = this.#Storage.Get( "auth.token" );


		if( BearerToken !== null ){
			
			HTTPHeaders.push( 
				new HTTPHeaderT( "Authorization", "Bearer " + BearerToken )
			);
			
		}
		
		this.#HTTPFileClient.Upload(
			ModelId,
			Id,
			new HTTPHeadersT( HTTPHeaders ),
			Files,
			
			SuccessCb,
			ErrorCb
		);
		
	}
	
	Download( 
		ModelId,
		Id,
		
		SuccessCb,
		ErrorCb
	){
		
		let HTTPHeaders = [
			
		];


		let BearerToken = this.#Storage.Get( "auth.token" );


		if( BearerToken !== null ){
			
			HTTPHeaders.push( 
				new HTTPHeaderT( "Authorization", "Bearer " + BearerToken )
			);
			
		}
		
		this.#HTTPFileClient.Download(
			ModelId,
			Id,
			new HTTPHeadersT( HTTPHeaders ),
			
			SuccessCb,
			ErrorCb
		);
		
	}
	
};
	
	
export default APIClientT;