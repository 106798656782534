import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/core";
import { Archive, Check, Visibility } from "@material-ui/icons";

let FilterIsActive = function (Item) {
  if (!Item.is_done) {
    return true;
  }
};

let FilterIsArchived = function (Item) {
  return !FilterIsActive(Item);
};

let FilterIsAll = function (Item) {
  return true;
};

export default function ArchiveSelect({ setFilter }) {
  const { t } = useTranslation();

  const [show, setShow] = useState("active");

  useEffect(() => {
    let Filter = null;

    switch (show) {
      case "active": {
        Filter = FilterIsActive;

        break;
      }

      case "archived": {
        Filter = FilterIsArchived;

        break;
      }

      default: {
        Filter = FilterIsAll;
      }
    }

    setFilter(() => Filter);
  }, [show]);

  return (
    <ToggleButtonGroup
      value={show}
      exclusive
      onChange={(event, newAlignment) => {
        if (newAlignment) {
          setShow(newAlignment);
        }
      }}
      color="primary"
      aria-label={t("Show")}
      sx={{ mr: 2 }}
    >
      <ToggleButton sx={{ py: 0 }} value="active" aria-label="right aligned">
        <Check sx={{ mr: 1 }} />
        {t("Aktivní")}
      </ToggleButton>

      <ToggleButton sx={{ py: 0 }} value="all" aria-label="centered">
        <Visibility sx={{ mr: 1 }} />
        {t("Vše")}
      </ToggleButton>

      <ToggleButton sx={{ py: 0 }} value="archived" aria-label="left aligned">
        <Archive sx={{ mr: 1 }} />
        {t("Archiv")}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
