import UrlService from "../services/UrlService";


const at = localStorage.getItem("accessToken");

export default function getSpouseSelect(id, filter_name, filter_surname, handleResult, handleError) {
    let formData = new FormData();
    formData.append("filter_name", filter_name);
    formData.append("filter_surname", filter_surname);

    fetch(UrlService.apiDomain() + "api/spouse/select/" + id, {
        method: 'post',
        body: formData,
        headers: {
            Authorization: "Bearer " + at,
        },
    })
        .then(r => r.json())
        .then(response => {
            if (!response.success) {
                handleError(response.error);
                return;
            }
            handleResult(response.employees);
        })
}