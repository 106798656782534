import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
} from "@material-ui/core";
import useSettings from "../../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import LocaleRu from "../../../aggrid/locale.ru.js";

import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";

const AddDownpaymentRequest = ({
  handleClosePopover,
  getRowdata,
  month,
  year,
  onAdd,
}) => {
  const { t } = useTranslation();
  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");

  const { settings } = useSettings();
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const formik = useFormik({
    initialValues: {
      amount: "",
      month: month,
      year: year,
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .typeError("Částka musí být digitální hodnota")
        .required(t("Povinné")),
      month: Yup.string().required(t("Povinné")),
      year: Yup.string().required(t("Povinné")),
    }),
    onSubmit: (values) => {
      handleOpen();
    },
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const closeAllDialogs = () => {
    handleClosePopover();
    handleClose();
    setOpenConfirm(false);
  };

  const sendData = async (values) => {
    setDisabledButton(true);
    handleClose();
    const data = {
      amount: values.amount,
      month: values.month,
      year: values.year,
    };

    try {
      const res = await HttpService.post(
        `${UrlService.apiDomain()}api/set-downpayment-request`,
        data
      );
      if (res.status != 200) {
        setDisabledButton(false);
        let message = res.data.message;
        let digitIndex = message.search(/\d/);

        if (digitIndex === -1) {
          alert(t(message));
        } else {
          let beforeDigit = message.substring(0, digitIndex);
          let afterDigit = message.substring(digitIndex);

          alert(`${t(beforeDigit)} ${afterDigit}`);
        }
        return;
      }
      getRowdata();
      formik.handleReset();
      setOpenConfirm(true);
      setDisabledButton(false);
    } catch (error) {
      console.log(error);
    } finally {
      onAdd?.();
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 4,
      }}
    >
      <Container maxWidth={settings.compact ? "xl" : false}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h5">
              {t("Přidat")}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleClosePopover}>
              {t("Zavřít")}
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ p: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              sx={{ mb: 2 }}
              name="amount"
              label={t("Částka")}
              variant="outlined"
              fullWidth
              autoFocus
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.amount}
              error={!!(formik.touched.amount && formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />

            <Button type="submit" variant="contained" fullWidth color="primary">
              {t("Přidat")}
            </Button>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("Potvrďte přidání")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t("Opravdu chcete požádat o zálohu?")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t("Zrušení")}
                </Button>
                <Button
                  disabled={disabledButton}
                  color="primary"
                  onClick={() => sendData(formik.values)}
                >
                  {t("Přidat")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={openConfirm} onClose={closeAllDialogs}>
              <DialogTitle>{t("Potvrzení přidání")}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("Požadavek byl úspěšně přidán")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeAllDialogs} color="primary">
                  {t("Zavřít")}
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Box>
      </Container>
    </Box>
  );
};
export default AddDownpaymentRequest;
