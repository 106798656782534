"use strict";


import EnvMechAbstractT from "../../Interface/Env/_/EnvMechAbstractT.mjs";


const EnvMechNodeJST = class EnvMechNodeJST extends EnvMechAbstractT {

	#URL = null;

	constructor( ){
		
		super( );
		
		
		let HostName = window.location.hostname;
		
		if( HostName === "db.awapartners.com" ){
			
			this.#URL = process.env.REACT_APP_PRODAPIURL;
			
		} else if( HostName === "db.testawapartners.com" ){
			
			this.#URL = process.env.REACT_APP_TESTAPIURL;
			
		} else {
			
			this.#URL = process.env.REACT_APP_LOCALAPIURL;
			
		}
		

		/*if( process.env.NODE_ENV === "production" ) {
			
			this.#URL = process.env.REACT_APP_PRODAPIURL;
			
		} else if( process.env.NODE_ENV === "test" ){
		
			this.#URL = process.env.REACT_APP_TESTAPIURL;
		
		} else if( process.env.NODE_ENV === "development" ){
		
			this.#URL = process.env.REACT_APP_LOCALAPIURL;
		
		} else if( process.env.NODE_ENV === "test" ){
		
			throw new Error( "Argument" );
		
		}*/
		
	}
	
	URL( ){
		
		return this.#URL;
		
	}

};


export default EnvMechNodeJST;