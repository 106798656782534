"use strict";


import React from "react";

import UIBooleanReactT from "./UIBooleanReactT.mjs";

import UIBooleanAbstractT from "../../../Interface/UI/Boolean/_/UIBooleanAbstractT.mjs"


const UIBooleanT = class UIBooleanT extends UIBooleanAbstractT {
	
	#Component = null;
  
	#Ref = null;

	constructor( Props ) {
		
		console.log( "UIBooleanT.constructor", Props );
		
		
		super( Props );


		this.#Ref = React.createRef( );


		this.#Component = React.createElement(
			UIBooleanReactT, 
			{
				...Props,
				ref: this.#Ref
			}
		);
	
	}
  
	PropSet(
		Name,
		Value
	){

		console.log( "UIBooleanT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#Ref.current.PropSet( Name, Value );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIBooleanReactT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.#Ref.current.PropGet( Name );
		
	}

	Component( 
	
	) {
		
		return this.#Component;
	
	}
	
};


export default UIBooleanT;