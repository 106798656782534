"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIGridReactT from "./UIGridReactT.mjs";

import UIGridAbstractT from "../../../Interface/UI/Grid/_/UIGridAbstractT.mjs";


const UIGridT = class UIGridT extends UIGridAbstractT {
	
	#Component = null;
	
	#Ref = null;
	
	#ReadyCb = null;
	
	constructor(
		Props,
		
		ReadyCb
	){
		
		console.log( "UIGridT.constructor", Props );
		
		
		if( typeof( ReadyCb ) !== "function" ){
		
			throw new Error( "Argument" );
		
		}
		
		
		super( );

		
		this.#Ref = null;
		
		
		this.#Component = React.createElement( 
			UIGridReactT, 
			{
				...Props,
	
				ref: this.#RefSet.bind( this )
			}
		);
		
		
		this.#ReadyCb = ReadyCb;
		
	}
	
	#RefSet( Ref ){
		
		console.log( "UIGridT.RefSet", Ref );
		
		
		this.#Ref = Ref;
		
		
		if( this.#Ref !== null ){
		
			this.#ReadyCb( );
		
		}
		
	}
	
	FilterModelGet(
		
	){
	
		return this.#Ref.FilterModelGet( );
	
	}
	
	FilterModelSet(
		Model
	){
	
		this.#Ref.FilterModelSet( Model );
	
	}
	
	FilterChanged(
	
	){
	
		this.#Ref.FilterChanged( );
	
	}
	
	ColDefsSet(
		ColDefs
	){
		
		console.log( "UIGridT.ColDefsSet", ColDefs );
		
		if( ( ColDefs instanceof Array ) === false ){ //TODO Full check
			
			throw new Error( "Argument" );
			
		}
	
		
		if( this.#Ref !== null ){
		
			this.#Ref.ColDefsSet( ColDefs );
		
		} else {
			
			throw new Error( "Not exists" );
			
		}
	
	}
	
	RowDataSet(
		RowData
	){
		
		console.log( "UIGridT.RowDataSet", RowData );
		
		if( ( RowData instanceof Array ) === false ){ //TODO Full check
			
			throw new Error( "Argument" );
			
		}
		
		
		if( this.#Ref !== null ){
		
			this.#Ref.RowDataSet( RowData );
			
			this.#Ref.AutoSize( );
		
		} else {
			
			throw new Error( "Not exists" );
			
		}
		
	}
	
	RowsSet( 
		Rows
	){
		
		console.log( "UIGridT.RowsSet", Rows );
		
		if( ( Rows instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		if( this.#Ref !== null ){
		
			this.#Ref.RowsSet( Rows );
		
		} else {
			
			throw new Error( "Not exists" );
			
		}
		
	}
	
	RowsAdd(
		Rows
	){
		
		console.log( "UIGridT.RowsAdd", Rows );
		
		if( ( Rows instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		if( this.#Ref !== null ){
		
			this.#Ref.RowsAdd( Rows );
		
		} else {
			
			throw new Error( "Not exists" );
			
		}
		
		
	}
	
	RowsDelete(
		RowIds
	){
		
		if( this.#Ref !== null ){
		
			this.#Ref.RowsDelete( RowIds );
		
		} else {
			
			throw new Error( "Not exists" );
			
		}
		
	}
	
	Component( ){
		
		return this.#Component;
		
	}
	
};


export default UIGridT;