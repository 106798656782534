import {useTranslation} from "react-i18next";
import LocaleCs from "../../aggrid/locale.cs";
import LocaleEn from "../../aggrid/locale.en";
import LocaleRu from "../../aggrid/locale.ru";
import useSettings from "../../hooks/useSettings";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Box, Button, Container, Grid, ThemeProvider, Typography} from "@material-ui/core";
import {AgGridReact} from "ag-grid-react";
import {AllCommunityModules} from "ag-grid-community";
import addPropsToGrid from "../../utils/addPropsToGrid";
import 'ag-grid-enterprise';
import {LicenseManager} from "ag-grid-enterprise";
import Select from "../../aggrid/Select";
import FileEditor from "../../aggrid/FileEditor";
import {Delete, Download, OpenInNew} from "@material-ui/icons";
import DeleteRenderer from "../../aggrid/DeleteRenderer";
import DatePicker from "../../aggrid/DatePicker";
import {createTheme} from "@material-ui/core/styles";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AgAutoComplete from "../../aggrid/AgAutoComplete";
import strComparator from "../../utils/strComparator";
import {getNameFromObject} from "../form/FormWithValidation";
import updateField from "../../api/base/updateField";
import MultiSelect from "../../aggrid/MultiSelect";
import JsxRenderer from "../../aggrid/JsxRenderer";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";
import httpGet from "../../api/base/httpGet";


LicenseManager.setLicenseKey("CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166");


const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
    },
})

const defaultFrameworkComponents = {
    Select: AgAutoComplete,
    FileEditor: FileEditor,
    OpenInNewRenderer: () => {
        return <OpenInNew color={'primary'}/>
    },
    DeleteRenderer: DeleteRenderer,
    DatePicker: DatePicker,
    MultiSelect: MultiSelect,
    JsxRenderer: JsxRenderer
}

export default function GridWrapper({
                                        model,
                                        children,
                                        data,
                                        colDefs,
                                        helmet,
                                        title,
                                        frameworkComponents,
                                        gridProps,
                                        clickable,
                                        style,
                                        handleChange,
                                        small,
                                        getRowStyle,
                                        handleSelected,
                                        updateUrl,
                                        interceptChange
                                    }) {
    const {t} = useTranslation();

    let lt;

    if (t('cs') === 'cs') lt = LocaleCs;
    else if (t('cs') === 'en') lt = LocaleEn;
    else if (t('cs') === 'ru') lt = LocaleRu;

    lt.noRowsToShow = "No data";

    const [locText] = useState(lt);

    const {settings} = useSettings();
    const [rowData, setRowData] = useState(null);
    const [columnDefs, setColumnDefs] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);

    const [ready, setReady] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);

    const defaultColDef = {
        flex: 1,
        minWidth: 130,
        editable: false,
        resizable: true,
        suppressMovableColumns: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab', 'columnsMenuTab'],
    };

    useEffect(() => {
        setRowData(data);
    }, [data]);

    useEffect(() => {
        if (handleSelected) {
            handleSelected(selectedRows);
        }
    }, [selectedRows, handleSelected]);

    useEffect(() => {
        if (colDefs) {
            let defs = colDefs.map(def => {
                if (def.type === 'select') {
                    let valueFormatter = function (params) {
                        let val = def.data_array.find(item => item?.id?.toString() === params?.value?.toString()) ?? "";
                        return getNameFromObject(val, def.data_array);
                    }

                    return {
                        ...def,
                        comparator: (a, b) => {
                            let items = JSON.parse(localStorage.getItem(def.data_array));
                            if (!items) {
                                return strComparator(a, b);
                            }
                            a = items.find(i => i?.id === a);
                            b = items.find(i => i?.id === b);
                            return strComparator(getNameFromObject(a, def.data_array), getNameFromObject(b, def.data_array))
                        },
                        valueFormatter: valueFormatter,
                        cellEditor: 'Select',
                        cellEditorParams: {
                            data_array: def.data_array,
                            data_array_model: def.data_array_model,
                        },
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            valueFormatter: valueFormatter
                        }
                    }
                }
                if (def.type === 'multiselect') {
                    let valueFormatter = function (params) {
                        let ids = params?.value?.toString().split('//');
                        let result = "";
                        if (!ids) return "";
                        for (const id of ids) {
                            result += getNameFromObject(def.data_array.find(item => item?.id?.toString() === id),
                                def.data_array_model) + ", ";
                            if (result.length > 100) {
                                result = result.substring(0, result.length - 2) + "...";
                                break;
                            }
                        }
                        return result.substring(0, result.length - 2);
                    }

                    return {
                        ...def,
                        valueFormatter: valueFormatter,
                        cellEditor: 'MultiSelect',
                        cellEditorParams: {
                            data_array: def.data_array,
                            data_array_model: def.data_array_model,
                        },
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            valueFormatter: valueFormatter
                        }
                    }
                }
                if (def.type === 'delete') {
                    return {
                        minWidth: 80,
                        maxWidth: 80,
                        suppressMenu: true,
                        suppressSorting: true,
                        headerName: t('Odstranit'),
                        cellRenderer: 'JsxRenderer',
                        cellRendererParams: {
                            jsx: (props) => <Button
                                sx={{ml: '-16px'}}
                                color="primary"
                                className="btn btn-default btn-sm delete-ingrid-but"
                                fullWidth
                                disabled={def.disabled ? def.disabled(props) : false}
                                onClick={() => {
                                    if (window.confirm(t('Odstranit'))) {
                                        let id = props.data.id;
                                        def.onDelete(id, props);
                                    }
                                }}
                            >
                                <Delete/>
                            </Button>
                        }
                    };
                }
                if (def.type === 'download') {
                    return {
                        ...def,
                        cellRenderer: 'JsxRenderer',
                        cellRendererParams: {
                            jsx: (props) => <Button
                                sx={{ml: '-16px'}}
                                color="primary"
                                className="btn btn-default btn-sm delete-ingrid-but"
                                fullWidth
                                onClick={() => {
                                    httpGet("attachments/download/" + props.value, (response) => {
                                        const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
                                            const byteCharacters = atob(b64Data);
                                            const byteArrays = [];

                                            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                                                const slice = byteCharacters.slice(offset, offset + sliceSize);

                                                const byteNumbers = new Array(slice.length);
                                                for (let i = 0; i < slice.length; i++) {
                                                    byteNumbers[i] = slice.charCodeAt(i);
                                                }

                                                const byteArray = new Uint8Array(byteNumbers);
                                                byteArrays.push(byteArray);
                                            }

                                            const blob = new Blob(byteArrays, {type: contentType});
                                            return blob;
                                        }
                                        const url = window.URL.createObjectURL(b64toBlob(response.file, response.type));
                                        const link = document.createElement('a');
                                        link.href = url;

                                        link.setAttribute(
                                            'download',
                                            response.name
                                        );

                                        // Append to html link element page
                                        document.body.appendChild(link);

                                        // Start download
                                        link.click();

                                        // Clean up and remove the link
                                        link.parentNode.removeChild(link);
                                    });
                                }}
                            >
                                <Download/>
                            </Button>
                        }
                    };
                }
                return def;
            });
            defs.push({
                headerName: "#",
                valueGetter: "node.rowIndex + 1",
                lockPosition: 'left',
                pinned: 'left',
                maxWidth: 150,
                sortable: false,
                resizable: false,
                suppressMenu: true,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true
            });
            setColumnDefs(defs);
            setReady(true);
        }
    }, [colDefs]);

    const onCellClicked = (e) => {
        let field = e.colDef.field;
        clickable?.find(i => i.field === field)?.onClick(e.data, e);
    }

    const onCellValueChanged = (e) => {
        let field = e.colDef.field;
        let newValue = e.newValue;
        let id = e.data.id;

        if (newValue === true) {
            newValue = 1;
        } else if (newValue === false) {
            newValue = 0;
        }

        let change = {id: id, field: field, value: newValue};

        if (interceptChange) {
            interceptChange(change, e);
        }

        updateField(model, change, updateUrl,
            (changed) => {
                let rows = rowData.map(row => {
                    if (row.id === changed.id) {
                        row[changed.field] = changed.value;
                    }
                    return row;
                });
                handleChange(rows);
            });
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const resizeAll = () => {
        if (columnApi) {
            const allColumnIds = [];
            columnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            columnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    let body = addPropsToGrid(children, {
      selected: selectedRows,
      rowData: rowData,
    });

    if (frameworkComponents) {
        frameworkComponents = {...frameworkComponents, defaultFrameworkComponents};
    } else {
        frameworkComponents = defaultFrameworkComponents;
    }

    let table = (
        <div id="myGrid" className="ag-theme-material"
             style={small ? {width: '100%', height: '35vh', minHeight: '100%'} : {
                 width: '100%',
                 height: '50vh',
                 minHeight: '100%'
             }}>
            <ThemeProvider theme={theme}>
                <AgGridReact
                    style={{width: 500, height: 200}}
                    modules={AllCommunityModules}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    onCellClicked={onCellClicked}
                    onSelectionChanged={() => setSelectedRows(gridApi.getSelectedRows())}
                    onSortChanged={(e) => {
                        e.api.refreshCells();
                    }}
                    onGridColumnsChanged={() => {
                        resizeAll();
                    }}
                    onRowDataChanged={() => {
                        resizeAll();
                    }}
                    getRowStyle={getRowStyle}
                    enableCellChangeFlash={true}
                    rowSelection={'multiple'}
                    suppressContextMenu={true}
                    frameworkComponents={frameworkComponents}
                    localeText={locText}
                    popupParent={document.querySelector('body')}
                    {...gridProps}
                />
            </ThemeProvider>
        </div>
    );

    if (small) {
        return table;
    }

    return (
        <>
            <Helmet>
                <title>{helmet}</title>
            </Helmet>
            <Box
                sx={style ?? {
                    backgroundColor: 'background.default',
                    height: '100%',
                    py: 4
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false} style={{width: '100%', height: '100%'}}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item xs={3}>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {t(title)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box sx={{mr: 2, display: 'flex'}}>
                                {ready && body}
                                {ready && <>
                                    <Button sx={{ml: 4, px: 2}} variant="contained" color="primary" onClick={() => {
                                        gridApi.exportDataAsExcel({
                                            processCellCallback: (params) => {
                                                const colDef = params.column.getColDef()
                                                // try to reuse valueFormatter from the colDef
                                                if (colDef.valueFormatter) {
                                                    const valueFormatterParams = {
                                                        ...params,
                                                        data: params.node.data,
                                                        node: params.node,
                                                        colDef: params.column.getColDef()
                                                    };
                                                    return colDef.valueFormatter(valueFormatterParams);
                                                }
                                                return params.value;
                                            },
                                        });
                                    }}>Excel</Button>
                                    <Button sx={{ml: 2, px: 2}} variant="contained" color="primary"
                                            onClick={() => {
                                                gridApi.setFilterModel(null);
                                                gridApi.onFilterChanged();
                                            }}>{t("Bez filtrů")}</Button>
                                </>}
                            </Box>

                        </Grid>
                    </Grid>
                    <Box sx={{mt: 3}} style={{width: '100%', height: 'calc(100% - 50px)'}}>
                        {table}
                    </Box>
                </Container>
            </Box>
        </>
    );
}