export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

export const setOpenInNewAttribute = (link) => {
    link.setAttribute('target', '_blank');
}

export const setDownloadAttribute = (link, filename) => {
    link.setAttribute(
        'download', filename,
    );
}

export const openBlob = (blob, setAttribute = setOpenInNewAttribute) => {
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;

    setAttribute(link);

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export const ContentPDF = 'application/pdf';