"use strict";


import UIPageContentT from "./UIPageContentT.mjs";

import UIPageContentProducerAbstractT from "../../../../Interface/UI/Page/Content/_/UIPageContentProducerAbstractT.mjs";


const UIPageContentProducerT = class UIPageContentProducerT extends UIPageContentProducerAbstractT {
	
	constructor(
		
	){
		
		console.log( "UIPageContentProducerT.constructor" );
		
	
		super( );
		
	}
	
	Produce(
		...Props
	){
		
		console.log( "UIPageContentProducerT.Produce", Props );
		
		
		return new UIPageContentT(
			...Props
		);
		
	}
	
};


export default UIPageContentProducerT;