import { IbanRegex } from "./Transfer/InputIBAN";
import { SwiftRegex } from "./Transfer/InputBicSwift";
import { AccountNumberRegex } from "./Transfer/InputAccountNumber";
import { FullInputRegex } from "./Transfer/InputFullText";
import { NumRegEx } from "./Transfer/InputNum";
import {
  PaymentSecondaryTextRegex,
  PaymentTextRegex,
} from "./Transfer/InputText";

export default function PaymentDetailsValidation(paymentData) {
  if (
    paymentData.payment_type !== "transfer" &&
    paymentData.payment_type !== "transferCz"
  )
    return true;
  for (let [key, value] of Object.entries(paymentData)) {
    if (key === "type" || key === "payment_type") continue;
    let regex;
    if (key === "iban") {
      regex = IbanRegex;
    } else if (key === "bic_swift") {
      regex = SwiftRegex;
    } else if (key === "account_number") {
      regex = AccountNumberRegex;
    } else if (key === "address") {
      regex = FullInputRegex;
    } else if (key === "ic" || key === "dic") {
      regex = NumRegEx;
    } else {
      if (key.endsWith("_2") && paymentData[key.replace("_2", "_1")]) {
        regex = PaymentSecondaryTextRegex;
      } else {
        regex = PaymentTextRegex;
      }
    }
    if (!regex.test(value)) {
      return false;
    }
  }
  return true;
}
