import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import useMounted from "../../hooks/useMounted";
import useSettings from "../../hooks/useSettings";

import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";

import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const Audit = (props) => {
  const { t } = useTranslation();

  const customComparator = (valueA, valueB) => {
    if (valueA != null && valueB != null)
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  var columns = {};
  JSON.parse(localStorage.getItem("columns")).map((c) => {
    columns[c.field] = c;
  });

  var coldefs = [
    {
      headerName: t("Datum"),
      field: "created_at",
      sort: "desc",
      valueGetter: function (params) {
        if (params.data.created_at == null || params.data.created_at == "")
          return "";
        var d = new Date(params.data.created_at);
        return (
          ("0" + d.getDate()).slice(-2) +
          "." +
          ("0" + (d.getMonth() + 1)).slice(-2) +
          "." +
          d.getFullYear() +
          " " +
          ("0" + d.getHours()).slice(-2) +
          ":" +
          ("0" + d.getMinutes()).slice(-2)
        );
      },

      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;

          if (dateAsString == null || dateAsString == "") {
            return -1;
          }

          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const parts = dateAsString.split(" ");
          const dateParts = parts[0].split(".");
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const timeParts = parts[1].split(":");
          const hour = timeParts[0];
          const minute = timeParts[1];

          const cellDate = new Date(year, month, day);

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      headerName: t("Typ"),
      field: "type",
    },
    {
      headerName: t("Kdo"),
      field: "requester_name",
    },
    {
      headerName: t("Koho"),
      field: "requested_name",
    },
    {
      headerName: t("Kolonka"),
      field: "column_name",
    },

    {
      field: "value",
      headerName: t("Hodnota"),

      valueGetter: function (params) {
        var column = "";
        var values = [];
        JSON.parse(localStorage.getItem("columns")).map((c) => {
          if (c.field === params.data.original_field) {
            column = c;
            return true;
          }
          return true;
        });
        var c = column;

        if (c.column_type === "boolean") {
          try {
            return JSON.parse(localStorage.getItem("booleans")).find(
              (refData) => String(refData.id) === String(params.data.value)
            )["name"];
          } catch (err) {
            console.log(params.data.value);
            console.log(c.field);
            console.log(err);
            return "";
          }
        } else if (c.column_type === "select") {
          values = [];
          var tmp_values = [];
          if (c.data_array === "custom") {
            if (c.data !== null && c.data !== "") values = c.data;
          } else if (
            c.data_array === "" ||
            c.data_array === null ||
            c.data_array === "none"
          ) {
            //
          } else {
            values = JSON.parse(localStorage.getItem(c.data_array));
          }

          if (params.data.value === null || params.data.value === "") return "";
          try {
            return values.find(
              (refData) => String(refData.id) == String(params.data.value)
            )["name"];
          } catch (err) {
            console.log(params.data.value);
            console.log(values);
            console.log(c);
            console.log(err);
            return "";
          }
        } else if (c.column_type === "multiselect") {
          values = [];
          if (c.data_array === "custom") {
            if (c.data !== null && c.data !== "") values = c.data;
          } else if (
            c.data_array === "" ||
            c.data_array === null ||
            c.data_array === "none"
          ) {
            //
          } else {
            values = JSON.parse(localStorage.getItem(c.data_array));
          }

          if (params.data.value === null || params.data.value === "") return "";
          var res = "";
          params.data.value.split("//").map((e) => {
            try {
              res +=
                values.find((refData) => String(refData.id) === String(e))[
                  "name"
                ] + "//";
            } catch (err) {
              console.log(err);
            }
            return true;
          });

          return res.slice(0, -2);
        } else if (c.column_type === "date") {
          if (params.data.value === null || params.data.value === "") return "";
          var d = new Date(params.data.value);
          var month = d.getMonth() + 1;
          return d.getDate() + "." + month + "." + d.getFullYear();
        } else {
          return params.data.value;
        }
      },

      valueFormatter: function (params) {
        var c = columns[params.data.original_field];
        if (c.column_type == "file") {
          console.log(params);
          if (params.value != null && params.value != "") {
            return "OK";
          } else {
            return "";
          }
        } else {
          return params.value;
        }
      },
    },
    {
      headerName: t("Stará hodnota"),
      field: "old_values",

      valueGetter: function (params) {
        var column = "";
        var values = [];
        JSON.parse(localStorage.getItem("columns")).map((c) => {
          if (c.field === params.data.original_field) {
            column = c;
            return true;
          }
          return true;
        });
        var c = column;

        if (c.column_type === "boolean") {
          try {
            return JSON.parse(localStorage.getItem("booleans")).find(
              (refData) => String(refData.id) === String(params.data.old_values)
            )["name"];
          } catch (err) {
            console.log(params.data.old_values);
            console.log(c.field);
            console.log(err);
            return "";
          }
        } else if (c.column_type === "select") {
          values = [];
          var tmp_values = [];
          if (c.data_array === "custom") {
            if (c.data !== null && c.data !== "") values = c.data;
          } else if (
            c.data_array === "" ||
            c.data_array === null ||
            c.data_array === "none"
          ) {
            //
          } else {
            values = JSON.parse(localStorage.getItem(c.data_array));
          }

          if (params.data.old_values === null || params.data.old_values === "")
            return "";
          try {
            return values.find(
              (refData) => String(refData.id) == String(params.data.old_values)
            )["name"];
          } catch (err) {
            console.log(params.data.old_values);
            console.log(values);
            console.log(c);
            console.log(err);
            return "";
          }
        } else if (c.column_type === "multiselect") {
          values = [];
          if (c.data_array === "custom") {
            if (c.data !== null && c.data !== "") values = c.data;
          } else if (
            c.data_array === "" ||
            c.data_array === null ||
            c.data_array === "none"
          ) {
            //
          } else {
            values = JSON.parse(localStorage.getItem(c.data_array));
          }

          if (params.data.old_values === null || params.data.old_values === "")
            return "";
          var res = "";
          params.data.old_values.split("//").map((e) => {
            try {
              res +=
                values.find((refData) => String(refData.id) === String(e))[
                  "name"
                ] + "//";
            } catch (err) {
              console.log(err);
            }
            return true;
          });

          return res.slice(0, -2);
        } else if (c.column_type === "date") {
          if (params.data.old_values === null || params.data.old_values === "")
            return "";
          var d = new Date(params.data.old_values);
          var month = d.getMonth() + 1;
          return d.getDate() + "." + month + "." + d.getFullYear();
        } else {
          return params.data.old_values;
        }
      },

      valueFormatter: function (params) {
        var c = columns[params.data.original_field];
        if (c.column_type == "file") {
          console.log(params);
          if (params.value != null && params.value != "") {
            return "OK";
          } else {
            return "";
          }
        } else {
          return params.value;
        }
      },
    },
  ];

  const mounted = useMounted();

  const { settings } = useSettings();
  const [rowdata, setRowdata] = useState(null);
  const [columndefs, setColumndefs] = useState(coldefs);
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});

  const defaultColDef = {
    flex: 1,
    minWidth: 170,
    editable: false,
    resizable: true,
    suppressMovableColumns: true,
    sortable: true,
    filter: true,
    suppressContextMenu: true,
  };

  const frameworkComponents = {};

  const getRowdata = () => {
    HttpService.get(
      UrlService.apiDomain() + `api/audit/${props.type}/${props.id}`
    )
      .then((res) => {
        setRowdata(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRowdata();
  }, [props.id]);

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);

    var columnState = JSON.parse(localStorage.getItem("audit_column_state"));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  };

  const onDragStopped = (params) => {
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem("audit_column_state", columnState);
  };

  useEffect(() => {
    setloctext(lt);
    setColumndefs(coldefs);
  }, [t("cs")]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Audit | AWA Partners</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("Audit")}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <div
              style={{ width: "100%", overflow: "auto", height: "500px" }}
              id="auditGrid"
              className="ag-theme-material"
            >
              <AgGridReact
                modules={AllCommunityModules}
                rowData={rowdata}
                columnDefs={columndefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onDragStopped={onDragStopped}
                frameworkComponents={frameworkComponents}
                enableCellChangeFlash={true}
                suppressContextMenu={true}
                localeText={loctext}
                popupParent={document.querySelector("body")}
              />
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Audit;
