import { useState } from "react";
import { IconButton, Dialog } from "@material-ui/core";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Body from "./Body";

const Support = ({ mainNavbar }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xl"
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: "10px",
            height: "80vh",
            minWidth: "50%",
          },
        }}
      >
        <Body onClose={handleClose} />
      </Dialog>
      <IconButton
        onClick={handleOpen}
        sx={{ color: mainNavbar ? "#1b4dd6" : "white" }}
      >
        <HelpOutlineIcon sx={{ fontSize: "36px" }} />
      </IconButton>
    </>
  );
};

export default Support;
