import { useState, useEffect, useCallback } from "react";

import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import useMounted from "../../../hooks/useMounted";
import useSettings from "../../../hooks/useSettings";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import LocaleRu from "../../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";
import { LicenseManager } from "ag-grid-enterprise";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useDispatch } from "../../../store";
import { returnCard } from "../../../slices/vacancies";
import { useContext } from "react";
import { DataContext } from "../../../contexts/DataContext";
import CopyVacancy from "./CopyVacancy.js";
import toast from "react-hot-toast";
import { Description } from "@material-ui/icons";
LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const Archiv = ({ id, handleOptionsClose }) => {
  const { t } = useTranslation();
  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies;
  const customComparator = (valueA, valueB) => {
    if (valueA === null || valueB === null) {
      return valueA === valueB ? 0 : valueA === null ? -1 : 1;
    }
    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else {
      // return default value for non-string and non-number values
      return 0;
    }
  };
  const dispatch = useDispatch();
  const { data: contextData, isLoading } = useContext(DataContext);
  const { professions } = contextData;
  console.log({ professions });
  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  const coldefs = [
    {
      headerName: t("Nazev"),
      field: "name",
    },
    {
      headerName: t("Popis"),
      field: "description",
    },
    {
      headerName: t("Nástup"),
      field: "due",
    },
    {
      headerName: t("Pohovor"),
      field: "interveiwDay",
    },
    {
      headerName: t("Pracovní zkušenosti"),
      field: "profession",
      cellRenderer: (params) => {
        return professions.find((prof) => prof.id == params.value)?.name;
      },
    },
    {
      headerName: t("Požadavek lidí"),
      field: "employeeNeeded",
    },
    {
      headerName: t("Přidáno lidí"),
      field: "employeeAdded",
    },
    {
      headerName: t("Potvrzeno lidí"),
      field: "employeeApproved",
    },
    {
      headerName: t("Vytvořil"),
      field: "created_by",
    },
    {
      headerName: t("Odstranil"),
      field: "deleted_by",
    },
    {
      headerName: t("Datum archivace"),
      field: "deleted_at",
    },
  ];

  if (!id) {
    coldefs.unshift({
      headerName: t("Uživatel"),
      field: "customer",
    });
  }

  if (policy_actions.includes("copy_from_archiv")) {
    coldefs.push({
      headerName: t("Kopírovat"),
      cellRenderer: "CopyVacancy",
      cellRendererParams: ({ data }) => {
        return {
          description: data.description,
          name: data.name,
          amount: data.employeeNeeded,
          profession: data.profession,
          customer_id: data.customer_id,
          salary: data.salary,
          bonus: data.bonus,
          shift: data.shift,
          citizenship: data.citizenship,
          sex: data.sex,
          age: data.age,
          languages: data.languages,
        };
      },
    });
  }
  if (policy_actions.includes("return")) {
    coldefs.push({
      headerName: t("Vrátit se"),
      field: "id",
      cellRendererFramework: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleReturn(params);
          }}
        >
          <AutorenewIcon />
        </Button>
      ),
    });
  }
  const mounted = useMounted();

  const { settings } = useSettings();
  const [rowdata, setRowdata] = useState([]);
  const [columndefs, setColumndefs] = useState(coldefs);
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    editable: false,
    resizable: true,
    suppressMovableColumns: true,
    menuTabs: ["filterMenuTab", "columnsMenuTab"],
    sortable: true,
    filter: true,
    suppressContextMenu: true,
    comparator: customComparator,
  };
  const frameworkComponents = {
    CopyVacancy: CopyVacancy,
  };

  const customer = id ? id : "all";

  const handleReturn = async ({ value }) => {
    try {
      const response = await HttpService.get(
        UrlService.apiDomain() + `api/vacancy-from-archive/${value}`
      );
      if (response.status !== 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      setRowdata((prevRowdata) =>
        prevRowdata.filter((row) => row.id !== value)
      );
      dispatch(returnCard(response.data));
      toast.success("Záznam vrácen");
    } catch (error) {
      console.log(error);
    }
  };

  const grd = () => {
    HttpService.get(
      UrlService.apiDomain() + `api/deleted-vacancies/${customer}`
    )
      .then((res) => {
        setRowdata(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    grd();
  }, [id]);

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);

    const columnState = JSON.parse(
      localStorage.getItem("vacancies_archiv_column_state")
    );
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  };

  const onDragStopped = (params) => {
    const columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem("vacancies_archiv_column_state", columnState);
  };

  useEffect(() => {
    setloctext(lt);
    setColumndefs(coldefs);
  }, [t("cs")]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    //  border: "2px solid #000",
  };

  return (
    <div style={style}>
      {/* <Box
        sx={{
          backgroundColor: "background.default",
          height: "100%",
          py: 8,
        }}
      > */}
      <Container
        maxWidth={settings.compact ? "xl" : false}
        style={{
          width: "100%",
          height: "100%",
          background: "#f4f5f7",
          borderRadius: "20px",
          paddingBottom: 100,
        }}
      >
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h5">
              {"Archiv požadávky"}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" spacing={3}>
              {policy_actions.includes("excel") && (
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    title={t("Excel")}
                    onClick={() => gridApi.exportDataAsExcel()}
                  >
                    Excel
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button variant="outlined" onClick={handleOptionsClose}>
                  {t("Zavřít")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{ mt: 3 }}
          style={{ width: "100%", height: "calc(100% - 100px)" }}
          id="myGrid"
          className="ag-theme-material"
        >
          <AgGridReact
            modules={AllCommunityModules}
            rowData={rowdata}
            columnDefs={columndefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onDragStopped={onDragStopped}
            enableCellChangeFlash={true}
            rowSelection={"multiple"}
            suppressContextMenu={true}
            localeText={loctext}
            popupParent={document.querySelector("body")}
            frameworkComponents={frameworkComponents}
          />
        </Box>
      </Container>
      {/* </Box> */}
    </div>
  );
};

export default Archiv;
