"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIPageContentTopBarFilterSwitcherReactT from "./UIPageContentTopBarFilterSwitcherReactT.mjs";

import UIPageContentTopBarFilterSwitcherAbstractT from "../../../../../../Interface/UI/Page/Content/TopBar/FilterSwitcher/_/UIPageContentTopBarFilterSwitcherAbstractT.mjs";


const UIPageContentTopBarFilterSwitcherT = class UIPageContentTopBarFilterSwitcherT extends UIPageContentTopBarFilterSwitcherAbstractT {
	
	#Component = null;
	
	#Ref = null;
	
	constructor(
		Props
	){
		
		console.log( "UIPageContentTopBarFilterSwitcherT.constructor", Props );
		
		
		super( );
		
		
		this.#Ref = React.createRef( );

		
		
		this.#Component = React.createElement( 
			UIPageContentTopBarFilterSwitcherReactT, 
			{
				...Props,
				ref: this.#Ref
			}
		);
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIPageContentTopBarFilterSwitcherT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		if( this.#Ref.current !== null ){
		
			this.#Ref.current.PropSet( Name, Value );
		
		}
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIButtonT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.#Ref.current.PropGet( Name );
		
	}
	
	Component( 
	
	){
		
		return this.#Component;
		
	}
	
};


export default UIPageContentTopBarFilterSwitcherT;