"use strict";


const EnvAbstractT = class EnvAbstractT {

	constructor( 
		
	){

	}
	
	URL( ){
		
		throw new Error( "Abstract" );
		
	}

};


export default EnvAbstractT;