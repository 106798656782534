import { Grid, TextField, Typography, Divider, Box } from "@material-ui/core";
import InputIBAN from "./Transfer/InputIBAN";
import InputBicSwift from "./Transfer/InputBicSwift";
import InputAccountNumber from "./Transfer/InputAccountNumber";
import InputText from "./Transfer/InputText";
import InputFullText from "./Transfer/InputFullText";
import { useEffect, useState } from "react";
import CountrySelect from "./Transfer/CountrySelect";
import InputNum from "./Transfer/InputNum";
import { useTranslation } from "react-i18next";

export default function TransferDetailsForm(props) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.type, props.paymentType, props.data]);

  const { t } = useTranslation();
  const form = [
    {
      name: "iban",
      label: "IBAN",
      size: 12,
      component: InputIBAN,
    },
    {
      name: "bic_swift",
      label: "BIC/SWIFT",
      size: 6,
      component: InputBicSwift,
    },
    {
      name: "bank_country",
      label: "Země banky",
      size: 6,
      component: CountrySelect,
    },
    {
      name: "bank_address_1",
      label: "Adresa banky 1",
      size: 6,
      component: InputText,
    },
    {
      name: "bank_address_2",
      label: "Adresa banky 2",
      size: 6,
      component: InputText,
    },
    {
      name: "bank_name_1",
      label: "Název banky 1",
      size: 6,
      component: InputText,
    },
    {
      name: "bank_name_2",
      label: "Název banky 2",
      size: 6,
      component: InputText,
    },
    {
      name: "beneficiary_address_1",
      label: "Adresa příjemce 1",
      size: 6,
      component: InputText,
    },
    {
      name: "beneficiary_address_2",
      label: "Adresa příjemce 2",
      size: 6,
      component: InputText,
    },
    {
      name: "beneficiary_name_1",
      label: "Název příjemce 1",
      size: 6,
      component: InputText,
    },
    {
      name: "beneficiary_name_2",
      label: "Název příjemce 1",
      size: 6,
      component: InputText,
    },
  ];
  const formCz = [
    {
      name: "account_number",
      label: "Číslo účtu",
      size: 12,
      component: InputAccountNumber,
    },
    {
      name: "beneficiary_name_1",
      label: "Název příjemce 1",
      size: 6,
      component: InputText,
    },
    {
      name: "beneficiary_name_2",
      label: "Název příjemce 1",
      size: 6,
      component: InputText,
    },
  ];

  let form2 = [];

  if (props.type == "self_employed") {
    form2.push({
      name: "ic",
      label: "IČ",
      size: 12,
      component: InputNum,
    });
    form2.push({
      name: "dic",
      label: "DIČ",
      size: 12,
      component: InputNum,
    });
    form2.push({
      name: "address",
      label: "Adresa",
      size: 12,
      component: InputFullText,
    });
  }
  if (props.type == "legal_entity") {
    form2.push({
      name: "ic",
      label: "IČ",
      size: 12,
      component: InputNum,
    });
    form2.push({
      name: "dic",
      label: "DIČ",
      size: 12,
      component: InputNum,
    });
    form2.push({
      name: "company_name",
      label: "Název společnosti",
      size: 12,
      component: InputText,
    });
    form2.push({
      name: "address",
      label: "Adresa",
      size: 12,
      component: InputFullText,
    });
  }

  const handleChange = (name, newValue) => {
    setData({ ...data, [name]: newValue });
    props.onChange(name, newValue);
  };

  const isDisabled = (name) => {
    if (!name.endsWith("_2")) return false;
    let other_name = name.replace("_2", "_1");
    let other_data = data[other_name];
    let result = !other_data;
    if (result && data[name] !== "") {
      handleChange(name, "");
    }
    return result;
  };

  return (
    <>
      {(props.type == "self_employed" || props.type == "legal_entity") &&
        form2.map((item) => {
          return (
            <Grid item xs={item.size} key={item.name}>
              <item.component
                onChange={handleChange}
                name={item.name}
                label={t(item.label)}
                value={data[item.name]}
                disabled={isDisabled(item.name)}
              />
            </Grid>
          );
        })}
      <Box sx={{ width: 1, pt: 2 }}>
        <Divider orientation="horizontal">{t("Bankovní spojení")}</Divider>
      </Box>
      {props.paymentType == "transfer" &&
        form.map((item) => {
          return (
            <Grid item xs={item.size} key={item.name}>
              <item.component
                onChange={handleChange}
                name={item.name}
                label={t(item.label)}
                value={data[item.name]}
                disabled={isDisabled(item.name)}
              />
            </Grid>
          );
        })}
      {props.paymentType == "transferCz" &&
        formCz.map((item) => {
          return (
            <Grid item xs={item.size} key={item.name}>
              <item.component
                onChange={handleChange}
                name={item.name}
                label={t(item.label)}
                value={data[item.name]}
                disabled={isDisabled(item.name)}
              />
            </Grid>
          );
        })}
    </>
  );
}
