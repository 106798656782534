import { useState } from "react";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";

import TextEditor from "./TextEditor.js";
import FullText from "./FullText.js";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Divider,
  TextField,
  Autocomplete,
  Typography,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { PlayArrow, Delete } from "@material-ui/icons";
import EditIcon from "@mui/icons-material/Edit";

const Player = ({ file, onPlay, onDelete }) => {
  const { t } = useTranslation();

  const policy_actions = JSON.parse(localStorage.getItem("policy_action_tree"))[
    "meeting_records"
  ];

  const { data: contextData } = useContext(DataContext);
  const { thp_users, all_customers } = contextData;

  const {
    name,
    created_at,
    html_content,
    employee_ids,
    customer_ids,
    transcription,
    id,
  } = file;

  const date = format(new Date(created_at), "dd.MM.yyyy");

  const [participantIds, setParticipantsIds] = useState(employee_ids || []);
  const [prevPartic, setPrevPartic] = useState(employee_ids || []);
  const [customerIds, setCustomerIds] = useState(customer_ids || []);
  const [prevCust, setPrevCust] = useState(customer_ids || []);
  const [openDetails, setOpenDetails] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const toggleDetails = () => {
    setOpenDetails(!openDetails);
  };

  const handleEdit = async (id, field, array) => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + `api/meeting_records/edit`,
        {
          data: {
            id,
            [field]: array,
          },
        }
      );
      if (!res.data.success) {
        alert(res.data.message);
        return;
      }
      toast.success("Seznam upraven");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  const handleDelete = async () => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + `api/meeting_records/delete`,
        {
          data: {
            id,
          },
        }
      );
      if (!res.data.success) {
        alert(res.data.message);
        return;
      }
      toast.success("Záznam smazán");
      handleCloseDelete();
      onDelete(id);
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };

  const uniqueCustomers = [];
  const namesSet = new Set();

  all_customers.forEach((customer) => {
    if (!namesSet.has(customer.name)) {
      namesSet.add(customer.name);
      uniqueCustomers.push(customer);
    }
  });

  const allCustomers = uniqueCustomers
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    .map((customer) => {
      return {
        label: customer.name,
        key: customer.id,
        value: customer.id,
      };
    });

  const availableUsers = thp_users
    .filter((user) => {
      let today = new Date();
      let ukonceni_pp_date = user.ukonceni_pp
        ? new Date(user.ukonceni_pp)
        : null;
      let smlouva_do_date = user.smlouva_do ? new Date(user.smlouva_do) : null;

      return (
        (!ukonceni_pp_date || ukonceni_pp_date > today) &&
        (!smlouva_do_date || smlouva_do_date > today)
      );
    })
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    .map((user) => {
      return {
        roles: user.roles || [],
        label: user.name,
        key: user.id,
        value: user.id,
      };
    });

  return (
    <Card sx={{ mb: 2 }}>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Potvrdit odstranění")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              "Jste si jisti, že chcete odstranit tuto položku a všechny položky s ní spojené?"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            {t("Ne")}
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {t("Ano")}
          </Button>
        </DialogActions>
      </Dialog>
      <CardHeader
        title={name}
        subheader={date}
        action={
          <Box display="flex">
            {policy_actions.includes("full_text") && transcription && (
              <FullText text={transcription} name={name} date={date} />
            )}
            {policy_actions.includes("see_html") && html_content && (
              <TextEditor text={html_content} name={name} date={date} id={id} />
            )}
            {policy_actions.includes("listening") && (
              <IconButton onClick={onPlay}>
                <PlayArrow />
              </IconButton>
            )}
            {policy_actions.includes("delete") && (
              <IconButton onClick={handleOpenDelete}>
                <Delete />
              </IconButton>
            )}
          </Box>
        }
      />
      <Divider />
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 4 }}>
          <Box>
            {!!customerIds.length && (
              <Typography sx={{ mb: 2 }}>
                {<strong>{t("Uživatele")}</strong>}:{" "}
                {customerIds.map((cust, index) => {
                  const customer = allCustomers.find(
                    (item) => item.value == cust
                  );
                  return (
                    <span key={cust}>
                      {customer ? customer.label : ""}
                      {index < customerIds.length - 1 && ", "}
                    </span>
                  );
                })}
              </Typography>
            )}
            {!!participantIds.length && (
              <Typography sx={{ mb: 2 }}>
                {<strong>{t("Účastníci")}</strong>}:{" "}
                {participantIds.map((empl, index) => {
                  const employee = thp_users.find((item) => item.id == empl);
                  return (
                    <span key={empl}>
                      {employee ? employee.name : ""}
                      {index < participantIds.length - 1 && ", "}
                    </span>
                  );
                })}
              </Typography>
            )}
          </Box>
          {policy_actions.includes("edit") && (
            <Box sx={{ flexShrink: 0 }}>
              <IconButton color="primary" onClick={toggleDetails}>
                <EditIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {openDetails && (
          <Grid container spacing={2}>
            {" "}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                name={"participants"}
                options={availableUsers}
                getOptionLabel={(option) => option.label}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setParticipantsIds(newValue.map((option) => option.value));
                }}
                fullWidth
                multiple
                limitTags={2}
                value={availableUsers.filter((user) =>
                  participantIds.includes(user.value)
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("Účastníci")} />
                )}
                onOpen={() => setPrevPartic(participantIds)}
                onClose={() => {
                  if (
                    JSON.stringify(participantIds) !==
                    JSON.stringify(prevPartic)
                  ) {
                    handleEdit(id, "employee_ids", participantIds);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                name={"customers"}
                options={allCustomers}
                getOptionLabel={(option) => option.label}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setCustomerIds(newValue.map((option) => option.value));
                }}
                fullWidth
                multiple
                limitTags={2}
                value={allCustomers.filter((сustomer) =>
                  customerIds.includes(сustomer.value)
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("Uživatele")} />
                )}
                onOpen={() => setPrevCust(customerIds)}
                onClose={() => {
                  if (
                    JSON.stringify(customerIds) !== JSON.stringify(prevCust)
                  ) {
                    handleEdit(id, "customer_ids", customerIds);
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default Player;
