import React, {useState} from "react";
import {Checkbox} from "@material-ui/core";

export default function CheckboxEditor(props) {
    const [checked, setChecked] = useState(props.value !== 'false' && Boolean(props.value));

    const handleChange = (val) => {
        props.node.setDataValue(props.column.colId, val);
    }

    return (
      <Checkbox
        disabled={props.disabled || !props.editable}
        sx={props.style ?? undefined}
        checked={checked}
        onChange={(event) => {
          let newValue = event.target.checked;
          if (
            window.confirm(
              `Jste si jistý, že chcete ${props.colDef.headerName} změnit na ${newValue} ?`
            )
          ) {
            setChecked(newValue);
            handleChange(newValue ? 1 : 0);
          }
        }}
      />
    );
}