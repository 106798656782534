"use strict";


const TranslationGridRUT = class TranslationGridRUT {
	
	static Data( ){
		
		return {
			// Set Filter
			selectAll: '(Выбрать все)',
			selectAllSearchResults: '(VВыбрать все результаты поиска)',
			searchOoo: 'Искать...',
			blanks: '(Пусто)',
			noMatches: 'Нет совпадений',

			// Number Filter & Text Filter
			filterOoo: 'Фильтровать...',
			equals: 'Равно',
			notEqual: 'Неравно',
			empty: 'Выберите',

			// Number Filter
			lessThan: 'Меньше чем',
			greaterThan: 'Больше чем',
			lessThanOrEqual: 'Меньше или равно',
			greaterThanOrEqual: 'Больше или равно',
			inRange: 'В диапазоне',
			inRangeStart: 'До',
			inRangeEnd: 'От',

			// Text Filter
			contains: 'Содержит',
			notContains: 'Несодержит',
			startsWith: 'Начинается',
			endsWith: 'Заканчивается',

			// Date Filter
			dateFormatOoo: 'dd.mm.yyyy',

			// Filter Conditions
			andCondition: 'И',
			orCondition: 'ИЛИ',

			// Filter Buttons
			applyFilter: 'Применить',
			resetFilter: 'Отменить',
			clearFilter: 'Удалить',
			cancelFilter: 'Отмена',

			// Filter Titles
			textFilter: 'Текстовый фильтр',
			numberFilter: 'Числовой фильтр',
			dateFilter: 'Фильтр даты',
			setFilter: 'Установить фильтр',

			// Side Bar
			columns: 'Колонки',
			filters: 'Фильтры',

			// columns tool panel
			pivotMode: 'Pivot Mode',
			groups: 'Row Groups',
			rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
			values: 'Values',
			valueColumnsEmptyMessage: 'Drag here to aggregate',
			pivots: 'Column Labels',
			pivotColumnsEmptyMessage: 'Drag here to set column labels',

			// Header of the Default Group Column
			group: 'Group',

			// Other
			loadingOoo: 'Загружаю...',
			noRowsToShow: 'Ожидаю данные',
			enabled: 'Включено',

			// Menu
			pinColumn: 'Зафиксировать колонку',
			pinLeft: 'Зафиксировать влево',
			pinRight: 'Зафиксировать вправо',
			noPin: 'Нефиксировать',
			valueAggregation: 'Value Aggregation',
			autosizeThiscolumn: 'Автоширина колонки',
			autosizeAllColumns: 'Автоширина всех колонок',
			groupBy: 'Group by',
			ungroupBy: 'Un-Group by',
			resetColumns: 'Сбросить настройки колонок',
			expandAll: 'Expand All',
			collapseAll: 'Close All',
			copy: 'Копировать',
			ctrlC: 'Ctrl+C',
			copyWithHeaders: 'Kopírovat se záhlavím',
			paste: 'Вставить',
			ctrlV: 'Ctrl+V',
			export: 'Экспортировать',
			csvExport: 'CSV Экспорт',
			excelExport: 'Excel Экспорт (.xlsx)',
			excelXmlExport: 'Excel Экспорт (.xml)',

			// Enterprise Menu Aggregation and Status Bar
			sum: 'Sum',
			min: 'Min',
			max: 'Max',
			none: 'None',
			count: 'Count',
			avg: 'Average',
			filteredRows: 'Отфильтрованные',
			selectedRows: 'Выбранныек',
			totalRows: 'Всего строчек',
			totalAndFilteredRows: 'Строчек',
			more: 'Больше',
			to: 'в',
			of: 'из',
			page: 'Страница',
			nextPage: 'Следующая страница',
			lastPage: 'Последняя страница',
			firstPage: 'Первая страница',
			previousPage: 'Предыдущая страница',

			// Enterprise Menu (Charts)
			pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
			pivotChart: 'Pivot Chart',
			chartRange: 'Chart Range',

			columnChart: 'Column',
			groupedColumn: 'Grouped',
			stackedColumn: 'Stacked',
			normalizedColumn: '100% Stacked',

			barChart: 'Bar',
			groupedBar: 'Grouped',
			stackedBar: 'Stacked',
			normalizedBar: '100% Stacked',

			pieChart: 'Pie',
			pie: 'Pie',
			doughnut: 'Doughnut',

			line: 'Line',

			xyChart: 'X Y (Scatter)',
			scatter: 'Scatter',
			bubble: 'Bubble',

			areaChart: 'Area',
			area: 'Area',
			stackedArea: 'Stacked',
			normalizedArea: '100% Stacked',

			histogramChart: 'Histogram',

			// Charts
			pivotChartTitle: 'Pivot Chart',
			rangeChartTitle: 'Range Chart',
			settings: 'Settings',
			data: 'Data',
			format: 'Format',
			categories: 'Categories',
			defaultCategory: '(None)',
			series: 'Series',
			xyValues: 'X Y Values',
			paired: 'Paired Mode',
			axis: 'Axis',
			navigator: 'Navigator',
			color: 'Color',
			thickness: 'Thickness',
			xType: 'X Type',
			automatic: 'Automatic',
			category: 'Category',
			number: 'Number',
			time: 'Time',
			xRotation: 'X Rotation',
			yRotation: 'Y Rotation',
			ticks: 'Ticks',
			width: 'Width',
			height: 'Height',
			length: 'Length',
			padding: 'Padding',
			spacing: 'Spacing',
			chart: 'Chart',
			title: 'Title',
			titlePlaceholder: 'Chart title - double click to edit',
			background: 'Background',
			font: 'Font',
			top: 'Top',
			right: 'Right',
			bottom: 'Bottom',
			left: 'Left',
			labels: 'Labels',
			size: 'Size',
			minSize: 'Minimum Size',
			maxSize: 'Maximum Size',
			legend: 'Legend',
			position: 'Position',
			markerSize: 'Marker Size',
			markerStroke: 'Marker Stroke',
			markerPadding: 'Marker Padding',
			itemSpacing: 'Item Spacing',
			itemPaddingX: 'Item Padding X',
			itemPaddingY: 'Item Padding Y',
			layoutHorizontalSpacing: 'Horizontal Spacing',
			layoutVerticalSpacing: 'Vertical Spacing',
			strokeWidth: 'Stroke Width',
			offset: 'Offset',
			offsets: 'Offsets',
			tooltips: 'Tooltips',
			callout: 'Callout',
			markers: 'Markers',
			shadow: 'Shadow',
			blur: 'Blur',
			xOffset: 'X Offset',
			yOffset: 'Y Offset',
			lineWidth: 'Line Width',
			normal: 'Normal',
			bold: 'Bold',
			italic: 'Italic',
			boldItalic: 'Bold Italic',
			predefined: 'Predefined',
			fillOpacity: 'Fill Opacity',
			strokeOpacity: 'Line Opacity',
			histogramBinCount: 'Bin count',
			columnGroup: 'Column',
			barGroup: 'Bar',
			pieGroup: 'Pie',
			lineGroup: 'Line',
			scatterGroup: 'X Y (Scatter)',
			areaGroup: 'Area',
			histogramGroup: 'Histogram',
			groupedColumnTooltip: 'Grouped',
			stackedColumnTooltip: 'Stacked',
			normalizedColumnTooltip: '100% Stacked',
			groupedBarTooltip: 'Grouped',
			stackedBarTooltip: 'Stacked',
			normalizedBarTooltip: '100% Stacked',
			pieTooltip: 'Pie',
			doughnutTooltip: 'Doughnut',
			lineTooltip: 'Line',
			groupedAreaTooltip: 'Area',
			stackedAreaTooltip: 'Stacked',
			normalizedAreaTooltip: '100% Stacked',
			scatterTooltip: 'Scatter',
			bubbleTooltip: 'Bubble',
			histogramTooltip: 'Histogram',
			noDataToChart: 'No data available to be charted.',
			pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
			chartSettingsToolbarTooltip: 'Menu',
			chartLinkToolbarTooltip: 'Linked to Grid',
			chartUnlinkToolbarTooltip: 'Unlinked from Grid',
			chartDownloadToolbarTooltip: 'Download Chart',

			// ARIA
			ariaHidden: 'hidden',
			ariaVisible: 'visible',
			ariaChecked: 'checked',
			ariaUnchecked: 'unchecked',
			ariaIndeterminate: 'indeterminate',
			ariaColumnSelectAll: 'Toggle Select All Columns',
			ariaInputEditor: 'Input Editor',
			ariaDateFilterInput: 'Date Filter Input',
			ariaFilterInput: 'Filter Input',
			ariaFilterColumnsInput: 'Filter Columns Input',
			ariaFilterValue: 'Filter Value',
			ariaFilterFromValue: 'Filter from value',
			ariaFilterToValue: 'Filter to value',
			ariaFilteringOperator: 'Filtering Operator',
			ariaColumnToggleVisibility: 'column toggle visibility',
			ariaColumnGroupToggleVisibility: 'column group toggle visibility',
			ariaRowSelect: 'Press SPACE to select this row',
			ariaRowDeselect: 'Press SPACE to deselect this row',
			ariaRowToggleSelection: 'Press Space to toggle row selection',
			ariaRowSelectAll: 'Press Space to toggle all rows selection',
			ariaSearch: 'Search',
			ariaSearchFilterValues: 'Search filter values'
		};
		
	}
		
};


export default TranslationGridRUT;
