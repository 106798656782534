import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar, Button } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import MenuIcon from "../../icons/Menu";
import FilterSelect from "../filter/filterSelect";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import LogoWhite from "../LogoWhite";
import SearchEmployee from "../search/SearchEmployee";
import useAuth from "../../hooks/useAuth";
import RefreshIcon from "@material-ui/icons/Refresh";
import NotificationsPopover from "../../components/dashboard/NotificationsPopover";
import PhonePopover from "./PhonePopover";
import Support from "../support";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { user } = useAuth();
  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );
  const { onSidebarMobileOpen, ...other } = props;
  let logo_link = "/";
  if (localStorage.getItem("my_user") != null) logo_link = "/dashboard";
  let user_info = `${user.name} ${user.surname}`;
  const URLParts = window.location.pathname.split("/");

  const urls = [
    "projects",
    "supplier_invoices",
    "clients",
    "supplier_account_numbers",
    "received_invoices",
    "pending_invoices",
    "wire_transfers",
    "additional_texts",
    "invoice_item_names",
    "issued_invoices",
    "account_numbers",
    "payments",
    "regular_payments",
  ];

  const isFinance = URLParts.some((el) => urls.includes(el));

  return (
    <DashboardNavbarRoot {...other} sx={{ mb: 2 }}>
      <Toolbar sx={{ minHeight: 64 }}>
        <RouterLink to={logo_link}>
          <LogoWhite
            sx={{
              display: {
                lg: "inline",
                xs: "none",
              },
              height: 40,
              width: 40,
            }}
          />
        </RouterLink>
        <IconButton
          sx={{ mb: "6px" }}
          color="inherit"
          onClick={onSidebarMobileOpen}
          // sx={{
          //     display: {
          //         lg: 'none'
          //     }
          // }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Button
          sx={{ color: "white", mb: "6px" }}
          onClick={() => {
            localStorage.setItem("public_settings_version", "0");
            localStorage.setItem("private_settings_version", "0");
            sessionStorage.clear();
            window.location.reload();
          }}
        >
          <RefreshIcon />
        </Button>
        <NotificationsPopover />
        <PhonePopover
          // display={{ lg: "none", xs: "block" }}
          display="block"
        />
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/* <Box
          sx={{
            mr: 2,
            display: {
              lg: "flex",
              xs: "none",
            },
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box sx={{ paddingTop: "5px" }}>
            <PhoneEnabledIcon fontSize={"large"} />
          </Box>
          <Box>
            <a
              style={{
                display: "block",
                textDecoration: "none",
                color: "white",
              }}
              href="tel:+420 800 128 128"
            >
              +420 800 128 128 (CZ)
            </a>
            <a
              style={{
                display: "block",
                textDecoration: "none",
                color: "white",
              }}
              href="tel:+420 601 128 128"
            >
              +420 601 128 128 (UA)
            </a>
          </Box>
        </Box> */}
        <SearchEmployee />
        {policy_action_tree.received_invoices.includes("browse") &&
          isFinance && <FilterSelect />}
        <Support />
        <LanguagePopover />
        {user && (
          <Box
            sx={{
              display: {
                lg: "inline",
                xs: "none",
              },
            }}
          >
            {user_info}
          </Box>
        )}
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
