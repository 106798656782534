import { useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import ClipboardListIcon from "../../../icons/ClipboardList";
import { deleteChecklist, updateChecklist } from "../../../slices/vacancies";
import { useDispatch } from "../../../store";
import KanbanCheckItem from "./KanbanCheckItem";
import { useTranslation } from "react-i18next";

const KanbanChecklistRoot = experimentalStyled("div")``;

const KanbanChecklist = (props) => {
  const { t } = useTranslation();
  const { card, checklist, ...other } = props;
  const dispatch = useDispatch();
  const [name, setName] = useState(checklist.name);
  const [editingName, setEditingName] = useState(false);
  const [editingCheckItem, setEditingCheckItem] = useState(null);

  const isDodavatel = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies.includes("dodavatel_view");

  const user_id = JSON.parse(localStorage.getItem("my_employee")).id;

  let { checkItems } = checklist;

  if (isDodavatel) {
    checkItems = checkItems.filter((item) => item.dodavatel_id == user_id);
  }

  const handleNameEdit = () => {
    setEditingName(true);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleNameSave = async () => {
    try {
      if (!name || name === checklist.name) {
        setEditingName(false);
        setName(checklist.name);
        return;
      }

      setEditingName(false);
      await dispatch(updateChecklist(card.id, checklist.id, { name }));
      toast.success("Checklist updated!");
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  };

  const handleNameCancel = () => {
    setEditingName(false);
    setName(checklist.name);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteChecklist(card.id, checklist.id));
      toast.success("Checklist deleted!");
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  };

  const handleCheckItemEditInit = (checkItemId) => {
    setEditingCheckItem(checkItemId);
  };

  const handleCheckItemEditCancel = () => {
    setEditingCheckItem(null);
  };

  const handleCheckItemEditComplete = () => {
    setEditingCheckItem(null);
  };

  const totalCheckItems = card.employeeNeeded;
  const completedCheckItems = checklist.checkItems.filter(
    (checkItem) => checkItem.state === "complete"
  ).length;
  const completePercentage =
    totalCheckItems === 0 ? 100 : (completedCheckItems / totalCheckItems) * 100;

  console.log({ checklist });

  return (
    <KanbanChecklistRoot {...other}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box sx={{ mr: 3 }}>
          <ClipboardListIcon fontSize="small" />
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Typography color="textPrimary" variant="h6">
            {t(checklist.name)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          mt: 1,
        }}
      >
        <Typography color="textSecondary" variant="caption">
          {Math.round(completePercentage)}%
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        >
          <LinearProgress
            color="primary"
            value={completePercentage}
            variant="determinate"
          />
        </Box>
      </Box>
      {checkItems.map((checkItem) => (
        <KanbanCheckItem
          card={card}
          cardId={card.id}
          checkItem={checkItem}
          checklistId={checklist.id}
          editing={editingCheckItem === checkItem.id}
          key={checkItem.id}
          onEditCancel={handleCheckItemEditCancel}
          onEditComplete={handleCheckItemEditComplete}
          onEditInit={() => handleCheckItemEditInit(checkItem.id)}
        />
      ))}
      <Box
        sx={{
          ml: 6,
          mt: 1,
        }}
      >
        {/* <KanbanCheckItemAdd
          cardId={card.id}
          checklistId={checklist.id}
        /> */}
      </Box>
    </KanbanChecklistRoot>
  );
};

KanbanChecklist.propTypes = {
  card: PropTypes.object.isRequired,
  checklist: PropTypes.object.isRequired,
  sx: PropTypes.object,
};

export default KanbanChecklist;
