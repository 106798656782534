import BasicAdd from "../../components/grid/basicAddNew";
import GridWrapper from "../../components/grid/gridWrapperNew";
import { useState, useMemo } from "react";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField, Grid } from "@material-ui/core";
import Archive from "./Archive.js";

export default function AccountingRequests({
  tab,
  browseData,
  employee_id,
  fromEmployeeCard,
}) {
  const { t } = useTranslation();

  const [employeeId, setEmployeeId] = useState(employee_id);
  const [people, setPeople] = useState([]);

  const roles = JSON.parse(localStorage.getItem("roles"));
  const policyActions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).accounting_requests;

  const handleSearch = (value) => {
    if (value.length > 2 && value.length < 5) {
      HttpService.get(
        UrlService.apiDomain() + `api/getAvailableUsers?search=${value}`
      )
        .then((res) => {
          setPeople([...res.data]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const memoizedBrowseData = useMemo(
    () => (browseData ? browseData : {}),
    [browseData]
  );
  return (
    <GridWrapper
      style={tab ? { mt: 2 } : null}
      helmet="Dashboard: Accounting Requests | AWA Partners"
      title="Požadavky účetní"
      model="accounting_requests"
      browseData={memoizedBrowseData}
    >
      <Archive />
      {!fromEmployeeCard && policyActions.includes("add") && (
        <Autocomplete
          sx={{ width: "350px", mr: 2 }}
          name={`employeeId`}
          options={people}
          getOptionLabel={(option) => {
            let userRoles = option.role;
            let roleNames = roles
              .filter((role) => userRoles.includes(role.id.toString()))
              .map((role) => role.name);
            return `${option.name} ${option.surname} ${
              option.date_of_birth ? ", " + option.date_of_birth : ""
            } ${option.code ? ", " + option.code : ""} ${
              option.role.length > 0 ? ", " + roleNames.join(", ") : ""
            } ${option.email ? ", " + option.email : ""}`;
          }}
          onChange={(e, value) => setEmployeeId(value?.id)}
          value={people.find((i) => i.id == employeeId) || null}
          onInputChange={(e, value) => handleSearch(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Zadejte první tři písmena příjmení")}
            />
          )}
        />
      )}
      <BasicAdd
        noTemplates={true}
        model={"accounting_requests"}
        presetValues={[
          {
            key: "employee_id",
            value: employeeId,
          },
        ]}
        buttonProps={{ disabled: !employeeId }}
        New={true}
      />
    </GridWrapper>
  );
}
