"use strict";


import JSONBNParserErrorT from "../../../Type/JSONBN/Parser/JSONBNParserErrorT.mjs";


const JSONBNParserT = class JSONBNParserT {
	
	#DigitMin = '\0';
		
	#DigitMax = '\0';
		
	#DigitMinus = '\0';
	
	#DigitDot = '\0';
	
	#DigitColon = '\0';
	
	#DigitComma = '\0';
	
	#DigitQuoteDouble = '\0';
	
	#DigitSlash = '\0';
	
	#DigitBackSlash = '\0';
	
	#DigitWhiteSpace = '\0';
	
	
	#DigitBackSpace = '\0';
		
	#DigitLineFeed = '\0';
	
	#DigitFormFeed = '\0';
	
	#DigitCarriageReturn = '\0';
	
	#DigitHorizontalTab = '\0';
	
	
	#DigitBracketFigureOpen = '\0';
	
	#DigitBracketFigureClose = '\0';
	
	#DigitBracketSquareOpen = '\0';
	
	#DigitBracketSquareClose = '\0';
	
	
	#DigitLetterLowerA = '\0';
	
	#DigitLetterLowerB = '\0';
	
	#DigitLetterLowerE = '\0';
	
	#DigitLetterLowerF = '\0';
	
	#DigitLetterLowerL = '\0';
	
	#DigitLetterLowerN = '\0';
	
	#DigitLetterLowerR = '\0';
	
	#DigitLetterLowerS = '\0';
	
	#DigitLetterLowerT = '\0';
	
	#DigitLetterLowerU = '\0';
	
	
	#DigitLetterUpperA = '\0';
	
	#DigitLetterUpperE = '\0';
	
	#DigitLetterUpperF = '\0';
	
	
	#DigitWhitespaces = null;
	
	#DigitExponentPrefixes = null;
	

	#Data = null;
	
	#Buffer = null;
	
	#Offset = 0;

	constructor(
	
	){
		
		this.#DigitMin = '0';
		
		this.#DigitMax = '9';
		
		this.#DigitMinus = '-';
		
		this.#DigitDot = '.';
		
		this.#DigitColon = ':';
		
		this.#DigitComma = ',';
	
		this.#DigitQuoteDouble = '"';
		
		this.#DigitSlash = '/';
		
		this.#DigitBackSlash = '\\';
		
		this.#DigitWhiteSpace = ' ';
		
		
		this.#DigitBackSpace = '\b';
		
		this.#DigitLineFeed = '\n';
		
		this.#DigitFormFeed = '\f';
		
		this.#DigitCarriageReturn = '\r';
		
		this.#DigitHorizontalTab = '\t';
		
		
		this.#DigitBracketFigureOpen = '{';
	
		this.#DigitBracketFigureClose = '}';
	
		this.#DigitBracketSquareOpen = '[';
	
		this.#DigitBracketSquareClose = ']';

		
		this.#DigitLetterLowerA = 'a';
	
		this.#DigitLetterLowerB = 'b';
		
		this.#DigitLetterLowerE = 'e';
		
		this.#DigitLetterLowerF = 'f';
		
		this.#DigitLetterLowerL = 'l';
		
		this.#DigitLetterLowerN = 'n';
		
		this.#DigitLetterLowerR = 'r';
		
		this.#DigitLetterLowerS = 's';
		
		this.#DigitLetterLowerT = 't';
		
		this.#DigitLetterLowerU = 'u';
		
		
		this.#DigitLetterUpperA = 'A';
		
		this.#DigitLetterUpperE = 'E';
		
		this.#DigitLetterUpperF = 'F';
		
		
		this.#DigitWhitespaces = [ 
			this.#DigitWhiteSpace, 
			this.#DigitCarriageReturn, 
			this.#DigitLineFeed, 
			this.#DigitHorizontalTab
		];
		
		this.#DigitExponentPrefixes = [ 
			this.#DigitLetterLowerE, 
			this.#DigitLetterUpperE
		];
		
		
		this.#Data = null;
		
		this.#Buffer = [ ];
	
		this.#Offset = 0;
	
	}
	
	#BufferWrite( Char ){
	    
	    this.#Buffer.push( Char );
	    
	}
	
	#BufferDrain( ){
		
		let Result = this.#Buffer.join( "" );
		
		this.#Buffer.splice( 0, this.#Buffer.length );
		
		return Result;
		
	}
	
	#SkipToNext( ){
		
		this.#Offset = this.#Offset + 1;
		
	}
	
	#SkipWhitespaces( ){
		
		while( this.#IsNextIn( this.#DigitWhitespaces ) === true ){
			
			this.#SkipToNext( );
			
		}
		
	}
	
	#IsNextIs(
		Char
	){
		
		return ( this.#Data.at( this.#Offset ) === Char );
		
	}
	
	#IsNextIn(
		Chars
	){
		
		return ( Chars.indexOf( this.#Data.at( this.#Offset ) ) !== -1 );
		
	}
	
	#IsNextDigit(
	
	){
		
		return (
			( this.#Data.at( this.#Offset ) >= this.#DigitMin ) &&
			( this.#Data.at( this.#Offset ) <= this.#DigitMax )
		);
		
	}
	
	#IsDataDrain( ){
		
		return ( this.#Offset >= this.#Data.length );
		
	}
	
	#IsBufferDrain( ){
		
		return ( this.#Buffer.length === 0 );
		
	}
	
	#EnsureNextIs(
		Char
	){
		
		if( this.#IsNextIs( Char ) === false ){
			
			throw new JSONBNParserErrorT( "Invalid char at: " + this.#Offset );
			
		}
		
	}
	
	#EnsureNextIsNot(
		Char
	){
		
		if( this.#IsNextIs( Char ) === true ){
			
			throw new JSONBNParserErrorT( "Invalid char at: " + this.#Offset );
			
		}
		
	}
	
	#EnsureNextIn(
		Chars
	){
		
		if( this.#IsNextIn( Chars ) === false ){
			
			throw new JSONBNParserErrorT( "Invalid char at: " + this.#Offset );
			
		}
		
	}
	
	#EnsureDigit( ){
		
		if( this.#IsNextDigit( ) === false ){
			
			throw new JSONBNParserErrorT( "Invalid char at: " + this.#Offset );
			
		}
		
	}
	
	#EnsureData( ){
		
		if( this.#IsDataDrain( ) === true ){
			
			throw new JSONBNParserErrorT( "Unexpected end" );
			
		}
		
	}
	
	#EnsureBuffer(
	
	){
		
		if( this.#IsBufferDrain( ) === true ){
			
			throw new JSONBNParserErrorT( "Buffer is drain" );
			
		}
		
	}
	
	#EnsureClean(
	
	){
		
		this.#Data = null;
		
		this.#Offset = 0;
		
		this.#Buffer.splice( 0, this.#Buffer.length );
		
	}
	
	#ToNextPush( Char ){
		
		this.#BufferWrite( Char );
		
		this.#Offset = this.#Offset + 1;
		
	}
	
	#ToNext( ){
			
		this.#BufferWrite( this.#Data.at( this.#Offset ) );
		
		this.#Offset = this.#Offset + 1;
			
	}
	
	#EnsureExponent(

	){
		
		this.#EnsureData( );
		
		
		this.#EnsureNextIn( this.#DigitExponentPrefixes );
		
		
		this.#ToNext( );
		
		
		this.#EnsureData( );
		
		
		if( this.#IsNextIs( this.#DigitMinus ) === true ){
			
			this.#ToNext( );
			
		}
		
		
		this.#EnsureData( );
		
		
		this.#EnsureDigit( );
		
		
		this.#ToNext( );
		
		
		while( ( this.#IsDataDrain( ) === false ) && ( this.#IsNextDigit( ) === true ) ){
			
			this.#ToNext( );
			
		}
		
	}
	
	#EnsureFraction(
	
	){
		
		this.#EnsureData( );
		
		this.#EnsureNextIs( this.#DigitDot );
		
		this.#ToNext( );
		
		
		this.#EnsureData( );
		
		this.#EnsureDigit( );
		
		this.#ToNext( );
		
		
		while( ( this.#IsDataDrain( ) === false ) && ( this.#IsNextDigit( ) === true ) ){
			
			this.#ToNext( );
			
		}
		
	}
	
	#EnsureDecimal( ){
		
		if( this.#IsNextIs( this.#DigitMinus ) === true ){
			
			this.#ToNext( );
			
		}
		
		
		this.#EnsureDigit( );
		
		this.#ToNext( );
		
		
		while( ( this.#IsDataDrain( ) === false ) && ( this.#IsNextDigit( ) === true ) ){
			
			this.#ToNext( );
			
		}
		
	}
	
	#ParseDouble(
		
	){
		
		this.#EnsureBuffer( );
		
		let Data = this.#BufferDrain( );
		
		return parseFloat( Data );
		
	}
	
	#ParseInteger( ){
		
		this.#EnsureBuffer( );
		
		let Data = this.#BufferDrain( );
		
		return BigInt( Data );
		
	}
	
	#ParseNumber( ){
		
		let IsDouble = false;
		
		
		this.#EnsureDecimal( );
		
		
		if( this.#IsNextIs( this.#DigitDot ) === true ){
			
			this.#EnsureFraction( );
			
			IsDouble = true;
			
		}
		
		if( this.#IsNextIn( this.#DigitExponentPrefixes ) === true ){
		    
			this.#EnsureExponent( );
			
			IsDouble = true;
			
		}
		
		
		if( IsDouble === true ){
		
			return this.#ParseDouble( );
		
		} else {
			
			return this.#ParseInteger( );
			
		}
		
	}
	
	#ParseNull(

	){
		
		this.#EnsureNextIs( this.#DigitLetterLowerN );
		
		this.#ToNext( );
		
		this.#EnsureNextIs( this.#DigitLetterLowerU );
		
		this.#ToNext( );
		
		this.#EnsureNextIs( this.#DigitLetterLowerL );
		
		this.#ToNext( );
		
		this.#EnsureNextIs( this.#DigitLetterLowerL );
		
		this.#ToNext( );


		this.#EnsureBuffer( );
		
		this.#BufferDrain( );
		
		
		return null;

	}
	
	#ParseBoolean(

	){
		
		if( this.#IsNextIs( this.#DigitLetterLowerT ) === true ) {
			
			this.#ToNext( );
			
			
			this.#EnsureNextIs( this.#DigitLetterLowerR );
			
			this.#ToNext( );
			
			this.#EnsureNextIs( this.#DigitLetterLowerU );
			
			this.#ToNext( );
			
			this.#EnsureNextIs( this.#DigitLetterLowerE );
			
			this.#ToNext( );
			
			
			this.#BufferDrain( );
			
			
			return true;
			
		} else if( this.#IsNextIs( this.#DigitLetterLowerF ) === true ){
			
			
			this.#ToNext( );
			
			
			this.#EnsureNextIs( this.#DigitLetterLowerA );
			
			this.#ToNext( );
			
			this.#EnsureNextIs( this.#DigitLetterLowerL );
			
			this.#ToNext( );
			
			this.#EnsureNextIs( this.#DigitLetterLowerS );
			
			this.#ToNext( );
			
			this.#EnsureNextIs( this.#DigitLetterLowerE );
			
			this.#ToNext( );
			
			
			this.#BufferDrain( );
			
			
			return false;
			
		} else {
			
			throw new JSONBNParserErrorT( "Invalid char at: " + this.#Offset );
			
		}
		
	}
	
	#IsNextHexDecDigit(
	
	){
		
		return this.#IsNextDigit( );
		
	}
	
	#IsNextHexDecLetter(
		
	){
		
		return (
			(
				( this.#Data.at( this.#Offset ) >= this.#DigitLetterLowerA ) &&
				( this.#Data.at( this.#Offset ) <= this.#DigitLetterLowerF )
			)
			||
			(
				( this.#Data.at( this.#Offset ) >= this.#DigitLetterUpperA ) &&
				( this.#Data.at( this.#Offset ) <= this.#DigitLetterUpperF )
			)
		);
		
	}
	
	#IsNextHexDec(
	
	){
			
		return ( ( this.#IsNextHexDecDigit( ) === true ) || ( this.#IsNextHexDecLetter( ) === true ) );
			
	}
	
	#EnsureHexDec(
	
	){
		
		if( this.#IsNextHexDec( ) === false ){
			
			throw new JSONBNParserErrorT( "Invalid char at: " + this.#Offset );
			
		}
		
	}
	
	#ToNextTo( BufferRef ){
		
		BufferRef.push( this.#Data.at( this.#Offset ) );
				
		this.#SkipToNext( );
		
	}
	
	#EnsureUnicodeChar( 
	
	){
		
		let Buffer = [ ];
		
		this.#EnsureHexDec( );
				
		this.#ToNextTo( Buffer );
		
		this.#EnsureHexDec( );
		
		this.#ToNextTo( Buffer );
		
		this.#EnsureHexDec( );
		
		this.#ToNextTo( Buffer );
		
		this.#EnsureHexDec( );
		
		this.#ToNextTo( Buffer );
		
		
		let Data = Buffer.join( "" );
				
		let CharCode = parseInt( "0x" + Data );
		
		let Char = String.fromCharCode( CharCode );
		
		this.#BufferWrite( Char );
		
	}
	
	#EnsureNextChar(
	
	){
		
		if( this.#IsNextIs( this.#DigitBackSlash ) === true ){
				
			this.#SkipToNext( );
			
			if( this.#IsNextIs( this.#DigitBackSlash ) === true ){
				
				this.#ToNextPush( this.#DigitBackSlash );
				
			} else if( this.#IsNextIs( this.#DigitQuoteDouble ) === true ){
				
				this.#ToNextPush( this.#DigitQuoteDouble );
				
			} else if( this.#IsNextIs( this.#DigitSlash ) === true ){
				
				this.#ToNextPush( this.#DigitSlash );
				
			} else if( this.#IsNextIs( this.#DigitLetterLowerB ) === true ){
				
				this.#ToNextPush( this.#DigitBackSpace );
				
			} else if( this.#IsNextIs( this.#DigitLetterLowerF ) === true ){
				
				this.#ToNextPush( this.#DigitFormFeed );
				
			} else if( this.#IsNextIs( this.#DigitLetterLowerN ) === true ){
				
				this.#ToNextPush( this.#DigitLineFeed );
				
			} else if( this.#IsNextIs( this.#DigitLetterLowerR ) === true ){
				
				this.#ToNextPush( this.#DigitCarriageReturn );
				
			} else if( this.#IsNextIs( this.#DigitLetterLowerT ) === true ){
				
				this.#ToNextPush( this.#DigitHorizontalTab );
				
			} else if( this.#IsNextIs( this.#DigitLetterLowerU ) === true ){
				
				this.#SkipToNext( );
				
				this.#EnsureUnicodeChar( );
				
			}
			
		} else {
			
			this.#ToNext( );
			
		}
		
	}
	
	#ParseString(
		
	){
		
		this.#EnsureNextIs( this.#DigitQuoteDouble );
		
		this.#SkipToNext( );
		
		
		while( ( this.#IsDataDrain( ) === false ) && ( this.#IsNextIs( this.#DigitQuoteDouble ) === false ) ){
			
			this.#EnsureNextChar( );
			
		}
		
		
		this.#EnsureNextIs( this.#DigitQuoteDouble );
		
		this.#SkipToNext( );
		
		
		return this.#BufferDrain( );
		
	}
	
	#ParseArray(
		
	){
		
		this.#EnsureNextIs( this.#DigitBracketSquareOpen );
		
		this.#SkipToNext( );
		
		this.#SkipWhitespaces( );
		
		
		let Result = [ ];
	
		
		while( ( this.#IsDataDrain( ) === false ) && ( this.#IsNextIs( this.#DigitBracketSquareClose ) === false ) ){
			
			let Element = this.#ParseElement( );
			
			Result.push( Element );
			
			this.#SkipWhitespaces( );
			
			
			if( this.#IsNextIs( this.#DigitComma ) === true ){
				
				this.#SkipToNext( );
				
				this.#SkipWhitespaces( );
				
				this.#EnsureNextIsNot( this.#DigitBracketSquareClose );
				
			}
			
		}
		
		
		this.#EnsureNextIs( this.#DigitBracketSquareClose );
		
		this.#SkipToNext( );
		
	
		return Result;
		
	}
	
	#ParseKey(
		
	){
		
		return this.#ParseString( );
		
	}
	
	#ParseValue( 
	
	){
		
		return this.#ParseElement( );
		
	}
	
	#ParsePair( 
		ResultRef 
	){
		
		let Key = this.#ParseKey( );
		
		
		this.#SkipWhitespaces( );
		
		this.#EnsureNextIs( this.#DigitColon );
		
		this.#SkipToNext( );
		
		this.#SkipWhitespaces( );
		
		
		let Value = this.#ParseValue( );
		
		
		ResultRef[ Key ] = Value;
		
	}
	
	
	#ParseObject(
		
	){
		
		
		this.#EnsureNextIs( this.#DigitBracketFigureOpen );
		
		this.#SkipToNext( );
		
		this.#SkipWhitespaces( );
		
		
		let Result = { };
	
		
		while( ( this.#IsDataDrain( ) === false ) && ( this.#IsNextIs( this.#DigitBracketFigureClose ) === false ) ){
			
			this.#ParsePair( Result );
			
			this.#SkipWhitespaces( );
			
			if( this.#IsNextIs( this.#DigitComma ) === true ){
				
				this.#SkipToNext( );
				
				this.#SkipWhitespaces( );
				
				this.#EnsureNextIsNot( this.#DigitBracketFigureClose );
				
			}
			
		}
		
		
		this.#EnsureNextIs( this.#DigitBracketFigureClose );
		
		this.#SkipToNext( );
		
	
		return Result;
	
	}

	#ParseElement(
		
	){
		
		this.#SkipWhitespaces( );
		
		
		let Result = null;
		
		
		if( this.#IsNextIs( this.#DigitBracketFigureOpen ) === true ){
			
			Result = this.#ParseObject( );
			
		} else if( this.#IsNextIs( this.#DigitBracketSquareOpen ) === true ){
			
			Result = this.#ParseArray( );
			
		} else if( this.#IsNextIs( this.#DigitQuoteDouble ) === true ){
			
			Result = this.#ParseString( );
			
		} else if(
			( this.#IsNextIs( this.#DigitMinus ) === true ) ||
			( this.#IsNextDigit( ) === true )
		){
			
			Result = this.#ParseNumber( );
			
		} else if(
			( this.#IsNextIs( this.#DigitLetterLowerT ) === true ) ||
			( this.#IsNextIs( this.#DigitLetterLowerF ) === true )
		){
			
			Result = this.#ParseBoolean( );
			
		} else if(
			( this.#IsNextIs( this.#DigitLetterLowerN ) === true )
		){
			
			Result = this.#ParseNull( );
			
		} else {
			
			throw new JSONBNParserErrorT( "Invalid data at: " + this.#Offset );
			
		}
		
		
		this.#SkipWhitespaces( );
		
		
		return Result;
	
	}
	
	Parse(
		Data
	){
		
		this.#EnsureClean( );
		
		
		this.#Data = Data;
		
		
		let Result = this.#ParseElement( );
		
		
		if( this.#Offset !== this.#Data.length ){
			
			throw new JSONBNParserErrorT( "Invalid data" );
			
		}
		
		
		this.#EnsureClean( );
		
		
		return Result;
		
	}

};


export default JSONBNParserT;