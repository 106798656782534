"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIButtonReactT from "../../../../Button/UIButtonReactT.mjs";

import UISVGT from "../../../../SVG/UISVGT.mjs";

import SVGOpenT from "../../../../../../Type/SVG/SVGOpenT.mjs";


const UIGridRendererButtonOpenT = class UIGridRendererButtonOpenT extends React.Component {
	
	#Ref = null;
	
	constructor( props ){
		
		console.log( "UIGridRendererButtonOpenT.constructor", props );
		
		
		super( props );
		
		
		this.#Ref = React.createRef( );
		
		
		console.log( props );
		
		this.state = {
			SX: ( props.SX ) ? props.SX : { },
			Color: ( props.Color ) ? props.Color : "success",
			Disabled: ( props.Disabled ) ? props.Disabled : false,
			Variant: ( ( props.Variant ) ? props.Variant : "outlined" ),
			FullWidth: ( props.FullWidth ) ? props.FullWidth : false,
			OnClick: ( props.OnClick ) ? props.OnClick : null,
			data: ( props.data ) ? props.data : null,
			node : ( props.node ) ? props.node : null,
			value: ( props.value ) ? props.value : [ ]
		};
		
	}
	
	ChildGet( Index ){
		
		return this.state.Children[ Index ];
		
	}
	
	DisabledSet( Value ){
		
		console.log( "UIGridRendererButtonOpenT.DisabledSet", Value );
		
		this.#Ref.current.DisabledSet( Value );
		
	}
	
	OnClick( ){
		
		this.state.OnClick( this, this.state.data, this.state.node );
		
	}
	
	render( ){
		
		console.log( "UIGridRendererButtonOpenT.render", this.state );
		
		
		return React.createElement(
			UIButtonReactT,
			{
				ref: this.#Ref,
				SX: this.state.SX,
				Color: this.state.Color,
				Disabled: this.state.Disabled,
				FullWidth: this.state.FullWidth,
				Variant: this.state.Variant,
				OnClick: this.OnClick.bind( this ),
				Children: [
					new UISVGT(
						{
							key: "button_open",
							SX: { },
							ViewBox: "0 0 22 22",
							Data: SVGOpenT.Data,
							Fill: ( this.state.value.length !== 0 ) ? "#4caf50" : "#5664d2"
						}
					)
				]
			}
		);
		
	}
	
};


export default UIGridRendererButtonOpenT;