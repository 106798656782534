import UrlService from "../../services/UrlService";

function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

export default function httpPost(data,
                                 url,
                                 handleResult,
                                 handleUnsuccessful = (message, response) => {
                                 alert(message);
                                 console.log("handleUnsuccessful: ", response);
                             },
                                 handleError = (error) => {
                                 alert("Chyba serveru");
                                 console.error("handleError: ", error);
                             }) {
								 
	const at = localStorage.getItem("accessToken");

    let form_data = new FormData();
    if (!Array.isArray(data)) {
        for (let key in data) {
            if (data.key.endsWith("[]")) {
                if (isIterable(data.value)) {
                    for (const file of data.value) {
                        form_data.append(data.key, file, file.name);
                    }
                } else {
                    form_data.append(data.key, data.value, data.value.name);
                }
            } else {
                form_data.append(key, data[key]);
            }
        }
    } else {
        for (const field of data) {
            let key = field.key ?? field.name;
            if (key.endsWith("[]")) {
                if (isIterable(field.value)) {
                    for (const file of field.value) {
                        form_data.append(key, file, file.name);
                    }
                } else {
                    form_data.append(key, field.value, field.value.name);
                }
            } else {
                form_data.append(key, field.value);
            }
        }
    }
    fetch(UrlService.apiDomain() + 'api/' + url, {
        method: 'post',
        body: form_data,
        headers: {
            Authorization: "Bearer " + at,
        },
    })
        .then(r => r.json())
        .then(response => {
            if (!response.success) {
                handleUnsuccessful(response.error, response);
                return;
            }
            handleResult(response);
        })
        .catch((error) => {
            handleError(error);
        });
}