"use strict";


const EnvMechAbstractT = class EnvMechAbstractT {

	constructor( ){
		
	}
	
	URL( ){
		
		throw new Error( "Abstract" );
		
	}

};


export default EnvMechAbstractT;