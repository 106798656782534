"use strict";


import HTTPClientAbstractT from "../../../../Interface/Network/HTTP/Client/_/HTTPClientAbstractT.mjs";

import JSONRPCClientAbstractT from "../../../../Procedure/JSONRPC/Client/_/JSONRPCClientAbstractT.mjs";

import JSONBNT from "../../../../Procedure/JSONBN/JSONBNT.mjs";

import HTTPHeadersT from "../../../../Type/HTTP/Header/HTTPHeadersT.mjs";

import JSONRPCRequestT from "../../../../Type/JSONRPC/JSONRPCRequestT.mjs";

import JSONRPCResponseT from "../../../../Type/JSONRPC/JSONRPCResponseT.mjs";



const JSONRPCClientT = class JSONRPCClientT extends JSONRPCClientAbstractT {
	
	#HTTPClient = null;
	
	#JSONBN = null;
	
	#Ids = null;
	
	#LastIds = null;
	
	#URL = null;
	
	#TextEncoder = null;
	
	#TextDecoder = null;
	
	constructor(
		HTTPClient,
		URL
	){
		
		if( ( HTTPClient instanceof HTTPClientAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( URL ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super( );
		
		
		this.#HTTPClient = HTTPClient;
		
		this.#JSONBN = new JSONBNT( );
		
		this.#Ids = new Map( );
		
		this.#LastIds = new Map( );
		
		this.#URL = URL;
		
		this.#TextEncoder = new TextEncoder( );
		
		this.#TextDecoder = new TextDecoder( );
		
	}
	
	#NextId( Method ){
		
		let Id = 0n;
		
		if( this.#Ids.has( Method ) === true ){
			
			Id = ( this.#Ids.get( Method ) + 1n );
			
			this.#Ids.set( Method, Id );
			
		} else {
			
			Id = 1n;
			
			this.#Ids.set( Method, Id );
			
		}
		
		return Id;
		
	}
	
	Request(
		JSONRPCRequest,
		Headers,
		
		SuccessCb,
		ErrorCb
	){
		
		console.log( "JSONRPCClientT.Request", JSONRPCRequest );
		
		
		if( ( JSONRPCRequest instanceof JSONRPCRequestT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( Headers instanceof HTTPHeadersT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		let Method = JSONRPCRequest.Method( );
		
		let Params = JSONRPCRequest.Params( );
		
		
		let JSONRPCDataRequest = {
			"jsonrpc": "2.0",
			"method": Method,
			"params": Params
		};
		
		
		if( JSONRPCRequest.IsNotification( ) === false ){
			
			JSONRPCDataRequest[ "id" ] = this.#NextId( Method );
			
		}
		
		
		let JSONData = this.#JSONBN.Serialize(
			JSONRPCDataRequest
		);
		
		
		this.#HTTPClient.Request(
			"POST",
			this.#URL,
			Headers,
			this.#TextEncoder.encode( JSONData ),
			
			function( Status, StatusMessage, Headers, Body ){
				
				console.log( "JSONRPCClientT.Request", "HTTP", Status, StatusMessage, Headers, Body );
				
				
				if( Status !== 200 ){
					
					ErrorCb( new Error( "Error during request" ) );
					
					return;
					
				}
				
				
				let JSONRPCData = this.#JSONBN.Parse( this.#TextDecoder.decode( Body ) );
				
				if( ( JSONRPCData instanceof Object ) === false ){
					
					ErrorCb( new Error( "Invalid response format" ) );
					
					return;
					
				}
				
				
				if( JSONRPCRequest.IsNotification( ) === true ){
					
					SuccessCb( null );
					
					return;
					
				}
				
				
				let JSONRPCResponse = null;
				
				let Err = null;
				
				try {
						
					let IsOverdue = false;
					
					let Method = JSONRPCRequest[ "method" ];
					
					if( this.#LastIds.has( Method ) === true ){
						
						if( this.#LastIds.get( Method ) > JSONRPCData.id ){
							
							IsOverdue = true;
							
						}
						
					} else {
						
						this.#LastIds.set( Method, JSONRPCData.id );
						
					}
						
						
					JSONRPCResponse = new JSONRPCResponseT(
						( JSONRPCData.result ) ? true : false,
						JSONRPCData.result,
						JSONRPCData.error,
						IsOverdue
					);
				
				} catch( IErr ){
					
					Err = IErr;
					
				}
				
				
				if( Err !== null ){
					
					ErrorCb( Err );
					
				} else {
				
					SuccessCb(
						JSONRPCResponse
					);
				
				}
				
			}.bind( this ),
			function( ErrorData ){
				
				console.log( ErrorData );
				
				ErrorCb( ErrorData );
				
			}.bind( this )
		);
		
	}
	
	Batch(
		JSONRPCRequestArray,
		Headers,
		
		SuccessCb,
		ErrorCb
	){
		
		console.log( "JSONRPCClientT.Batch", JSONRPCRequestArray );
		
		
		if( ( JSONRPCRequestArray instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		for(
			let I = 0;
			I < JSONRPCRequestArray.length;
			I++
		){
			
			let JSONRPCRequest = JSONRPCRequestArray[ I ];
			
			if( ( JSONRPCRequest instanceof JSONRPCRequestT ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
		}
		
		if( ( Headers instanceof HTTPHeadersT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		
		let JSONRPCData = [ ];
		
		let JSONRPCRequests = [ ];
		
		for(
			let I = 0;
			I < JSONRPCRequestArray.length;
			I++
		){
			
			let JSONRPCRequest = JSONRPCRequestArray[ I ];
			
			let Method = JSONRPCRequest.Method( );
			
			let Params = JSONRPCRequest.Params( );
			
			let JSONRPCDataRequest = {
				
				"jsonrpc": "2.0",
				"method": Method,
				"params": Params,
			
			};
			
			if( JSONRPCRequest.IsNotification( ) === false ){
			
				JSONRPCDataRequest[ "id" ] = this.#NextId( Method );
			
			
				JSONRPCData.push( JSONRPCDataRequest );
				
				JSONRPCRequests.push( JSONRPCDataRequest );
				
			} else {
			
				JSONRPCData.push( JSONRPCDataRequest );
			
			}
			
		}
		
		
		let JSONData = this.#JSONBN.Serialize(
			JSONRPCData
		);
		
		
		this.#HTTPClient.Request(
			"POST",
			this.#URL,
			Headers,
			this.#TextEncoder.encode( JSONData ),
			
			function( Status, StatusMessage, Headers, Body ){
				
				console.log( "JSONRPCClientT.Batch", "HTTP", Status, StatusMessage, Headers, Body );
				
				if( Status !== 200 ){
					
					ErrorCb( new Error( "Error during request" ) );
					
					return;
					
				}
				
				let JSONRPCDataArray = this.#JSONBN.Parse( this.#TextDecoder.decode( Body ) );
				
				console.log( JSONRPCDataArray );
				
				if( ( JSONRPCDataArray instanceof Array ) === false ){
					
					ErrorCb( new Error( "Invalid response format" ) );
					
					return;
					
				}
				
				
				console.log( "JSONRPCClientT.Batch", JSONRPCDataArray );
				
				if( JSONRPCDataArray.length !== JSONRPCRequests.length ){
					
					ErrorCb( new Error( "Invalid response batch count" ) );
					
					return;
					
				}
				
				
				let JSONRPCResponses = [ ];
				
				let Err = null;
				
				try {
				
					for( 
						let I = 0;
						I < JSONRPCDataArray.length;
						I++
					){
						
						let JSONRPCData = JSONRPCDataArray[ I ];
						
						let IsOverdue = false;
						
						let Method = JSONRPCRequests[ I ][ "method" ];
						
						if( this.#LastIds.has( Method ) === true ){
							
							if( this.#LastIds.get( Method ) > JSONRPCData.id ){
								
								IsOverdue = true;
								
							}
							
						} else {
							
							this.#LastIds.set( Method, JSONRPCData.id );
							
						}
						
						
						let JSONRPCResponse = new JSONRPCResponseT(
							( JSONRPCData.result ) ? true : false,
							JSONRPCData.result,
							JSONRPCData.error,
							IsOverdue
						);
						
						JSONRPCResponses.push( JSONRPCResponse );
						
					}
				
				} catch( IErr ){
					
					Err = IErr;
					
				}
				
				if( Err !== null ){
					
					ErrorCb( Err );
					
				} else {
				
					SuccessCb(
						JSONRPCResponses
					);
				
				}
				
			}.bind( this ),
			function( ErrorData ){
				
				console.log( ErrorData );
				
				ErrorCb( ErrorData );
				
			}.bind( this )
		);
		
	}
	
};


export default JSONRPCClientT;