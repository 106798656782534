"use strict";


import UIGridRendererButtonT from "../UIGridRendererButtonReactT.mjs";

import UISVGT from "../../../../SVG/UISVGT.mjs";

import SVGTrashT from "../../../../../../Type/SVG/SVGTrashT.mjs";


const UIGridRendererButtonDeleteT = class UIGridRendererButtonDeleteT extends UIGridRendererButtonT {
	
	static defaultProps = {
		Variant: "outlined",
		Color: "success", //primary
		Children: [
			new UISVGT(
				{
					key: "button_delete", //TODO
					SX: { },
					ViewBox: "0 0 22 22",
					Data: SVGTrashT.Data,
					Fill: "#5664d2"
				}
			)
		]
	};
	
	#Ref = null;
	
	constructor( props ){
		
		console.log( "UIGridRendererButtonOpenT.constructor", props );
		

		let Id = props.data.id;
		
		super( {
			...props,
			OnClick: function( ){
				props.OnClick( Id )
			}
		} );
		
		
		
	}
	
	render( ){
		
		console.log( "UIGridRendererButtonOpenT.render", this.state );
		
		return super.render( );
		
	}
	
};


export default UIGridRendererButtonDeleteT;