"use strict";


import UIBooleanT from "./UIBooleanT.mjs";

import UIBooleanProducerAbstractT from "../../../Interface/UI/Boolean/_/UIBooleanProducerAbstractT.mjs";


const UIBooleanProducerT = class UIBooleanProducerT extends UIBooleanProducerAbstractT {
	
	constructor(
	
	) {
		
		console.log( "UIBooleanProducerT.constructor" );
		
		
		super( );
  
	}

	Produce( 
		...Props 
	) {
		
		console.log( "UIBooleanProducerT.Produce", Props );
		

		return new UIBooleanT( ...Props );
		
	}
	
};


export default UIBooleanProducerT;
