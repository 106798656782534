"use strict";


import UIGridT from "./UIGridT.mjs";

import UIGridProducerAbstractT from "../../../Interface/UI/Grid/_/UIGridProducerAbstractT.mjs";


const UIGridProducerT = class UIGridProducerT extends UIGridProducerAbstractT {
	
	constructor(
		
	){
		
		console.log( "UIGridProducerT.constructor" );
		
		
		super( );
		
	}
	
	Produce(
		...Props
	){
		
		console.log( "UIGridProducerT.Produce", Props );
		
		
		return new UIGridT(
			...Props
		);
		
	}
	
};


export default UIGridProducerT;