import { useState } from "react";
import {
  IconButton,
  TextField,
  Box,
  Typography,
  InputAdornment,
  Chip,
  Grid,
} from "@material-ui/core";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { Send, AttachFile } from "@material-ui/icons";

const SendMessage = () => {
  const { t } = useTranslation();
  const at = localStorage.getItem("accessToken");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleFileChange = (event) => {
    setFiles([...files, ...Array.from(event.target.files)]);
  };

  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((files) => files.filter((file) => file !== fileToDelete));
  };

  const handleSend = async () => {
    try {
      if (message || files.length > 0) {
        const formData = new FormData();
        formData.append("message", message);
        files.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });

        const res = await HttpService.post(
          UrlService.apiDomain() + "api/send_support_email",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + at,
            },
          }
        );
        if (res.status != 200) {
          toast.error(t("Něco se pokazilo!"));
          return;
        }
        setMessage("");
        setFiles([]);
        toast.success(t("Zpráva odeslána"));
      }
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  const handleKeyUp = async (event) => {
    try {
      if (event.key === "Enter" && (message || files.length > 0)) {
        const formData = new FormData();
        formData.append("message", message);
        files.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });

        const res = await HttpService.post(
          UrlService.apiDomain() + "api/send_support_email",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.status != 200) {
          toast.error(t("Něco se pokazilo!"));
          return;
        }
        setMessage("");
        setFiles([]);
        toast.success(t("Poznámka přidána"));
      }
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        p: 5,
      }}
    >
      <Typography variant="h6" align="center" sx={{ mb: 2 }}>
        {t("Technická podpora")}
      </Typography>
      <TextField
        autoFocus
        multiline
        rows="3"
        fullWidth
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        placeholder={t("Napište zprávu...")}
        value={message}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="icon-button-file"
                type="file"
                multiple
                onChange={handleFileChange}
              />
              <label htmlFor="icon-button-file">
                <IconButton color="primary" component="span">
                  <AttachFile />
                </IconButton>
              </label>
              <IconButton
                color="primary"
                disabled={!message && files.length === 0}
                onClick={handleSend}
              >
                <Send />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={1} sx={{ mt: 2 }}>
        {files.map((file, index) => (
          <Grid item key={index}>
            <Chip
              label={file.name}
              onDelete={handleDeleteFile(file)}
              color="primary"
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SendMessage;
