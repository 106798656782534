import ModalWithButton from "../modal/ModalWithButton";
import {useTranslation} from "react-i18next";
import {Button, TextField} from "@material-ui/core";
import {useState} from "react";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";
import PaymentDetailsModal from "../payment/company/PaymentDetailsModal";
import PaymentDetailsModalNew from "../payment/company/PaymentDetailsModalNew";
import ModalWrapper from "../modal/ModalWrapper";

export function ChangePaymentDetailsModal(){
	
    const {t} = useTranslation();
	
	const [isModalOpened, setIsModalOpened] = useState(false);

    return (
      <>
        <Button
          color="primary"
          type="button"
          sx={{ height: "100%", px: 2 }}
          onClick={() => setIsModalOpened(true)}
          fullWidth={true}
          variant={"outlined"}
        >
          {t("Změna platebních údaji")}
        </Button>
        <PaymentDetailsModalNew
          isEdit={true}
          isOpen={isModalOpened}
          onClose={() => setIsModalOpened(false)}
          on_update_success={() => setIsModalOpened(false)}
        />
      </>
    );
}