import { useState, useEffect } from "react";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@material-ui/core";
import SimpleLoader from "../../components/SimpleLoader.js";
import ContactForm from "./ContactForm";
import toast from "react-hot-toast";
const Contacts = ({ id, main }) => {
  const { t } = useTranslation();

  const [customerContacts, setCustomerContacts] = useState([]);
  const [loading, setLoading] = useState(id && true);
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).customer_contacts;

  const handleShowAddForm = () => {
    setIsAddFormVisible(true);
    setShowButton(false);
    setIsCreating(true);
  };

  const handleHideAddForm = () => {
    setIsAddFormVisible(false);
    setShowButton(true);
    setIsCreating(false);
  };

  const grd = () => {
    if (id) {
      HttpService.get(
        UrlService.apiDomain() + "api/customer_contacts/get/all/" + id
      )
        .then((res) => {
          setCustomerContacts(res.data.data ?? []);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    grd();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await HttpService.delete(
        `${UrlService.apiDomain()}api/customer_contacts/delete/${id}`
      );
      if (response.status != 200) {
        if (response.data.message) {
          alert(response.data.message);
        }
        toast.error(t("Něco se pokazilo!"));
        return;
      } else {
        toast.success(t("Záznam obnoven"));
        setCustomerContacts((prev) => prev.filter((item) => item.id != id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 1,
      }}
    >
      {loading ? (
        <SimpleLoader />
      ) : (
        <>
          {customerContacts
            .sort((a, b) => (a.id === main ? -1 : b.id === main ? 1 : 0))
            .map((contact) => (
              <ContactForm
                data={contact}
                key={contact.id}
                handleDelete={handleDelete}
                customer={id}
                main={main == contact.id}
              />
            ))}
          {isAddFormVisible && (
            <ContactForm
              grd={grd}
              handleDelete={handleDelete}
              handleCancel={handleHideAddForm}
              customer={id}
              isCreating={isCreating}
            />
          )}
          {showButton &&
            policy_actions.includes("add") && (
              <Button
                sx={{ mt: 1 }}
                color="primary"
                variant="contained"
                onClick={handleShowAddForm}
              >
                {t("Přidat kontakt")}
              </Button>
            )}
        </>
      )}
    </Box>
  );
};

export default Contacts;
