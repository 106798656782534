import { useState, useEffect } from "react";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import GridWrapper from "../../components/grid/gridWrapperNew";
import { useTranslation } from "react-i18next";
import KanbanCardModal from "../../components/dashboard/vacancies/KanbanCardModal";
import { getBoard } from "../../slices/vacancies";
import { useDispatch, useSelector } from "../../store";
import SimpleLoader from "../../components/SimpleLoader.js";
import KanbanCardAdd from "../../components/dashboard/vacancies/KanbanCardAdd.js";

const Details = ({ reportId, field, fieldLable, customer_id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [openCard, setOpenCard] = useState(false);
  const [card, setCard] = useState(null);
  const { cards, isLoaded } = useSelector((state) => state.vacancies);

  const handleCardClose = () => {
    setOpenCard(false);
  };

  const interceptClick = (e) => {
    let id = e.data.vacancy_id;
    setCard(cards.byId[id]);
    setOpenCard(true);
  };

  const columnDefs =
    field === "new_orders"
      ? [
          {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: "left",
            pinned: "left",
            maxWidth: 150,
            sortable: false,
            resizable: false,
            suppressMenu: true,
          },
          {
            headerName: t("Požadavek lidí"),
            field: "vacancy_name",
            cellStyle: { color: "#5664d2" },
            width: "100%",
          },
        ]
      : [
          {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            lockPosition: "left",
            pinned: "left",
            maxWidth: 150,
            sortable: false,
            resizable: false,
            suppressMenu: true,
          },
          {
            headerName: t("Příjmení"),
            field: "surname",
            cellStyle: { color: "#5664d2" },
            width: "50%",
          },
          {
            headerName: t("Jméno"),
            field: "name",
            width: "50%",
          },
        ];

  const getAdditionalData = async () => {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + "api/dailycoordinator_reports/names_list",
        {
          field,
          dailycoordinator_report_id: reportId,
        }
      );
      if (!response.data.success) {
        alert(response.data.message);
        return;
      }
      setData(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (field === "new_orders" && !isLoaded) {
      dispatch(getBoard());
    }
  }, [dispatch, data, isLoaded]);

  useEffect(() => {
    getAdditionalData();
  }, [field]);

  if (field === "new_orders" && !isLoaded) {
    return (
      <div style={{ height: "50vh" }}>
        <SimpleLoader />
      </div>
    );
  }
  return (
    <>
      {card && (
        <KanbanCardModal
          setCard={setCard}
          card={card}
          onClose={handleCardClose}
          open={openCard}
          column={{ id: customer_id }}
        />
      )}
      <GridWrapper
        needResize={false}
        data={data}
        colDefs={columnDefs}
        title={fieldLable}
        interceptClick={field === "new_orders" ? interceptClick : null}
      >
        {field === "new_orders" && <KanbanCardAdd columnId={customer_id} />}
      </GridWrapper>
    </>
  );
};
export default Details;
