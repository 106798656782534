"use strict";


const UIModalTopBarAbstractT = class UIModalTopBarAbstractT {
	
	constructor( ){
		
	}
	
	//TODO
	
};


export default UIModalTopBarAbstractT;