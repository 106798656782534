"use strict";


const UILabelAbstractT = class UILabelAbstractT {
	
	constructor( ){
		
	}
	
	PropSet(
		Name,
		Value
	){

		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	PropGet(
		Name
	){
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		throw new Error( "Abstract" );
		
	}
	
};


export default UILabelAbstractT;