import { Dialog, Button } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RepeatsTable from "./RepeatsTable";

const Repeats = ({ data }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog maxWidth="lg" fullWidth={true} onClose={handleClose} open={open}>
        <RepeatsTable data={data} />
      </Dialog>
      <Button
        sx={{ width: 1 }}
        variant="contained"
        onClick={handleOpen}
        disabled={!data}
      >{`${t("Počet opakování")} ${data ? "- " + data.length : ""}`}</Button>
    </>
  );
};

export default Repeats;
