"use strict";


import DBAbstractT from "../../Interface/DB/_/DBAbstractT.mjs";

import JSONBNT from "../../Procedure/JSONBN/JSONBNT.mjs";


const DBLocalStorageT = class DBLocalStorageT extends DBAbstractT {
	
	#JSONBN = null;
	
	constructor( 
		
	){
		
		super( );
		
		
		this.#JSONBN = new JSONBNT( );
		
	}
	
	Get( 
		Key 
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		let Data = localStorage.getItem( Key );
		
		if( Data === null ){
			
			return null;
			
		}
	
		return this.#JSONBN.Parse(  
			Data
		);
	
	}
	
};


export default DBLocalStorageT;