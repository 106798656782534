import { Autocomplete, TextField, ThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useFilter from "../../hooks/useFilter";
import { strComparator } from "../../services/getColumnDefinitions";
import { createTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { is } from "date-fns/locale";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ffff",
    },
    secondary: {
      main: "#fffff",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: "white",
          },

          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
              borderRadius: "6px",
              color: "white",
            },
            "& input": {
              color: "white",
            },
            "& button": {
              color: "white",
            },
          },
        },
      },
    },
  },
});

export default function FilterSelect() {
  const { t } = useTranslation();
  const filter = useFilter();
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [subProjects, setSubProjects] = useState([]);

  const [buildings, setBuildings] = useState([]);

  const [building, setBuilding] = useState();
  const [company, setCompany] = useState();

  const [project, setProject] = useState();
  const [subProject, setSubProject] = useState();

  const URLParts = window.location.pathname.split("/");

  const urls = [
    "received_invoices",
    "wire_transfers",
    "issued_invoices",
  ];
  const isFinance = URLParts.some((el) => urls.includes(el));

  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [month, setMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );

  useEffect(() => {
    if (!isFinance) {
      setYear(null);
      setMonth(null);
    } else {
      setYear(new Date().getFullYear().toString());
      setMonth((new Date().getMonth() + 1).toString().padStart(2, "0"));
    }
  }, [isFinance]);

  useEffect(() => {
    filter.setYearFilter(year);
    filter.setMonthFilter(month);
  }, [year, month]);

  useEffect(() => {
    let cFilter = localStorage.getItem("companyFilter");
    if (cFilter) {
      setCompany(JSON.parse(cFilter));
    }

    let pFilter = localStorage.getItem("projectFilter");
    if (pFilter) {
      setProject(JSON.parse(pFilter));
    }

    let spFilter = localStorage.getItem("subProjectFilter");
    if (spFilter) {
      setSubProject(JSON.parse(spFilter));
    }

    let bFilter = localStorage.getItem("buildingFilter");
    if (bFilter) {
      setBuilding(JSON.parse(bFilter));
    }
  }, []);

  useEffect(() => {
    if (company) {
      localStorage.setItem("companyFilter", JSON.stringify(company));
    } else {
      localStorage.removeItem("companyFilter");
    }
    if (project) {
      localStorage.setItem("projectFilter", JSON.stringify(project));
    } else {
      localStorage.removeItem("projectFilter");
    }

    if (subProject) {
      localStorage.setItem("subProjectFilter", JSON.stringify(subProject));
    } else {
      localStorage.removeItem("subProjectFilter");
    }
    if (building) {
      localStorage.setItem("buildingFilter", JSON.stringify(building));
    } else {
      localStorage.removeItem("buildingFilter");
    }
  }, [building, company, project, subProject]);

  useEffect(() => {
    filter.setCompanyFilter(company);
    let pronajimatel =
      JSON.parse(localStorage.getItem("companies")).find(
        (c) => c.id === company
      )?.pronajimatel ?? 1;
    filter.setPronajimatel(pronajimatel);
    setBuilding(null);
  }, [company]);

  useEffect(() => {
    filter.setBuildingFilter(building);
  }, [building]);

  useEffect(() => {
    filter.setProjectFilter(project);
  }, [project]);

  useEffect(() => {
    filter.setSubProjectFilter(subProject);
  }, [subProject]);

  useEffect(() => {
    if (filter.clearRequested) {
      setCompany(null);
      setProject(null);
      setBuilding(null);

      setSubProject(null);
      filter.cleared();
    }
  }, [filter]);

  useEffect(() => {
    let allowedProjects = filter.byAccess(
      JSON.parse(localStorage.getItem("projects")),
      "projects"
    );
    if (company) {
      const filteredProjects = allowedProjects.filter((project) => {
        const projectCompanyIds = project.company_ids
          ? project.company_ids.split("//")
          : null;
        return (
          projectCompanyIds && projectCompanyIds.includes(company.toString())
        );
      });
      setProjects(
        filteredProjects
          .map((b) => ({
            value: b.id,
            label: b.name,
          }))
          .sort((a, b) => strComparator(a.label, b.label))
      );
    } else {
      setProjects(
        allowedProjects
          .map((b) => ({
            value: b.id,
            label: b.name,
          }))
          .sort((a, b) => strComparator(a.label, b.label))
      );
    }
  }, [company]);

  useEffect(() => {
    let allowedCompanies = filter.byAccess(
      JSON.parse(localStorage.getItem("finance_agencies")),
      "companies"
    );
    let allowedProjects = filter.byAccess(
      JSON.parse(localStorage.getItem("projects")),
      "projects"
    );

    let subProjects = JSON.parse(localStorage.getItem("sub_projects"));

    if (project) {
      const proj = allowedProjects.filter((p) => p.id == project)[0];
      if (proj.company_ids) {
        const projectCompanyIds = proj.company_ids.split("//");
        const filteredCompanies = allowedCompanies.filter((company) =>
          projectCompanyIds.includes(String(company.id))
        );
        setCompanies(
          filteredCompanies
            .map((b) => {
              return {
                value: b.id,
                label: b.name,
              };
            })
            .sort((a, b) => strComparator(a.label, b.label))
        );
      }
      setSubProjects(
        subProjects
          .filter((sp) => sp.project_id == project)
          .map((b) => ({
            value: b.id,
            label: b.name,
          }))
          .sort((a, b) => strComparator(a.label, b.label))
      );
    } else {
      setCompanies(
        allowedCompanies
          .map((b) => {
            return {
              value: b.id,
              label: b.name,
            };
          })
          .sort((a, b) => strComparator(a.label, b.label))
      );
      setSubProjects(
        subProjects
          .map((b) => ({
            value: b.id,
            label: b.name,
          }))
          .sort((a, b) => strComparator(a.label, b.label))
      );
    }
  }, [project]);

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        sx={{ minWidth: "200px" }}
        size={"small"}
        value={companies.find((i) => i.value === company) ?? null}
        options={companies}
        onChange={(_, pair) => {
          setCompany(pair?.value);
        }}
        renderInput={(params) => <TextField {...params} label={t("Firma")} />}
      />
      <Autocomplete
        sx={{ minWidth: "200px", ml: "10px" }}
        size={"small"}
        value={projects.find((i) => i.value === project) ?? null}
        options={projects}
        onChange={(_, pair) => {
          setProject(pair?.value);
        }}
        renderInput={(params) => <TextField {...params} label={t("Projekt")} />}
      />
      <Autocomplete
        sx={{ minWidth: "200px", ml: "10px" }}
        size={"small"}
        value={subProjects.find((i) => i.value === subProject) ?? null}
        options={subProjects}
        onChange={(_, pair) => {
          setSubProject(pair?.value);
        }}
        renderInput={(params) => (
          <TextField {...params} label={t("Podprojekt")} />
        )}
      />
      {/* {filter.pronajimatel === 1 && (
        <Autocomplete
          sx={{ minWidth: "200px", ml: "10px" }}
          size={"small"}
          value={buildings.find((i) => i.value === building) ?? null}
          options={buildings}
          onChange={(_, pair) => {
            setBuilding(pair?.value);
          }}
          renderInput={(params) => <TextField {...params} label={"Budova"} />}
        />
      )} */}
      <Autocomplete
        sx={{ width: "120px", ml: "10px" }}
        size={"small"}
        value={year}
        options={["2022", "2023", "2024", "2025"]}
        onChange={(_, val) => {
          setYear(val);
        }}
        renderInput={(params) => <TextField {...params} label={t("Rok")} />}
      />
      <Autocomplete
        sx={{ minWidth: "120px", ml: "10px", mr: "20px" }}
        size={"small"}
        value={month}
        options={[
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ]}
        onChange={(_, val) => {
          setMonth(val);
        }}
        renderInput={(params) => <TextField {...params} label={t("Měsíc")} />}
      />
    </ThemeProvider>
  );
}
