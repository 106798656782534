import { Box, IconButton, Popover } from "@mui/material";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import { useState } from "react";

const PhonePopover = ({ display }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2 }}>
          <a
            style={{
              display: "block",
              textDecoration: "none",
              color: "black",
            }}
            href="tel:+420 800 128 128"
          >
            +420 800 128 128 (CZ)
          </a>
          <a
            style={{
              display: "block",
              textDecoration: "none",
              color: "black",
            }}
            href="tel:+420 601 128 128"
          >
            +420 601 128 128 (UA)
          </a>
        </Box>
      </Popover>
      <IconButton sx={{ display }} onClick={handleClick}>
        <PhoneEnabledIcon fontSize={"medium"} sx={{ color: "white" }} />
      </IconButton>
    </>
  );
};

export default PhonePopover;
