import { useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { createCard } from "../../../slices/vacancies";
import { useDispatch } from "../../../store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MobileDatePicker } from "@material-ui/lab";
import { format } from "date-fns";
import { useContext } from "react";
import { DataContext } from "../../../contexts/DataContext";
import { useTranslation } from "react-i18next";

const KanbanCardAdd = (props) => {
  const { t } = useTranslation();
  const { onStateChange = () => {}, columnId = "", ...other } = props;
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const { data: contextData } = useContext(DataContext);
  const options =
    contextData?.professions?.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    ) || [];
  const customers =
    contextData?.customers?.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    ) || [];
  const languages =
    contextData?.languages?.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    ) || [];
  const citizenship =
    contextData?.countries?.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    ) || [];

  const sex = [
    { id: "1", name: "M" },
    { id: "2", name: "Ž" },
  ];

  const driverLicenceGroups = [
    { id: "1", name: "A" },
    { id: "2", name: "B" },
    { id: "3", name: "C" },
    { id: "5", name: "D" },
    { id: "6", name: "BE" },
    { id: "7", name: "CE" },
    { id: "8", name: "DE" },
    { id: "9", name: "Žádná" },
    { id: "10", name: "T" },
  ];

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies;

  const handleClose = () => {
    setIsExpanded(false);
  };

  const formik = useFormik({
    initialValues: {
      customerId: columnId,
      name: "",
      description: "",
      amount: "",
      startDate: new Date(),
      interviewDate: new Date(),
      profession: "",
      salary: "",
      bonus: "",
      shift: "",
      citizenship: [],
      sex: [],
      age: "",
      languages: [],
      driver_license_group: [],
      forklift_license: false,
      welding_license: false,
    },
    validationSchema: Yup.object({
      customerId: Yup.string().required(t("Povinné")),
      name: Yup.string().required(t("Povinné")),
      description: Yup.string(),
      amount: Yup.number().required(t("Je vyžadována alespoň jedna osoba")),
      startDate: Yup.date().required(t("Povinné")),
      interviewDate: Yup.date().required(t("Povinné")),
      profession: Yup.string(),
      salary: Yup.string(),
      bonus: Yup.string(),
      shift: Yup.string(),
      citizenship: Yup.array(),
      sex: Yup.array(),
      age: Yup.string(),
      languages: Yup.array(),
      driver_license_group: Yup.array(),
      forklift_license: Yup.boolean(),
      welding_license: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      let columnId = values.customerId;
      try {
        await dispatch(createCard({ columnId, values }));
        setIsExpanded(false);
        onStateChange(false);
        formik.handleReset();
        toast.success(t("Záznam vtvořen!"));
      } catch (err) {
        console.error(err);
        toast.error(t("Něco se pokazilo!"));
      }
    },
  });

  const handleAddInit = () => {
    setIsExpanded(true);
    onStateChange(true);
  };

  const handleAddCancel = () => {
    setIsExpanded(false);
    onStateChange(false);
    formik.handleReset();
  };

  if (!policy_actions.includes("add")) {
    return null;
  }

  return (
    <div {...other}>
      <Dialog
        open={isExpanded}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{ sx: { padding: 4 } }}
      >
        <DialogTitle>{t("Přidat pozici")}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {!columnId && (
              <Grid item xs={12} lg={6}>
                <Autocomplete
                  id={`customer`}
                  name={`customer`}
                  options={customers}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    formik.setFieldValue(`customerId`, value?.id);
                  }}
                  onBlur={formik.handleBlur}
                  value={
                    customers.find(
                      (cust) => cust.id === formik.values.customerId
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Uživatel")}
                      error={
                        !!(
                          formik.touched.customerId && formik.errors.customerId
                        )
                      }
                      helperText={
                        formik.touched.customerId && formik.errors.customerId
                      }
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <TextField
                name="name"
                label={t("Název")}
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={!!(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                name="description"
                label={t("Popis")}
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                error={
                  !!(formik.touched.description && formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                name="amount"
                label={t("Počet lidí")}
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                error={!!(formik.touched.amount && formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                id={`profession`}
                name={`profession`}
                options={options}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  formik.setFieldValue(`profession`, value?.id);
                }}
                onBlur={formik.handleBlur}
                value={
                  options.find(
                    (prof) => prof.id === formik.values.profession
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Pracovní zkušenosti")}
                    error={
                      !!(formik.touched.profession && formik.errors.profession)
                    }
                    helperText={
                      formik.touched.profession && formik.errors.profession
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                name="salary"
                label={t("Základní mzda")}
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.salary}
                error={!!(formik.touched.salary && formik.errors.salary)}
                helperText={formik.touched.salary && formik.errors.salary}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                name="bonus"
                label={t("Bonusy")}
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bonus}
                error={!!(formik.touched.bonus && formik.errors.bonus)}
                helperText={formik.touched.bonus && formik.errors.bonus}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                name="shift"
                label={t("Směny")}
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.shift}
                error={!!(formik.touched.shift && formik.errors.shift)}
                helperText={formik.touched.shift && formik.errors.shift}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                name={"citizenship"}
                options={citizenship}
                getOptionLabel={(option) => option.name}
                fullWidth
                multiple
                onChange={(e, value) => {
                  formik.setFieldValue(
                    `citizenship`,
                    value.map((val) => val.id.toString())
                  );
                }}
                onBlur={formik.handleBlur}
                value={
                  citizenship.filter((item) =>
                    formik.values.citizenship.includes(item.id.toString())
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Statni občanství")}
                    error={
                      !!(
                        formik.touched.citizenship && formik.errors.citizenship
                      )
                    }
                    helperText={
                      formik.touched.citizenship && formik.errors.citizenship
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                name={"sex"}
                options={sex}
                getOptionLabel={(option) => option.name}
                fullWidth
                multiple
                onChange={(e, value) => {
                  formik.setFieldValue(
                    `sex`,
                    value.map((val) => val.id.toString())
                  );
                }}
                onBlur={formik.handleBlur}
                value={
                  sex.filter((item) =>
                    formik.values.sex.includes(item.id.toString())
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Pohlaví")}
                    error={!!(formik.touched.sex && formik.errors.sex)}
                    helperText={formik.touched.sex && formik.errors.sex}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                name="age"
                label={t("Maximální věk")}
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.age}
                error={!!(formik.touched.age && formik.errors.age)}
                helperText={formik.touched.age && formik.errors.age}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                name={"languages"}
                options={languages}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                fullWidth
                multiple
                onChange={(e, value) => {
                  formik.setFieldValue(
                    `languages`,
                    value.map((val) => val.id.toString())
                  );
                }}
                onBlur={formik.handleBlur}
                value={
                  languages.filter((item) =>
                    formik.values.languages.includes(item.id.toString())
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Znalost jazyku")}
                    error={
                      !!(formik.touched.languages && formik.errors.languages)
                    }
                    helperText={
                      formik.touched.languages && formik.errors.languages
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                name={"driver_licince"}
                options={driverLicenceGroups}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                fullWidth
                multiple
                onChange={(e, value) => {
                  formik.setFieldValue(
                    `driver_license_group`,
                    value.map((val) => val.id.toString())
                  );
                }}
                onBlur={formik.handleBlur}
                value={
                  driverLicenceGroups.filter((item) =>
                    formik.values.driver_license_group.includes(
                      item.id.toString()
                    )
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Řidičský průkaz")}
                    error={
                      !!(
                        formik.touched.driver_license_group &&
                        formik.errors.driver_license_group
                      )
                    }
                    helperText={
                      formik.touched.driver_license_group &&
                      formik.errors.driver_license_group
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <FormControlLabel
                  label={t("VZV")}
                  control={
                    <Checkbox
                      id="forklift_license"
                      name="forklift_license"
                      type="checkbox"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.forklift_license}
                    />
                  }
                />
                <FormControlLabel
                  label={t("Svářečský průkaz")}
                  control={
                    <Checkbox
                      id="welding_license"
                      name="welding_license"
                      type="checkbox"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.welding_license}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MobileDatePicker
                label={t("Datum nástupu")}
                inputFormat="dd.MM.yyyy"
                value={formik.values.startDate}
                onChange={(value) => {
                  formik.setFieldValue(
                    "startDate",
                    format(value, "yyyy-MM-dd")
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <MobileDatePicker
                label={t("Pohovor")}
                inputFormat="dd.MM.yyyy"
                value={formik.values.interviewDate}
                onChange={(value) => {
                  formik.setFieldValue(
                    "interviewDate",
                    format(value, "yyyy-MM-dd")
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button color="primary" onClick={handleAddCancel} variant="text">
              {t("Zrušení")}
            </Button>
            <Button color="primary" type="submit" variant="contained">
              {t("Přidat")}
            </Button>
          </Box>
        </form>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button color="primary" onClick={handleAddInit} variant="contained">
          {t("Přidat pozici")}
        </Button>
      </Box>
    </div>
  );
};

KanbanCardAdd.propTypes = {
  columnId: PropTypes.string,
};

export default KanbanCardAdd;
