"use strict";


import UIPageContentTopBarT from "./UIPageContentTopBarT.mjs";

import UIPageContentTopBarProducerAbstractT from "../../../../../Interface/UI/Page/Content/TopBar/_/UIPageContentTopBarProducerAbstractT.mjs";


const UIPageContentTopBarProducerT = class UIPageContentTopBarProducerT extends UIPageContentTopBarProducerAbstractT {
	
	constructor(
		
	){
		
		console.log( "UIPageContentTopBarProducerT.constructor" );
	
	
		super( );
		
	}
	
	Produce(
		...Props
	){
		
		console.log( "UIPageContentTopBarProducerT.Produce" );
		
		
		return new UIPageContentTopBarT(
			...Props
		);
		
	}
	
};


export default UIPageContentTopBarProducerT;