import ChildComment from "./ChildComment";
import AddComment from "./AddComment";
import { format } from "date-fns";
import {
  Avatar,
  Box,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import TrashIcon from "../../../icons/Trash";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { compareDesc } from "date-fns";
import { useState } from "react";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import { Close } from "@material-ui/icons";

const CustomerComment = ({
  onDelete,
  comment,
  allCommentators,
  childComments,
  allComments,
  grd,
  customer,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { created_at, created_by, message, id, deleted, personal } = comment;
  const creator = allCommentators[created_by];
  const [openAdd, setOpenAdd] = useState(false);

  const visibleDeleteIcon = user.id == created_by ? "visible" : "hidden";
  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).customer_comments;

  if (personal && user.id != created_by) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{ mr: 2, width: 70, height: 70, borderRadius: "50%" }}
        />
        <Box
          sx={{
            ml: 2,
            flexGrow: 1,
          }}
        >
          <Typography color="textPrimary" variant="subtitle2">
            {`${creator?.name} ${creator?.surname}`}
          </Typography>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: 1,
            }}
          >
            <Paper
              sx={{
                width: 1,
                backgroundColor: "background.default",
                mt: 1,
                p: 2,
              }}
              variant="outlined"
            >
              <Typography color="textPrimary" variant="body2">
                {deleted ? t("Komentář smazán autorem") : message}
              </Typography>
            </Paper>
            <Box sx={{ display: "flex", gap: 3 }}>
              {!openAdd && policy_actions.includes("add") && (
                <IconButton
                  color="primary"
                  title={t("Odpovědět")}
                  onClick={() => setOpenAdd(true)}
                >
                  <SubdirectoryArrowLeftIcon />
                </IconButton>
              )}
              {openAdd && (
                <IconButton
                  title={t("Zrušit")}
                  onClick={() => setOpenAdd(false)}
                >
                  <Close />
                </IconButton>
              )}
              {!deleted &&
                policy_actions.includes("delete") && (
                  <IconButton
                    title={t("Smazat")}
                    onClick={() => onDelete(id)}
                    sx={{ visibility: visibleDeleteIcon }}
                  >
                    <TrashIcon fontSize="small" />
                  </IconButton>
                )}
            </Box>
          </Box>
          <Typography
            color="textSecondary"
            component="p"
            sx={{ mt: 1 }}
            variant="caption"
          >
            {format(new Date(created_at), " dd.LL 'v' H:mm")}
          </Typography>
        </Box>
      </Box>
      {openAdd && (
        <AddComment
          customer={customer}
          grd={grd}
          parent_id={id}
          onAdd={() => setOpenAdd(false)}
        />
      )}
      {childComments.map((comment) => {
        const children_ids = comment.children_ids;

        const childComments = allComments.filter((comment) =>
          children_ids.includes(comment.id)
        );
        const sortedChild = childComments.sort((comment1, comment2) => {
          const createdAt1 = new Date(comment1.createdAt);
          const createdAt2 = new Date(comment2.createdAt);
          return compareDesc(createdAt1, createdAt2);
        });
        return (
          <ChildComment
            customer={customer}
            key={comment.id}
            comment={comment}
            onDelete={onDelete}
            allCommentators={allCommentators}
            childComments={sortedChild}
            grd={grd}
            parent_id={id}
            onAdd={() => setOpenAdd(false)}
          />
        );
      })}
    </>
  );
};

export default CustomerComment;
