"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


const UIPageContentTopBarFilterSwitcherReactT = class UIPageContentTopBarFilterSwitcherReactT extends React.Component {
	
	constructor( Props ){
		
		console.log( "UIPageContentTopBarFilterSwitcherReactT.constructor", Props );
		
		
		super( Props );
		
		
		this.state = {
			key: ( Props.Key ? Props.key : null ),
			
			SX: ( ( Props.SX ) ? Props.SX : { ml: 4, px: 2 } ),
			Size: ( ( Props.Size ) ? Props.Size : "small" ),
			Value: ( ( Props.Value ) ? Props.Value : "All" ),
			OnChange: ( ( Props.OnChange ) ? Props.OnChange : null )
		};
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIButtonReactT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		if( Name === "Value" ){
		
			if( this.state.OnChange !== null ){
			
				this.state.OnChange( Value );
		
			}
			
		}
		
		
		this.setState( {
			[ Name ]: Value
		} );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIPageContentTopBarFilterSwitcherReactT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.state[ Name ];
		
	}
	
	OnChange( Ev, Value ){
		
		console.log( Ev, Value );
		
		this.PropSet( "Value", Value.props.value );
		
		if( this.state.OnChange !== null ){
		
			this.state.OnChange( Value.props.value );
	
		}
	
	}
	
	render(
	
	){
		
		return React.createElement(
			MaterialUI.Select,
			{
				key: this.state.key,
				sx: this.state.SX, 
				size: this.state.Size,
				onChange: this.OnChange.bind( this ),
				value: this.state.Value
			},
			[
				React.createElement(
					MaterialUI.MenuItem,
					{
						key: "All",
						value: "All"
					},
					[
						"Vše"
					]
				),
				
				React.createElement(
					MaterialUI.MenuItem,
					{
						key: "Active",
						value: "Active"
					},
					[
						"Aktuální"
					]
				),
				
				React.createElement(
					MaterialUI.MenuItem,
					{
						key: "Archive",
						value: "Archive"
					},
					[
						"Archiv"
					]
				)
			]
		);
		
	}
	
};


export default UIPageContentTopBarFilterSwitcherReactT;