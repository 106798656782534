import BasicAdd from "../../../components/grid/basicAdd";
import GridWrapper from "../../../components/grid/gridWrapper";

export default function PlaceAdresses({
  tab,
  presetValues,
  handleRowData,
  customer,
}) {
  return (
    <GridWrapper
      style={tab ? { mt: 2 } : null}
      helmet="Dashboard: Place Addresses | AWA Partners"
      title="Adresy místa"
      model="place_addresses"
      filter={handleRowData}
      uzivatel={customer}
    >
      <BasicAdd
        noTemplates={true}
        model={"place_addresses"}
        presetValues={[{ key: "customer", value: customer }]}
      />
    </GridWrapper>
  );
}
