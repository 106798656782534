"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIButtonReactT from "./UIButtonReactT.mjs";

import UIButtonAbstractT from "../../../Interface/UI/Button/_/UIButtonAbstractT.mjs";


const UIButtonT = class UIButtonT extends UIButtonAbstractT {
	
	#Component = null;
	
	#Ref = null;
	
	constructor(
		Props
	){
		
		console.log( "UIButtonT.constructor", Props );
		
		
		if( ( Props.Children instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super( );
		
		
		this.#Component = React.createElement( 
			UIButtonReactT, 
			{
				...Props,
				ref: this.RefSet.bind( this )
			}
		);
		
	}
	
	RefSet( Ref ){
		
		this.#Ref = Ref;
		
		if( this.#Ref === null ){
			
			return;
			
		}
		
		let OnReadyCb = this.PropGet( "OnReady" );
		
		if( OnReadyCb !== null ){
			
			OnReadyCb( );
			
		}
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIButtonT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}

		
		if( this.#Ref !== null ){
		
			this.#Ref.PropSet( Name, Value );
		
		}
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIButtonT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.#Ref.PropGet( Name );
		
	}
	
	Component( 
	
	){
		
		return this.#Component;
		
	}
	
};


export default UIButtonT;