"use strict";


import EnvAbstractT from "./_/EnvAbstractT.mjs";


const EnvURLT = class EnvURLT {

	#Env = null

	constructor( 
		Env
	){
		
		if( ( Env instanceof EnvAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}

		
		this.#Env = Env;
		
	}
	
	Get( ){
		
		return this.#Env.URL( );
		
	}

};


export default EnvURLT;