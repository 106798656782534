import { useState, useEffect, useCallback } from "react";
import { OpenInNew } from "@material-ui/icons";
import MultiFileModal from "../grid/multiFileModal";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Popover,
  Dialog,
  Select as MuiSelect,
  MenuItem,
} from "@material-ui/core";
import DownloadAttachment from "../../aggrid/downloadAttachment";
import useMounted from "../../hooks/useMounted";
import Add2 from "./add2.js";
import PlusIcon from "../../icons/Plus";
import useSettings from "../../hooks/useSettings";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import MainTableColumnDefs from "../../aggrid/MainTableColumnsDefs.js";
import FileEditor from "../../aggrid/FileEditor";
import ReservForm from "../placeReservationForm/ReservForm";
import AccommodateForm from "../accommodateForm/AccommodateForm";
import AddUbytovatelRest from "../addUbytovatelRests";
import DateTimePicker from "../../aggrid/DateTimePicker.jsx";
import CheckboxRenderer from "../../aggrid/CheckboxRenderer.js";
import DatePicker from "../../aggrid/DatePicker.js";
import Select from "../../aggrid/Select.js";
import DeleteRenderer from "../../aggrid/DeleteRenderer.js";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";
import { CalendarEventForm } from "../dashboard/calendar";
import MultiSelectSimple from "../../aggrid/MultiSelectSimple.js";
import { LicenseManager } from "ag-grid-enterprise";
import AgAutoComplete from "../../aggrid/AgAutoComplete";

LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const EmployeeModel = (props) => {
  const { t } = useTranslation();

  var main_model = props.model;
  if (props.model == "fines.koordinator" || props.model == "fines.ubytovatel")
    main_model = "fines";
  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );
  var coldefs = MainTableColumnDefs(main_model);

  const employmentDepartmentActions = [
    { id: 1, name: "INFORMAČNÍ KARTA" },
    { id: 2, name: "SDĚLENÍ" },
  ];

  const employmentDepartmentCommunicationTypes = [
    { id: 1, name: "Nastoupení nebo změna" },
    // { id: 2, name: "Nenastoupení" },
    { id: 3, name: "Ukončení zaměstnání" },
    // { id: 4, name: "Předčasné ukončení" },
    // { id: 5, name: "Nepotřebuje povolení" },
  ];

  const monthNames = [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec",
  ];
  const month_list = [];

  for (let i = 1; i <= 12; i++) {
    month_list.push(
      <MenuItem key={i} value={i}>
        {t(monthNames[i - 1])}
      </MenuItem>
    );
  }

  const year_list = [];

  for (let i = -5; i <= 1; i++) {
    var d = new Date();
    const y = d.getYear() + i + 1900;
    year_list.push(
      <MenuItem key={y} value={y}>
        {y}
      </MenuItem>
    );
  }

  let def = coldefs.find((i) => i.field === "created_at");
  if (!def) {
    def = {
      headerName: t("Vytvořeno dne"),
      field: "created_at",
      sort: "desc",
      editable: false,
      cellStyle: { backgroundColor: "rgba(0,0,0,0.1)" },
    };
    coldefs.push(def);
  }

  const refreshParentNode = () => {
    var send_sc = props.sc.join(",");
    if (send_sc == "") send_sc = "none";
    HttpService.get(
      UrlService.apiDomain() +
        "api/employee_by_id/" +
        props.month +
        "/" +
        props.year +
        "/" +
        props.uzivatel +
        "/" +
        send_sc +
        "/" +
        props.id
    )
      .then((res) => {
        if (res.status != 200) {
          alert(
            t("Chyba serveru, prosím zkuste pozdějí") +
              ". " +
              t(res.data.message)
          );
        } else {
          props.node.setData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const grd = () => {
    if (props.id) {
      HttpService.get(
        UrlService.apiDomain() +
          "api/employee_models/" +
          props.model +
          "/" +
          props.id
      )
        .then((res) => {
          setRowdata(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  if (
    policy_action_tree[main_model].includes("delete") &&
    main_model !== "fines.ubytovatel"
  )
    coldefs.push({
      headerName: t("Odstranit"),
      cellRenderer: "DeleteRenderer",
      minWidth: 90,
      cellRendererParams: {
        gridType: "employee_models/" + props.model,
        // afterUpdate: refreshParentNode,
        afterUpdate: grd,
      },
      suppressMenu: true,
    });
  if (
    policy_action_tree.pokuty_ubytovatel.includes("delete") &&
    main_model == "fines.ubytovatel"
  )
    coldefs.push({
      headerName: t("Odstranit"),
      cellRenderer: "DeleteRenderer",
      minWidth: 90,
      cellRendererParams: {
        gridType: "employee_models/" + props.model,
        // afterUpdate: refreshParentNode,
        afterUpdate: grd,
      },
      suppressMenu: true,
    });
  if (main_model == "rests" || main_model == "assignments")
    coldefs.push({
      headerName: "#",
      valueGetter: (params) => {
        return params.node.rowIndex + 1;
      },
      lockPosition: "left",
      pinned: "left",
      maxWidth: 100,
      sortable: false,
      resizable: false,
      suppressMenu: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    });
  if (main_model == "medical_checkups") {
    coldefs = coldefs.filter(
      (col) =>
        col.field !== "name" &&
        col.field !== "surname" &&
        col.field !== "employee_id"
    );
  }

  const mounted = useMounted();
  const [openEvent, setOpenEvent] = useState(false);
  const { settings } = useSettings();
  const [rowdata, setRowdata] = useState(null);
  const [open, setOpen] = useState(false);
  const [now, setNow] = useState(Date.now());
  const [columndefs, setColumndefs] = useState(coldefs);
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});
  const [optionComponent, setOptionComponent] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [multiFileData, setMultiFileData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getYear() + 1900);
  const [employmentDepartmentAction, setEmploymentDepartmentAction] =
    useState(1);
  const [
    employmentDepartmentCommunicationType,
    setEmploymentDepartmentCommunicationType,
  ] = useState(1);

  const handleEventClose = () => {
    setOpenEvent(false);
  };
  const handlePopoverClose = () => {
    setOpenPopover(false);
  };
  const defaultColDef = {
    flex: 1,
    minWidth: 170,
    editable: true,
    resizable: true,
    suppressMovableColumns: true,
    sortable: true,
    filter: true,
    suppressContextMenu: true,
  };

  const frameworkComponents = {
    FileEditor: FileEditor,
    DownloadAttachment: DownloadAttachment,
    OpenInNewRenderer: (params) => {
      return <OpenInNew sx={{ color: params.value.length ? "#4caf50" : "#5664d2" }} />;
    },
    CheckboxRenderer: CheckboxRenderer,
    MultiSelectSimple: MultiSelectSimple,
    DeleteRenderer: DeleteRenderer,
    DatePicker: DatePicker,
    DateTimePicker: DateTimePicker,
    Select: Select,
    Autocomplete: AgAutoComplete,
  };

  useEffect(() => {
    grd();
  }, [props.id]);

  const onCellClicked = (e) => {
    let field = e.colDef.field;

    if (e.colDef.column_type === "multifile") {
      setMultiFileData({
        id: e.data.id,
        field: field,
        model: props.model,
        label: e.colDef.headerName,
      });
    }
  };

  const onCellCValueChanged = (event) => {
    event.data.field = event.colDef.field;
    HttpService.put(
      UrlService.apiDomain() +
        "api/employee_models/" +
        props.model +
        "/" +
        event.data.id,
      event.data
    )
      .then((res) => {
        if (res.status != 200) {
          alert(
            t("Chyba serveru, prosím zkuste pozdějí") +
              ". " +
              t(res.data.message)
          );
          event.node.data[event.data.field] = event.oldValue;
          event.node.setData(event.node.data);
        } else if (res.data.result == "failed") {
          alert(t(res.data.message));
          event.node.data[event.data.field] = event.oldValue;
          event.node.setData(event.node.data);
        } else {
          console.log("Successfully updated");

          if (
            typeof res.data.create_instead !== "undefined" &&
            res.data.create_instead == true
          ) {
            let r = gridApi.updateRowData({
              add: [res.data.entry],
            });

            gridApi.flashCells({ rowNodes: [r.add[0]] });
          } else {
            event.node.setData(res.data.entry);
          }

          if (res.data.notification) {
            // let notification = res.data.notification;
            // if (notification.success) {
            //   console.log("Odhláška úspěšně odeslána na ÚP");
            // } else {
            //   let message = "Odhláška NEBYLA odeslána na ÚP, chybí:\n";
            //   for (const missing of notification.missing) {
            //     message += missing + "\n";
            //   }
            //   alert(message);
            // }
          }

          refreshParentNode();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleAdd = (data) => {
    data.employee_id = props.id;
    if (props.model != "medical_checkups") {
      data.month = props.month;
      data.year = props.year;
    }

    const formData = new FormData();

    for (const key in data) {
      if (data[key] instanceof Date) {
        formData.append(key, data[key].toISOString().split("T")[0]);
      } else if (typeof data[key] == "object") {
        for (const fileKey in data[key]) {
          const file = data[key][fileKey];
          if (file instanceof File) {
            formData.append(`files[${fileKey}]`, file);
          }
        }
      } else {
        formData.append(key, data[key]);
      }
    }

    HttpService.post(
      UrlService.apiDomain() + "api/employee_models/" + props.model,
      formData
    )
      .then((res) => {
        if (res.status != 200) {
          alert(
            t("Chyba serveru, prosím zkuste pozdějí") +
              ". " +
              t(res.data.message)
          );
        } else if (res.data.result == "failed") {
          alert(t(res.data.message));
        } else {
          console.log("Successfully updated");
          handleClose();
          // let r = gridApi.updateRowData({
          //   add: [res.data.entry],
          // });

          // gridApi.flashCells({ rowNodes: [r.add[0]] });
          // refreshParentNode();
          grd();

          if (props.model === "assignments") {
            if (res.data.notification1) {
              // let notification1 = res.data.notification1;
              // if (notification1.success) {
              //   console.log("Odhláška úspěšně odeslána na ÚP");
              // } else {
              //   let message = "Odhláška NEBYLA odeslána na ÚP, chybí:\n";
              //   for (const missing of notification1.missing) {
              //     message += missing + "\n";
              //   }
              //   alert(message);
              // }
            }
            if (res.data.notification2) {
              /*
                            // Docasne notifikace odebrany
                            let notification2 = res.data.notification2;
                            if (notification2.success) {
                                alert("Přihláška úspěšně odeslána na ÚP");
                            } else {
                                let message = "Přihláška NEBYLA odeslána na ÚP, chybí:\n";
                                for (const missing of notification2.missing) {
                                    message += missing + "\n";
                                }
                                alert(message);
                            }
                            */
            }
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);

    //params.api.setDomLayout('autoHeight');
    //document.querySelector('#myGrid').style.height = '';

    var columnState = JSON.parse(
      localStorage.getItem(props.model + "_column_state")
    );
    if (!columnState) {
      columnState = params.columnApi.getColumnState();
    }

    columnState.map((col) => {
      col.sort = null;
      col.sortIndxe = null;
      return col;
    });

    if (props.model === "employments") {
      // let def = columnState.find((i) => i.colId === "smlouva_od");
      // def.sort = "desc";
    } else if (props.model === "assignments") {
      let def = columnState.find((i) => i.colId === "docasne_prideleni_od");

      if (def) {
        def.sort = "desc";
        def.sortIndex = 0;
      }

      def = columnState.find((i) => i.colId === "pozice_administrativa");
      if (def) {
        def.sort = "desc";
        def.sortIndex = 1;
      }
    } else if (props.model === "suppliers") {
      let def = columnState.find((i) => i.colId === "dodavatel");
      def.valueGetter = function (params) {
        return params.data.dodavatel_fullname;
      };
    }

    params.columnApi.setColumnState(columnState);
  };

  const onDragStopped = (params) => {
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem(props.model + "_column_state", columnState);
  };

  useEffect(() => {
    setloctext(lt);
    setColumndefs(coldefs);
  }, [t("cs")]);

  var header = "Data";
  if (props.model == "employments") header = "Pracovní poměry";
  if (props.model == "assignments") header = "Přiřazení";
  if (props.model == "agency_accommodations") header = "Ubytování agentury";
  if (props.model == "suppliers") header = "Dodavatele";
  if (props.model == "visas") header = "Víza";
  if (props.model == "rests") header = "Resty";
  if (props.model == "pokuty_ubytovatel") header = "Škody ubytovatel";
  if (props.model == "fines.ubytovatel") header = "Pokuty ubytovatel";
  if (props.model == "fines.koordinator") header = "Pokuty koordinator";
  if (props.model == "interactions") header = "Interakce";
  if (props.model == "medical_checkups") header = "Lékařské prohlídky";

  const handleCloseRest = (restsAction) => {
    const ids = selectedRows.map((item) => item.id);
    HttpService.post(
      UrlService.apiDomain() + `api/edit-rests/${year}/${month}/${restsAction}`,
      ids
    )
      .then((res) => {
        if (res.status != 200) {
          alert(res.data.message);
          return;
        }
        grd();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRowStyle = (params) => {
    if (
      (props.model == "employments" ||
        props.model == "assignments" ||
        props.model == "visas" ||
        props.model == "suppliers" ||
        props.model == "agency_accommodations") &&
      params.data.actual
    ) {
      return { backgroundColor: "#d2fadd" };
    }
  };

  const handleDepartmentDataSend = () => {
    let method =
      employmentDepartmentAction == 1
        ? "employment_department_infos.add_by_employee_id"
        : "employment_department_notices.add_by_employee_id";
    let params =
      employmentDepartmentAction == 1
        ? {
            employee_id: +props.id,
            assignment_id: +selectedRows[0].id,
            notification_type: employmentDepartmentCommunicationType,
          }
        : {
            employee_id: +props.id,
            assignment_id: +selectedRows[0].id,
            notification_type: employmentDepartmentCommunicationType,
          };

    HttpService.post(`${UrlService.apiDomain()}api/json_rpc`, {
      jsonrpc: "2.0",
      method: method,
      params: params,
      id: "1",
    })
      .then((res) => {
        if (res.data.error) {
          alert(res.data.error.message);
          return;
        }
        toast.success(t("Osoba přidána"));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  for (let I = 0; I < columndefs.length; I++) {
    if (columndefs[I].field === "ubytovany_od") {
      if (!policy_action_tree[main_model].includes("edit_date")) {
        columndefs[I].editable = false;

        columndefs[I].cellStyle = {
          backgroundColor: "rgba(0,0,0,0.1)",
        };
      }
    }

    if (columndefs[I].field === "ubytovany_do") {
      if (policy_action_tree[main_model].includes("edit_date") === false) {
        columndefs[I].cellEditorParams.disabled = true;

        columndefs[I].cellEditorParams.value = new Date()
          .toISOString()
          .substring(0, 10);
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <MultiFileModal
          data={multiFileData}
          onClose={() => setMultiFileData(null)}
          isOpen={multiFileData !== null}
        />
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleEventClose}
          open={openEvent}
        >
          <CalendarEventForm
            employeeName={props.profileData && props.profileData.name}
            employeeSurname={props.profileData && props.profileData.surname}
            employeeId={props.id}
            onAddComplete={handleEventClose}
            onCancel={handleEventClose}
            onDeleteComplete={handleEventClose}
            onEditComplete={handleEventClose}
            // range={selectedRange}
          />
        </Dialog>
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t(header)}
              </Typography>
            </Grid>
            <Popover
              anchorOrigin={{
                horizontal: "center",
                vertical: "top",
              }}
              anchorEl={document.querySelector("body")}
              keepMounted
              onClose={handleClose}
              open={open}
              PaperProps={{
                sx: { width: 540 },
              }}
            >
              <Add2
                model={main_model}
                typ={props.model}
                tmp={now}
                node={props.profileData}
                onAdd={handleAdd}
              />
            </Popover>
            <Popover
              anchorOrigin={{
                horizontal: "center",
                vertical: "top",
              }}
              anchorEl={document.querySelector("body")}
              keepMounted
              onClose={handlePopoverClose}
              open={openPopover}
              PaperProps={{
                sx: { width: "100%" },
              }}
            >
              {optionComponent}
            </Popover>
            {props.model == "assignments" &&
              policy_action_tree["employment_department_infos"].includes(
                "add"
              ) && (
                <Grid item>
                  <MuiSelect
                    disabled={!selectedRows.length}
                    sx={{ m: 1 }}
                    size="small"
                    onChange={(event, value) => {
                      setEmploymentDepartmentAction(value.props.value);
                    }}
                    value={employmentDepartmentAction}
                  >
                    {employmentDepartmentActions.map((a) => (
                      <MenuItem key={a.id} value={a.id}>
                        {t(a.name)}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                  <MuiSelect
                    sx={{ m: 1 }}
                    disabled={!selectedRows.length}
                    size="small"
                    onChange={(event, value) => {
                      setEmploymentDepartmentCommunicationType(
                        value.props.value
                      );
                    }}
                    value={employmentDepartmentCommunicationType}
                  >
                    {employmentDepartmentCommunicationTypes.map((a) => (
                      <MenuItem key={a.id} value={a.id}>
                        {t(a.name)}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                  <Button
                    disabled={!selectedRows.length}
                    color="primary"
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={handleDepartmentDataSend}
                  >
                    {t("Odeslat")}
                  </Button>
                </Grid>
              )}
            {props.model == "rests" && (
              <Grid item>
                <MuiSelect
                  sx={{ m: 1 }}
                  size="small"
                  onChange={(event, value) => {
                    setMonth(value.props.value);
                  }}
                  value={month}
                  id="month_select"
                >
                  {month_list}
                </MuiSelect>
                <MuiSelect
                  sx={{ m: 1 }}
                  size="small"
                  onChange={(event, value) => {
                    setYear(value.props.value);
                  }}
                  value={year}
                  id="year_select"
                >
                  {year_list}
                </MuiSelect>
                <Button
                  disabled={!selectedRows.length}
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => {
                    handleCloseRest("1");
                  }}
                >
                  {t("Ztrhnout ze mzdy")}
                </Button>
                <Button
                  disabled={!selectedRows.length}
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => {
                    handleCloseRest("2");
                  }}
                >
                  {t("Vratit uživateli")}
                </Button>
                <Button
                  disabled={!selectedRows.length}
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => {
                    handleCloseRest("3");
                  }}
                >
                  {t("Vratit na sklad")}
                </Button>
              </Grid>
            )}
            {policy_action_tree[main_model].includes("add") && (
              <Grid item>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item>
                    {props.model !== "agency_accommodations" &&
                      props.model !== "pokuty_ubytovatel" &&
                      props.model !== "interactions" &&
                      props.model !== "fines.ubytovatel" && (
                        <Button
                          color="primary"
                          startIcon={<PlusIcon fontSize="small" />}
                          sx={{ m: 1 }}
                          variant="contained"
                          onClick={() => {
                            setNow(Date.now());
                            setOpen(true);
                          }}
                        >
                          {t("Přidat")}
                        </Button>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.model == "fines.ubytovatel" &&
              policy_action_tree.pokuty_ubytovatel.includes("add") && (
                <Grid item>
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => {
                      setNow(Date.now());
                      setOpen(true);
                    }}
                  >
                    {t("Přidat")}
                  </Button>
                </Grid>
              )}
            {props.model == "agency_accommodations" && (
              <Grid item>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item>
                    <Button
                      disabled={
                        !policy_action_tree.agency_accommodations.includes(
                          "reserv_button"
                        )
                      }
                      color="primary"
                      startIcon={<PlusIcon fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={() => {
                        setOptionComponent(
                          <ReservForm
                            user_info={props.profileData}
                            handleClosePopover={handlePopoverClose}
                          />
                        );
                        setOpenPopover(true);
                      }}
                    >
                      {t("Rezervovat")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={
                        !policy_action_tree.agency_accommodations.includes(
                          "add"
                        )
                      }
                      color="primary"
                      startIcon={<PlusIcon fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={() => {
                        setOptionComponent(
                          <AccommodateForm
                            getRowdata={grd}
                            user_info={props.profileData}
                            handleCloseAccommodate={handlePopoverClose}
                          />
                        );
                        setOpenPopover(true);
                      }}
                    >
                      {t("Ubytovat")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.model == "interactions" && (
              <Grid item>
                <Button
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => {
                    setOpenEvent(true);
                  }}
                >
                  {t("Přidat interakci")}
                </Button>
              </Grid>
            )}
            {props.model == "pokuty_ubytovatel" && (
              <Grid item>
                <Button
                  startIcon={<PlusIcon fontSize="small" />}
                  disabled={
                    !policy_action_tree.pokuty_ubytovatel.includes("add")
                  }
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => {
                    setOptionComponent(
                      <AddUbytovatelRest
                        getRowdata={grd}
                        user_info={props.profileData}
                        onClose={handlePopoverClose}
                      />
                    );
                    setOpenPopover(true);
                  }}
                >
                  {t("Přidat")}
                </Button>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 3 }}>
            <div
              style={{ width: "100%", overflow: "auto", height: "500px" }}
              id="myGrid"
              className="ag-theme-material"
            >
              <AgGridReact
                modules={AllCommunityModules}
                getRowStyle={getRowStyle}
                rowData={rowdata}
                columnDefs={columndefs}
                defaultColDef={defaultColDef}
                onSelectionChanged={() =>
                  setSelectedRows(gridApi.getSelectedRows())
                }
                onGridReady={onGridReady}
                onDragStopped={onDragStopped}
                onCellValueChanged={onCellCValueChanged}
                onCellClicked={onCellClicked}
                frameworkComponents={frameworkComponents}
                enableCellChangeFlash={true}
                rowSelection={
                  (main_model = "assignments" ? "single" : "multiple")
                }
                suppressContextMenu={true}
                localeText={loctext}
                popupParent={document.querySelector("body")}
              />
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EmployeeModel;
