import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import TransferDetailsForm from "./TransferDetailsForm";
import getYourCompanyDetails from "../../../api/getYourCompanyDetails";
import PaymentDetailsValidation from "./PaymentDetailsValidation";
import updateYourCompanyDetails from "../../../api/updateYourCompanyDetails";

export default function PaymentDetailsModal(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [valid, setValid] = useState(false);
  const [data, setData] = useState({});
  const [type, setType] = useState("individual");
  const [paymentType, setPaymentType] = useState("cash");
  const [dataLoadingState, setDataLoadingState] = useState(
    props.isEdit ? "waiting" : "loaded"
  );
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value);
  };

  useEffect(() => {
    if (props.isEdit) {
      getData();
    }
  }, [props.isEdit]);

  useEffect(() => {
    if (paymentType == "transfer") {
      if (type == "individual") {
        setData({
          type: "individual",
          payment_type: "transfer",
          iban: "",
          bic_swift: "",
          bank_country: "",
          bank_address_1: "",
          bank_address_2: "",
          bank_name_1: "",
          bank_name_2: "",
          beneficiary_address_1: "",
          beneficiary_address_2: "",
          beneficiary_name_1: "",
          beneficiary_name_2: "",
        });
      } else if (type == "self_employed") {
        setData({
          type: "self_employed",
          payment_type: "transfer",
          address: "",
          ic: "",
          dic: "",
          iban: "",
          bic_swift: "",
          bank_country: "",
          bank_address_1: "",
          bank_address_2: "",
          bank_name_1: "",
          bank_name_2: "",
          beneficiary_address_1: "",
          beneficiary_address_2: "",
          beneficiary_name_1: "",
          beneficiary_name_2: "",
        });
      } else if (type == "legal_entity") {
        setData({
          type: "legal_entity",
          payment_type: "transfer",
          address: "",
          ic: "",
          dic: "",
          company_name: "",
          iban: "",
          bic_swift: "",
          bank_country: "",
          bank_address_1: "",
          bank_address_2: "",
          bank_name_1: "",
          bank_name_2: "",
          beneficiary_address_1: "",
          beneficiary_address_2: "",
          beneficiary_name_1: "",
          beneficiary_name_2: "",
        });
      }
    } else if (paymentType == "transferCz") {
      if (type == "individual") {
        setData({
          type: "individual",
          payment_type: "transferCz",
          account_number: "",
          beneficiary_name_1: "",
          beneficiary_name_2: "",
        });
      } else if (type == "self_employed") {
        setData({
          type: "self_employed",
          payment_type: "transferCz",
          address: "",
          ic: "",
          dic: "",
          account_number: "",
          beneficiary_name_1: "",
          beneficiary_name_2: "",
        });
      } else if (type == "legal_entity") {
        setData({
          type: "legal_entity",
          payment_type: "transferCz",
          address: "",
          ic: "",
          dic: "",
          company_name: "",
          account_number: "",
          beneficiary_name_1: "",
          beneficiary_name_2: "",
        });
      }
    } else if (paymentType == "cash") {
      if (type == "self_employed") {
        setData({
          payment_type: "cash",
          type: "self_employed",
        });
      } else if (type == "individual") {
        setData({
          payment_type: "cash",
          type: "individual",
        });
      }
    } else if (paymentType == "card") {
      if (type == "self_employed") {
        setData({
          payment_type: "card",
          type: "self_employed",
        });
      } else if (type == "individual") {
        setData({
          payment_type: "card",
          type: "individual",
        });
      }
    }
  }, [type, paymentType]);

  console.log({ data });

  let allfields = {
    type: "individual",
    payment_type: "cash",
    account_number: "",
    address: "",
    ic: "",
    dic: "",
    company_name: "",
    iban: "",
    bic_swift: "",
    bank_country: "",
    bank_address_1: "",
    bank_address_2: "",
    bank_name_1: "",
    bank_name_2: "",
    beneficiary_address_1: "",
    beneficiary_address_2: "",
    beneficiary_name_1: "",
    beneficiary_name_2: "",
  };

  const getData = () => {
    if (dataLoadingState === "waiting") {
      setDataLoadingState("loading");
      getYourCompanyDetails(
        (company) => {
          setData({
            type: company.type,
            payment_type: company.payment_type,
            iban: company.iban,
            bic_swift: company.bic_swift,
            bank_country: company.bank_country,
            bank_address_1: company.bank_address_1,
            bank_address_2: company.bank_address_2,
            bank_name_1: company.bank_name_1,
            bank_name_2: company.bank_name_2,
            beneficiary_address_1: company.beneficiary_address_1,
            beneficiary_address_2: company.beneficiary_address_2,
            beneficiary_name_1: company.beneficiary_name_1,
            beneficiary_name_2: company.beneficiary_name_2,
          });
          setType(company.type);
          setPaymentType(company.payment_type);
          setDataLoadingState("loaded");
        },
        (error) => {
          alert(error);
        }
      );
    }
  };

  const handleChange = (e) => {
    updateData([e.target.name], e.target.value);
  };

  const updateData = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleUpdate = () => {
    setButtonLoading(true);
    updateYourCompanyDetails(
      data,
      () => {
        setButtonLoading(false);
        if (props.on_update_success) {
          props.on_update_success();
        }
      },
      (error) => {
        alert(error);
      }
    );
  };

  useEffect(() => {
    setValid(PaymentDetailsValidation(data));
    props.onChange(data);
  }, [type, paymentType, data]);

  useEffect(() => {
    if (type != "individual" && !props.isEdit) {
      setPaymentType("transfer");
    }
  }, [type, props.isEdit]);

  useEffect(() => {
    setIsOpen(props.isOpen);
    // if (props.isOpen && props.isEdit) {
    //   setDataLoadingState("waiting");
    // }
  }, [props.isOpen]);

  let payment_part;

  if (dataLoadingState == "waiting") {
    return (
      <Dialog
        onClose={props.onClose}
        open={isOpen}
        sx={{
          minWidth: 400,
          mx: "auto",
          p: 4,
          width: "50vw",
        }}
      >
        <Paper elevation={12} sx={{ width: "100%", p: 3 }}>
          <CircularProgress />
        </Paper>
      </Dialog>
    );
  } else {
    switch (paymentType) {
      case "transfer":
        payment_part = (
          <TransferDetailsForm
            onChange={updateData}
            data={data}
            type={type}
            paymentType={paymentType}
          />
        );
        break;
      case "transferCz":
        payment_part = (
          <TransferDetailsForm
            onChange={updateData}
            data={data}
            type={type}
            paymentType={paymentType}
          />
        );
        break;
      case "cash":
        payment_part = undefined;
        break;
      case "card":
        payment_part = undefined;
        break;
    }

    return (
      <Dialog
        onClose={props.onClose}
        open={isOpen}
        sx={{
          minWidth: 400,
          mx: "auto",
          p: 4,
          width: "50vw",
        }}
      >
        <Paper elevation={12} sx={{ width: "100%", p: 3 }}>
          <Typography color="textPrimary" variant="h6">
            {t("Platební a firemní údaje")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="radio-label-1">{t("Typ osoby")}</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-label-1"
                  value={type}
                  onChange={handleTypeChange}
                  row
                >
                  <FormControlLabel
                    value="individual"
                    control={<Radio />}
                    label={t("Fyzická osoba")}
                  />
                  <FormControlLabel
                    value="self_employed"
                    control={<Radio />}
                    label={t("OSVČ")}
                  />
                  <FormControlLabel
                    value="legal_entity"
                    control={<Radio />}
                    label={t("Právnická osoba")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="radio-label-2">{t("Typ platby")}</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-label-2"
                  value={paymentType}
                  onChange={handlePaymentTypeChange}
                  row
                >
                  <FormControlLabel
                    value="transfer"
                    control={<Radio />}
                    label={t("Převodem (mimo ČR)")}
                  />
                  <FormControlLabel
                    value="transferCz"
                    control={<Radio />}
                    label={t("Převodem (ČR)")}
                  />
                  <FormControlLabel
                    disabled={type !== "individual"}
                    value="cash"
                    control={<Radio />}
                    label={t("Hotovost (pouze v ČŘ)")}
                  />
                  <FormControlLabel
                    disabled={type === "legal_entity"}
                    value="card"
                    control={<Radio />}
                    label={t("Fido karta")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {payment_part}
          </Grid>
          <Divider sx={{ my: 1 }} />
          {props.isEdit && !buttonLoading && (
            <Button
              disabled={!valid}
              fullWidth
              variant="contained"
              onClick={handleUpdate}
            >
              {t("Uložit")}
            </Button>
          )}
          {props.isEdit && buttonLoading && <LinearProgress />}
        </Paper>
      </Dialog>
    );
  }
}
