import ModalWrapper from "../../components/modal/ModalWrapper";
import { useEffect, useState } from "react";
import GridWrapper from "../../components/grid/gridWrapperNew";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BasicAdd from "../../components/grid/basicAddNew";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import ApplyProposition from "./ApplyProposition.js";

export default function CustomerRequirmentsPropositions({
  open,
  id,
  onClose,
  customer_id,
  getData,
  getMainData,
}) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [ready, setReady] = useState(false);
  const [usersData, setUsersData] = useState(null);

  const userId = JSON.parse(localStorage.getItem("my_employee")).id;

  const handleAdd = (data) => {
    setRows(rows ? rows.concat(data) : [data]);
  };

  const getAllPropositions = async () => {
    if (!id) return;
    let mounted = true;
    setReady(false);

    try {
      const res = await HttpService.post(
        UrlService.apiDomain() +
          "api/customer_start_requirement_propositions/get/all",
        {
          data: {
            customer_start_requirement_id: id,
          },
        }
      );
      if (!res.data.success) {
        alert(res.data.message);
        return;
      }

      setRows(Object.values(res?.data.data) ?? []);
      setUsersData(Object.values(res?.data.data_employees));
      setReady(true);
    } catch (err) {
      console.error(err);
    }

    return () => {
      mounted = false;
    };
  };

  useEffect(() => {
    getAllPropositions();
  }, [id]);

  if (!ready) {
    return (
      <ModalWrapper isOpen={open} title={t("Přidat doporučení")}>
        <LinearProgress sx={{ minWidth: "200px" }} />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      isOpen={open}
      onClose={onClose}
      size={rows.length === 0 ? "xs" : "md"}
      onAdd={handleAdd}
    >
      {rows.length > 0 && (
        <GridWrapper
          title={t("Doporučení")}
          data={rows}
          model={"customer_start_requirement_propositions"}
          hidden={["customer_start_requirement_id", "is_new"]}
          browseData={{}}
          usersData={usersData}
        >
          <ApplyProposition
            customer_id={customer_id}
            getRowData={getData}
            getMainData={getMainData}
          />
          <BasicAdd
            handleOnAdd={getAllPropositions}
            noTemplates={true}
            model={"customer_start_requirement_propositions"}
            presetValues={[
              { key: "customer_start_requirement_id", value: id },
              { key: "proposer_employee_id", value: userId },
            ]}
          />
        </GridWrapper>
      )}
      {rows.length === 0 && (
        <BasicAdd
          handleOnAdd={getAllPropositions}
          buttonProps={{ sx: { width: 1 } }}
          title={t("Přidat doporučení")}
          noTemplates={true}
          model={"customer_start_requirement_propositions"}
          presetValues={[
            { key: "customer_start_requirement_id", value: id },
            { key: "proposer_employee_id", value: userId },
          ]}
        />
      )}
    </ModalWrapper>
  );
}
