import UrlService from "../services/UrlService";

export default function getYourCompanyDetails(handleResult, handleError) {
  const at = localStorage.getItem("accessToken");
  fetch(UrlService.apiDomain() + "api/companies/your/details", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + at,
    },
  })
    .then((r) => r.json())
    .then((response) => {
      if (!response.success) {
        handleError(response.error);
        return;
      }
      handleResult(response.company);
    });
}
