"use strict";


const UIGridAbstractT = class UIGridAbstractT {
	
	constructor( ){
		
	}
	
	//TODO
	
};


export default UIGridAbstractT;