"use strict";


const HTTPFileClientAbstractT = class HTTPFileClientAbstractT {
	
	constructor(

	){
		
	}
	
	//TODO
	
};


export default HTTPFileClientAbstractT;