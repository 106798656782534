"use strict";


import JSONBNParserT from "./Parser/JSONBNParserT.mjs";

import JSONBNSerializerT from "./Serializer/JSONBNSerializerT.mjs";


const JSONBNT = class JSONBNT {

	#Parser = null;
	
	#Serializer = null;
	
	constructor( ){
		
		this.#Parser = new JSONBNParserT( );
		
		this.#Serializer = new JSONBNSerializerT( );
		
	}
	
	Parse( 
		Data 
	){
	
		return this.#Parser.Parse( Data );
	
	}
	
	Serialize( 
		Data 
	){
		
		return this.#Serializer.Serialize( Data );
		
	}
	
};


export default JSONBNT;