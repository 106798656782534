import GridWrapper from "../../components/grid/gridWrapperNew";
import CalendarCustomerForm from "../../components/dashboard/calendar/CalendarCustomerForm";
import ModalWrapper from "../../components/modal/ModalWrapper";
import { useState } from "react";
import { Button } from "@mui/material";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@material-ui/core";

export default function Interactions({
  tab,
  presetValues,
  handleRowData,
  customer,
}) {
  const { t } = useTranslation();
  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).customer_interactions;

  const { data: contextData, isLoading } = useContext(DataContext);
  const [openEvent, setOpenEvent] = useState(false);
  const [contactId, setContactId] = useState("all");
  const [event, setEvent] = useState(null);

  const contacts_list = [
    <MenuItem key="all" value="all">
      {t("Všechny kontakty")}
    </MenuItem>,
  ];
  contextData.customer_contacts
    .filter((item) => !item.deleted)
    .map((contact) => {
      if (contact.customer == customer) {
        contacts_list.push(
          <MenuItem key={contact.id} value={contact.id}>
            {contact.name}
          </MenuItem>
        );
      }
    });

  const handleEventClose = () => {
    setOpenEvent(false);
  };

  const interceptClick = (e) => {
    if (e.colDef.headerName != "Odstranit") {
      setEvent(e.data);
      setOpenEvent(true);
    }
  };

  function handleColumnDefs(colDefs) {
    return colDefs.map((columnDef) => {
      if (columnDef.field == "title") {
        columnDef.cellStyle = { color: "#5664d2" };
      }
      return columnDef;
    });
  }

  return (
    <GridWrapper
      style={tab ? { mt: 2 } : null}
      helmet="Dashboard: Cars List | AWA Partners"
      title="Interakce"
      model="customer_interactions"
      interceptClick={interceptClick}
      handleColumnDefs={handleColumnDefs}
      filter={(data) => {
        let filteredData = data;
        switch (contactId) {
          case "all":
            filteredData = data;
            break;
          default:
            filteredData = filteredData.filter(
              (item) => item.customer_contact_id == contactId
            );
            break;
        }
        return filteredData;
      }}
	  browseData={{customer_id:customer}}
    >
      <Select
        sx={{ m: 1 }}
        size="small"
        onChange={(event, value) => {
          setContactId(value.props.value);
        }}
        value={contactId}
        id="contact_select"
      >
        {contacts_list}
      </Select>
      {policy_actions.includes("add") && (
        <Button
          color="primary"
          type="button"
          variant="contained"
          onClick={() => {
            setEvent(null);
            setOpenEvent(true);
          }}
        >
          {t("Přidat")}
        </Button>
      )}

      <ModalWrapper size="sm" isOpen={openEvent} onClose={handleEventClose}>
        <CalendarCustomerForm
          event={event}
          customer_contact_id={contactId == "all" ? null : contactId}
          customer_id={customer}
          onAddComplete={handleEventClose}
          onCancel={handleEventClose}
          onDeleteComplete={handleEventClose}
          onEditComplete={handleEventClose}
        />
      </ModalWrapper>
    </GridWrapper>
  );
}
