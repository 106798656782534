import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

import { useTranslation } from "react-i18next";
import $ from "jquery";
import { Autocomplete, TextField, MenuItem } from "@material-ui/core";
import useFilter from "../hooks/useFilter";
import { getName } from "../services/getColumnDefinitions";
import { useContext } from "react";
import { DataContext } from "../contexts/DataContext";
import { strComparator } from "../services/getColumnDefinitions";

export default forwardRef((props, ref) => {
  const { t } = useTranslation();
  const filter = useFilter();
  const [value, setValue] = useState("");
  const [optionsArray, setOptionsArray] = useState([]);
  let options = [];
  const { data: contextData } = useContext(DataContext);

  let rooms = contextData?.rooms;

  const inputRef = useRef(null);
  console.log(props);
  let locale = "cs";
  if (localStorage.getItem("locale") !== null)
    locale = localStorage.getItem("locale");

  async function getOptions() {
    let data_array = props.data_array ?? [];
    if (props.colDef.field === "down_payment_invoice_id") {
      data_array = props.data_array.filter(
        (i) => i.invoice_type?.toString() === "2"
      );
    }
    if (props.colDef.field === "danovy_doklad_invoice") {
      data_array = props.data_array.filter(
        (i) => i.invoice_type?.toString() === "3"
      );
    }

    // if (props.data_array_model === "projects") {
    //   let company_id = props.data.company_id;
    //   data_array = data_array.filter((i) => {
    //     if (!i.company_ids) return true;
    //     let ids = i.company_ids.split("//");
    //     return ids.includes(company_id.toString());
    //   });
    // }

    if (props.data_array_model === "invoice_item_names") {
      setOptionsArray(
        props.data_array.map((data) => {
          return {
            value: data.name,
            label: data.name,
          };
        })
      );
    } else if (props.data_array_model === "additional_texts") {
      setOptionsArray(
        props.data_array.map((data) => {
          return {
            value: data.text,
            label: data.text,
          };
        })
      );
    } else if (props.column.colId == "pokoj" && props.data.ubytovna != null) {
      rooms.map((e) => {
        if (e.accommodation_id == props.data.ubytovna) {
          options.push({
            value: e.id,
            label: e.name,
          });
        }
      });
      setOptionsArray(options);
    } else if (
      props.column.colId == "sub_project_id" &&
      props.data.project_id != null
    ) {
      console.log(props.data.project_id);
      JSON.parse(localStorage.getItem("sub_projects")).map((e) => {
        if (e.project_id == props.data.project_id) {
          options.push({
            value: e.id,
            label: e.name,
          });
        }
      });
      setOptionsArray(options);
    } else if (props.column.colId == "main_contact") {
      contextData.customer_contacts.map((e) => {
        if (e.customer == props.data.id) {
          options.push({
            value: e.id,
            label: e.name,
          });
        }
      });
      setOptionsArray(options);
    } else if (
      (props.column.colId == "pozice_koordinator" ||
        props.column.colId == "pozice_administrativa") &&
      props.data.uzivatel != null
    ) {
      JSON.parse(localStorage.getItem("positions")).map((e) => {
        if (e.customer_id == props.data.uzivatel) {
          options.push({
            value: e.id,
            label: e.name,
          });
        }
      });
      setOptionsArray(options);
    } else if (props.column.colId == "company_role") {
      if (props.data.company_role === "owner") {
        setOptionsArray([
          {
            value: "owner",
            label: t("Owner"),
          },
        ]);
      } else {
        setOptionsArray([
          {
            value: "employee",
            label: t("Employee"),
          },
          {
            value: "manager",
            label: t("Manager"),
          },
        ]);
      }
    } else {
      setOptionsArray(
        data_array.map((data) => {
          return {
            value: data.id,
            label: getName(data, props.data_array_model),
          };
        })
      );
    }
  }

  useEffect(() => {
    getOptions();
  }, [filter]); // to work with  filter context you need to add filter to array of dependencies

  function getVal(e) {
    let val = props.data[props.column.colId];
    if (val == null) val = "0";
    setValue(val);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: async () => {
        setValue(props.value);
        await getOptions();
        getVal();

        inputRef.current.focus();
        inputRef.current.click();
        $("#mycstselect svg").click();
      },

      isPopup: () => {
        return true;
      },
    };
  });

  let val;
  if (
    props.data_array_model !== "invoice_item_names" &&
    props.data_array_model !== "additional_texts"
  ) {
    val =
      optionsArray.find(
        (option) => option?.value?.toString() === value?.toString()
      ) ?? "";
  } else {
    val = value?.toString() ?? "";
  }

  return (
    <Autocomplete
      ref={inputRef}
      options={optionsArray.sort((a, b) => strComparator(a.label, b.label))}
      fullWidth
      sx={{ minWidth: "200px", maxWidth: "200px" }}
      value={val}
      onChange={(_, pair) => {
        if (!pair) {
          let confirmed = window.confirm(t("Clear value?"));
          if (!confirmed) return;
        }
        if (typeof pair !== "object") {
          setValue(pair?.toString() ?? "");
        } else {
          setValue(pair?.value);
        }
      }}
      freeSolo={
        props.data_array_model === "invoice_item_names" ||
        props.data_array_model === "additional_texts"
      }
      autoSelect={
        props.data_array_model === "invoice_item_names" ||
        props.data_array_model === "additional_texts"
      }
      renderInput={(params) => (
        <TextField {...params} label={props.colDef.headerName} />
      )}
    />
  );
});
