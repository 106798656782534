import ModalWrapper from "../../components/modal/ModalWrapper";
import {
  Popover,
  Button,
  Box,
  Container,
  Grid,
  IconButton,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Check from "../../icons/Check";
import { FileUpload } from "@material-ui/icons";
import React, { useEffect, useRef } from "react";
import httpPost from "../../api/base/httpPost";
import CircularProgress from "@material-ui/core/CircularProgress";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";
import httpGet from "../../api/base/httpGet";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";
import DownloadIcon from "@mui/icons-material/Download";

export default function UploadsModalWithPhoto({ open, id, onClose }) {
  const { settings } = useSettings();
  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).employees;

  const { t } = useTranslation();
  const fileInputRef = useRef(undefined);
  const [type, setType] = React.useState();

  const [form, setForm] = React.useState([]);
  const [passport, setPassport] = React.useState([]);
  const [passportBack, setPassportBack] = React.useState([]);
  const [visa, setVisa] = React.useState([]);
  const [insurance, setInsurance] = React.useState([]);
  const [accountNumber, setAccountNumber] = React.useState([]);
  const [certificate, setСertificate] = React.useState([]);
  const [questionnaire, setQuestionnaire] = React.useState([]);

  const [formState, setFormState] = React.useState("loading");
  const [passportState, setPassportState] = React.useState("loading");
  const [passportBackState, setPassportBackState] = React.useState("loading");
  const [visaState, setVisaState] = React.useState("loading");
  const [insuranceState, setInsuranceState] = React.useState("loading");
  const [accountNumberState, setAccountNumberState] = React.useState("loading");
  const [certificateState, setCertificateState] = React.useState("loading");
  const [questionnaireState, setQuestionnaireState] = React.useState("loading");

  const [openPopover, setOpenPopover] = React.useState(false);
  const [value, setValue] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [toDelete, setToDelete] = React.useState(null);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const popoverWidth = { xs: "100%", md: "50%" };

  useEffect(() => {
    if (!open) {
      setFormState("loading");
      setPassportState("loading");
      setPassportBackState("loading");
      setVisaState("loading");
      setInsuranceState("loading");
      setAccountNumberState("loading");
      setCertificateState("loading");
      setQuestionnaireState("loading");

      setPassport([]);
      setPassportBack([]);
      setVisa([]);
      setForm([]);
      setInsurance([]);
      setAccountNumber([]);
      setСertificate([]);
      setQuestionnaire([]);
    } else {
      HttpService.get(UrlService.apiDomain() + "api/employee_attachments/" + id)
        .then((res) => {
          let data = res.data;
          if (data.success) {
            let attachments = data.attachments;
            let f = attachments.filter((a) => a.type === "form");
            if (f.length > 0) {
              setFormState("uploaded");
              setForm(f);
            } else {
              setFormState("empty");
            }

            let p = attachments.filter((a) => a.type === "passport");
            if (p.length > 0) {
              setPassportState("uploaded");
              setPassport(p);
            } else {
              setPassportState("empty");
            }

            let pb = attachments.filter((a) => a.type === "passport_back");
            if (pb.length > 0) {
              setPassportBackState("uploaded");
              setPassportBack(pb);
            } else {
              setPassportBackState("empty");
            }

            let v = attachments.filter((a) => a.type === "visa");
            if (v.length > 0) {
              setVisaState("uploaded");
              setVisa(v);
            } else {
              setVisaState("empty");
            }

            let i = attachments.filter((a) => a.type === "insurance");
            if (i.length > 0) {
              setInsuranceState("uploaded");
              setInsurance(i);
            } else {
              setInsuranceState("empty");
            }

            let a = attachments.filter((a) => a.type === "account_number");
            if (a.length > 0) {
              setAccountNumberState("uploaded");
              setAccountNumber(a);
            } else {
              setAccountNumberState("empty");
            }

            let c = attachments.filter((a) => a.type === "certificate");
            if (c.length > 0) {
              setCertificateState("uploaded");
              setСertificate(c);
            } else {
              setCertificateState("empty");
            }

             let q = attachments.filter((a) => a.type === "questionnaire");
             if (q.length > 0) {
               setQuestionnaireState("uploaded");
               setQuestionnaire(q);
             } else {
               setQuestionnaireState("empty");
             }
          } else {
            alert(data.error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [open]);

  const upload = (e) => {
    if (type === "form") {
      setFormState("loading");
    } else if (type === "passport") {
      setPassportState("loading");
    } else if (type === "passport_back") {
      setPassportBackState("loading");
    } else if (type === "visa") {
      setVisaState("loading");
    } else if (type === "insurance") {
      setInsuranceState("loading");
    } else if (type === "account_number") {
      setAccountNumberState("loading");
    } else if (type === "certificate") {
      setCertificateState("loading");
    } else if (type === "questionnaire") {
      setQuestionnaireState("loading");
    }

    let file = e.target.files[0];
    httpPost(
      [
        {
          key: "file",
          value: file,
        },
        {
          key: "model",
          value: "employees",
        },
        {
          key: "type",
          value: type,
        },
        {
          key: "id",
          value: id,
        },
      ],
      "attachments/add",
      (result) => {
        let file = result.attachments[0];
        if (type === "form") {
          setFormState("uploaded");
          setForm((prev) => [...prev, file]);
          form.length > 0 && prepareFiles(file);
        } else if (type === "passport") {
          setPassportState("uploaded");
          passport.length > 0 && setPassport((prev) => [...prev, file]);
          prepareFiles(file);
        } else if (type === "passport_back") {
          setPassportBackState("uploaded");
          setPassportBack((prev) => [...prev, file]);
          passportBack.length > 0 && prepareFiles(file);
        } else if (type === "visa") {
          setVisaState("uploaded");
          setVisa((prev) => [...prev, file]);
          visa.length > 0 && prepareFiles(file);
        } else if (type === "insurance") {
          setInsuranceState("uploaded");
          setInsurance((prev) => [...prev, file]);
          insurance.length > 0 && prepareFiles(file);
        } else if (type === "account_number") {
          setAccountNumberState("uploaded");
          setAccountNumber((prev) => [...prev, file]);
          accountNumber.length > 0 && prepareFiles(file);
        } else if (type === "certificate") {
          setCertificateState("uploaded");
          setСertificate((prev) => [...prev, file]);
          certificate.length > 0 && prepareFiles(file);
        } else if (type === "questionnaire") {
          setQuestionnaireState("uploaded");
          setQuestionnaire((prev) => [...prev, file]);
          questionnaire.length > 0 && prepareFiles(file);
        }

        if (!result.success) {
          alert(result.message);
        }
      }
    );
  };

  const prepareFiles = (att) => {
    httpGet("attachments/download/" + att.id, (response) => {
      const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };

      const url = window.URL.createObjectURL(
        b64toBlob(response.file, response.type)
      );

      setValue((prev) => [
        ...prev,
        { id: att.id, path: url, name: response.name },
      ]);
    });
  };

  const getButton = (status, text, type) => {
    let icon = <FileUpload sx={{ fontSize: "60px" }} />;
    let onClick = () => {
      fileInputRef.current.click();
      setType(type);
    };
    if (status === "uploaded") {
      icon = <Check sx={{ fontSize: "60px", color: "green" }} />;
      onClick = () => {
        setType(type);
        let attachmentIds;
        let backToUploaded;
        if (type === "form") {
          setFormState("loading");
          backToUploaded = () => setFormState("uploaded");
          attachmentIds = form;
        } else if (type === "passport") {
          setPassportState("loading");
          backToUploaded = () => setPassportState("uploaded");
          attachmentIds = passport;
        } else if (type === "passport_back") {
          setPassportBackState("loading");
          backToUploaded = () => setPassportBackState("uploaded");
          attachmentIds = passportBack;
        } else if (type === "visa") {
          setVisaState("loading");
          backToUploaded = () => setVisaState("uploaded");
          attachmentIds = visa;
        } else if (type === "account_number") {
          setAccountNumberState("loading");
          backToUploaded = () => setAccountNumberState("uploaded");
          attachmentIds = accountNumber;
        } else if (type === "insurance") {
          setInsuranceState("loading");
          backToUploaded = () => setInsuranceState("uploaded");
          attachmentIds = insurance;
        } else if (type === "certificate") {
          setCertificateState("loading");
          backToUploaded = () => setCertificateState("uploaded");
          attachmentIds = certificate;
        } else if (type === "questionnaire") {
          setQuestionnaireState("loading");
          backToUploaded = () => setQuestionnaireState("uploaded");
          attachmentIds = questionnaire;
        }

        attachmentIds.forEach((att) => {
          prepareFiles(att);
        });

        setOpenPopover(true);
        backToUploaded();
      };
    }
    if (status === "loading") {
      icon = <CircularProgress />;
      onClick = undefined;
    }
    return (
      <>
        <Grid item xs={8} sx={{ fontSize: "40px" }}>
          {t(text)}:
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ fontSize: "40px", display: "flex", justifyContent: "end" }}
        >
          <Button
            sx={{ width: "64px", mt: "-10px" }}
            color="primary"
            className="btn btn-default btn-sm delete-ingrid-but"
            fullWidth
            onClick={onClick}
          >
            {icon}
          </Button>
        </Grid>
      </>
    );
  };
  const handleDownload = (path, name) => {
    const link = document.createElement("a");
    link.href = path;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleClose = () => {
    setValue([]);
    setOpenPopover(false);
  };

  console.log({ value });

  const deletePhoto = async (id) => {
    try {
      const response = await HttpService.delete(
        UrlService.apiDomain() + "api/delete/files/" + id
      );
      if (response.data.alert != null && response.data.alert != "") {
        alert(response.data.alert);
        return;
      }

      setValue((prev) => prev.filter((item) => item.id !== id));
      handleDialogClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Potvrdit odstranění")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              "Jste si jisti, že chcete odstranit tuto položku a všechny položky s ní spojené?"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("Ne")}
          </Button>
          <Button
            onClick={() => deletePhoto(toDelete)}
            color="primary"
            autoFocus
          >
            {t("Ano")}
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        open={openPopover}
        anchorEl={document.querySelector("body")}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        PaperProps={{
          sx: { width: popoverWidth },
        }}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.default",
            py: 5,
          }}
        >
          <Container maxWidth={settings.compact ? "xl" : false}>
            <Grid container justifyContent="end" spacing={3}>
              <Button
                sx={{ mb: 2, mr: 2 }}
                variant="outlined"
                onClick={() => fileInputRef.current.click()}
              >
                {t("Nahrát")}
              </Button>

              <Button sx={{ mb: 2 }} variant="outlined" onClick={handleClose}>
                {t("Zavřít")}
              </Button>
            </Grid>
            {value.map((val) => (
              <Box key={val.id} sx={{ mb: 2, position: "relative" }}>
                {val.name.toLowerCase().endsWith("pdf") ? (
                  <Button
                    onClick={() => handleDownload(val.path, val.name)}
                    style={{
                      width: "100%",
                      height: "auto",
                      padding: "20px",
                      backgroundColor: "#f0f0f0",
                      textAlign: "center",
                    }}
                  >
                    {`${t("Stáhnout")} PDF`}
                  </Button>
                ) : (
                  <>
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "-4px",
                        right: "24px",
                        color: "#5664d2",
                      }}
                      onClick={() => handleDownload(val.path, val.name)}
                    >
                      <DownloadIcon />
                    </IconButton>
                    <img
                      src={val.path}
                      alt="photo"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </>
                )}
                {policy_actions.includes("delete") && (
                  <IconButton
                    onClick={() => {
                      setToDelete(val.id);
                      handleOpenDialog();
                    }}
                    style={{
                      position: "absolute",
                      top: "-4px",
                      right: "-4px",
                      color: "#5664d2",
                    }}
                  >
                    <Close />
                  </IconButton>
                )}
              </Box>
            ))}
          </Container>
        </Box>
      </Popover>
      <ModalWrapper isOpen={open} onClose={onClose} size={"xs"}>
        <input
          id={"file"}
          onChange={upload}
          hidden
          ref={fileInputRef}
          name="file"
          type="file"
        />
        <Grid container spacing={2} sx={{ p: 4 }}>
          {getButton(insuranceState, "Pojištění", "insurance")}
          {getButton(formState, "Dotazník", "form")}
          {getButton(passportState, "Pas", "passport")}
          {getButton(passportBackState, "Pas - zadní", "passport_back")}
          {getButton(visaState, "Vízum", "visa")}
          {getButton(accountNumberState, "Číslo účtu", "account_number")}
          {getButton(certificateState, "Průkaz", "certificate")}
          {/* {getButton(questionnaireState, "Ostatní", "questionnaire")} */}
        </Grid>
      </ModalWrapper>
    </>
  );
}
