import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Divider } from "@material-ui/core";
import {
  addRecipient,
  getParticipants,
  getThread,
  markThreadAsSeen,
  removeRecipient,
  resetActiveThread,
} from "../../../slices/chat";
import { useDispatch, useSelector } from "../../../store";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import ChatMessageAdd from "./ChatMessageAdd";
import ChatMessages from "./ChatMessages";
import ChatThreadComposer from "./ChatThreadComposer";
import ChatThreadToolbar from "./ChatThreadToolbar";

const threadSelector = (state) => {
  const { threads, activeThreadId } = state.chat;
  const thread = threads.byId[activeThreadId];

  if (thread) {
    return thread;
  }

  return {
    id: null,
    messages: [],
    participants: [],
    unreadMessages: 0,
  };
};

const ChatThread = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { threadKey } = useParams();
  const [messages, setMessages] = useState([]);
  const [participants, setParticipants] = useState([]);
  const { activeThreadId, recipients } = useSelector((state) => state.chat);
  const thread = useSelector((state) => threadSelector(state));

  const getDetails = async () => {
    dispatch(getParticipants(threadKey));

    try {
      await dispatch(getThread(threadKey));
    } catch (err) {
      dispatch(resetActiveThread());
      // If thread key is not a valid key (thread id or username)
      // the server throws an error, this means that the user tried a shady route
      // and we redirect him on the compose route
      console.error(err);
      navigate("/dashboard/chat/new");
    }
  };
  useEffect(() => {
    if (props.node) {
      getMessages();
      setParticipants([
        { id: 0, name: "AWA Partners" },
        { id: props.node.id, name: props.node.name },
      ]);
    }
  }, [props.id]);

  useEffect(() => {
    if (activeThreadId) {
      dispatch(markThreadAsSeen(activeThreadId));
    }
  }, [activeThreadId]);

  // In our case there two possible routes
  // one that contains chat/new and one with a chat/:threadKey
  // if threadKey does not exist, it means that the chat is in compose mode
  const mode = threadKey ? "DETAIL" : "COMPOSE";

  const handleAddRecipient = (recipient) => {
    dispatch(addRecipient(recipient));
  };

  const handleRemoveRecipient = (recipientId) => {
    dispatch(removeRecipient(recipientId));
  };

  const getMessages = async () => {
    HttpService.get(
      UrlService.apiDomain() + "api/communication/" + props.node.id
    )
      .then((res) => {
        if (res.data.result == "failed") {
          alert(res.data.message);
        } else {
          var msgs = [];

          res.data.forEach((e) => {
            var d = new Date(e.created_at);
            var sender_type = "contact";
            if (e.from == props.node.id) sender_type = "user";
            msgs.push({
              id: e.id,
              body: e.text,
              createdAt: d.getTime(),
              attachment: e.attachment,
              contentType: "text",
              messageType: e.type,
              senderId: e.from,
              senderName: e.from_name,
              senderType: sender_type,
            });
          });

          setMessages(msgs);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleSendMessage = async (comm) => {
    getMessages();
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      {props.showNames && <ChatThreadToolbar participants={participants} />}

      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <ChatMessages messages={messages} participants={participants} />
      </Box>
      <Divider />
      <ChatMessageAdd
        disabled={false}
        onSend={handleSendMessage}
        node={props.node}
      />
    </Box>
  );
};

export default ChatThread;
