import UrlService from "../../services/UrlService";

const at = localStorage.getItem("accessToken");

export default function httpGet(url,
                                handleResult,
                                handleUnsuccessful = (message, response) => {
                                    alert(message);
                                    console.error("Error: ", response);
                                },
                                handleError = (error) => {
                                    alert("Chyba serveru");
                                    console.error("Error: ", error);
                                }) {
    fetch(UrlService.apiDomain() + 'api/' + url, {
        method: 'get',
        headers: {
            Authorization: "Bearer " + at,
        },
    })
        .then(r => r.json())
        .then(response => {
            if (!response.success) {
                handleUnsuccessful(response.error, response);
                return;
            }
            handleResult(response);
        })
        .catch((error) => {
            handleError(error);
        });
}