import ModalWithButton from "../modal/ModalWithButton";
import {useTranslation} from "react-i18next";
import {Button, TextField} from "@material-ui/core";
import {useState} from "react";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";

export function ChangePasswordModal(){
    const {t} = useTranslation();

    return <>
        <ModalWithButton title={t('Change password')} buttonProps={{fullWidth: true, variant: 'outlined'}}>
            <ChangePassword/>
        </ModalWithButton>
    </>
}

function ChangePassword({onClose}) {
    const {t} = useTranslation();

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

    let oldPasswordValid = oldPassword.length >= 8;
    let newPasswordValid = newPassword.length >= 8;
    let newPasswordRepeatValid = newPasswordRepeat === newPassword;
    return <>
        <TextField sx={{mt: 2}} label={t("Staré heslo")} fullWidth type={"password"}
                   error={!oldPasswordValid}
                   onChange={(event) => {
                       setOldPassword(event.target.value)
                   }
                   }/>
        <TextField sx={{mt: 2}} label={t("Nové heslo")} fullWidth type={"password"}
                   disabled={!oldPasswordValid} error={!newPasswordValid && oldPasswordValid}
                   onChange={(event) => {
                       setNewPassword(event.target.value)
                   }}/>
        <TextField sx={{mt: 2}} label={t("Zopakujte nové heslo")} fullWidth type={"password"}
                   disabled={!newPasswordValid} error={!newPasswordRepeatValid && newPasswordValid}
                   onChange={(event) => {
                       setNewPasswordRepeat(event.target.value)
                   }}/>
        <Button sx={{mt: 2}} fullWidth variant="contained" disabled={!(newPasswordRepeatValid && oldPasswordValid && newPasswordValid)}
                onClick={() => {
                    if(newPasswordRepeatValid && oldPasswordValid && newPasswordValid){
                        let formData = new FormData();
                        formData.append("old_password", oldPassword);
                        formData.append("new_password", newPassword);
                        HttpService.post(UrlService.apiDomain() + "api/password/change", formData).then((response) => {
                            if(response.data.success){
                                alert(t('Success'));
                                onClose();
                            } else {
                                alert(t('Wrong password'));
                            }
                        });
                    }
                }
                }>
            {t('Změnit heslo')}
        </Button>
    </>
}