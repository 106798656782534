import i18n from "i18next";
import en from "./translations/en.js";
import ru from "./translations/ru.js";

import cs from "./translations/cs.js";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import UrlService from "./services/UrlService";
import SessionStorageService from "./services/SessionStorageService";

window.on_public_data_load = null;
window.public_data_loaded = false;

//console.log(cs);
const initialize = async () => {
  var public_settings_version = 1;
  var private_settings_version = 1;

  if (localStorage.getItem("private_settings_version") != null)
    private_settings_version = localStorage.getItem("private_settings_version");
  if (localStorage.getItem("public_settings_version") != null)
    public_settings_version = localStorage.getItem("public_settings_version");

  const public_data = await axios.get(
    UrlService.apiDomain() + "api/public_data/" + public_settings_version
  );

  if (public_data.data.settings_version.value != public_settings_version) {
    var ru = {};

    public_data.data.translates.forEach((e) => {
      if (e.language == "ru") {
        ru[e.original] = e.translation;
      }
    });
    //public_data.data.translates = null;
    SessionStorageService.initializeLocalDB(public_data.data, true);
    // console.log(ru);
    i18n.addResourceBundle("ru", "ns", ru, true, true);
  } else {
    var ru = {};

    JSON.parse(localStorage.getItem("translates")).forEach((e) => {
      if (e.language == "ru") {
        ru[e.original] = e.translation;
      }
    });

    i18n.addResourceBundle("ru", "ns", ru, true, true);
  }

  window.public_data_loaded = true;

  if (window.on_public_data_load != null) {
    window.on_public_data_load();
  }
};
initialize();

const resources = {
  cs: {
    translation: cs,
  },

  ru: {
    translation: ru,
  },
};

var lang = "cs";
var setlang = localStorage.getItem("language");
if (setlang != "" && setlang != null) lang = setlang;

i18n.use(initReactI18next).init({
  resources,
  keySeparator: "~",
  nsSeparator: "|",
  lng: lang,
  defaultNS: "ns",
  fallbackLng: "cs",
  interpolation: {
    escapeValue: false,
  },
});

i18n.changeLanguage(localStorage.getItem("language"));
