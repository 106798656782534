import { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import SearchIcon from "../../icons/Search";
import useSettings from "../../hooks/useSettings";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import EmployeeCard from "../../components/employee_card";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import SimpleLoader from "../../components/SimpleLoader.js";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import TextEditor from "../meeting_records/TextEditor.js";
import { format } from "date-fns";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const PhoneCalls = ({ employee_id, style }) => {
  const { t } = useTranslation();

  const customComparator = (valueA, valueB) => {
    if (valueA === null || valueB === null) {
      return valueA === valueB ? 0 : valueA === null ? -1 : 1;
    }
    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else {
      // return default value for non-string and non-number values
      return 0;
    }
  };

  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");
  const [locale, setLocale] = useState(lc);

  const { settings } = useSettings();
  const [rowdata, setRowdata] = useState([]);
  const [loctext, setloctext] = useState(lt);
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState(new Date(new Date().setDate(1)));
  const [dateTo, setDateTo] = useState(new Date());
  const [search, setSearch] = useState("");

  const [openCard, setOpenCard] = useState(false);
  const [employeeId, setEmployeeId] = useState("");

  const handlePlay = async (fileName) => {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/meeting_records/get_audio`,
        {
          data: {
            audio_file: fileName,
          },
        }
      );
      if (!response.data.success) {
        alert(response.data.message);
        return;
      }
      const link = response.data.url;
      window.open(link, "_blank");
    } catch (err) {
      console.error(err);
    }
  };

  const coldefs = useMemo(
    () => [
      {
        headerName: t("Datum"),
        field: "created_at",
        cellEditor: "DateTimePicker",
        valueFormatter: (params) => {
          let d = new Date(params.value);

          let str =
            ("0" + d.getDate()).slice(-2) +
            "." +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "." +
            d.getFullYear() +
            " " +
            ("0" + d.getHours()).slice(-2) +
            ":" +
            ("0" + d.getMinutes()).slice(-2) +
            ":" +
            ("0" + d.getSeconds()).slice(-2);

          return params.value && params.value !== "1970-01-01 00:00:00"
            ? str
            : "";
        },
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: function (date1, date2) {
            function _monthToNum(date) {
              if (date === undefined || date === null || date === "") {
                return null;
              }

              var date_arr1 = date.split(" ");
              var date_arr = date_arr1[0].split(".");

              var yearNumber = parseInt(date_arr[2]);
              var monthNumber = parseInt(date_arr[1]);
              var dayNumber = parseInt(date_arr[0]);

              var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
              // 29/08/2004 => 20040829
              //console.log(result);
              return result;
            }

            var date1Number = _monthToNum(date1);
            var date2Number = _monthToNum(date2);

            if (date1Number === null && date2Number === null) {
              return 0;
            }
            if (date1Number === null) {
              return -1;
            }
            if (date2Number === null) {
              return 1;
            }

            return date1Number - date2Number;
          },
        },
        comparator: (a, b) => {
          if (a && b) {
            let aDate = new Date(a);
            let bDate = new Date(b);
            bDate.setHours(0, 0, 0, 0);
            return aDate.getTime() - bDate.getTime();
          }
          if (a) {
            return 1;
          }
          return -1;
        },
      },
      {
        headerName: t("Doba trvání"),
        field: "duration",
      },

      {
        headerName: t("Telefon volajícího"),
        field: "caller_phone",
      },
      {
        headerName: t("Jméno volajícího"),
        field: "caller_name",
        cellStyle: { color: "#5664d2" },
      },
      {
        headerName: t("Volající telefon"),
        field: "calling_phone",
      },

      {
        headerName: t("Volající jméno"),
        field: "calling_name",
        cellStyle: { color: "#5664d2" },
      },
      {
        headerName: t("Transkripce"),
        field: "transcription",
        cellRendererFramework: (params) => {
          if (params.value) {
            return <TextEditor text={params.value} readOnly={true} />;
          } else return null;
        },
      },
      {
        headerName: t("Poslouchat"),
        field: "file",
        cellRendererFramework: (params) => (
          <IconButton onClick={() => handlePlay(params.value)}>
            <PlayArrow />
          </IconButton>
        ),
      },
    ],
    []
  );

  const defaultColDef = {
    flex: 1,
    minWidth: 120,
    editable: false,
    resizable: true,
    sortable: true,
    suppressContextMenu: true,
    menuTabs: ["filterMenuTab", "columnsMenuTab"],
    filter: true,
    comparator: customComparator,
  };

  const frameworkComponents = {};

  const gtr = async () => {
    setLoading(true);
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + "api/phone_calls/get/all",
        {
          data: {
            employee_id,
            from: employee_id ? null : format(dateFrom, "yyyy-MM-dd"),
            to: employee_id ? null : format(dateTo, "yyyy-MM-dd"),
            search,
          },
        }
      );
      if (!response.data.success) {
        alert(response.data.message);
        setLoading(false);
        return;
      }
      setRowdata(response.data.data || []);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const onCellClicked = (e) => {
    const field = e.column.colId;
    if (!employee_id) {
      if (field === "caller_name" && e.node.data.caller_id) {
        setEmployeeId(e.node.data.caller_id);
        setOpenCard(true);
        return true;
      }
      if (field === "calling_name" && e.node.data.calling_id) {
        setEmployeeId(e.node.data.calling_id);
        setOpenCard(true);
        return true;
      }
    }
  };

  useEffect(() => {
    gtr();
  }, [employee_id, dateFrom, dateTo]);

  useEffect(() => {
    setloctext(lt);
  }, [t("cs")]);

  return (
    <>
      <Helmet>
        <title>Phone Calls | AWA Partners</title>
      </Helmet>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={() => {
          setOpenCard(false);
        }}
        open={openCard}
      >
        <EmployeeCard
          id={employeeId}
          child_model={"phone_calls"}
          onClose={() => {
            setOpenCard(false);
          }}
        />
      </Dialog>
      <Box
        sx={{
          backgroundColor: "background.default",
          height: "100%",
          py: 8,
        }}
      >
        <Container
          maxWidth={settings.compact ? "xl" : false}
          style={style ?? { width: "100%", height: "100%" }}
        >
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("Nahrávky telefonních hovorů")}
              </Typography>
            </Grid>
            {!employee_id && (
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={localeMap[locale]}
                    >
                      <MobileDatePicker
                        label={t("Od")}
                        mask="__.__.____"
                        openTo="day"
                        value={dateFrom}
                        onChange={(event) => {
                          setDateFrom(event);
                        }}
                        renderInput={(inputProps) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            {...inputProps}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={localeMap[locale]}
                    >
                      <MobileDatePicker
                        label={t("Do")}
                        mask="__.__.____"
                        openTo="day"
                        value={dateTo}
                        maxDate={new Date()}
                        onChange={(event) => {
                          setDateTo(event);
                        }}
                        renderInput={(inputProps) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            {...inputProps}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <TextField
                variant="outlined"
                sx={{ width: "400px", flexGrow: 1 }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t("Vyhledat...")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    gtr();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      disabled={!search}
                      aria-label="toggle password visibility"
                      onClick={gtr}
                    >
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{ mt: 3 }}
            style={{ width: "100%", height: "calc(100% - 100px)" }}
            id="myGrid"
            className="ag-theme-material"
          >
            {loading ? (
              <SimpleLoader />
            ) : (
              <AgGridReact
                modules={AllCommunityModules}
                rowData={rowdata}
                columnDefs={coldefs}
                defaultColDef={defaultColDef}
                onCellClicked={onCellClicked}
                frameworkComponents={frameworkComponents}
                enableCellChangeFlash={true}
                rowSelection={"single"}
                suppressContextMenu={true}
                localeText={loctext}
                popupParent={document.querySelector("body")}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PhoneCalls;
