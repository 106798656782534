"use strict";


const UIGridColumnCreatorT = class UIGridColumnCreatorT {
	
	constructor(
		
	){
		
	}
	
	CreateFromProperty(
		Property
	){
		
		if( Property.view === false ){
			
			return null;
			
		}
		
		let Editable = ( ( Property.disabled === false ) && ( Property.update === true ) );
		
		let CellStyle = ( ( Editable === true ) ? { } : { backgroundColor: "rgba(0,0,0,0.1)" } );
		
		if( Property.type === "boolean" ){
			
			return {
				field: Property.field,
				headerName: Property.name,
				editable: Editable,
				cellStyle: CellStyle,
				required: Property.required,
				cellRenderer: "UIGridRendererBooleanReactT",
				cellRendererParams: {
					Label: Property.name,
					Disabled: !Editable
				},
				sortable: true,
				comparator( A, B ){
					
					if( ( A === null ) && ( B === null ) ){
						
						return 0;
						
					} else if( ( A !== null ) && ( B === null ) ){
						
						return 1;
						
					} else if( ( A === null ) && ( B !== null ) ){
						
						return -1;
						
					} else {
						
						if( ( A === true ) && ( B === false ) ){
							
							return 1;
							
						} else if( ( A === false ) && ( B === true ) ){
							
							return -1;
							
						} else {
							
							return 0;
							
						}
						
					}
					
				},
				valueGetter( Params ){
					
					let Field = Params.colDef.field;
					
					let Value = Params.data[ Field ];
					
					return Value ? Value : null;
					
				},
				filter: "agSetColumnFilter",
				filterParams: {
					valueFormatter( Params ){
					
						if( Params.value === null ){
							
							return "Ne";
							
						}
						
						if( Params.value === false ){
							
							return "Ne";
							
						} else {
						
							return "Ano"
							
						}
						
					},
					comparator( A, B ){
					
						if( ( A === null ) && ( B === null ) ){
							
							return 0;
							
						} else if( ( A !== null ) && ( B === null ) ){
							
							return 1;
							
						} else if( ( A === null ) && ( B !== null ) ){
							
							return -1;
							
						} else {
							
							if( ( A === true ) && ( B === false ) ){
								
								return 1;
								
							} else if( ( A === false ) && ( B === true ) ){
								
								return -1;
								
							} else {
								
								return 0;
								
							}
							
						}
						
					}
				},
				resizable: true
			};
		
		} else if ( Property.type === "integer" ){
			
			return {
				filter: "agNumberColumnFilter",
				sortable: true,
				comparator( A, B ){
					
					if( ( A === null ) && ( B === null ) ){
						
						return 0;
						
					} else if( ( A !== null ) && ( B === null ) ){
						
						return 1;
						
					} else if( ( A === null ) && ( B !== null ) ){
						
						return -1;
						
					} else {
						
						if( A > B ){
							
							return 1;
							
						} else if( A < B ){
							
							return -1;
							
						} else {
							
							return 0;
							
						}
						
					}
					
				},
				resizable: true
			};
			
		} else if ( Property.type === "decimal" ){

			return {
				filter: "agNumberColumnFilter",
				sortable: true,
				comparator( A, B ){
					
					if( ( A === null ) && ( B === null ) ){
						
						return 0;
						
					} else if( ( A !== null ) && ( B === null ) ){
						
						return 1;
						
					} else if( ( A === null ) && ( B !== null ) ){
						
						return -1;
						
					} else {
						
						if( A > B ){
							
							return 1;
							
						} else if( A < B ){
							
							return -1;
							
						} else {
							
							return 0;
							
						}
						
					}
					
				},
				resizable: true
			};
		
		} else if( Property.type === "text" ){
			
			return {
				field: Property.field,
				headerName: Property.name,
				editable: Editable,
				cellStyle: CellStyle,
				cellEditor: "UIGridEditorTextReactT",
				cellEditorPopup: false,
				cellEditorParams: {
					Required: Property.required,
					Label: Property.name,
					Name: Property.field,
					SX: { },
					Type: "text",
					Disabled: !Editable,
					AllowedRegex: Property.allowed_regex,
					Regex: Property.regex
				},
				filter: "agTextColumnFilter",
				filterParams: {
					buttons: [ "reset" ],
					filterOptions: [ 
						"empty",
						"equals", 
						"notEqual", 
						"contains", 
						"notContains",
						"startsWith",
						"endsWith",
						{
							displayKey: "null",
							displayName: "Null",
							test: function ( FilterValue, CellValue ) {
								return ( CellValue === null );
							},
							hideFilterInput: true
						},
						{
							displayKey: "notNull",
							displayName: "Not null",
							test: function ( FilterValue, CellValue ) {
								return ( CellValue !== null );
							},
							hideFilterInput: true
						}
					],
					comparator( A, B ){
					
						if( ( A === null ) && ( B === null ) ){
							
							return 0;
							
						} else if( ( A !== null ) && ( B === null ) ){
							
							return 1;
							
						} else if( ( A === null ) && ( B !== null ) ){
							
							return -1;
							
						} else {
							
							let AC = A.toLowerCase( );
							
							let BC = B.toLowerCase( );
							
							if( AC > BC ){
								
								return 1;
								
							} else if( AC < BC ){
								
								return -1;
								
							} else {
								
								return 0;
								
							}
							
						}
						
					}
				},
				sortable: true,
				comparator( A, B ){
					
					if( ( A === null ) && ( B === null ) ){
						
						return 0;
						
					} else if( ( A !== null ) && ( B === null ) ){
						
						return 1;
						
					} else if( ( A === null ) && ( B !== null ) ){
						
						return -1;
						
					} else {
						
						let AC = A.toLowerCase( );
						
						let BC = B.toLowerCase( );
						
						if( AC > BC ){
							
							return 1;
							
						} else if( AC < BC ){
							
							return -1;
							
						} else {
							
							return 0;
							
						}
						
					}
					
				},
				resizable: true
			};
			
		} else if( Property.type === "select" ){ 
		
			return {
				field: Property.field,
				headerName: Property.name,
				editable: Editable,
				cellStyle: CellStyle,
				cellEditor: "UIGridEditorSelectReactT",
				cellEditorPopup: false,
				cellEditorParams: {
					options: Property.options,
					required: Property.required,
					label: Property.name,
					name: Property.field,
					sx: { },
					type: "text",
					disabled: !Editable
				},
				valueSetter( Params ){
					
					let Field = Params.colDef.field;
					
					Params.data[ Field ] = Params.newValue;
					
					return true;
					
					
				},
				valueFormatter( Params ){
						
					if( Params.value === null ){
						
						return "";
						
					}
					
					let Option = Property.options[ Params.value.toString( ) ];
					
					return Option ? Option.name : "";
					
				},
				valueGetter( Params ){
					
					let Field = Params.colDef.field;
					
					let Value = Params.data[ Field ];
					
					return Value ? Value : null;
					
				},
				filter: "agSetColumnFilter",
				filterParams: {
					valueFormatter( Params ){
					
						if( Params.value === null ){
							
							return "";
							
						}
						
						let Option = Property.options[ Params.value.toString( ) ];
						
						return Option ? Option.name : "";
						
					},
					comparator: function( A, B ) {
						
						if( ( A === null ) && ( B === null ) ){
							
							return 0;
							
						} else if( ( A !== null ) && ( B === null ) ){
							
							return 1;
							
						} else if( ( A === null ) && ( B !== null ) ){
							
							return -1;
							
						} else {
							
							let AText = ( Property.options[ A.toString( ) ] ? Property.options[ A.toString( ) ].name : "" );
							
							let BText = ( Property.options[ B.toString( ) ] ? Property.options[ B.toString( ) ].name : "" );
							
							if( AText > BText ){
								
								return 1;
								
							} else if( AText < BText ){
								
								return -1;
								
							} else {
								
								return 0;
								
							}
							
						}
						
					}
				},
				sortable: true,
				comparator: function( A, B ) {
					
					if( ( A === null ) && ( B === null ) ){
						
						return 0;
						
					} else if( ( A !== null ) && ( B === null ) ){
						
						return 1;
						
					} else if( ( A === null ) && ( B !== null ) ){
						
						return -1;
						
					} else {
						
						let AText = ( Property.options[ A.toString( ) ] ? Property.options[ A.toString( ) ].name : "" );
						
						let BText = ( Property.options[ B.toString( ) ] ? Property.options[ B.toString( ) ].name : "" );
						
						if( AText > BText ){
							
							return 1;
							
						} else if( AText < BText ){
							
							return -1;
							
						} else {
							
							return 0;
							
						}
						
					}
					
				},
				resizable: true
			};
		
		} else if( Property.type === "date" ) { 
		
			return {
				field: Property.field,
				headerName: Property.name,
				editable: Editable,
				cellStyle: CellStyle,
				cellEditor: "UIGridEditorDateReactT",
				cellEditorPopup: false,
				cellEditorParams: {
					Required: Property.required,
					Label: Property.name,
					Name: Property.field,
					Disabled: !Editable
				},
				valueFormatter: function( params ) {
					
					let Value = params.value;
					
					if( Value === null ){
						
						return "";
						
					}
					
					let ValueDate = new Date( Value );
					
					const Day = ( ValueDate.getUTCDate( ) ).toString( );
					
					const Month = ( ValueDate.getUTCMonth( ) + 1 ).toString( );

					const Year = ( ValueDate.getUTCFullYear( ) ).toString( );
					
					return Day.padStart( 2, "0" ) + "." + Month.padStart( 2, "0" ) + "." + Year.padStart( 4, "0" );
					
				},
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: [ "reset" ],
					filterOptions: [ 
						"empty",
						"equals", 
						"notEqual", 
						"lessThan", 
						"lessThanOrEqual",
						"greaterThan",
						"greaterThanOrEqual",
						"inRange", 
						"blank", 
						"notBlank"
					],
					comparator: function( FilterDate, A ) {
						
						let B = null;
						
						if( FilterDate ){
						
							const Day = ( FilterDate.getDate( ) ).toString( );
						
							const Month = ( FilterDate.getMonth( ) + 1 ).toString( );

							const Year = ( FilterDate.getFullYear( ) ).toString( );
						
							B = Year.padStart( 4, "0" ) + "-" + Month.padStart( 2, "0" ) + "-" + Day.padStart( 2, "0" );
						
						}
						
						if( ( A === null ) && ( B === null ) ){
						
							return 0;
						
						} else if( ( A !== null ) && ( B === null ) ){
							
							return 1;
							
						} else if( ( A === null ) && ( B !== null ) ){
							
							return -1;
							
						} else {
							
							if( A > B ){
								
								return 1;
								
							} else if( A < B ){
								
								return -1;
								
							} else {
								
								return 0;
								
							}
							
						}
						
					}
					
				},
				sortable: true,
				comparator: function( A, B ) {
					
					if( ( A === null ) && ( B === null ) ){
					
						return 0;
					
					} else if( ( A !== null ) && ( B === null ) ){
						
						return 1;
						
					} else if( ( A === null ) && ( B !== null ) ){
						
						return -1;
						
					} else {
						
						if( A > B ){
							
							return 1;
							
						} else if( A < B ){
							
							return -1;
							
						} else {
							
							return 0;
							
						}
						
					}
					
				},
				resizable: true
			};
		
		} else if( Property.type === "datetime" ) { 
		
			return {
				field: Property.field,
				headerName: Property.name,
				editable: Editable,
				cellStyle: CellStyle,
				cellEditor: "UIGridEditorDatetimeReactT",
				cellEditorPopup: false,
				cellEditorParams: {
					Required: Property.required,
					Label: Property.name,
					Name: Property.field,
					Disabled: !Editable
				},
				valueFormatter: function( params ) {
					
					let Value = params.value;
					
					if( Value === null ){
						
						return "";
						
					}
					
					let ValueDate = new Date( Value );
					
					const Day = ( ValueDate.getUTCDate( ) ).toString( );
					
					const Month = ( ValueDate.getUTCMonth( ) + 1 ).toString( );

					const Year = ( ValueDate.getUTCFullYear( ) ).toString( );
					
					const Hours = ( ValueDate.getHours( ) ).toString( );
					
					const Minutes = ( ValueDate.getMinutes( ) ).toString( );

					const Seconds = ( ValueDate.getSeconds( ) ).toString( );
					
					return Day.padStart( 2, "0" ) + "." + Month.padStart( 2, "0" ) + "." + Year.padStart( 4, "0" ) +
						" " +
						Hours.padStart( 2, "0" ) + ":" + Minutes.padStart( 2, "0" ) + ":" + Seconds.padStart( 2, "0" );
					
				},
				filter: "agDateColumnFilter",
				filterParams: {
					buttons: [ "reset" ],
					filterOptions: [ 
						"empty",
						"equals", 
						"notEqual", 
						"lessThan", 
						"lessThanOrEqual",
						"greaterThan",
						"greaterThanOrEqual",
						"inRange", 
						"blank", 
						"notBlank"
					],
					comparator: function( FilterDate, A ) {
						
						let B = null;
						
						if( FilterDate ){
						
							const Day = ( FilterDate.getDate( ) ).toString( );
						
							const Month = ( FilterDate.getMonth( ) + 1 ).toString( );

							const Year = ( FilterDate.getFullYear( ) ).toString( );
							
							const Hours = ( FilterDate.getHours( ) ).toString( );
					
							const Minutes = ( FilterDate.getMinutes( ) ).toString( );

							const Seconds = ( FilterDate.getSeconds( ) ).toString( );
						
							B = Year.padStart( 4, "0" ) + "-" + Month.padStart( 2, "0" ) + "-" + Day.padStart( 2, "0" ) +
								" " +
								Hours.padStart( 2, "0" ) + ":" + Minutes.padStart( 2, "0" ) + ":" + Seconds.padStart( 2, "0" );
						
						}
						
						if( ( A === null ) && ( B === null ) ){
						
							return 0;
						
						} else if( ( A !== null ) && ( B === null ) ){
							
							return 1;
							
						} else if( ( A === null ) && ( B !== null ) ){
							
							return -1;
							
						} else {
							
							if( A > B ){
								
								return 1;
								
							} else if( A < B ){
								
								return -1;
								
							} else {
								
								return 0;
								
							}
							
						}
						
					}
					
				},
				sortable: true,
				comparator: function( A, B ) {
					
					if( ( A === null ) && ( B === null ) ){
					
						return 0;
					
					} else if( ( A !== null ) && ( B === null ) ){
						
						return 1;
						
					} else if( ( A === null ) && ( B !== null ) ){
						
						return -1;
						
					} else {
						
						if( A > B ){
							
							return 1;
							
						} else if( A < B ){
							
							return -1;
							
						} else {
							
							return 0;
							
						}
						
					}
					
				},
				resizable: true
			};
		
		} else {
			
			throw new Error( "Unknown type" );
			
		}
		
	}
	
	Sort( 
		A,
		B
	){
		
		if( ( A.order !== null ) && ( B.order === null ) ){
			
			return -1;
			
		} else if( ( A.order === null ) && ( B.order !== null ) ){
		
			return 1;
		
		} else if( ( A.order === null ) && ( B.order === null ) ){
			
			return 0;
			
		} else {
			
			if( A.order > B.order ){
			
				return 1;

			} else if( A.order < B.order ){
				
				return -1;
				
			} else {
				
				return 0;
				
			}
			
		}
		
	}
	
	CreateFromProperties(
		Properties
	){
		
		let ColDefs = new Map( );
		
		
		let PropertiesSorted = Properties.sort( this.Sort.bind( this ) );
		
		console.log( PropertiesSorted );
		
		
		let Groups = [ ];
		
		for(
			let I = 0;
			I < PropertiesSorted.length;
			I++
		){
			
			let Property = PropertiesSorted[ I ];
			
			let ColDef = this.CreateFromProperty( Property );
			
			if( ColDef === null ){
				
				continue;
				
			}

			
			ColDefs.set( Property.field, ColDef );
			
		}
		
		return ColDefs;
		
	}
	
};


export default UIGridColumnCreatorT;