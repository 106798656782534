"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


const UILabelReactT = class UILabelReactT extends React.Component {
	
	constructor( Props ){
		
		console.log( "UILabelReactT.constructor", Props );
		
		
		if( Props.Children ){
		
			if( ( Props.Children instanceof Array ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
			for(
				let I = 0;
				I < Props.Children.length;
				I++
			){
				
				if( typeof( Props.Children[ I ] ) !== "string" ){
					
					throw new Error( "Argument" );
					
				}
				
			}
		
		}
		
		
		super( Props );
		
		
		this.state = {
			key: ( Props.key ? Props.key : null ),
			SX: ( ( Props.SX ) ? Props.SX : { ml: 0, px: 0 } ),
			Variant: ( ( Props.Variant ) ? Props.Variant : "string" ),
			Color: ( ( Props.Color ) ? Props.Color : "textPrimary" ),
			Children: ( ( Props.Children ) ? Props.Children : [ ] )
		};
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UILabelReactT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		if( Name === "Children" ){
			
			if( ( Value instanceof Array ) === false ){
			
				throw new Error( "Argument" );
				
			}
			
			for(
				let I = 0;
				I < Value.length;
				I++
			){
				
				if( typeof( Value[ I ] ) !== "string" ){
					
					throw new Error( "Argument" );
					
				}
				
			}
			
		}
		
		
		this.setState( {
			[ Name ]: Value
		} );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UILabelReactT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.state[ Name ];
		
	}
	
	render(
	
	){
		
		return React.createElement(
			MaterialUI.Typography,
			{
				key: this.state.key,
				sx: this.state.SX,
				variant: this.state.Variant,
				color: this.state.Color
			},
			[
				...this.state.Children
			]
		);
		
	}
	
};


export default UILabelReactT;