import { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, useFormik } from "formik";
import * as Yup from "yup";
import { MobileDatePicker, TimePicker } from "@material-ui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import { Add as AddIcon } from "@material-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import scanPassport from "../../api/scanPassport";
import {
  Alert,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Box,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
  LinearProgress,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { format } from "date-fns";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";

import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const ReservForm = ({
  data = {},
  handleClosePopover = () => {},
  getRowdata = () => {},
  getMainTableData = () => {},
  hostel_id = "",
  user_info = {},
}) => {
  const { info: { employee_id } = {}, volná_místa = "" } = data;
  const {
    name,
    surname,
    id = "",
    date_of_birth,
    employeeId,
    jméno,
    příjmení,
  } = user_info;

  const { t } = useTranslation();
  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");
  const [locale, setLocale] = useState(lc);
  const [loctext, setloctext] = useState(lt);

  const { settings } = useSettings();
  const [people, setPeople] = useState([]);
  const [personFields, setPersonFields] = useState([0]);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [freeHostels, setFreeHostels] = useState([]);
  const [freeRooms, setFreeRooms] = useState([]);
  const passportInputRef = useRef(null);
  const [failedText, setFailedText] = useState("");
  const [openFailed, setOpenFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).agency_accommodations;

  const addPersonField = () => {
    const newPersonFields = [...personFields];
    newPersonFields.push(newPersonFields.length);
    setPersonFields(newPersonFields);
  };
  const removePersonField = (index) => {
    const newPersonFields = personFields.filter(
      (personField, i) => i !== index
    );
    const newPeople = formik.values.people.filter((item, i) => i !== index);
    formik.setFieldValue("people", newPeople);
    setPersonFields(newPersonFields);
  };
  const formik = useFormik({
    initialValues: {
      showExtraFields: false,
      showDetails: false,
      hostel: data.ubytovna || "",
      room: data.pokoj || "",
      date: new Date(),
      // time: (() => {
      //   const defaultTime = new Date();
      //   defaultTime.setHours(23);
      //   defaultTime.setMinutes(59);
      //   return defaultTime;
      // })(),
      people: [{ id: id || employeeId }],
      pickupDate: new Date(),
      pickupTime: new Date(),
      pickupPlace: "",
      pickupCity: "Plzeň",
      firstName: "",
      lastName: "",
      birthDate: "",
      note: "",
    },
    validationSchema: Yup.object({
      hostel: Yup.string().required(t("Povinné")),
      room: Yup.string().required(t("Povinné")),
      date: Yup.date().required(t("Povinné")),
      // time: Yup.date().required("Povinné"),
      showExtraFields: Yup.boolean(),
      showDetails: Yup.boolean(),
      note: Yup.string(),
      people: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
          })
        )
        .when("showExtraFields", {
          is: true,
          then: Yup.array().notRequired(),
          otherwise: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required(t("Je vyžadována alespoň jedna osoba")),
            })
          ),
        }),

      firstName: Yup.string().when("showExtraFields", {
        is: true,
        then: Yup.string().required(t("Povinné")),
      }),
      lastName: Yup.string().when("showExtraFields", {
        is: true,
        then: Yup.string().required(t("Povinné")),
      }),
      birthDate: Yup.string().when("showExtraFields", {
        is: true,
        then: Yup.string()
          .required(t("Povinné"))
          .matches(
            /^\d{2}\.\d{2}\.\d{4}$/,
            t("Neplatný formát data. Použijte formát dd.mm.rrrr")
          ),
      }),
    }),
    onSubmit: (values) => {
      handleOpen();
    },
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const closeAllDialogs = () => {
    handleClosePopover();
    handleClose();
    setOpenConfirm(false);
  };

  const handleSearch = (value) => {
    const currentId = formik.values.people[0]?.id || "";
    if (value.length > 2 && value.length < 5) {
      HttpService.get(
        UrlService.apiDomain() + `api/getAvailableUsers?search=${value}`,
        {
          id: currentId,
          employee_id: employee_id || "",
        }
      )
        .then((res) => {
          setPeople([...people, ...res.data]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleHostelSearch = (value) => {
    if (value.length > 2 && value.length < 5) {
      HttpService.get(
        UrlService.apiDomain() + `api/getFreeHostels?search=${value}`
      )
        .then((res) => {
          setFreeHostels(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRoomsSearch = (hostel_id) => {
    HttpService.get(UrlService.apiDomain() + `api/getFreeRooms/` + hostel_id)
      .then((res) => {
        setFreeRooms(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadPassport = (e) => {
    setIsLoading(true);
    const files = Array.from(e.target.files);
    scanPassport(
      files[0],
      (result, passport, photo) => {
        formik.setFieldValue("firstName", result.name);
        formik.setFieldValue("lastName", result.surname);
        formik.setFieldValue(
          "birthDate",
          format(new Date(result.date_of_birth), "dd.MM.yyyy")
        );

        setIsLoading(false);
      },
      (error) => {
        setFailedText(t("Chyba čtení pasu"));
        setOpenFailed(true);
      }
    );
  };

  const sendData = async (values) => {
    handleClose();

    const data = {
      hostel_id: hostel_id || values.hostel,
      room: values.room,
      date: format(values.date, "yyyy-MM-dd"),
      // time: format(values.time, "HH:mm:ss"),
      people: values.people.map((person) => person.id),
      pickup_place: values.pickupPlace,
      pickup_city: values.pickupCity,
      pickup_date: format(values.pickupDate, "yyyy-MM-dd"),
      pickup_time: format(values.pickupTime, "HH:mm:ss"),
      firstName: values.firstName,
      lastName: values.lastName,
      birthDate: values.birthDate,
      showDetails: values.showDetails,
      note: values.note,
    };
    try {
      const response = await HttpService.post(
        `${UrlService.apiDomain()}api/setReservation`,
        data
      );
      if (response.status === 418) {
        setAlertMessage(
          t("Není možné odbavit muže se ženami, pokud netvoří pár")
        );
        setShowAlert(true);
        return;
      } else if (response.status === 419) {
        setAlertMessage(t("Uživatel existuje"));
        setShowAlert(true);
        return;
      } else if (response.status === 420) {
        setAlertMessage(t("Nedostatek volných míst"));
        setShowAlert(true);
        return;
      } else {
        getRowdata();
        getMainTableData();
        formik.handleReset();
        setOpenConfirm(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 8,
      }}
    >
      <Container maxWidth={settings.compact ? "xl" : false}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h5">
              {t("Rezervovat")}
            </Typography>
          </Grid>
          <Grid item>
            {policy_actions.includes("driver") ? (
              <Button variant="outlined" onClick={formik.handleReset}>
                {t("Resetovat")}
              </Button>
            ) : (
              <Button variant="outlined" onClick={handleClosePopover}>
                {t("Zavřít")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Box sx={{ p: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disabled={data.ubytovna && true}
                      id={`hostel`}
                      name={`hostel`}
                      options={freeHostels}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        formik.setFieldValue(`hostel`, value?.id);
                        handleRoomsSearch(value?.id);
                      }}
                      onInputChange={(e, value) => handleHostelSearch(value)}
                      onBlur={formik.handleBlur}
                      value={
                        freeHostels.find(
                          (hostel) => hostel.id === formik.values.hostel
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={data.ubytovna ? data.ubytovna : t("Ubytovna")}
                          error={
                            !!(formik.touched.hostel && formik.errors.hostel)
                          }
                          helperText={
                            formik.touched.hostel && formik.errors.hostel
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disabled={data.ubytovna && true}
                      id={`room`}
                      name={`room`}
                      options={freeRooms}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        formik.setFieldValue(`room`, value?.name);
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        freeRooms.find(
                          (room) => room.name === formik.values.room
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={data.ubytovna ? data.pokoj : t("Pokoj")}
                          error={!!(formik.touched.room && formik.errors.room)}
                          helperText={formik.touched.room && formik.errors.room}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[locale]}
                  >
                    <MobileDatePicker
                      disabled={!policy_actions.includes("reserve_long")}
                      label={t("Datum")}
                      inputFormat="dd/MM/yyyy"
                      value={formik.values.date}
                      onChange={(value) => {
                        formik.setFieldValue("date", value);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {personFields.map((personField, index) => (
                  <Grid item xs={12} key={personField} sx={{ display: "flex" }}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disabled={jméno || (name && true)}
                        id={`people[${personField}].id`}
                        name={`people[${personField}].id`}
                        options={people}
                        getOptionLabel={(option) =>
                          `${option.name} ${option.surname}, ${option.date_of_birth}, ${option?.code}, Číslo pasu: ${option?.passport_number}`
                        }
                        onChange={(e, value) => {
                          formik.setFieldValue(
                            `people[${personField}].id`,
                            value?.id
                          );
                        }}
                        onInputChange={(e, value) => handleSearch(value)}
                        onBlur={formik.handleBlur}
                        value={
                          (people &&
                            people.find(
                              (person) =>
                                person.id ===
                                formik.values.people[personField]?.id
                            )) ||
                          null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              (jméno &&
                                `${jméno} ${příjmení} ${date_of_birth}`) ||
                              (name && `${name} ${surname} ${date_of_birth}`) ||
                              t("Zadejte první tři písmena příjmení")
                            }
                            error={
                              !!(
                                formik.touched.people &&
                                formik.touched.people[personField] &&
                                formik.touched.people[personField].id &&
                                formik.errors.people &&
                                formik.errors.people[personField] &&
                                formik.errors.people[personField].id
                              )
                            }
                            helperText={
                              formik.touched.people &&
                              formik.touched.people[personField] &&
                              formik.touched.people[personField].id &&
                              formik.errors.people &&
                              formik.errors.people[personField] &&
                              formik.errors.people[personField].id
                            }
                          />
                        )}
                      />
                    </FormControl>
                    {index > 0 && (
                      <IconButton onClick={() => removePersonField(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    {index === 0 && (
                      <IconButton
                        disabled={
                          (!policy_actions.includes("overbooking") &&
                            volná_místa === personFields.length) ||
                          jméno ||
                          (name && true)
                        }
                        onClick={addPersonField}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mb: 2 }}
                  name="note"
                  label={t("Poznámka")}
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.note}
                  error={!!(formik.touched.note && formik.errors.note)}
                  helperText={formik.touched.note && formik.errors.note}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={t("Potřebuje dopravu")}
                  control={
                    <Checkbox
                      id="showDetails"
                      name="showDetails"
                      type="checkbox"
                      onChange={(event) => {
                        formik.handleChange(event);
                        formik.setFieldValue("pickupPlace", "");
                      }}
                      onBlur={formik.handleBlur}
                      checked={formik.values.showDetails}
                    />
                  }
                />
              </Grid>
              {formik.values.showDetails && (
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="pickupPlace"
                      label={t("Místo")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pickupPlace}
                      error={
                        !!(
                          formik.touched.pickupPlace &&
                          formik.errors.pickupPlace
                        )
                      }
                      helperText={
                        formik.touched.pickupPlace && formik.errors.pickupPlace
                      }
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      name="pickupCity"
                      label={t("Město")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pickupCity}
                      error={
                        formik.touched.pickupCity && formik.errors.pickupCity
                      }
                      helperText={
                        formik.touched.pickupCity && formik.errors.pickupCity
                      }
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={localeMap[locale]}
                    >
                      <MobileDatePicker
                        disabled={!policy_actions.includes("reserve_long")}
                        label={t("Datum setkání")}
                        inputFormat="dd/MM/yyyy"
                        value={formik.values.pickupDate}
                        onChange={(value) => {
                          formik.setFieldValue("pickupDate", value);
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {formik.touched.pickupDate && formik.errors.pickupDate && (
                    <div>{formik.errors.pickupDate}</div>
                  )}

                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      ampm={false}
                      label={t("Čas setkání")}
                      value={formik.values.pickupTime}
                      onChange={(value) => {
                        formik.setFieldValue("pickupTime", value);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText=""
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  {formik.touched.pickupTime && formik.errors.pickupTime && (
                    <div>{formik.errors.pickupTime}</div>
                  )}
                </Grid>
              )}

              <Grid item>
                <FormControlLabel
                  label={t("Neexistuje v databázi")}
                  control={
                    <Checkbox
                      disabled={
                        policy_actions.includes("driver") ? false : true
                      }
                      id="showExtraFields"
                      name="showExtraFields"
                      type="checkbox"
                      onChange={(event) => {
                        formik.handleChange(event);
                        formik.setFieldValue("firstName", "");
                        formik.setFieldValue("lastName", "");
                        formik.setFieldValue("birthDate", "");
                      }}
                      onBlur={formik.handleBlur}
                      checked={formik.values.showExtraFields}
                    />
                  }
                />
              </Grid>
              {formik.values.showExtraFields && (
                <Grid item container spacing={2}>
                  <TextField
                    sx={{ mb: 2 }}
                    name="firstName"
                    label={t("Jméno")}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    error={
                      !!(formik.touched.firstName && formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />

                  <TextField
                    sx={{ mb: 2 }}
                    name="lastName"
                    label={t("Příjmení")}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    error={
                      !!(formik.touched.lastName && formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                  <TextField
                    sx={{ mb: 2 }}
                    name="birthDate"
                    label={t("Zadejte datum ve formátu dd.mm.rrrr")}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.birthDate}
                    error={
                      !!(formik.touched.birthDate && formik.errors.birthDate)
                    }
                    helperText={
                      formik.touched.birthDate && formik.errors.birthDate
                    }
                  />
                  <input
                    id={"passportInput"}
                    onChange={uploadPassport}
                    hidden
                    ref={passportInputRef}
                    name="file"
                    type="file"
                  />
                  <Button
                    sx={{ m: "0 auto", display: "block" }}
                    color="primary"
                    type="button"
                    variant="contained"
                    onClick={() => passportInputRef.current.click()}
                  >
                    {t("Nahrát pas")}
                  </Button>
                  {isLoading && <LinearProgress />}

                  <Grid item xs={12}>
                    <Collapse in={openFailed}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenFailed(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {failedText}
                      </Alert>
                    </Collapse>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                {showAlert && (
                  <Alert
                    severity="error"
                    onClose={handleAlertClose}
                    open={showAlert}
                  >
                    {alertMessage}
                  </Alert>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                >
                  {t("Rezervovat")}
                </Button>
              </Grid>
            </Grid>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("Potvrďte rezervaci")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t("Opravdu si chcete rezervovat místo v pokoji?")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t("Zrušení")}
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  onClick={() => sendData(formik.values)}
                >
                  {t("Rezervovat")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={openConfirm} onClose={closeAllDialogs}>
              <DialogTitle>{t("Potvrzení rezervace")}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("Rezervace úspěšně vytvořena")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeAllDialogs} color="primary">
                  {t("Zavřít")}
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default ReservForm;
