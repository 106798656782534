import React, { useState, useEffect } from "react";
import { Modal, Button, Dialog } from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";
import KanbanCardAction from "./KanbanCardAction";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const PrioritySelector = ({ card, handleDetailsUpdate }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [priority, setPriority] = useState(card.priority);
  const { user } = useAuth();
  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies;

  const disabled =
    user.id == card?.created_by || policy_actions.includes("edit");

  const handleIconClick = (event) => {
    setShowModal(true);
  };

  const handlePrioritySelect = (selectedPriority) => {
    setPriority(selectedPriority);
    handleDetailsUpdate({ priority: selectedPriority });
    setShowModal(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "25px",
          height: "25px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          if (disabled) {
            handleIconClick();
          } else return null;
        }}
      >
        {/* В зависимости от выбранного приоритета будет отображаться соответствующая иконка */}
        {priority === "low" && (
          <img
            src="/static/icons/low.svg"
            alt="Low Priority"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        )}
        {priority === "medium" && (
          <img
            src="/static/icons/medium.svg"
            alt="Medium Priority"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        )}
        {priority === "high" && (
          <img
            src="/static/icons/hight.svg"
            alt="High Priority"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        )}
        {!priority && (
          <img
            src="/static/icons/no_priority.svg"
            alt="No Priority"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        )}
      </Box>

      {/* Модальное окно */}
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">{t("Vyberte prioritu")}</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2 }}>
            <img
              src="/static/icons/no_priority.svg"
              alt="No Priority"
              style={{
                width: "10%",
                height: "10%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => handlePrioritySelect("")}
            />
            <img
              src="/static/icons/low.svg"
              alt="Low Priority"
              style={{
                width: "10%",
                height: "10%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => handlePrioritySelect("low")}
            />
            <img
              src="/static/icons/medium.svg"
              alt="Medium Priority"
              style={{
                width: "10%",
                height: "10%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => handlePrioritySelect("medium")}
            />
            <img
              src="/static/icons/hight.svg"
              alt="High Priority"
              style={{
                width: "10%",
                height: "10%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => handlePrioritySelect("high")}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default PrioritySelector;
