import { Checkbox } from "@material-ui/core";

const CheckRenderer = (props) => {
  const { value, column, node, disabled } = props;
  const checked = value != null ? Boolean(value) : false;

  const checkedHandler = (event) => {
    const checked = event.target.checked;
    const colId = column.colId;
    node.setDataValue(colId, checked);
  };
  if (value == null) return null;

  return (
    <Checkbox checked={checked} onChange={checkedHandler} disabled={disabled} />
  );
};

export default CheckRenderer;
