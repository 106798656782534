import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { Button } from "@material-ui/core";
import latinize from "latinize";

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleAutocompleteForMap(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    props.onChange(value);
  }, [value]);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (props.isLoaded) {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }
      if (!autocompleteService.current) {
        return undefined;
      }

      if (inputValue === "") {
        setOptions(value ? [value] : []);
        return undefined;
      }

      fetch({ input: inputValue }, (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

      return () => {
        active = false;
      };
    }
  }, [value, inputValue, fetch, props.isLoaded]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue ? newValue : "");
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          label={props.label}
          variant="outlined"
          error={props.error}
          helperText={props.helperText}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
      )}
      renderOption={(props, option) => {
        let fullName = "";
        let parts = [];
        if (option.structured_formatting) {
          fullName = `${option.structured_formatting.main_text}, ${option.structured_formatting.secondary_text}`;
          const matches =
            option.structured_formatting?.main_text_matched_substrings ?? [];
          parts = parse(
            option.structured_formatting?.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
        }
        return (
          <Grid container alignItems="center">
            <Button
              variant="text"
              fullWidth
              onClick={() => {
                setValue(latinize(fullName));
                setOpen(false);
              }}
            >
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs sx={{ textAlign: "left" }}>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting?.secondary_text ?? ""}
                </Typography>
              </Grid>
            </Button>
          </Grid>
        );
      }}
    />
  );
}
