import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {Backspace, Clear} from "@material-ui/icons";
import {IconButton, TextField, ThemeProvider} from "@material-ui/core";
import {DesktopDatePicker, LocalizationProvider} from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import {createTheme} from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
    },
})


export default forwardRef((props, ref) => {
    const getValueFromProps = () => {
        dayjs.extend(customParseFormat);
        if(!props?.value || props.value === "") return "";
        let date = props.value;

        let result = null;
        if (/^\d{1,2}[.]\d{1,2}[.]\d{4}$/.test(date)) {
            let input = date.split(".");
            date = input[2] + "-" + input[1].padStart(2,'0') + "-" + input[0].padStart(2,'0');
        } else if(/^\d{4}-\d{1,2}-\d{1,2}$/.test(date)) {
            let input = date.split("-");
            date = input[0] + "-" + input[1].padStart(2,'0') + "-" + input[2].padStart(2,'0');
        }
        result = dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

        return result;
    }

    const [value, setValue] = useState(getValueFromProps());
    const [valueStr, setValueStr] = useState(value ? dayjs(value).format('DD.MM.YYYY HH:mm:ss') : null);
    const [lastValid, setLastValid] = useState(getValueFromProps());

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return lastValid;
            },
            isPopup: () => {
                return true;
            }
        };
    });

    useEffect(() => {
        dayjs.extend(customParseFormat);

        if (!valueStr) {
            if (!value) {
                setLastValid(null);
            } else {
                setLastValid(dayjs(value).format('YYYY-MM-DD'));
            }
            return;
        }
        if (dayjs(valueStr, 'DD.MM.YYYY HH:mm:ss', true).isValid()) {
            setLastValid(dayjs(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }, [valueStr, value]);

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    clearable
                    inputFormat="dd.MM.yyyy HH:mm:ss"
                    value={value ? dayjs(value) : null}
                    onChange={(date, dateStr) => {
                        setValue(date);
                        if(date && !dateStr){
                            dateStr = dayjs(date).format('DD.MM.YYYY HH:mm:ss');
                        }
                        setValueStr(dateStr);
                    }}
                    renderInput={(params) => {
                        params.inputProps.value = valueStr ?? "";
                        return <TextField {...params} size={"small"}/>}}
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton
                                    title={'Clear'}
                                    onClick={() => {
                                        setValue(null);
                                        setValueStr(null);
                                    }}>
                                    <Backspace/>
                                </IconButton>
                                <IconButton
                                    title={'Close'}
                                    onClick={() => props.stopEditing()}>
                                    <Clear/>
                                </IconButton>
                            </>
                        )
                    }}
                    InputAdornmentProps={{
                        position: "start"
                    }}
                />
            </LocalizationProvider>
        </ThemeProvider>
    )
})



