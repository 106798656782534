import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import Communication from "../communication";
import Profile from "../profile";
import GenerateDocuments from "../generate_documents/index.js"; //remove last part of route to work with assignments & employments select
import GeneratedDocuments from "../generated_documents";
import EmployeeModels from "../employee_models";
import SimpleLoader from "../SimpleLoader.js";
import DownpaymentsRequests from "../../pages/downpayments/downpaymentsRequests";
import AccountingRequests from "../../pages/accounting_requests/index.js";
import PhoneCalls from "../../pages/phone_calls/index.js";
/*
import {
    AccountBillingSettings,
    AccountGeneralSettings,
    AccountNotificationsSettings,
    AccountSecuritySettings
} from '../../components/dashboard/account';
*/
import useSettings from "../../hooks/useSettings";
import gtm from "../../lib/gtm";
import { Close } from "@material-ui/icons";

const EmployeeCard = (props) => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useState(props.child_model);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );
  const tabs = [];

  useEffect(() => {
    grd();
  }, [props.id]);

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  useEffect(() => {
    setCurrentTab(props.child_model);
  }, [props.child_model]);

  if (
    policy_action_tree.employees.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Profil"), value: "profile" });
  if (
    policy_action_tree.interactions.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({
      label: t("Interakce"),
      value: "interactions",
    });
  if (
    policy_action_tree.employees.includes("generate_documents") &&
    !props.accommodationreports
  )
    tabs.push({
      label: t("Generovat"),
      value: "generate_documents",
    });
  if (
    policy_action_tree.employees.includes("attachments") &&
    !props.accommodationreports
  )
    // elki-palki
    tabs.push({
      label: t("Dokumenty"),
      value: "generated_documents",
    });

  if (
    policy_action_tree.employees.includes("docusign") &&
    !props.accommodationreports
  )
    tabs.push({
      label: t("Podpisy"),
      value: "signed_documents",
    });
  if (
    policy_action_tree.employments.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Pracovní poměry"), value: "employments" });
  if (
    policy_action_tree.assignments.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Přiřazení"), value: "assignments" });
  if (
    policy_action_tree.accounting_requests.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Požadavky účetní"), value: "accounting_requests" });
  if (
    policy_action_tree.visas.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Víza"), value: "visas" });
  if (
    policy_action_tree.suppliers.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Dodavatele"), value: "suppliers" });
  if (policy_action_tree.agency_accommodations.includes("browse"))
    tabs.push({
      label: t("Ubytování agentury"),
      value: "agency_accommodations",
    });
  if (
    policy_action_tree.rests.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Resty"), value: "rests" });
  if (
    policy_action_tree.pokuty_ubytovatel.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Škody ubytovatel"), value: "pokuty_ubytovatel" });
  if (
    policy_action_tree.fines.includes("ubytovatel") &&
    !props.accommodationreports
  )
    tabs.push({
      label: t("Pokuty - Ubytovatel"),
      value: "fines.ubytovatel",
    });
  if (
    policy_action_tree.fines.includes("koordinator") &&
    !props.accommodationreports
  )
    tabs.push({
      label: t("Pokuty - Koordinator"),
      value: "fines.koordinator",
    });
  if (
    policy_action_tree.medical_checkups.includes("browse") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Lékařské prohlídky"), value: "medical_checkups" });
  if (
    policy_action_tree.downpayments.includes("employee_report") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Požadavky zálohy"), value: "downpayments_requests" });
  if (
    policy_action_tree.employees.includes("communication") &&
    !props.accommodationreports
  )
    tabs.push({ label: t("Komunikace"), value: "communication" });
  if (
    policy_action_tree.settings.includes("phone_calls_card") &&
    !props.accommodationreports
  ) {
    tabs.push({
      label: t("Nahrávky telefonních hovorů"),
      value: "phone_calls",
    });
  }

  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  const grd = () => {
    if (props.id) {
      HttpService.post(
        UrlService.apiDomain() +
          "api/employees/all/" +
          month +
          "/" +
          year +
          "/all" +
          "/none",
        {
          startRow: 0,
          endRow: 20,
          rowGroupCols: [],
          valueCols: [],
          pivotCols: [],
          pivotMode: false,
          groupKeys: [],
          filterModel: {},
          sortModel: [
            {
              sort: "asc",
              colId: "surname",
            },
          ],
          filter: "all",
          current_employee: props.id,
        }
      )
        .then((res) => {
          setProfileData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 1,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Box
            sx={{
              mt: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <IconButton onClick={props.onClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          {loading ? (
            <SimpleLoader />
          ) : (
            <Box sx={{ mt: 0 }}>
              {currentTab === "profile" &&
                policy_action_tree.employees.includes("browse") && (
                  <Profile
                    profileData={profileData}
                    id={props.id}
                    month={month}
                    year={year}
                    uzivatel={"all"}
                    sc={"none"}
                    type="employees"
                    // node={props.node}
                  />
                )}
              {currentTab === "generate_documents" &&
                policy_action_tree.employees.includes("generate_documents") && (
                  <GenerateDocuments
                    id={props.id}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    type="employees"
                    node={props.node}
                  />
                )}
              {currentTab === "generated_documents" &&
                policy_action_tree.employees.includes("attachments") && (
                  <GeneratedDocuments
                    profileData={profileData}
                    id={props.id}
                    signed={false}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    type="employees"
                    node={props.node}
                  />
                )}
              {currentTab === "signed_documents" &&
                policy_action_tree.employees.includes("docusign") && (
                  <GeneratedDocuments
                    profileData={profileData}
                    id={props.id}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    signed={true}
                    sc={props.sc}
                    type="employees"
                    node={props.node}
                  />
                )}

              {currentTab === "communication" &&
                policy_action_tree.employees.includes("communication") && (
                  <Communication
                    profileData={profileData}
                    id={props.id}
                    type="employees"
                    node={props.node}
                  />
                )}
              {currentTab === "employments" &&
                policy_action_tree.employments.includes("browse") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "assignments" &&
                policy_action_tree.assignments.includes("browse") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "interactions" &&
                policy_action_tree.interactions.includes("browse") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "medical_checkups" &&
                policy_action_tree.medical_checkups.includes("browse") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "visas" &&
                policy_action_tree.visas.includes("browse") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "suppliers" &&
                policy_action_tree.suppliers.includes("browse") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "agency_accommodations" &&
                policy_action_tree.agency_accommodations.includes("browse") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "rests" &&
                policy_action_tree.rests.includes("browse") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "pokuty_ubytovatel" &&
                policy_action_tree.pokuty_ubytovatel.includes("browse") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "fines.ubytovatel" &&
                policy_action_tree.fines.includes("ubytovatel") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    typ="ubytovatel"
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "fines.koordinator" &&
                policy_action_tree.fines.includes("koordinator") && (
                  <EmployeeModels
                    profileData={profileData}
                    month={props.month}
                    year={props.year}
                    uzivatel={props.uzivatel}
                    sc={props.sc}
                    model={currentTab}
                    typ="koordinator"
                    id={props.id}
                    node={props.node}
                  />
                )}
              {currentTab === "downpayments_requests" &&
                policy_action_tree.downpayments.includes("employee_report") && (
                  <DownpaymentsRequests
                    employeeId={props.id}
                    style={{
                      width: "100%",
                      overflow: "auto",
                      height: "500px",
                    }}
                  />
                )}
              {currentTab === "accounting_requests" &&
                policy_action_tree.accounting_requests.includes("browse") && (
                  <AccountingRequests
                    employee_id={props.id}
                    tab={true}
                    fromEmployeeCard={true}
                    browseData={{ employee_id: props.id }}
                  />
                )}
              {currentTab === "phone_calls" &&
                policy_action_tree.settings.includes("phone_calls_card") && (
                  <PhoneCalls
                    style={{
                      width: "100%",
                      overflow: "auto",
                      height: "500px",
                    }}
                    employee_id={props.id}
                  />
                )}
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

export default EmployeeCard;
