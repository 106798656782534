import ModalWrapper from "../modal/ModalWrapper";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import apiGet from "../../api/apiGet";
import GridWrapper from "./gridWrapper";
import DownloadAttachment from "../../aggrid/downloadAttachment";
import FileUpload from "../inputs/file/FileUpload";
import FileUploadWithSubmit from "../inputs/file/FileUploadWithSubmit";
import DeleteRenderer from "../../aggrid/DeleteRenderer";

export default function MultiFileModal({ data, isOpen, onClose }) {
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState([]);

  // let editable = data
  //   ? JSON.parse(localStorage.getItem("my_roles_meta"))
  //       .find((refData) => refData["name"] == data.model + "-editable")
  //       .value.split("//")
  //   : [];

  const editable = data
    ? JSON.parse(localStorage.getItem("policy_column_tree"))[data.model].update
    : [];

  let hasPermission = data ? editable.includes(data.field) : false;

  useEffect(() => {
    let mounted = true;
    if (data) {
      apiGet(`${data.model}/${data.id}/attachments/${data.field}`).then(
        (response) => {
          if (mounted) {
            if (response.data && response.data.length > 0) {
              setAttachments(response.data);
            } else {
              console.log("No attachments found");
            }
          }
        }
      );
    }
    return function cleanup() {
      mounted = false;
    };
  }, [data]);

  useEffect(() => {
    if (!isOpen) {
      setAttachments([]);
    }
  }, [isOpen]);

  const columnDefs = [
    {
      headerName: t("Soubor"),
      editable: false,
      field: "attachment",
    },
    {
      headerName: t("Akce"),
      editable: false,
      field: "id",
      cellRenderer: "downloadAttachment",
    },
  ];

  if (hasPermission) {
    columnDefs.push({
      headerName: t("Odstranit"),
      editable: false,
      cellRendererParams: {
        gridType: "delete/files",
      },
      cellRenderer: "DeleteRenderer",
      suppressMenu: true,
      editable: false,
      resizable: false,
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
    });
  }
  return (
    <ModalWrapper size="xl" isOpen={isOpen} onClose={onClose}>
      {data && (
        <GridWrapper
          withoutExcel={true}
          style={{ mt: 2 }}
          helmet={`Dashboard: ${data.label} List | AWA Partners`}
          title={data.label}
          data={attachments}
          frameworkComponents={{
            downloadAttachment: DownloadAttachment,
            DeleteRenderer: DeleteRenderer,
          }}
          colDefs={columnDefs}
        >
          {hasPermission && (
            <FileUploadWithSubmit
              model={data.model}
              id={data.id}
              field={data.field}
            />
          )}
        </GridWrapper>
      )}
    </ModalWrapper>
  );
}
