"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


const UISVGReactT = class UISVGReactT extends React.Component {
	
	constructor( 
		Props
	){
		
		console.log( "UISVGReactT.constructor", Props );
		
	
		super( Props );
		
	
		this.state = {
			key: ( Props.key ? Props.key : null ),
			SX: ( Props.SX ? Props.SX : { } ),
			Data: ( Props.Data ? Props.Data : "" ),
			Fill: ( Props.Fill ? Props.Fill : "currentColor" ),
			ViewBox: ( Props.ViewBox ? Props.ViewBox : "0 0 20 20" )
		};
		
	}
	
	componentDidMount(
	
	){
		
		console.log( "UISVGReactT.componentDidMount", this.state );
		
	}
	
	componentDidUpdate(
		PropsPrev,
		StatePrev
	){
		
		console.log( "UISVGReactT.componentDidUpdate", this.state );
			
	}		   
				   
	componentWillUnmount(
	
	){
		
		console.log( "UISVGReactT.componentWillUnmount", this.state );
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UISVGReactT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.setState( {
			[ Name ]: Value
		} );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UISVGReactT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.state[ Name ];
		
	}
	
	render( 
	
	){
		
		console.log( "UISVGReactT.render", this.state );
		
		
		return React.createElement(
			MaterialUI.SvgIcon, 
			{
				key: this.state.key,
				sx: this.state.SX
			},
			[
			
				React.createElement(
					"svg", 
					{
						key: this.state.key + "_" + "svg",
						
						xmlns: "http://www.w3.org/2000/svg",
						viewBox: this.state.ViewBox,
						fill: this.state.Fill
					},
					[
						React.createElement(
							"path",
							{
								key: this.state.key + "_" + "svg" + "_" + "path",
								
								fillRule: "evenodd",
								clipRule: "evenodd",
								d: this.state.Data
							}
						)
					]
				)
				
			]
			
		);
		
	}
	
};


export default UISVGReactT;