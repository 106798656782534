import { IconButton, Dialog } from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";
import { GetApp } from "@material-ui/icons";
import { useState } from "react";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import toast from "react-hot-toast";
import { stateToHTML } from "draft-js-export-html";
import DescriptionIcon from "@mui/icons-material/Description";
import SimpleLoader from "../../components/SimpleLoader.js";
import {
  convertFromHTML,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";

const TextEditor = ({ text, id, name, date, readOnly }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const policy_actions = JSON.parse(localStorage.getItem("policy_action_tree"))[
    "meeting_records"
  ];

  const ContentHTML = convertFromHTML(text);

  const State = ContentState.createFromBlockArray(
    ContentHTML.contentBlocks,
    ContentHTML.entityMap
  );

  const Content = JSON.stringify(convertToRaw(State));

  const controls = [
    "title",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "highlight",
    "undo",
    "redo",
    "numberList",
    "bulletList",
    "quote",
    "code",
    "download",
    "save",
  ];

  const customControls = [
    {
      name: "download",
      icon: loading ? <SimpleLoader size={20} /> : <GetApp />,
      type: "callback",
      onClick: async (editorState) => {
        setLoading(true);
        const contentState = editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);

        try {
          const docContent = `
            <html xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office"
            xmlns:w="urn:schemas-microsoft-com:office:word"
            xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
            xmlns="http://www.w3.org/TR/REC-html40">
            <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
              <title>${name}</title>
              <style>
                body { font-family: Arial, sans-serif; }
              </style>
            </head>
            <body>
              ${htmlContent}
            </body>
            </html>`;

          const blob = new Blob(["\ufeff", docContent], {
            type: "application/msword",
          });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${name}_${date}.doc`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error creating DOC file:", error);
        } finally {
          setLoading(false);
        }
      },
    },
  ];

  const saveDesc = async (data) => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + `api/meeting_records/edit`,
        {
          data: {
            id,
            html_content: data,
          },
        }
      );
      if (!res.data.success) {
        alert(res.data.message);
        return;
      }
      toast.success("Popis aktualizován");
    } catch (err) {
      console.error(err);
      toast.error("Něco se pokazilo!");
    }
  };
  return (
    <>
      <Dialog
        open={open}
        maxWidth="xl"
        onClose={handleClose}
        PaperProps={{ style: { padding: "10px" } }}
      >
        <MUIRichTextEditor
          controls={controls}
          customControls={customControls}
          label="Zde by měla být zobrazena krátká textová reprezentace schůzky..."
          defaultValue={Content}
          onSave={(data) => {
            saveDesc(stateToHTML(convertFromRaw(JSON.parse(data))));
          }}
          toolbar={
            readOnly ? false : policy_actions.includes("edit") ? true : false
          }
          readOnly={
            readOnly ? false : policy_actions.includes("edit") ? false : true
          }
        />
      </Dialog>
      <IconButton
        color="primary"
        sx={{ m: 1 }}
        variant="contained"
        onClick={handleOpen}
        title={"Uprava"}
      >
        <DescriptionIcon />
      </IconButton>
    </>
  );
};

export default TextEditor;
