"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


const UISelectReactT = class UISelectReactT extends React.Component {
	
	#Ref = null;
	
	#Element = null;
	
	constructor( 
		props
	){
		
		console.log( "UISelectReactT.constructor", props );
	
		super( props ); 
		
			
		this.state = {
			name: props.name,
			disabled: props.disabled,
			style: props.style,
			ref: props.ref,
			freeSolo: props.freeSolo,
			autoSelect: props.autoSelect,
			options: props.options,
			option_array: Object.values( props.options ),
			value: props.value,
			value_object: ( ( props.value !== null ) ? props.options[ props.value.toString( ) ] : null )
		};
		
		console.log( this.state.value, this.state.value_object );

		
		this.#Ref = React.createRef( );
		
		
		this.#Element = React.createElement(
			MaterialUI.Autocomplete,
			{
				name: this.state.name,
				disabled: this.state.disabled,
				style: this.state.style,
				value: this.state.value_object,
				ref: this.#Ref,
				options: this.state.option_array,
				freeSolo: this.state.freeSolo,
				autoSelect: this.state.autoSelect,
				onChange: this.OnChangeCb.bind( this ),
				fullWidth: true,
				onInputChange: function( D ){
					console.log( "onInputChange", D );
				}, //TODO CB
				getOptionLabel: function( option ){ 
					return option.name 
				}, //TODO CB
				renderInput: function( params ) {
					
					console.log( "renderInput", params );
					
					return React.createElement(
						MaterialUI.TextField,
						{
							...params,
							id: "1",
							value: "BBB",
							label: this.state.name
						}
						
					);
					
				}.bind( this ) //TODO CB
			},
			[
				
			]
		);
		
	}
	
	OnChangeCb( Ev, NewValue ){
		
		console.log( "OnChangeCb", Ev, NewValue );


		this.ValueSet( NewValue );
		
	}
	
	componentDidMount(
	
	){
		
		console.log( "UISelectReactT.componentDidMount", this.state );
		
	}
	
	componentDidUpdate(
		PropsPrev,
		StatePrev
	){
		
		console.log( "UISelectReactT.componentDidUpdate", this.state );
		
		
		if( this.state.value_object !== StatePrev.value_object ){
			
			this.#Ref.current.value = this.state.value_object;
		
		}
			
	}
	
	componentWillUnmount(
	
	){
		
		console.log( "UISelectReactT.componentWillUnmount", this.state );
		
	}
	
	ValueGet( ){
		
		return this.state.value_object.id;
		
	}
	
	ValueSet(
		Value
	){
		
		console.log( "UISelectReactT.ValueSet", Value );
		
		
		this.setState( { value: Value.id, value_object: Value } );
		
	}
	
	render( ){
		
		console.log( "UISelectReactT.render", this.state );
		
		
		return this.#Element;
		
	}
	
};


export default UISelectReactT;