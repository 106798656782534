import { createContext } from "react";
import { memo, useState, useEffect, useMemo, useContext } from "react";
import HttpService from "../services/HttpService.js";
import UrlService from "../services/UrlService";
import useAuth from "../hooks/useAuth";

export const DataContext = createContext();

const DataProviderComponent = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     setLoading(true);
  //     HttpService.get(UrlService.apiDomain() + "api/getContext").then(
  //       (response) => {
  //         setData(response.data);
  //         setLoading(false);
  //       }
  //     );
  //   } else {
  //     setLoading(false);
  //   }
  // }, [isAuthenticated]);

  // const contextValue = useMemo(() => ({ data, isLoading }), [data, isLoading]);
  useEffect(() => {
    if (isAuthenticated) {
      updateData();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  const updateData = () => {
    setLoading(true);
    HttpService.get(UrlService.apiDomain() + "api/getContext")
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Ошибка при обновлении данных:", error);
        setLoading(false);
      });
  };

  const contextValue = useMemo(
    () => ({ data, isLoading, setData, updateData }),
    [data, isLoading]
  );

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};

export const DataProvider = memo(DataProviderComponent);
