import UrlService from "../services/UrlService";



export default function searchEmployee(employee, handleResult, handleError, where) {
    const at = localStorage.getItem("accessToken");
    const formData = new FormData();

    formData.append('name', employee.name);
    formData.append('surname', employee.surname);
    formData.append('birthday', employee.birthday);
    if(where)
    {
        formData.append('where', JSON.stringify(where));
    }

    fetch(UrlService.apiDomain() + "api/search/employee", {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + at,
        },
        body: formData
    })
        .then(r => r.json())
        .then(response => {
            console.log("Response: ", response);

            if (!response.success) {
                handleError(response.error);
                return;
            }

            handleResult(response.employee);
        })
}

export function searchMultipleEmployees(search, handleResult, handleError){
    const at = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('search', JSON.stringify(search));

    fetch(UrlService.apiDomain() + "api/search/employee/multiple", {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + at,
        },
        body: formData
    })
        .then(r => r.json())
        .then(response => {
            console.log("Response: ", response);

            if (!response.success) {
                handleError(response.error);
                return;
            }

            handleResult(response.employees);
        })
}