"use strict";


import EnvMechNodeJST from "./Infrastructure/Env/EnvMechNodeJST.mjs";

import HTTPClientXMLHTTPRequestT from "./Infrastructure/Network/HTTP/Client/HTTPClientXMLHTTPRequestT.mjs";

import DBLocalStorageT from "./Infrastructure/DB/DBLocalStorageT.mjs";

import UIGridEditorTextReactT from "./Infrastructure/UI/Grid/Editor/Text/UIGridEditorTextReactT.mjs";
import UIGridEditorSelectReactT from "./Infrastructure/UI/Grid/Editor/Select/UIGridEditorSelectReactT.mjs";
import UIGridEditorDateReactT from "./Infrastructure/UI/Grid/Editor/Date/UIGridEditorDateReactT.mjs";
import UIGridEditorDatetimeReactT from "./Infrastructure/UI/Grid/Editor/Datetime/UIGridEditorDatetimeReactT.mjs";

import UIGridRendererBooleanReactT from "./Infrastructure/UI/Grid/Renderer/Boolean/UIGridRendererBooleanReactT.mjs";
import UIGridRendererButtonReactT from "./Infrastructure/UI/Grid/Renderer/Button/UIGridRendererButtonReactT.mjs";

import UIGridProducerT from "./Infrastructure/UI/Grid/UIGridProducerT.mjs";

import UIPageContentProducerT from "./Infrastructure/UI/Page/Content/UIPageContentProducerT.mjs";
import UIPageContentTopBarProducerT from "./Infrastructure/UI/Page/Content/TopBar/UIPageContentTopBarProducerT.mjs";
import UIPageContentTopBarFilterSwitcherProducerT from "./Infrastructure/UI/Page/Content/TopBar/FilterSwitcher/UIPageContentTopBarFilterSwitcherProducerT.mjs"; 
import UIButtonProducerT from "./Infrastructure/UI/Button/UIButtonProducerT.mjs"; 
import UISVGProducerT from "./Infrastructure/UI/SVG/UISVGProducerT.mjs"; 
import UIBooleanProducerT from "./Infrastructure/UI/Boolean/UIBooleanProducerT.mjs";
import UILabelProducerT from "./Infrastructure/UI/Label/UILabelProducerT.mjs";


import StorageT from "./Interface/Storage/StorageT.mjs";
import EnvT from "./Interface/Env/EnvT.mjs";
import JSONRPCClientT from "./Interface/Network/JSONRPC/Client/JSONRPCClientT.mjs";
import HTTPClientFileT from "./Interface/Network/HTTP/Client/HTTPClientFileT.mjs";


import ViewPageEmploymentDepartmentInfosT from "./Interface/View/Page/EmploymentDepartment/Info/ViewPageEmploymentDepartmentInfosT.mjs";
import ViewPageEmploymentDepartmentNoticesT from "./Interface/View/Page/EmploymentDepartment/Notice/ViewPageEmploymentDepartmentNoticesT.mjs";


import EnvURLT from "./Procedure/Env/EnvURLT.mjs";
import APIClientT from "./Procedure/API/APIClientT.mjs";


import HTTPHeaderT from "./Type/HTTP/Header/HTTPHeaderT.mjs";
import HTTPHeadersT from "./Type/HTTP/Header/HTTPHeadersT.mjs";



const EnvMechNodeJS = new EnvMechNodeJST( 

);

const Env = new EnvT( 
	EnvMechNodeJS
);

const EnvURL = new EnvURLT( 
	Env
);


const DBLocalStorage = new DBLocalStorageT(

);

const Storage = new StorageT(
	DBLocalStorage
);


const HTTPClient = new HTTPClientXMLHTTPRequestT(

);

const JSONRPCClient = new JSONRPCClientT(
	HTTPClient,
	EnvURL.Get( ) + "api/json_rpc"
);

const HTTPClientFile = new HTTPClientFileT(
	HTTPClient,
	EnvURL.Get( ) + "api/json_rpc_file_upload",
	EnvURL.Get( ) + "api/json_rpc_file_download"
);

const APIClient = new APIClientT(
	JSONRPCClient,
	HTTPClientFile,
	Storage
);

let UIGridProducer = new UIGridProducerT( );

let UIPageContentProducer = new UIPageContentProducerT( );

let UIPageContentTopBarProducer = new UIPageContentTopBarProducerT( );

let UIPageContentTopBarFilterSwitcherProducer = new UIPageContentTopBarFilterSwitcherProducerT( );

let UIButtonProducer = new UIButtonProducerT( );

let UIBooleanProducer = new UIBooleanProducerT( );

let UILabelProducer = new UILabelProducerT( );

let UISVGProducer = new UISVGProducerT( );


let Language = Storage.Get( "config.language" );


let ViewPageEmploymentDepartmentInfos = new ViewPageEmploymentDepartmentInfosT(
	UIGridProducer,
	UIPageContentProducer,	
	UIPageContentTopBarProducer,
	UIPageContentTopBarFilterSwitcherProducer,
	UIButtonProducer,
	UIBooleanProducer,
	UILabelProducer,
	UISVGProducer,
	APIClient,
	Storage,
	{
		"UIGridEditorTextReactT": UIGridEditorTextReactT,
		"UIGridEditorSelectReactT": UIGridEditorSelectReactT,
		"UIGridEditorDateReactT": UIGridEditorDateReactT,
		"UIGridEditorDatetimeReactT": UIGridEditorDatetimeReactT,
		
		"UIGridRendererBooleanReactT": UIGridRendererBooleanReactT,
		"UIGridRendererButtonT": UIGridRendererButtonReactT,
	},
	( ( Language !== null ) ? Language : "CS" )
);

let ViewPageEmploymentDepartmentNotices = new ViewPageEmploymentDepartmentNoticesT(
	UIGridProducer,
	UIPageContentProducer,	
	UIPageContentTopBarProducer,
	UIPageContentTopBarFilterSwitcherProducer,
	UIButtonProducer,
	UIBooleanProducer,
	UILabelProducer,
	UISVGProducer,
	APIClient,
	Storage,
	{
		"UIGridEditorTextReactT": UIGridEditorTextReactT,
		"UIGridEditorSelectReactT": UIGridEditorSelectReactT,
		"UIGridEditorDateReactT": UIGridEditorDateReactT,
		"UIGridEditorDatetimeReactT": UIGridEditorDatetimeReactT,
		
		"UIGridRendererBooleanReactT": UIGridRendererBooleanReactT,
		"UIGridRendererButtonT": UIGridRendererButtonReactT,
	},
	( ( Language !== null ) ? Language : "CS" )
);


export {
	APIClient as APIClient,
	ViewPageEmploymentDepartmentInfos as ViewPageEmploymentDepartmentInfos,
	ViewPageEmploymentDepartmentNotices as ViewPageEmploymentDepartmentNotices
};