import React, { createContext, useState } from "react";

export const SearchContext = createContext(null);

export const SearchProvider = (props) => {
  const { children } = props;
  const [employee, setEmployee] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [customer, setCustomer] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  const setFoundEmployee = (e) => {
    setEmployee(e);
    setLoaded(false);
  };

  const clearFoundEmployee = () => {
    setEmployee(null);
  };

  const getFoundEmployee = () => {
    return employee;
  };

  return (
    <SearchContext.Provider
      value={{
        employee: employee,
        setFoundEmployee: setFoundEmployee,
        clearFoundEmployee: clearFoundEmployee,
        getFoundEmployee: getFoundEmployee,
        setLoaded: setLoaded,
        getLoaded: () => loaded,
        setYear,
        setCustomer,
        setMonth,
        month,
        customer,
        year,
        setBirthday,
        setName,
        setSurname,
        birthday,
        name,
        surname,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
