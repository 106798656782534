import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { Autocomplete, TextField } from "@material-ui/core";
import { getName } from "../services/getColumnDefinitions";
import { useContext } from "react";
import { DataContext } from "../contexts/DataContext";

export default forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [value, setValue] = useState([]);
  const [optionsArray, setOptionsArray] = useState([]);
  const inputRef = useRef(null);
  const { data: contextData } = useContext(DataContext);

  let user = JSON.parse(localStorage.getItem("my_employee"));
  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );
  const policy_record_tree = JSON.parse(
    localStorage.getItem("policy_record_tree")
  );

  let cars = contextData?.cars;
  let customers = contextData?.customers;
  let hostels = contextData?.accommodations;
  let employees = contextData?.thp_users;
  let received_expense_types = contextData?.received_expense_types;
  let issued_expense_types = contextData?.issued_expense_types;
  let doctors = contextData?.doctors;
  let vedeni = contextData?.vedeni;
  let data_array = props.data_array ?? [];

  let key = "supplier_id";
  let key2 = "danovy_doklad_invoice";
  let key3 = "swift";
  let key4 = "main_model";
  const recieved = Object.keys(props.data).some((el) => el == key);
  const issued = Object.keys(props.data).some((el) => el == key2);
  const accountNumbersTable = Object.keys(props.data).some((el) => el == key3);
  const coordinators_expences = Object.keys(props.data).some(
    (el) => el == key4
  );

  let allowedCars = cars.filter(
    (car) =>
      car.employee_id &&
      car.employee_id.split("//").includes(user.id.toString())
  );

  let allowedCustomersIds = policy_record_tree.customers;

  const allowedCustomers = customers.filter((i) =>
    allowedCustomersIds.includes(i.id.toString())
  );

  function parse(data) {
    return data ? data.toString().split("//") : [];
  }

  function stringify(data) {
    if (!data) return "";
    return data.map((d) => d.value).join("//");
  }

  async function getOptions() {
    if (props.colDef.field === "customer_id" && coordinators_expences) {
      data_array = policy_action_tree.coordinators_expences.includes("full")
        ? customers
        : allowedCustomers;
    }
    if (props.colDef.field === "model_item" && coordinators_expences) {
      let model = props.data?.model;
      if (model == 4) {
        data_array = policy_action_tree.coordinators_expences.includes("full")
          ? cars
          : allowedCars;
      } else if (model == 5) {
        data_array = policy_action_tree.coordinators_expences.includes("full")
          ? customers
          : allowedCustomers;
      } else if (model == 1) {
        data_array = hostels;
      } else if (model == 3) {
        data_array = employees;
      } else if (model == 7) {
        data_array = doctors;
      } else if (model == 2 || !model) {
        data_array = [];
      }
      setOptionsArray(
        data_array.map((data) => {
          return {
            value: data.id,
            label: getName(data),
          };
        })
      );
    } else if (props.colDef.field === "model_item" && recieved) {
      let model = received_expense_types.find(
        (type) => type.id == props.data.model
      )?.model;
      if (model == 4) {
        data_array = cars;
      } else if (model == 5) {
        data_array = customers;
      } else if (model == 1) {
        data_array = hostels;
      } else if (model == 3) {
        data_array = employees;
      }
      // else if (model == 8) {
      //   data_array = vedeni;
      // }
      else if (model == 2 || !model) {
        data_array = [];
      }
      setOptionsArray(
        data_array.map((data) => {
          return {
            value: data.id,
            label: getName(data),
          };
        })
      );
    } else if (props.colDef.field === "model_item" && issued) {
      let model = issued_expense_types.find(
        (type) => type.id == props.data.model
      )?.model;
      if (model == 4) {
        data_array = cars;
      } else if (model == 5) {
        data_array = customers;
      } else if (model == 1) {
        data_array = hostels;
      } else if (model == 3) {
        data_array = employees;
      }
      // else if (model == 6) {
      //   data_array = vedeni;
      // }
      else if (model == 2 || !model) {
        data_array = [];
      }
      setOptionsArray(
        data_array.map((data) => {
          return {
            value: data.id,
            label: getName(data),
          };
        })
      );
    } else if (props.colDef.field === "model_item") {
      let model = props.data?.model;
      if (model == 4) {
        data_array = cars;
      } else if (model == 5) {
        data_array = customers;
      } else if (model == 1) {
        data_array = hostels;
      } else if (model == 3) {
        data_array = employees;
      } else if (model == 2 || !model) {
        data_array = [];
      }
      setOptionsArray(
        data_array.map((data) => {
          return {
            value: data.id,
            label: getName(data),
          };
        })
      );
    } else if (props.colDef.field === "type" && accountNumbersTable) {
      const allDisplayedRows = [];
      props.api.forEachNode((node) => {
        const rowData = node.data;
        allDisplayedRows.push(rowData);
      });
      let accountNumbers = allDisplayedRows;
      let accounts = accountNumbers.filter(
        (item) => item.company_id == props.data.company_id
      );
      let existingTypes = accounts
        .flatMap((item) => item.type && item.type.split("//"))
        .filter((type) => type !== null);

      const filteredDataArray = data_array.filter(
        (item) => !existingTypes.includes(item.id.toString())
      );
      setOptionsArray(
        filteredDataArray.map((data) => {
          return {
            value: data.id,
            label: data.name,
          };
        })
      );
    } else if (
      props.column.colId == "sub_project_ids" &&
      props.data.project_ids != null
    ) {
      let options = [];
      let ids = props.data.project_ids.split("//");
      JSON.parse(localStorage.getItem("sub_projects")).map((e) => {
        if (ids.find((el) => el == e.project_id)) {
          options.push({
            value: e.id,
            label: e.name,
          });
        }
      });
      setOptionsArray(options);
    } else {
      setOptionsArray(
        data_array.map((data) => {
          return {
            value: data.id,
            label: data.name,
          };
        })
      );
    }
  }

  function getVal() {
    let values =
      props.data_array_model == "employees_sale_managers"
        ? props.value
        : parse(props.value);

    setValue(
      values.map((val) => {
        let item = data_array.find(
          (data) => data.id.toString() === val.toString()
        );
        return {
          value: val,
          label: item ? item.name : "not found",
        };
      })
    );
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return props.data_array_model == "employees_sale_managers"
          ? value.map((d) => d.value.toString())
          : stringify(value);
      },
      afterGuiAttached: async () => {
        await getOptions();
        getVal();

        inputRef.current.focus();
        inputRef.current.click();
        $("#mycstselect svg").click();
      },

      isPopup: () => {
        return true;
      },
    };
  });

  return (
    <Autocomplete
      multiple
      ref={inputRef}
      options={optionsArray.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )}
      fullWidth
      sx={{ minWidth: "300px", maxWidth: "300px" }}
      value={value ?? []}
      onChange={(_, data) => {
        if (data.length === 0) {
          let confirmed = window.confirm(t("Clear value?"));
          if (!confirmed) return;
        }
        setValue(data);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& .MuiInputBase-input": {
              color: "black",
            },
          }}
          label={t(props.colDef.headerName)}
        />
      )}
    />
  );
});
