import { useState } from "react";
import PropTypes from "prop-types";
import { compareAsc } from "date-fns";
import { useTranslation } from "react-i18next";
import { Box, Divider, Button, Modal } from "@material-ui/core";
import { useSelector } from "../../store";
import KanbanCard from "../../components/dashboard/vacancies/KanbanCard";
import KanbanCardAdd from "../../components/dashboard/vacancies/KanbanCardAdd";
import Archiv from "../../components/dashboard/vacancies/Archiv";

const columnSelector = (state, columnId) => {
  const { columns } = state.vacancies;

  return columns.byId[columnId];
};

const VacanciesList = (props) => {
  const { t } = useTranslation();
  const { columnId, ...other } = props;
  const column = useSelector((state) => columnSelector(state, columnId));
  const { cards } = useSelector((state) => state.vacancies);
  const [openModal, setOpenModal] = useState(false);

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies;

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const sortedCardIds =
    column &&
    [...column.cardIds].sort((cardId1, cardId2) => {
      const due1 = new Date(cards.byId[cardId1].due);
      const due2 = new Date(cards.byId[cardId2].due);
      return compareAsc(due1, due2);
    });

  return (
    <>
      <Modal onClose={handleClose} open={openModal}>
        <div>
          <Archiv id={columnId} handleOptionsClose={handleClose} />
        </div>
      </Modal>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          px: 2,
          py: 2,
        }}
      >
        <Button variant={"contained"} color={"primary"} onClick={handleOpen}>
          {t("Archiv")}
        </Button>
      </Box>
      {column && (
        <>
          <Box
            sx={{
              flexGrow: 1,
              minHeight: 80,
              overflowY: "auto",
              px: 10,
              py: 1,
            }}
          >
            {sortedCardIds.map((cardId, index) => (
              <KanbanCard
                cardId={cardId}
                index={index}
                key={cardId}
                column={column}
              />
            ))}
          </Box>
          <Divider />
        </>
      )}
      {policy_actions.includes("add") && (
        <Box sx={{ p: 2 }}>
          <KanbanCardAdd columnId={columnId} />
        </Box>
      )}
    </>
  );
};

VacanciesList.propTypes = {
  columnId: PropTypes.string.isRequired,
};

export default VacanciesList;
