import UrlService from "../services/UrlService";

export default function apiGet(api, blob = false) {
  const at = localStorage.getItem("accessToken");
  return fetch(UrlService.apiDomain() + "api/" + api, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + at,
    },
  })
    .then((r) => {
      if (r.status !== 200 && r.status !== 2) {
        return { message: "Chyba serveru", success: false };
      }
      if (blob) return r.blob();
      return r.json();
    })
    .then((response) => {
      if (!blob && !response.success) {
        console.error("Responded with error:", response);
      }
      return response;
    })
    .catch((error) => {
      alert("ERROR: " + error.message);
    });
}
