import {TextField} from "@material-ui/core";
import {useEffect, useState} from "react";

export default function InputWithValidationOld(props) {
    const [value, setValue] = useState(props.value);
    const [valid, setValid] = useState(false);
    const [disabled, setDisabled] = useState(props?.disabled ?? false);

    useEffect(() => {
        validate(value);
        props.onChange(props.name, value);
    }, [value]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);

    const validate = (valueToValidate) => {
        if(!props.emptyAllowed && !valueToValidate){
            setValid(false);
            return false;
        }
        let regex = props.regex;
        let result = regex ? regex.test(valueToValidate) : true;
        setValid(result);
        return result;
    }

    const handleChange = (event) => {
        let newValue;
        if (props.processValue) {
            newValue = props.processValue(event.target.value);
        } else {
            newValue = event.target.value;
        }
        if (validate(newValue) || newValue === "" || !props.onlyValid) {
            setValue(newValue);
        } else if (value){
            setValid(true);
        }
    }

    return (
        <TextField label={props.label} name={props.name} value={value}
                   variant={props.disabled ? "filled" : "outlined"}
                   error={!valid && !disabled} disabled={disabled}
                   fullWidth onChange={handleChange}/>
    );
}