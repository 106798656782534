"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIModalReactT from "./UIModalReactT.mjs";

import UIModalAbstractT from "../../../Interface/UI/Modal/_/UIModalAbstractT.mjs";


const UIModalT = class UIModalT extends UIModalAbstractT {
	
	#Component = null;
	
	#Ref = null;
	
	#OnOpenCb = null;
	
	constructor(
		Props
	){
		
		console.log( "UIModalT.constructor", Props );
		
		
		super( );

		
		this.#Component = React.createElement( 
			UIModalReactT, 
			{
				...Props,
				ref: this.RefSet.bind( this )
			}
		);
		
	}
	
	RefSet( Ref ){
		
		this.#Ref = Ref;
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIModalT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}

		
		if( this.#Ref !== null ){
		
			this.#Ref.PropSet( Name, Value );
		
		}
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIModalT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.#Ref.PropGet( Name );
		
	}
	
	Component( 
	
	){
		
		return this.#Component;
		
	}
	
};


export default UIModalT;