import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import PropTypes from "prop-types";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import { formatDistanceToNowStrict, formatDistance } from "date-fns";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { useTranslation } from "react-i18next";
import { Lightbox } from "react-modal-image";
import { Avatar, Box, Link, Typography, Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const maskMap = {
  ru: "__.__.____",
  cs: "__.__.____",
};

const downloadAttachment = (X) => {
  HttpService.postblob(
    UrlService.apiDomain() + "api/attachments/download_chat_attachment",
    { attachment: X }
  ).then((response) => {
    // Create blob link to download

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    let tmp = X.split("/");
    let filename = tmp[tmp.length - 1];
    link.setAttribute("download", filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });
};

const ChatMessage = (props) => {
  const { t } = useTranslation();
  const [locale, setLocale] = useState(t("cs"));
  const {
    body,
    contentType,
    createdAt,
    senderName,
    senderType,
    attachment,
    ...other
  } = props;
  const [expandMedia, setExpandMedia] = useState(false);

  var attachments = [];
  if (attachment) {
    JSON.parse(attachment).forEach((e) => {
      var name = e.split("/")[e.split("/").length - 1];
      var col = "secondary";
      if (senderType !== "user") col = "primary";
      attachments.push(
        <Button
          key={e}
          color={col}
          onClick={() => downloadAttachment(e)}
          endIcon={<GetAppIcon fontSize="small" />}
        >
          {name}
        </Button>
      );
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        mb: 2,
      }}
      {...other}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: senderType === "user" ? "row-reverse" : "row",
          maxWidth: 500,
          ml: senderType === "user" ? "auto" : 0,
        }}
      >
        <div>
          <Box
            sx={{
              backgroundColor:
                senderType === "user" ? "primary.main" : "background.paper",
              borderRadius: 1,
              boxShadow: 1,
              color:
                senderType === "user" ? "primary.contrastText" : "text.primary",
              px: 2,
              py: 1,
            }}
          >
            <Link
              color="inherit"
              component={RouterLink}
              to="#"
              variant="subtitle2"
            >
              {senderName}
            </Link>
            <Box sx={{ mt: 1 }}>
              {contentType === "image" ? (
                <Box
                  onClick={() => setExpandMedia(true)}
                  sx={{
                    mt: 2,
                    "& img": {
                      cursor: "pointer",
                      height: "auto",
                      maxWidth: "100%",
                      width: 380,
                    },
                  }}
                >
                  <img alt="Attachment" src={body} />
                </Box>
              ) : (
                <>
                  <Typography color="inherit" variant="body1">
                    {body}
                  </Typography>
                  {attachments}
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: senderType === "user" ? "flex-end" : "flex-start",
              mt: 1,
              px: 2,
            }}
          >
            <Typography color="textSecondary" noWrap variant="caption">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={localeMap[locale]}
              >
                {t("Před")}{" "}
                {formatDistance(
                  new Date(),
                  new Date(createdAt),
                  { locale: localeMap[t("cs")] } // Pass the locale as an option
                )}
              </LocalizationProvider>
            </Typography>
          </Box>
        </div>
      </Box>
      {expandMedia && (
        <Lightbox large={body} onClose={() => setExpandMedia(true)} />
      )}
    </Box>
  );
};

ChatMessage.propTypes = {
  body: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  createdAt: PropTypes.number.isRequired,

  senderType: PropTypes.oneOf(["contact", "user"]),
};

export default ChatMessage;
