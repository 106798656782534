const translat = {
  "Přihlásit se": "Войти",
  /*
    'Přihlásit se do osobního profilu': 'Войти в личный кабинет',
    'Chybný formát emailu': 'Email в неправильном формате',
    'Email je povinný': 'Email обязателен',
    'Heslo je povinné': 'Пароль обязателен',
    'Heslo': 'Пароль',
    'Chybné přihašovací údaje': 'Неверный пароль',
    'Odhlásit se': 'Выйти',
    'Nastavení': 'Настройки',
    'Uživatele': 'Пользователи',
    'Účty': 'Учетные записи',
    'Role': 'Роли',
    'Práva': 'Права',
    'Nástěnka': 'Контрольная панель',
    'Uživatel': 'Пользователь',
    'Jméno': 'Имя',
    'Role': 'Роль',
    'Email': 'Email',
    'Telefon': 'Телефон',
    'Pozice': 'Позиция',
    'Aktivní': 'Активный',
    'Přidat': 'Добавить',
    'Uložit': 'Сохранить',
    'cs': 'ru',
    'Odstranit': 'Удалить',
    'Potvrdit odstranění': 'Подтвердите удаление',
    'Jste si jisti, že chcete odstranit tuto položku a všechny položky s ní spojené?': 'Вы уверены что хотите удалить этот элемент и все с ним связанные элементы?',
    'Právo': 'Право',
    'Celé jméno': 'Полное имя',
    'Ne': 'Нет',
    'Ano': 'Да',
    'Editable': 'Редактируемые',
    'Visible': 'Видимые',
    'Employees': 'Соискатели',
    'Text': 'Текст',
    'Integer': 'Целое числе',
    'Decimals': 'Десятичное число',
    'Yes/No': 'Да/Нет',
    'Select': 'Выбор',
    'Multi Select': 'Мульти выбор',
    'Date': 'Дата',
    'Date and time': 'Дата и время',
    'Languages': 'Языки',
    'Countries': 'Страны',
    'Users': 'Пользователи',
    'Custom': 'Собственный',
    'None': 'Нет',
    'Delete': 'Удалить',

    'Kolonky Lidí': 'Колонки людей',
    'Název': 'Название',
    'Typ kolonky': 'Тип колонки',
    'Zdroj dat': 'Источник данных',
    'Uzamčená': 'Закрытая',
    'Formulář': 'В форме',
    'Opce': 'Опции',
    'CSS třída': 'CSS класс',
    'AWADB název': 'AWADB название',
    'Název v AWADB': 'Название в AWADB',


    'Státní občanství': 'Гражданство',
    'Jméno': 'Имя',
    'Datum narození': 'Дата Рождения',
    'Pohlaví': 'Пол',
    'Příjmení': 'Фамилия',
    'Pracovní zkušenosti': 'Опыт работы',
    'Dodavatel': 'Поставщик',
    'Datum registrace': 'Дата регистрации',
    'Vzdělání': 'Образование',
    'První zaměstnání v ČR': 'Первая работа в Чехии',
    'Doporuč kamaráda': 'Регистрация соискателя',
    'Muž': 'Мужчина',
    'Žena': 'Женщина',
    'Svářeč': 'Сварщик',
    'Truhlář': 'Плотник',
    'Řídič VZV': 'Водитель погрузчика',
    'Základní': 'Начальное',
    'Středoškolské': 'Среднее',
    'Vysokoškolské': 'Высшее',
    'Lidí': 'Люди',
    'Ucházeči': 'Соискатели',
    'Registrovat': 'Зарегистрировать',

    'Chyba serveru, prosím zkuste pozdějí': 'Chyba serveru, prosím zkuste pozdějí',
    'Tento člověk je již registrovaný': 'Tento člověk je již registrovaný',
    'Z bezpečnostních důvodů nemáte právo upravovat roli ucházeč': 'Z bezpečnostních důvodů nemáte právo upravovat roli ucházeč',
*/
  "Seznam aktuálních nabídek prací - sylka na":
    "Список актуальных вакансий - ссылка на",
  "Seznam aktuálních nabídek prací": "Список актуальных вакансий",
};

export default translat;
