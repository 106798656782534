import {
    Box,
    Button,
    FormControlLabel,
    Paper,
    Divider,
    Grid,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
const Modal7 = (props) => {
    const {t} = useTranslation();
    const [name, setName] = useState('');

    console.log(props);
    const handleAdd = () => {
        const data={name};
        props.onAdd(data);
        setName('');


    }

    return (
        <Paper
            elevation={12}
            sx={{
                maxWidth: 620,
                mx: 'auto',
                p: 2
            }}
        >
            <Typography
                color="textPrimary"
                variant="h6"
            >
                {t('Přidat')}
            </Typography>
            <Divider sx={{marginBottom:3}} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('Jméno')}
                        required
                        value={name}
                        onChange={(event)=>{setName(event.target.value)}}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={()=>{
                            handleAdd()
                        }}
                    >
                        {t('Uložit')}
                    </Button>
                </Grid>

            </Grid>
        </Paper>
    )
};

export default Modal7;
