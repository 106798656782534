import {
  FormControl,
  FormLabel,
  RadioGroup,
  Button,
  FormControlLabel,
  Paper,
  Divider,
  Grid,
  Radio,
  IconButton,
  Typography,
  Checkbox,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import CircularProgress from "@material-ui/core/CircularProgress";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import SubjectIcon from "@mui/icons-material/Subject";
import RewardDeteails from "../home/RewardDeteails.js";

const ConfirmEmail = (props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(props.email);
  const [loading, setLoading] = useState("none");
  const [checked, setChecked] = useState(false);
  const [emailRequired, setEmailRequired] = useState(true);
  const [validators, setValidators] = useState(["required", "isEmail"]);
  const [openRewardDetails, setOpenRewardDetails] = useState(false);
  const [selectedEployeeReward, setSelectedEmployeeReward] = useState(null);
  const [employeeReward, setEmployeeReward] = useState(
    props.employeeReward || "1"
  );

  const employeeRewards = JSON.parse(
    localStorage.getItem("bonus_rules")
  ).filter((item) => item.is_visible);

  const hasEmployeeReward =
    props.customer_id == "18" ||
    props.customer_id == "761" ||
    props.customer_id == "325";

  employeeRewards.forEach((rew) => {
    if (rew.id == "1") {
      rew.desc_cz =
        "Bonus za věrnost - za odpracování 450 hodin bonus 10 000 Kč - pracovník musí splnit následující podmínky: 2.1 absence zameškání práce bez řádné omluvy/neomluvené absence 2.2 výjimku tvoří nemoc pracovníka a dovolená – v případě napracování zameškaných hodin 2.3 pokud pracovník bude chtít ukončit pracovní poměr, bude mu bonus vyplacen pouze v případě, že ukončí pracovní poměr dohodou a informuje koordinátora měsíc před datem ukončení 2.4 v případě, že pracovník bude propuštěn továrnou z důvodu snížení objemu výroby, bude pracovníkovi vyplacena poměrná část bonusu";
      rew.desc_ru =
        "Бонус лояльности - за отработку 450 часов бонус 10 000 крон - работник должен выполнить следующие условия: 2.1 отсутствие пропусков работы по неуважительной причине/ прогулов  2.2 исключение составляет болезнь работника, отпуск - в случае отработки пропущенных часов 2.3 если работник будет хотеть уволиться - бонус ему будет выплачен только в случае, если он увольняется по соглашению сторон и предупредил координатора за месяц до даты увольнения 2.4 в случае увольнения работника фабрикой по причине снижения объёмов производства - работнику будет выплачена пропорциональная часть бонуса";
    } else if (rew.id == "2") {
      rew.desc_cz =
        "Příspěvek na ubytování (90 dnu od podpisu smlouvy) - bonus až 3 500 Kč (110 Kč/den)- pracovník musí splnit následující podmínky: 1.1 odpracování celého kalendářního měsíce (odpracování fondu pracovních hodin za měsíc) 1.2 není v toleranci absence, nemocenská a dovolená 1.3 výjimku tvoří pouze prostoje z důvodu závady na straně továrny 1.4 pokud pracovník bude chtít ukončit pracovní poměr, bude mu příspěvek na bydlení vyplacen pouze v případě, že ukončí pracovní poměr dohodou a informuje koordinátora měsíc před datem ukončení 1.5 v případě, že pracovník bude propuštěn továrnou z důvodu snížení objemu výroby, bude pracovníkovi vyplacena poměrná část bonusu";
      rew.desc_ru =
        "Пособие на жильё (90 дней от подписи договора) - бонус до 3 500 Крон (110 крон/день)- работник должен выполнить следующие условия: 1.1 отработка полного календарного месяца ( отработка фонда рабочих часов за месяц)1.2 должны отсутствовать пропуски работы, отпуск, больничные1.3 исключение составляют только простои по вине фабрики 1.4 если работник будет хотеть уволиться - пособие на жильё ему будет выплачено только в случае, если он увольняется по соглашению сторон и предупредил координатора за месяц до даты увольнения 1.5 в случае увольнения работника фабрикой по причине снижения объёмов производства - работнику будет выплачена пропорциональная часть бонуса";
    }
  });

  const handleEployeeRewardChange = (event) => {
    setEmployeeReward(event.target.value);
  };

  const rewardEmpOptions = employeeRewards.map((rew) => (
    <React.Fragment key={rew.id}>
      <FormControlLabel
        value={rew.id}
        control={<Radio />}
        label={t(rew.name)}
        sx={{ m: 0 }}
      />
      <IconButton
        sx={{ mr: 2 }}
        size="small"
        onClick={() => {
          setSelectedEmployeeReward(rew);
          setOpenRewardDetails(true);
        }}
      >
        <SubjectIcon />
      </IconButton>
    </React.Fragment>
  ));

  const handleAdd = () => {
    setLoading("block");
    HttpService.post(
      `${UrlService.apiDomain()}api/sign_pregenerated_documents/${props.id}`,
      {
        email: email,
        employeeReward,
      }
    )
      .then((response) => {
        if (!response.data.success) {
          console.log(response);
          setLoading("none");
          if (response.data.message) {
            alert(response.data.message);
          } else {
            alert(
              "Digisign potřebuje ještě cca 10-20 vteřin na přípravu podpisů. Prosím, zkuste znovu kliknout tlačítko 'Podepsat dokumenty' za okamžik"
            );
          }

          /*
                    if (response.data.message.indexOf('jeden z')) {
                        alert("Digisign potřebuje ještě cca 10-20 vteřin na přípravu podpisů. Prosím, zkuste znovu kliknout tlačítko 'Podepsat dokumenty' za okamžik");
                    } else {
                        alert("Chyba: " + response.data.message);
                    }
                    */
        } else {
          if (response.data.message) {
            alert(response.data.message);
          }
          if (response.data.urls.length > 0) {
            response.data.urls.forEach((item) => {
              setTimeout(() => {
                window.open(item, "_blank");
              }, 1000); // Задержка в 1 секунду между открытием каждого окна
            });
            setLoading("none");
          }
          // window.open(response.data.url, "_blank");
        }

        props.handleClose();
        // setEmail('');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckChange = () => {
    setEmail("");
    setEmailRequired(checked);
    if (checked) {
      setValidators(["required", "isEmail"]);
    } else {
      setValidators([]);
    }
    setChecked(!checked);
  };

  return (
    <Paper
      elevation={12}
      sx={{
        maxWidth: 620,
        mx: "auto",
        p: 2,
      }}
    >
      {openRewardDetails && (
        <RewardDeteails
          employeeReward={selectedEployeeReward}
          onClose={() => setOpenRewardDetails(false)}
          isOpen={openRewardDetails}
        />
      )}
      <Typography color="textPrimary" variant="h6">
        {t("Ověření emailové adresy")}
      </Typography>
      <Divider sx={{ marginBottom: 3 }} />
      <ValidatorForm
        onSubmit={handleAdd}
        onError={(errors) => console.log(errors)}
      >
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <TextValidator
              fullWidth
              label={t("Email")}
              validators={validators}
              required={emailRequired}
              disabled={checked}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              variant="outlined"
              errorMessages={["Email je povinný", "Email není validní"]}
            />
          </Grid>
          <Grid item xs={2}>
            {props.email_verified == true && (
              <CheckIcon fontSize="large" style={{ color: "green" }} />
            )}
            {props.email_verified == false && (
              <CancelIcon fontSize="large" style={{ color: "red" }} />
            )}
          </Grid>

          <Grid item xs={2}>
            <CircularProgress sx={{ display: loading }} />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={handleCheckChange}
                  sx={{ ml: 1 }}
                />
              }
              label="Uživatel nemá emailovou adresu"
            />
          </Grid>

          {hasEmployeeReward && (
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="radio-label-2">
                  {t("Odměna pracovníků")}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="radio-label-2"
                  value={employeeReward}
                  onChange={handleEployeeRewardChange}
                  row
                >
                  {rewardEmpOptions}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button color="primary" fullWidth variant="contained" type="submit">
              {t("Uložit a podepsat")}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Paper>
  );
};

export default ConfirmEmail;
