"use strict";


import UIModalT from "../UIModalT.mjs";

import UIModalTopBarT from "../TopBar/UIModalTopBarT.mjs";

import UIGridT from "../../Grid/UIGridT.mjs";

import UISVGT from "../../SVG/UISVGT.mjs";

import UIButtonT from "../../Button/UIButtonT.mjs";

import UILabelT from "../../Label/UILabelT.mjs";

import UIButtonFileUploadT from "../../Button/FileUpload/UIButtonFileUploadT.mjs";

import SVGCheckT from "../../../../Type/SVG/SVGCheckT.mjs";

import SVGTrashT from "../../../../Type/SVG/SVGTrashT.mjs";

import SVGCloseT from "../../../../Type/SVG/SVGCloseT.mjs";

import UIGridRendererButtonDeleteT from "../../../../Infrastructure/UI/Grid/Renderer/Button/Delete/UIGridRendererButtonDeleteT.mjs";

import UIGridRendererButtonDownloadT from "../../../../Infrastructure/UI/Grid/Renderer/Button/Download/UIGridRendererButtonDownloadT.mjs";


const UIModalFileGridT = class UIModalFileGridT extends UIModalT {
	
	#Title = null;
	
	#TopBar = null;
	
	#UIGrid = null;
	
	#ButtonFileUpload = null;
	
	#Files = null;
	
	constructor(
		Props,
		OnOpenCb
	){
		
		console.log( "UIModalFileGridT.constructor", Props );
		
		
		const columnDefs = [
			{
				headerName: "Soubor", //TODO Translate
				editable: false,
				field: "path",
				resizable: true,
				minWidth: 650
			},
			{
				headerName: "Akce", //TODO Translate
				editable: false,
				field: "id",
				cellRenderer: "UIGridRendererButtonDownloadT",
				cellRendererParams: {
					
					OnClick: function( Id, Filename ){
						
						Props.OnDownload( Id, Filename );
						
					}
					
				},
				resizable: true,
				minWidth: 100,
				maxWidth: 100
			}
		];

		//if( Props.CanDelete === true ) { //TODO let hasPermission = data ? editable.includes(data.field) : false;
			
			columnDefs.push( {
				headerName: "Odstranit", //TODO Translate
				editable: false,
				cellRenderer: "UIGridRendererButtonDeleteT",
				cellRendererParams: {
					
					OnClick: function( Id ){
						
						Props.OnDelete( Id );
						
					}
					
				},
				suppressMenu: true,
				editable: false,
				resizable: true,
				sortable: false,
				minWidth: 100,
				maxWidth: 100
			} );
			
		//}
		
		let FilesToUpload = [ ];
  
		let Title = new UILabelT( {
			key: "title",
			SX: { },
			Variant: "h5",
			Color: "textPrimary",
			Children: [ Props.ModalTitle ]
		} );
		
		
		let ButtonFileClear = new UIButtonT( {
			key: "button_clear",
			FullWidth: true,
            Type: "button",
            Variant: "outlined",
            SX:{ height: "100%", display: "none" },
			Color: "primary",
			Children: [
				new UISVGT( {
					key: Props.key + "_" + "trash_svg",
					SX: { },
					Data: SVGTrashT.Data,
					ViewBox: "0 0 24 24"
				} ),
				new UILabelT( {
					key: "title",
					SX: { 
						FullWidth: true
					},
					Color: "primary",
					Children: [ "Odstranit" ]
				} )
			],
			OnClick( ){
				
				ButtonFileUpload.Clear( );
				
			}
		} );
		
		let ButtonFileSend = new UIButtonT( {
			key: "button_send",
			FullWidth: true,
            Type: "button",
            Variant: "outlined",
            SX:{ height: "100%", display: "none" },
			Color: "primary",
			Children: [
				new UISVGT( {
					key: Props.key + "_" + "success_svg",
					SX: { },
					Data: SVGCheckT.Data,
					ViewBox: "0 0 24 24"
				} ),
				new UILabelT( {
					key: "title",
					SX: { 
						FullWidth: true
					},
					Color: "primary",
					Children: [ "Odeslat" ]
				} )
			],
			OnClick: function( ){
				
				Props.OnUpload( FilesToUpload );
				
			}
		} );
		
		let ButtonFileUpload = new UIButtonFileUploadT( {
			key: "button_upload",
			Label: "přílohy",
			FullWidth: true,
            Type: "button",
            Variant: "outlined",
            SX:{ display: "inline-flex", "white-space": "nowrap" },
			Color: "primary",
			IsMultiple: true,
			OnClick: function( ){
				
				console.log( "OnClick" );
				
				ButtonFileUpload.Click( );
				
			},
			OnChange: function( Files ){
				
				console.log( "OnChange", Files );
				
				if( Files.length === 0 ){
					
					let SX = ButtonFileUpload.PropGet( "SX" );
					
					SX.display = "inline-flex";
					
					ButtonFileUpload.PropSet( "SX", SX );
					
					
					let SX2 = ButtonFileSend.PropGet( "SX" );
					
					SX2.display = "none";
					
					ButtonFileSend.PropSet( "SX", SX2 );
					
					
					let SX3 = ButtonFileClear.PropGet( "SX" );
					
					SX3.display = "none";
					
					ButtonFileClear.PropSet( "SX", SX3 );
					
				} else {
					
					let SX = ButtonFileUpload.PropGet( "SX" );
					
					SX.display = "none";
					
					ButtonFileUpload.PropSet( "SX", SX );
					
					
					let SX2 = ButtonFileSend.PropGet( "SX" );
					
					SX2.display = "inline-flex";
					
					ButtonFileSend.PropSet( "SX", SX2 );
					
					
					let SX3 = ButtonFileClear.PropGet( "SX" );
					
					SX3.display = "inline-flex";
					
					ButtonFileClear.PropSet( "SX", SX3 );
					
				}
				
				FilesToUpload = [ ...Files ];
				
			}
		} );
		
		let ButtonClose = new UIButtonT( {
			key: "button_close",
			FullWidth: true,
            Type: "button",
            Variant: "text",
            SX:{ height: "100%", display: "inline-flex" },
			Color: "primary",
			Children: [
				new UISVGT( {
					key: Props.key + "_" + "trash_svg",
					SX: { },
					Data: SVGCloseT.Data,
					ViewBox: "0 0 24 24"
				} )
			],
			OnClick: function( ){
				
				Props.ModalDialogOnCloseCb( );
				
			}
		} );
  
		let UIModalTopBar = new UIModalTopBarT(
			{
				key: "top_bar"
			},
			
			function( ){
				
				UIModalTopBar.ChildrenSet( [ 
					{
						key: "top_bar" + "_" + "title",
							
						XS: null,
						Components: [ Title ]
					},
					{
						key: "top_bar" + "_" + "buttons",
						
						XS: null,
						SX: {
							display: "inline-flex"
						},
						Components: [ ButtonFileClear, ButtonFileSend, ButtonFileUpload ]
					},
					{
						key: "top_bar" + "_" + "close",
							
						XS: null,
						Components: [ ButtonClose ]
					},
				] );
				
			}
		);
  
		let UIGrid = new UIGridT( 
			{
				key: "file_grid_ui_grid",
				className: "ag-theme-material",
				
				style: { 
					width: "100%", 
					border: "1px solid black",
					height: "80%"
				},
				
				columnDefs: columnDefs,
				
				frameworkComponents: { 
					"UIGridRendererButtonDeleteT": UIGridRendererButtonDeleteT, 
					"UIGridRendererButtonDownloadT": UIGridRendererButtonDownloadT 
				},
				
				getRowNodeId: function( Row ){ 

					return Row.id;
		
				}
				
			},
			function( ){
				
				console.log( "gridready" );
			
				Props.ModalDialogOnOpenCb( );
				
			}
		);
		
		super( 
			{
				ModalDialogOnOpenCb: function( ){ console.log( "ModalDialogOnOpenCb" ); }, //( ( Props.ModalDialogOnOpenCb ) ? Props.ModalDialogOnOpenCb : null ),
				ModalDialogMaxWidth: ( Props.ModalDialogMaxWidth ? Props.ModalDialogMaxWidth : "sm" ),
				ModalDialogOnCloseCb: ( ( Props.ModalDialogOnCloseCb ) ? Props.ModalDialogOnCloseCb : null ),
				ModalDialogIsOpen: ( ( Props.ModalDialogIsOpen ) ? Props.ModalDialogIsOpen : false ),
				ModalDialogStyle: {
					minHeight: "90%",
					height: "100%",
					maxHeight: "90%"
				},
				ModalPaperElevation: ( ( Props.ModalPaperElevation ) ? Props.ModalPaperElevation : 12 ),
				ModalPaperSX: ( ( Props.ModalPaperSX ) ? Props.ModalPaperSX : { p: 2 } ),
				ModalPaperStyle: {
					height: "100%"
				},
				ModalBoxStyle: {
					backgroundColor: "background.default",
					height: "100%",
					paddingTop: "4px",
					paddingBottom: "4px"
				}, 
				ModalBoxContainerStyle: {
					height: "100%",
					maxWidth: "100%"
				},
				ModalBoxContainerTopBarStyle: {
					paddingTop: "20px", 
					paddingBottom: "20px"
				},
				ModalBoxContainerGridStyle: { 
					width: "100%", 
					height: "calc(90% - 50px)",
					marginTop: "3px"
				},
				
				TopBar: UIModalTopBar,
				Grid: UIGrid
			}
		);
		
		
		this.#UIGrid = UIGrid;
		
		this.#TopBar = UIModalTopBar;
		
		this.#Title = Title;
		
		this.#Files = FilesToUpload;
		
		this.#ButtonFileUpload = ButtonFileUpload;
		
	}
	
	FilesClear( ){
		
		this.#ButtonFileUpload.Clear( );
		
	}
	
	PropSet( 
		Name,
		Value
	){

		if( Name === "GridData" ){
			
			this.#UIGrid.RowDataSet( Value );
			
		} else {
			
			super.PropSet( Name, Value );
			
		}
		
	}
	
	RowsAdd(
		Rows
	){
		
		this.#UIGrid.RowsAdd( Rows );
		
	}
	
	RowsDelete(
		RowIds
	){
		
		this.#UIGrid.RowsDelete( RowIds );
		
	}
	
};


export default UIModalFileGridT;