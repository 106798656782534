"use strict";


const JSONBNSerializerErrorT = class JSONBNSerializerErrorT extends Error {
	
	constructor( Message ){
		
		super( Message );
		
	}
	
};


export default JSONBNSerializerErrorT;