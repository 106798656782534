import { useEffect, useRef, useState } from "react";
import formatTimeFromSeconds from "../../utils/formatTimeFromSeconds.js";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Typography,
  TextField,
  Autocomplete,
} from "@material-ui/core";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { useTranslation } from "react-i18next";
import { PlayArrow, Pause, Stop, FiberManualRecord } from "@material-ui/icons";

const NewRecord = ({ onStop }) => {
  const { t } = useTranslation();
  const { data: contextData } = useContext(DataContext);

  const { thp_users, all_customers } = contextData;
  const userId = JSON.parse(localStorage.getItem("my_employee")).id.toString();

  const TOKEN = process.env.REACT_APP_WEBSOCKET_TOKEN;

  let HostName = window.location.hostname;

  const API =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_WEBSOCKET_TEST
      : HostName === "db.awapartners.com"
      ? process.env.REACT_APP_WEBSOCKET_PROD
      : process.env.REACT_APP_WEBSOCKET_TEST;

  const roles = JSON.parse(localStorage.getItem("roles"))
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    .map((role) => {
      return {
        label: role.name,
        key: role.id,
        value: role.id.toString(),
      };
    });

  const uniqueCustomers = [];
  const namesSet = new Set();

  all_customers.forEach((customer) => {
    if (!namesSet.has(customer.name)) {
      namesSet.add(customer.name);
      uniqueCustomers.push(customer);
    }
  });

  const allCustomers = uniqueCustomers
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    .map((customer) => {
      return {
        label: customer.name,
        key: customer.id,
        value: customer.id,
      };
    });

  const availableUsers = thp_users
    .filter((user) => {
      let today = new Date();
      let ukonceni_pp_date = user.ukonceni_pp
        ? new Date(user.ukonceni_pp)
        : null;
      let smlouva_do_date = user.smlouva_do ? new Date(user.smlouva_do) : null;

      return (
        (!ukonceni_pp_date || ukonceni_pp_date > today) &&
        (!smlouva_do_date || smlouva_do_date > today)
      );
    })
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    .map((user) => {
      return {
        roles: user.roles || [],
        label: user.name,
        key: user.id,
        value: user.id,
      };
    });

  const mediaStreamRef = useRef(null);
  const webSocketRef = useRef(null);
  const recorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [meetingName, setMitingName] = useState("");
  const [participantIds, setParticipantsIds] = useState([]);
  const [roleIds, setRoleIds] = useState([]);
  const [customerIds, setCustomerIds] = useState([]);
  const intervalRef = useRef(null);

  const startRecording = async () => {
    try {
      webSocketRef.current = new WebSocket(`wss://${API}`);

      webSocketRef.current.onopen = () => {
        console.log("WebSocket connection opened");
        sendMetadata();
        setIsRecording(true);
        setIsPaused(false);
        startTimer();
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          mediaStreamRef.current = stream;
          recorderRef.current = new MediaRecorder(stream);
          recorderRef.current.ondataavailable = (event) => {
            if (
              event.data.size > 0 &&
              webSocketRef.current.readyState === WebSocket.OPEN
            ) {
              webSocketRef.current.send(event.data);
            }
          };
          recorderRef.current.start(1000);
        });
      };

      webSocketRef.current.onmessage = (message) => {
        console.log("Received message:", message.data);
      };

      webSocketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      webSocketRef.current.onclose = () => {
        console.log("WebSocket connection closed");
      };
    } catch (error) {
      console.error("Error accessing audio stream:", error);
    }
  };

  const sendMetadata = () => {
    const metadata = {
      token: TOKEN,
      user: userId,
      name: meetingName,
      employee_ids: participantIds,
      customer_ids: customerIds,
    };
    webSocketRef.current.send(JSON.stringify(metadata));
  };

  const stopRecording = () => {
    setIsRecording(false);
    setIsPaused(false);
    cleanup();
    stopTimer();
    setRecordingTime(0);
    onStop();
  };

  const togglePause = () => {
    if (isPaused) {
      recorderRef.current.resume();
      startTimer();
    } else {
      recorderRef.current.pause();
      stopTimer();
    }
    setIsPaused((prevPaused) => !prevPaused);
  };

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
  };

  const cleanup = () => {
    if (recorderRef.current) {
      recorderRef.current.stop();
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (webSocketRef.current) {
      webSocketRef.current.close();
    }
    setRecordingTime(0);
  };

  useEffect(() => {
    return () => cleanup();
  }, []);

  useEffect(() => {
    const selectedUsers = availableUsers
      .filter((user) => user.roles.some((role) => roleIds.includes(role)))
      .map((user) => user.value);
    // setParticipantsIds((prev) => [...prev, ...selectedUsers]);
    setParticipantsIds(selectedUsers);
  }, [roleIds]);

  useEffect(() => {
    setParticipantsIds([userId]);
  }, []);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Card sx={{ height: "100%" }}>
        <CardHeader title={t("Nový zvukový záznam")} />
        <CardContent>
          <Box
            sx={{ mb: 10, display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <TextField
                onChange={(e) => setMitingName(e.target.value)}
                fullWidth
                multiline
                rows={4}
                id="outlined-textarea"
                label={t("Téma schůzky")}
                disabled={isRecording}
              />
            </Box>
            <Box>
              <Autocomplete
                name={"roles"}
                options={roles}
                getOptionLabel={(option) => option.label}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setRoleIds(newValue.map((option) => option.value));
                }}
                fullWidth
                disabled={isRecording}
                multiple
                limitTags={2}
                value={roles.filter((user) => roleIds.includes(user.value))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Vyberte účastníky podle role")}
                  />
                )}
              />
            </Box>
            <Box>
              <Autocomplete
                name={"participants"}
                options={availableUsers}
                disabled={isRecording}
                getOptionLabel={(option) => option.label}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setParticipantsIds(newValue.map((option) => option.value));
                }}
                fullWidth
                multiple
                limitTags={2}
                value={availableUsers.filter((user) =>
                  participantIds.includes(user.value)
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("Účastníci")} />
                )}
              />
            </Box>
            <Box>
              <Autocomplete
                name={"customers"}
                options={allCustomers}
                disabled={isRecording}
                getOptionLabel={(option) => option.label}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setCustomerIds(newValue.map((option) => option.value));
                }}
                fullWidth
                multiple
                limitTags={2}
                value={allCustomers.filter((сustomer) =>
                  customerIds.includes(сustomer.value)
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("Uživatele")} />
                )}
              />
            </Box>
          </Box>
          {participantIds.length == 0 && (
            <Typography
              align="center"
              variant="subtitle1"
              sx={{ color: "red" }}
            >
              {t("Vyberte alespoň jednoho účastníka")}
            </Typography>
          )}
          <Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <IconButton
                sx={{
                  color: "red",
                  width: "64px",
                  height: "64px",
                }}
                onClick={startRecording}
                disabled={isRecording || participantIds.length == 0}
              >
                <FiberManualRecord sx={{ fontSize: "48px" }} />
              </IconButton>
              <IconButton
                sx={{
                  color: "#1b4dd6",
                  width: "64px",
                  height: "64px",
                }}
                color="secondary"
                onClick={togglePause}
                disabled={!isRecording}
              >
                {isPaused ? (
                  <PlayArrow sx={{ fontSize: "48px" }} />
                ) : (
                  <Pause sx={{ fontSize: "48px" }} />
                )}
              </IconButton>
              <IconButton
                sx={{
                  color: "#172b4d",
                  width: "64px",
                  height: "64px",
                }}
                color="default"
                onClick={stopRecording}
                disabled={!isRecording}
              >
                <Stop sx={{ fontSize: "48px" }} />
              </IconButton>
            </Box>
            <Typography variant="body1">
              {`${t("Status")}: `}
              <Typography component="span" color="textSecondary">
                {isRecording
                  ? isPaused
                    ? t("Pozastaveno")
                    : t("Záznam")
                  : t("Zastavil")}
              </Typography>
            </Typography>
            <Typography variant="body1" gutterBottom>
              {`${t("Doba nahrávání")}: ${formatTimeFromSeconds(recordingTime)}`}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(recordingTime / 10800) * 100}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NewRecord;
