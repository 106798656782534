import { makeStyles } from "@material-ui/core/styles";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Popover, Button, Box, Container, Grid } from "@material-ui/core";
import { useState } from "react";
import useSettings from "../hooks/useSettings";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  link: {
    display: "flex",
    alignItems: "center",
  },
});

let docId = null;

function LinkToFotoRenderer({ value, icon }) {
  const { settings } = useSettings();

  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(document.querySelector("body"));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (!value) {
    return "";
  }
  
  
  if( value instanceof Array ){
	  
	  
	  const ids = ( "popover" + "_" );
	  
	  return (
		<>
			<div>
		{ value.map( ( val, index ) => (
			<>
			  <Button className={classes.link} style={{ display: "inline-flex", marginLeft: "-20px" }} onClick={ (e) => { handleClick(e); docId = ids + index; } }>
				{!icon && ( <PhotoCameraIcon /> )}
				{ icon && ( icon )}
			  </Button>
			  <Popover
				open={ open && ( docId === ( ids + index ) )}
				anchorEl={anchorEl}
				anchorOrigin={{
				  horizontal: "center",
				  vertical: "top",
				}}
				PaperProps={{
				  sx: { width: "50%" },
				}}
				onClose={handleClose}
				transformOrigin={{
				  vertical: "top",
				  horizontal: "center",
				}}
			  >
				<Box
				  sx={{
					backgroundColor: "background.default",
					py: 5,
				  }}
				>
				  <Container maxWidth={settings.compact ? "xl" : false}>
					<Grid container justifyContent="end" spacing={3}>
					  <Button sx={{ mb: 2 }} variant="outlined" onClick={handleClose}>
						{t("Zavřít")}
					  </Button>
					</Grid>
					<img
					  // src change to {value} when links will be ready
					  // src="https://images.unian.net/photos/2023_02/1676137911-8847.jpeg?r=856200"
					  src={val}
					  alt="photo"
					  style={{ width: "100%", height: "auto" }}
					/>
				  </Container>
				</Box>
			  </Popover>
			</>
			)
		  )
		}
		</div>
		</>
	);
	  
	  
  }

  return (
    <>
      <Button className={classes.link} onClick={handleClick}>
        {!icon && ( <PhotoCameraIcon /> )}
		{ icon && ( icon )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        PaperProps={{
          sx: { width: "50%" },
        }}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.default",
            py: 5,
          }}
        >
          <Container maxWidth={settings.compact ? "xl" : false}>
            <Grid container justifyContent="end" spacing={3}>
              <Button sx={{ mb: 2 }} variant="outlined" onClick={handleClose}>
                {t("Zavřít")}
              </Button>
            </Grid>
            <img
              // src change to {value} when links will be ready
              // src="https://images.unian.net/photos/2023_02/1676137911-8847.jpeg?r=856200"
              src={value}
              alt="photo"
              style={{ width: "100%", height: "auto" }}
            />
          </Container>
        </Box>
      </Popover>
    </>
  );
}

export default LinkToFotoRenderer;
