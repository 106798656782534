import ModalWrapper from "../modal/ModalWrapper";
import { useEffect, useState } from "react";
import GridWrapper from "./gridWrapper";
import ModalWithButton from "../modal/ModalWithButton";
import FormWithSubmit from "../form/FormWithSubmit";
import apiPost from "../../api/apiPost";
import apiGet from "../../api/apiGet";
import { LinearProgress } from "@material-ui/core";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default function Notes({ open, id, model, onClose }) {
  const { t } = useTranslation();
  const [rows, setRows] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!id || !model) return;
    let mounted = true;
    setReady(false);
    apiGet(`${model}/${id}/notes`).then((response) => {
      if (mounted) {
        setRows(response?.data ?? []);
        setReady(true);
      }
    });

    return () => {
      mounted = false;
    };
  }, [id, model]);

  if (!ready) {
    return (
      <ModalWrapper isOpen={open} title={t("Přidat poznámku")}>
        <LinearProgress fullWidth sx={{ minWidth: "200px" }} />
      </ModalWrapper>
    );
  }

  const form = (
    <FormWithSubmit
      noTemplates={true}
      submitText={t("Přidat poznámku")}
      onSubmit={(data) => {
        apiPost(
          `${model}/${id}/notes`,
          data,
          (result) => {
            setRows(rows ? rows.concat(result.data) : [result.data]);
          },
          (error, response) => {
            alert(error);
            console.log(error, response);
          }
        );
      }}
      form={[
        {
          name: "text",
          label: t("Text"),
          editable: true,
          size: 12,
          required: 1,
          type: "text",
        },
      ]}
    />
  );

  return (
    <ModalWrapper
      isOpen={open}
      onClose={onClose}
      size={rows.length === 0 ? "xs" : "md"}
    >
      {rows.length > 0 && (
        <GridWrapper
          title={t("Poznámky")}
          data={rows}
          colDefs={[
            { field: "text", headerName: t("Text") },
            { field: "author", headerName: t("Author") },
            {
              field: "created_at",
              headerName: t("Vytvořeno"),
              valueFormatter: (params) => {
                return params.value && params.value !== "0000-00-00"
                  ? format(new Date(params.value), "dd.MM.yyyy")
                  : "";
              },
              filter: "agDateColumnFilter",
              filterParams: {
                comparator: (aDate, b) => {
                  if (aDate && b) {
                    let bDate = new Date(b);
                    bDate.setHours(0, 0, 0, 0);
                    return aDate.getTime() - bDate.getTime();
                  }
                  if (aDate) {
                    return 1;
                  }
                  return -1;
                },
              },
              comparator: (a, b) => {
                if (a && b) {
                  let aDate = new Date(a);
                  let bDate = new Date(b);
                  bDate.setHours(0, 0, 0, 0);
                  return aDate.getTime() - bDate.getTime();
                }
                if (a) {
                  return 1;
                }
                return -1;
              },
            },
          ]}
        >
          <ModalWithButton title={t("Nová poznámka")}>{form}</ModalWithButton>
        </GridWrapper>
      )}
      {rows.length === 0 && (
        <ModalWithButton
          title={t("Nová poznámka")}
          buttonProps={{ fullWidth: true }}
        >
          {form}
        </ModalWithButton>
      )}
    </ModalWrapper>
  );
}
