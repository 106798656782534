import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";

import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import { useTranslation } from 'react-i18next';
import XIcon from '../icons/X';
import ExternalLink from '../icons/ExternalLink';
import Download from '../icons/Download';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Button,
} from '@material-ui/core';

export default forwardRef((props, ref) => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    if (typeof props.data[props.field]=='undefined') {
        from_profile=true;
        props.data[props.field]=props.data.value
    }
    const [value, setValue] = useState(props.data[props.field]);
    const [loading, setLoading] = useState('none');
    const [attach, setAttach] = useState('block');


    var locale = 'cs';
    if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');

    var from_profile=false;




    const handleAttach = () => {
        fileInputRef.current.click();
    };


    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
console.log('getval '+value+'mm')
                return value;
            },
            afterGuiAttached: async() => {

            if (props.data[props.field]==null) {
                fileInputRef.current.click();
            }

                if (props.data[props.field]!='' && props.data[props.field]!=null) {
                    downloadAttachment(props.data[props.field]);
                }


            },

            isPopup: () => {
                return false;
            }
        };
    });

    const handleUpload=()=> {
        const fileInput = document.querySelector('#file_'+props.node.data.id);
        var files=fileInput.files;

        if (files.length===0) {
            alert(t('Please choose the file.'));
        } else {

            uploadFile(files[0], props.node.data.id);
        }
    }

    useEffect(() => {

       if (value!=props.data[props.field]) {
           console.log(value);
           console.log(props.data[props.field]);
           props.api.stopEditing();
       }
    }, [value]);

    const handleNull=() => {
console.log('handlenull')
        setValue('NULL');
        //props.api.stopEditing();
    }

    const downloadAttachment=(X)=>{
        HttpService.postblob(UrlService.apiDomain() + 'api/attachments/download_attachment', {attachment:X, model:props.model, id:props.node.data.id})
            .then((response) => {
                // Create blob link to download

                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                let tmp=X.split('/');
                let filename=tmp[tmp.length-1];
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const uploadFile=(file, id)=> {
        setLoading('block');
        setAttach('none');
        const formData = new FormData();
        formData.append('file1', file);
        HttpService.post(UrlService.apiDomain()+'api/upload/'+props.model+'/'+props.field+'/'+id, formData)
            .then(res => {
                if (typeof res.data.ocr_result !='undefined' && res.data.ocr_result !='') {
                    const regex = /{[^}]+}/gm;
                    var fintext=res.data.ocr_result.replaceAll(regex, function(match) {
                        console.log(match);
                        match=match.replaceAll('}', '');
                        match=match.replaceAll('{', '');
                        var fin=t(match);
                        console.log(fin);
                        console.log(match);
                        return fin;
                    });
                    alert(fintext);
                }
                console.log(t('Successfully uploaded'));
                setLoading('none');
                setAttach('block');
                setValue(res.data.path);

            })
            .catch((error) => {
                console.log(error);
            })
    }

    console.log(props);


    return (
        <>
    <input  ref={fileInputRef} onChange={handleUpload} hidden id={"file_"+props.node.data.id} name="file" type="file" />
        {props.data[props.field]!=null &&
            <>
        <Button
            id="fil_download"
            color="primary"
            sx={{marginTop:1}}
            className="btn btn-default btn-sm delete-ingrid-but"


        >
            <Download onClick={function() {downloadAttachment(props.data[props.field])}} />
        </Button>
        <Button sx={{marginTop:1}}
                id="fil_delete"
            className="btn btn-default btn-sm delete-ingrid-but"
            onClick={handleNull}
            ><XIcon />
            </Button>
            </>
        }
        <Button
            color="primary"
            id="fil_attach"
            sx={{marginTop:1, display:attach}}
            className="btn btn-default btn-sm delete-ingrid-but"
            onClick={handleAttach}

        >
            <ExternalLink />
        </Button>

        <CircularProgress sx={{display:loading}} />



</>
    )
})
