import GridWrapper from "../../../components/grid/gridWrapperNew";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../../contexts/DataContext";
import { useContext } from "react";
import { format } from "date-fns";

const RepeatsTable = ({ data }) => {
  console.log({ data });
  const { t } = useTranslation();
  const {
    data: { thp_users },
  } = useContext(DataContext);

  const columnDefs = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      lockPosition: "left",
      pinned: "left",
      maxWidth: 150,
      sortable: false,
      resizable: false,
      suppressMenu: true,
    },
    {
      headerName: t("Vytvořil"),
      field: "employee_id",
      width: "50%",
      valueFormatter: (params) => {
        return thp_users.find((user) => user.id == params.value)?.name || "";
      },
    },
    {
      headerName: t("Vytvořeno"),
      field: "created_at",
      valueFormatter: (params) => {
        return params.value &&
          params.value !== null &&
          params.value !== "0000-00-00"
          ? format(new Date(params.value), "dd.MM.yyyy")
          : "";
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (aDate, b) => {
          if (aDate && b) {
            let bDate = new Date(b);
            bDate.setHours(0, 0, 0, 0);
            return bDate.getTime() - aDate.getTime();
          }
          if (aDate) {
            return 1;
          }
          return -1;
        },
      },
      comparator: (a, b) => {
        if (a && b) {
          let aDate = new Date(a);
          let bDate = new Date(b);
          bDate.setHours(0, 0, 0, 0);
          return aDate.getTime() - bDate.getTime();
        }
        if (a) {
          return 1;
        }
        return -1;
      },
    },
    {
      headerName: t("Nové datum"),
      field: "datum_nastupu",
      width: "25%",
      valueFormatter: (params) => {
        return params.value &&
          params.value !== null &&
          params.value !== "0000-00-00"
          ? format(new Date(params.value), "dd.MM.yyyy")
          : "";
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (aDate, b) => {
          if (aDate && b) {
            let bDate = new Date(b);
            bDate.setHours(0, 0, 0, 0);
            return bDate.getTime() - aDate.getTime();
          }
          if (aDate) {
            return 1;
          }
          return -1;
        },
      },
      comparator: (a, b) => {
        if (a && b) {
          let aDate = new Date(a);
          let bDate = new Date(b);
          bDate.setHours(0, 0, 0, 0);
          return aDate.getTime() - bDate.getTime();
        }
        if (a) {
          return 1;
        }
        return -1;
      },
    },
  ];

  return (
    <>
      <GridWrapper
        needResize={false}
        data={data}
        colDefs={columnDefs}
        title={t("Počet opakování")}
      />
    </>
  );
};

export default RepeatsTable;
