import { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toast from "react-hot-toast";
import { createCard } from "../../../slices/vacancies";
import { useDispatch } from "../../../store";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const CopyVacancy = ({
  name,
  description,
  amount,
  profession,
  customer_id,
  salary,
  bonus,
  shift,
  citizenship,
  sex,
  age,
  languages,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [dueDate, setDueDate] = useState(new Date());
  const [interviewDate, setInterviewDate] = useState(new Date());

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");
  const [locale, setLocale] = useState(lc);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e?.stopPropagation?.();
    setOpen(false);
  };

  const values = {
    name,
    description,
    amount,
    startDate: format(dueDate, "yyyy-MM-dd"),
    interviewDate: format(interviewDate, "yyyy-MM-dd"),
    profession,
    salary,
    bonus,
    shift,
    citizenship,
    sex,
    age,
    languages,
  };

  const handleCopy = async (e) => {
    e.stopPropagation();
    try {
      await dispatch(createCard({ columnId: customer_id, values }));
      handleClose();
      toast.success(t("Záznam vtvořen!"));
    } catch (err) {
      console.error(err);
      handleClose();
      toast.error(t("Něco se pokazilo!"));
    }
  };

  return (
    <>
      <Dialog
        onClick={(e) => e.stopPropagation()}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Potvrďte kopírování")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Opravdu chcete zkopírovat tento požadavek?")}
          </DialogContentText>
          <Box sx={{ mb: 2 }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={localeMap[locale]}
            >
              <MobileDatePicker
                label={t("Datum nástupu")}
                mask="__.__.____"
                openTo="day"
                minDate={new Date()}
                value={dueDate}
                onChange={(event) => {
                  setDueDate(event);
                }}
                renderInput={(inputProps) => (
                  <TextField fullWidth variant="outlined" {...inputProps} />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ mb: 2 }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={localeMap[locale]}
            >
              <MobileDatePicker
                label={t("Pohovor")}
                mask="__.__.____"
                openTo="day"
                name="date"
                minDate={new Date()}
                value={interviewDate}
                onChange={(event) => {
                  setInterviewDate(event);
                }}
                renderInput={(inputProps) => (
                  <TextField fullWidth variant="outlined" {...inputProps} />
                )}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Ne")}
          </Button>
          <Button onClick={handleCopy} color="primary" autoFocus>
            {t("Ano")}
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton color="primary" onClick={handleOpen} title={t("Kopírovat")}>
        <ContentCopyIcon />
      </IconButton>
    </>
  );
};

export default CopyVacancy;
