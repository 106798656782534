import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
} from "@material-ui/core";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import moment from "moment/moment";
import UploadIcon from "@material-ui/icons/Upload";
import CheckIcon from "@material-ui/icons/Check";
import { CloseIcon } from "react-modal-image/lib/icons";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import scanPassport from "../../api/scanPassport";
import useAuth from "../../hooks/useAuth";
import searchEmployee, {
  searchMultipleEmployees,
} from "../../api/searchEmployee";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import dayjs from "dayjs";
import { Backspace, Clear } from "@material-ui/icons";
import { DesktopDatePicker } from "@material-ui/lab";

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

export default function SearchDialog(props) {
  const { t } = useTranslation();
  const [locale] = useState(t("cs"));

  const [birthday, setBirthday] = useState(props.birthday);
  const [name, setName] = useState(props.name);
  const [surname, setSurname] = useState(props.surname);

  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [failedText, setFailedText] = useState("");
  const [state, setState] = useState("form");

  const passportInputRef = useRef(null);

  const { user, logout } = useAuth();

  const [columns] = useState(
    JSON.parse(localStorage.getItem("columns")).filter(
      (i) => i.model === "employees" && i.can_search === 1
    )
  );

  const [searchFields, setSearchFields] = useState({});

  useEffect(() => {
    if (state === "loaded") {
      search();
    }
  }, [state]);

  // useEffect(() => {
  //   if (!isOpen) {
  //     setName("");
  //     setSurname("");
  //     setBirthday(null);
  //     setFailedText("");
  //     setState("form");
  //   }
  // }, [isOpen]);

  const handleFound = (employee) => {
    setName("");
    setSurname("");
    setBirthday(null);
    setFailedText("");
    props.onFound(employee);
    props.onClose();
    setState("form");
  };

  useEffect(() => {
    if (name || surname || birthday) {
      search();
    }
  }, [props.month, props.year, props.customer]);

  const search = () => {
    setState("loading");
    if (can_search_multiple) {
      let fields = {
        name: name,
        surname: surname,
        date_of_birth: birthday ? moment(birthday).format("YYYY-MM-DD") : null,
        month: props.month,
        year: props.year,
        customer: props.customer,
      };

      for (let key in fields) {
        if (fields[key] === null || fields[key] === "") {
          delete fields[key];
        }
      }

      for (let key in searchFields) {
        if (searchFields[key] !== null && searchFields[key] !== "") {
          fields[key] = searchFields[key];
        }
      }

      if (!fields || Object.keys(fields).length === 0) {
        setState("failed");
        setFailedText(t("Empty search"));
        return;
      }

      searchMultipleEmployees(
        fields,
        (employees) => {
          handleFound(employees);
        },
        (error) => {
          setFailedText(t(error));
          setState("failed");
        },
        props.validation
      );
    } else {
      searchEmployee(
        { name, surname, birthday },
        (employee) => {
          handleFound(employee);
        },
        (error) => {
          setFailedText(t(error));
          setState("failed");
        },
        props.validation
      );
    }
  };

  const uploadPassport = (e) => {
    setState("loading");
    const files = Array.from(e.target.files);
    scanPassport(
      files[0],
      (result, passport, photo) => {
        setName(passport.name);
        setSurname(passport.surname);
        setBirthday(passport.birthday);
        setState("loaded");
      },
      (error) => {
        setFailedText(t("Chyba čtení pasu"));
        setState("failed");
      }
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).employees;

  let can_search_multiple = policy_actions.includes("full_search");

  return (
    <Dialog open={isOpen} onClose={props.onClose} onKeyPress={handleKeyPress}>
      <Paper elevation={12} sx={{ width: "100%", p: 3 }}>
        {state === "form" && (
          <>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TextField
                id="search-name"
                label={t("Jméno")}
                variant="standard"
                sx={{ height: "70%" }}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                  props.getName(event.target.value);
                }}
              />
              <TextField
                id="search-surname"
                label={t("Příjmení")}
                variant="standard"
                sx={{ height: "70%" }}
                value={surname}
                onChange={(event) => {
                  setSurname(event.target.value);
                  props.getSurname(event.target.value);
                }}
              />
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={localeMap[locale]}
              >
                <DesktopDatePicker
                  clearable
                  inputFormat="dd.MM.yyyy"
                  label={t("Datum narození")}
                  value={birthday ? Date.parse(birthday) : null}
                  onChange={(date, dateStr) => {
                    let d = null;
                    if (date) {
                      d = moment(new Date(date)).format("YYYY-MM-DD");
                    }
                    setBirthday(d);
                    props.getBirthday(d);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{ width: "150px" }}
                      helperText={null}
                    />
                  )}
                />
              </LocalizationProvider>
              <input
                id={"passportInput"}
                onChange={uploadPassport}
                hidden
                ref={passportInputRef}
                name="file"
                type="file"
              />
              {props.passportSearch && (
                <Button
                  color="primary"
                  type="button"
                  variant="outlined"
                  onClick={() => passportInputRef.current.click()}
                >
                  <UploadIcon />
                </Button>
              )}
              <Button
                color="primary"
                type="button"
                variant="outlined"
                onClick={search}
                sx={{ ml: 1 }}
              >
                <CheckIcon />
              </Button>
            </Box>
            {can_search_multiple && (
              <Grid spacing={2} container>
                {columns.map((column) => {
                  const handleChange = (value) => {
                    let fields = { ...searchFields };
                    fields[column.field] = value;
                    setSearchFields(fields);
                  };

                  const value = searchFields[column.field]
                    ? searchFields[column.field]
                    : "";

                  if (column.column_type === "text") {
                    return (
                      <Grid key={column.id} item xs={4}>
                        <TextField
                          id="search-name"
                          label={t(column.name)}
                          variant="standard"
                          sx={{ height: "70%" }}
                          value={value}
                          onChange={(event) => handleChange(event.target.value)}
                        />
                      </Grid>
                    );
                  } else if (column.column_type === "date") {
                    return (
                      <Grid key={column.id} item xs={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={localeMap[locale]}
                        >
                          <DesktopDatePicker
                            clearable
                            inputFormat="dd.MM.yyyy"
                            label={t(column.name)}
                            value={birthday ? Date.parse(birthday) : null}
                            onChange={(date, dateStr) => {
                              let d = null;
                              if (date) {
                                d = moment(new Date(date)).format("YYYY-MM-DD");
                              }
                              handleChange(d);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    );
                  } else if (column.column_type === "select") {
                    let data_array = JSON.parse(
                      localStorage.getItem(column.data_array)
                    );
                    let options = data_array.map((item) => ({
                      key: item.id,
                      label: t(item.name),
                      value: item.id,
                    }));

                    return (
                      <Grid key={column.id} item xs={4}>
                        <Autocomplete
                          name={name}
                          options={options}
                          onChange={(event, newValue) => {
                            let val = newValue?.value;
                            handleChange(val);
                          }}
                          fullWidth
                          value={
                            options.find((option) => option.value === value)
                              ?.label ?? ""
                          }
                          renderInput={(params) => (
                            <TextField
                              variant={"standard"}
                              {...params}
                              label={t(column.name)}
                            />
                          )}
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            )}
          </>
        )}
        {state === "loading" && <LinearProgress sx={{ width: "300px" }} />}
        {state === "failed" && (
          <Alert severity="error" onClose={() => setState("form")}>
            {failedText}
          </Alert>
        )}
      </Paper>
    </Dialog>
  );
}
