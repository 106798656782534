import {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Box, Card, CardContent, Container, Divider, Link, Typography} from '@material-ui/core';
import AuthBanner from '../../components/authentication/AuthBanner';
import MainNavbar from '../../components/MainNavbar';
import {useTranslation} from 'react-i18next';
import {
    LoginAmplify,
    LoginAuth0,
    LoginFirebase,
    LoginJWT
} from '../../components/authentication/login/index2';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import {ResetPasswordModal} from "../../components/dashboard/ResetPassword";

const platformIcons = {
    Amplify: '/static/icons/amplify.svg',
    Auth0: '/static/icons/auth0.svg',
    Firebase: '/static/icons/firebase.svg',
    JWT: '/static/icons/jwt.svg'
};

const Login = () => {
    const {t} = useTranslation();
    const {platform} = useAuth();

    useEffect(() => {
        gtm.push({event: 'page_view'});
    }, []);

    return (
        <>
            <MainNavbar/>
            <Helmet>
                <title>Login | AWA Partners</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >

                <Container
                    maxWidth="sm"
                    sx={{py: '80px'}}
                >

                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 4
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3
                                }}
                            >
                                <div>
                                    <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h4"
                                    >
                                        {t('Přihlásit se')}
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {t('Jsem již registrovaný - přihlášení')}
                                    </Typography>
                                </div>

                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3
                                }}
                            >
                                {platform === 'Amplify' && <LoginAmplify/>}
                                {platform === 'Auth0' && <LoginAuth0/>}
                                {platform === 'Firebase' && <LoginFirebase/>}
                                {platform === 'JWT' && <LoginJWT/>}
                            </Box>
                            <Divider sx={{my: 3}}/>
                            <ResetPasswordModal/>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Login;
