import { Button } from "@material-ui/core";
import UploadIcon from "@material-ui/icons/Upload";
import React, { memo, useRef } from "react";
import { Delete } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

export default memo(
  ({ label, name, onChange, required = false, multiple, file }) => {
    const { t } = useTranslation();

    const fileInputRef = useRef(undefined);

    const onUpload = (e) => {
      let files = e.target.files;
      if (!name.endsWith("[]") && !multiple) {
        files = files[0];
      }
      onChange(name, files, files !== undefined);
    };

    const onClear = () => {
      onChange(name, undefined, false);
    };

    if (file) {
      return (
        <>
          <Button
            fullWidth
            type="button"
            variant="outlined"
            sx={{ height: "100%" }}
            onClick={onClear}
          >
            <Delete />
            {t("Odstranit ")}
            {t(label)}
          </Button>
        </>
      );
    }

    return (
      <>
        <input
          id={"passportInput"}
          onChange={onUpload}
          hidden
          ref={fileInputRef}
          name="file"
          type="file"
          multiple={multiple}
        />
        <Button
          fullWidth
          sx={{ height: "100%" }}
          color="primary"
          type="button"
          variant="outlined"
          onClick={() => fileInputRef.current.click()}
        >
          <UploadIcon />
          {t("Nahrát")} {t(label)}
          {required && "*"}
        </Button>
      </>
    );
  },
  (prev, next) => {
    return prev.file === next.file;
  }
);
