"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UILabelReactT from "./UILabelReactT.mjs";

import UILabelAbstractT from "../../../Interface/UI/Label/_/UILabelAbstractT.mjs";


const UILabelT = class UILabelT extends UILabelAbstractT {
	
	#Component = null;
	
	#Ref = null;
	
	constructor(
		Props
	){
		
		console.log( "UILabelT.constructor", Props );
		
		
		super( );
		
		
		this.#Ref = React.createRef( );
		
		
		this.#Component = React.createElement( 
			UILabelReactT, 
			{
				...Props,
				ref: this.#Ref
			}
		);
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UILabelT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#Ref.current.PropSet( Name, Value );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UILabelT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.#Ref.current.PropGet( Name );
		
	}
	
	Component( 
	
	){
		
		return this.#Component;
		
	}
	
};


export default UILabelT;