import { Dialog, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import addPropsToGrid from "../../utils/addPropsToGrid";

export default function ModalWrapper({
  isOpen,
  onClose,
  children,
  size,
  onAdd,
  getData,
  rowData
}) {
  let body = addPropsToGrid(children, {
    onAdd: onAdd,
    getData: getData,
    rowData,
  });
  return (
    <Dialog fullWidth maxWidth={size ?? "sm"} onClose={onClose} open={isOpen}>
      <Paper elevation={12} sx={{ p: 2 }}>
        {body}
      </Paper>
    </Dialog>
  );
}
