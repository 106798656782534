"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIPageContentTopBarReactT from "../../Page/Content/TopBar/UIPageContentTopBarReactT.mjs";

import UIGridReactT from "../../Grid/UIGridReactT.mjs";


const UIPageContentReactT = class UIPageContentReactT extends React.Component {
	
	constructor( 
		Props
	){
		
		console.log( "UIPageContentReactT.constructor", Props );
		
	
		super( Props ); 
		
			
		this.state = {
			key: Props.key ? Props.key : null,
			
			StylePageContentBox: Props.StylePageContentBox ? Props.StylePageContentBox : { },
			StylePageContentBoxContainer: Props.StylePageContentBoxContainer ? Props.StylePageContentBoxContainer : { },
			StylePageContentBoxContainerBoxTopBar: Props.StylePageContentBoxContainerBoxTopBar ? Props.StylePageContentBoxContainerBoxTopBar : { },
			StylePageContentBoxContainerBoxGrid: Props.StylePageContentBoxContainerBoxGrid ? Props.StylePageContentBoxContainerBoxGrid : { },
			
			TopBar: Props.TopBar ? Props.TopBar : null,
			Grid: Props.Grid ? Props.Grid : null
		};
		
	}
	
	componentDidMount(
	
	){
		
		console.log( "UIPageContentReactT.componentDidMount", this.state );
		
	}
	
	componentDidUpdate(
		PropsPrev,
		StatePrev
	){
		
		console.log( "UIPageContentReactT.componentDidUpdate", this.state );
			
	}
	
	componentWillUnmount(
	
	){
		
		console.log( "UIPageContentReactT.componentWillUnmount", this.state );
		
	}
	
	TopBarGet(
	
	){
		
		return this.state.TopBar;
		
	}
	
	TopBarSet(
		UIPageContentTopBarReactElement
	){
		
		console.log( "UIPageContentReactT.TopBarSet", UIPageContentTopBarReactElement );
		
		
		if( UIPageContentTopBarReactElement !== null ){
		
			if( React.isValidElement( UIPageContentTopBarReactElement ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
			if( UIPageContentTopBarReactElement.type !== UIPageContentTopBarReactT ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		this.setState( { TopBar: UIPageContentTopBarReactElement } );
		
	}
	
	GridGet(
	
	){
	
		return this.state.Grid;
	
	}
	
	GridSet(
		UIGridReactElement
	){
		
		console.log( "UIPageContentReactT.GridSet", UIGridReactElement );
		
		
		if( UIGridReactElement !== null ){
		
			if( React.isValidElement( UIGridReactElement ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
			if( UIGridReactElement.type !== UIGridReactT ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		this.setState( { Grid: UIGridReactElement } );
		
	}
	
	render( ){
		
		console.log( "UIPageContentReactT.render", this.state );
		
		
		return React.createElement(
			MaterialUI.Box,
			{
				key: this.state.key + "_" + "box",
				style: this.state.StylePageContentBox
			},
			[
				React.createElement(
					MaterialUI.Container,
					{
						key: this.state.key + "_" + "box" + "_" + "container",
						
						style: this.state.StylePageContentBoxContainer
					},
					[
					
						React.createElement(
							MaterialUI.Box,
							{
								key: this.state.key + "_" + "box" + "_" + "container" + "_" + "box" + "_" + "top_bar",
								
								style: this.state.StylePageContentBoxContainerBoxTopBar
							},
							[
								this.state.TopBar
							]
						),
			
						React.createElement(
							MaterialUI.Box,
							{
								key: this.state.key + "_" + "box" + "_" + "container" + "_" + "box" + "_" + "grid",
								
								style: this.state.StylePageContentBoxContainerBoxGrid
							},
							[
								this.state.Grid
							]
						)
							
					]
				)
			]
		);
		
	}
	
};


export default UIPageContentReactT;