import React, { Component } from 'react';
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import { ChatSidebar, ChatThread } from '../dashboard/chat';
import { useTranslation } from 'react-i18next';

const Communication = (props) =>{
    const { t } = useTranslation();


    return (
      <>
        <ChatThread id={props.id} node={props.profileData} />
      </>
    );

}


export default Communication;