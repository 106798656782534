"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIPageContentReactT from "./UIPageContentReactT.mjs";

import UIGridT from "../../Grid/UIGridT.mjs";

import UIPageContentTopBarT from "./TopBar/UIPageContentTopBarT.mjs";

import UIPageContentAbstractT from "../../../../Interface/UI/Page/Content/_/UIPageContentAbstractT.mjs";


const UIPageContentT = class UIPageContentT extends UIPageContentAbstractT {
	
	#Component = null;
	
	#Ref = null;
	
	#ReadyCb = null;
	
	constructor(
		Props,
		
		ReadyCb
	){
		
		console.log( "UIPageContentT.constructor", Props );
		
		
		if( typeof( ReadyCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		

		super( );
		
		
		this.#Ref = null;
		
		
		this.#Component = React.createElement( 
			UIPageContentReactT, 
			{
				...Props,
				ref: this.RefSet.bind( this )
			}
		);
		
		
		this.#ReadyCb = ReadyCb;
		
	}
	
	RefSet( Ref ){
		
		console.log( "UIPageContentT.RefSet", Ref );
		
		
		this.#Ref = Ref;
		
		
		if( this.#Ref !== null ){
		
			this.#ReadyCb( );
		
		}
		
	}
	
	TopBarSet(
		UIPageContentTopBar
	){
		
		console.log( "UIPageContentT.TopBarSet", UIPageContentTopBar );
		
		
		if( UIPageContentTopBar !== null ){
		
			if( ( UIPageContentTopBar instanceof UIPageContentTopBarT ) === false ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		if( this.#Ref !== null ){
		
			this.#Ref.TopBarSet( ( UIPageContentTopBar !== null ) ? UIPageContentTopBar.Component( ) : null );
		
		} else {
			
			throw new Error( "Not mounted" );
			
		}
		
	}
	
	GridSet(
		UIGrid
	){
		
		console.log( "UIPageContentT.GridSet", UIGrid );
		
		
		if( UIGrid !== null ){
		
			if( ( UIGrid instanceof UIGridT ) === false ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		if( this.#Ref !== null ){
		
			this.#Ref.GridSet( ( UIGrid !== null ) ? UIGrid.Component( ) : null );
		
		} else {
			
			throw new Error( "Not mounted" );
			
		}
		
	}
	
	Component( ){
		
		return this.#Component;
		
	}
	
};


export default UIPageContentT;