import { Box, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";

const SimpleLoader = ({ size }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <CircularProgress size={size} />
      <Typography variant="subtitle1">{t("Chvilku strpení...")}</Typography>
    </Box>
  );
};

export default SimpleLoader;
