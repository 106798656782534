"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


const UIModalTopBarReactT = class UIModalTopBarReactT extends React.Component {
	
	constructor(
		Props
	){
		
		console.log( "UIModalTopBarReactT.constructor", Props );
		
		
		super( 
			Props 
		);
		
		
		this.state = {
			key: ( ( Props.key ) ? Props.key : null ),
			
			TopBarContentJustify: ( ( Props.TopBarContentJustify ) ? Props.TopBarContentJustify : "space-between" ),
			TopBarSpacing: ( ( Props.TopBarSpacing ) ? Props.TopBarSpacing : 3 ),
			Children: ( ( Props.Children ) ? Props.Children : [ ] )
		};
		
	}
	
	ChildrenSet( 
		Children
	){
		
		this.setState( {
			Children: Children
		} );
		
	}
	
	componentDidMount(
	
	){
		
		console.log( "UIModalReactT.componentDidMount", this.state );
		
	}
	
	componentDidUpdate(
		PropsPrev,
		StatePrev
	){
		
		console.log( "UIModalTopBarReactT.componentDidUpdate", this.state );
			
	}
	
	componentWillUnmount(
	
	){
		
		console.log( "UIModalTopBarReactT.componentWillUnmount", this.state );
		
	}
	
	render( ){
		
		console.log( "UIModalTopBarReactT.render", this.state );
		
		
		let Children = [ ];
		
		
		for(
			let I = 0;
			I < this.state.Children.length;
			I++
		){
			
			console.log( this.state.Children[ I ] );
			
			let XS = this.state.Children[ I ].XS;
			
			let SX = this.state.Children[ I ].SX;
			
			let Components = this.state.Children[ I ].Components;
			
			let key = this.state.Children[ I ].key;
			
			let ComponentsChildren = [ ];
			
			for(
				let I2 = 0;
				I2 < Components.length;
				I2++
			){
				
				ComponentsChildren.push( Components[ I2 ].Component( ) );
				
			}
			
			Children.push(
			
				React.createElement(
					MaterialUI.Grid,
					{
						key: key,
						
						item: true,
						xs: XS,
						sx: SX
					},
					ComponentsChildren
				)
				
			);
			
		}
		
		
		return React.createElement(
			MaterialUI.Grid,
			{
				key: this.state.key,
				container: true,
				justifyContent: this.state.TopBarContentJustify,
				spacing: this.state.TopBarSpacing
			},
			[
				...Children
				
			]
		);
		
	}
	
};


export default UIModalTopBarReactT;