"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


import UIModalTopBarReactT from "./UIModalTopBarReactT.mjs";

import UIModalTopBarAbstractT from "../../../../Interface/UI/Modal/TopBar/_/UIModalTopBarAbstractT.mjs";


const UIModalTopBarT = class UIModalTopBarT extends UIModalTopBarAbstractT {
	
	#Component = null;
	
	#Ref = null;
	
	#ReadyCb = null;
	
	constructor(
		props,
		
		ReadyCb
	){
		
		if( typeof( ReadyCb ) !== "function" ){
		
			throw new Error( "Argument" );
		
		}
		
		
		super( );
		
		
		this.#ReadyCb = ReadyCb;
		
		
		this.#Component = React.createElement( 
			UIModalTopBarReactT, 
			{
				...props,
				ref: this.RefSet.bind( this )
			}
		);
		
	}
	
	RefSet( Ref ){
		
		console.log( "UIModalT.RefSet", Ref );
		
		
		this.#Ref = Ref;
		
		
		if( this.#Ref !== null ){
		
			this.#ReadyCb( );
		
		}
		
	}
	
	ChildrenSet(
		Children
	){
		
		this.#Ref.ChildrenSet( Children );
		
	}
	
	Component( ){
		
		return this.#Component;
		
	}
	
};


export default UIModalTopBarT;