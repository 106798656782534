import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";

import {
  Grid,
  TextField,
  Alert,
  Avatar,
  Button,
  Box,
  Container,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";

const ContactForm = ({
  data,
  handleDelete,
  handleCancel,
  customer,
  grd,
  isCreating,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const at = localStorage.getItem("accessToken");
  const { setData } = useContext(DataContext);
  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).customer_contacts;

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [ava, setAva] = useState(data?.avatar ?? "");
  const [isEdit, setIsEdit] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = () => {
    setIsOpen(true);
    setIsEdit(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsEdit(false);
  };
  const handleOpenDeleteAlert = () => {
    setOpenDeleteAlert(true);
  };

  const handleCloseDeleteAlert = () => {
    setOpenDeleteAlert(false);
  };

  const formik = useFormik({
    initialValues: {
      name: data?.name ?? "",
      phone: data?.phone ?? "",
      email: data?.email ?? "",
      position: data?.position ?? "",
      linkedin: data?.linkedin ?? "",
      facebook: data?.facebook ?? "",
      description: data?.description ?? "",
      customer: customer,
      tags: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Povinné")),
      phone: Yup.string().required(t("Povinné")),
      email: Yup.string().email(t("Zadejte správný e-mail")),
      position: Yup.string(),
      linkedin: Yup.string(),
      facebook: Yup.string(),
      description: Yup.string(),
      tags: Yup.array(),
    }),
    onSubmit: async (values) => {
      if (data) {
        try {
          const response = await HttpService.post(
            `${UrlService.apiDomain()}api/customer_contacts/edit/${data.id}`,
            { data: values }
          );
          if (!response.data.success) {
            if (response.data.message) {
              setAlertMessage(response.data.message);
              setShowAlert(true);
            }

            toast.error(t("Něco se pokazilo!"));
            return;
          } else {
            toast.success(t("Záznam obnoven"));
            handleClose();
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const response = await HttpService.post(
            `${UrlService.apiDomain()}api/customer_contacts/create`,
            { data: values }
          );
          if (!response.data.success) {
            if (response.data.message) {
              setAlertMessage(response.data.message);
              setShowAlert(true);
            }

            toast.error(t("Něco se pokazilo!"));
            return;
          } else {
            toast.success(t("Záznam obnoven"));
            setData((prev) => {
              prev.customer_contacts.push(response.data.data);
              return prev;
            });
            handleCancel();
            grd();
            handleClose();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();

      formData.append(`logo`, file);
      formData.append(`id`, data.id);

      try {
        const response = await HttpService.post(
          UrlService.apiDomain() + `api/add-customer-logo`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + at,
            },
          }
        );
        if (response.status != 200) {
          toast.error("Něco se pokazilo!");
          return;
        }
        setAva(response.data.data);
        toast.success("Popis aktualizován");
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!data) {
      setIsOpen(true);
    }
  }, [data]);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
      }}
    >
      <Container>
        <Box sx={{ p: 1 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid item container spacing={1} justifyContent={"space-between"}>
              {!isOpen && (
                <Grid item xs={12} sm={1} sx={{ mt: "-6px" }}>
                  <Avatar
                    src={ava}
                    alt="Company Logo"
                    onClick={handleImageUpload}
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleImageChange}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={isOpen ? 4 : 3}>
                <TextField
                  name="name"
                  label={t("Jméno / Název")}
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={!!(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={isOpen ? 4 : 3}>
                <TextField
                  name="phone"
                  label={t("Telefon")}
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  error={!!(formik.touched.phone && formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12} sm={isOpen ? 4 : 3}>
                <TextField
                  name="email"
                  label={t("E-mail")}
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={!!(formik.touched.email && formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              {!isOpen && (
                <Grid item xs={12} sm={1}>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <IconButton color="primary" onClick={toggleOpen}>
                      {isOpen ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {policy_actions.includes("edit") && (
                      <IconButton color="primary" onClick={handleEdit}>
                        <EditIcon />
                      </IconButton>
                    )}
                    {policy_actions.includes("delete") && (
                      <IconButton color="error" onClick={handleOpenDeleteAlert}>
                        <Close />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              )}
              {isOpen && (
                <>
                  <Grid item xs={12} sm={6}>
                    {" "}
                    <TextField
                      name="position"
                      label={t("Pozice / typ kontaktu")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.position}
                      error={
                        !!(formik.touched.position && formik.errors.position)
                      }
                      helperText={
                        formik.touched.position && formik.errors.position
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="linkedin"
                      label={t("Linkedin")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.linkedin}
                      error={
                        !!(formik.touched.linkedin && formik.errors.linkedin)
                      }
                      helperText={
                        formik.touched.linkedin && formik.errors.linkedin
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="facebook"
                      label={t("Facebook")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.facebook}
                      error={
                        !!(formik.touched.facebook && formik.errors.facebook)
                      }
                      helperText={
                        formik.touched.facebook && formik.errors.facebook
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="description"
                      label={t("Popis")}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      error={
                        !!(
                          formik.touched.description &&
                          formik.errors.description
                        )
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {showAlert && (
              <Alert
                sx={{ my: 2 }}
                severity="error"
                onClose={handleAlertClose}
                open={showAlert}
              >
                {alertMessage}
              </Alert>
            )}

            {isOpen && (
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Button
                  onClick={data ? handleClose : handleCancel}
                  type="button"
                  variant="outlined"
                  color="primary"
                >
                  {t("Zrušit")}
                </Button>
                {(isEdit || isCreating) && (
                  <Button type="submit" variant="contained" color="primary">
                    {t("Uložit")}
                  </Button>
                )}
              </Box>
            )}
          </form>
        </Box>
      </Container>
      <Dialog
        open={openDeleteAlert}
        onClose={handleCloseDeleteAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Potvrdit odstranění")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              "Jste si jisti, že chcete odstranit tuto položku a všechny položky s ní spojené?"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteAlert} color="primary">
            {t("Ne")}
          </Button>
          <Button onClick={() => handleDelete(data.id)} color="primary">
            {t("Ano")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default ContactForm;
