"use strict";


import UILabelT from "./UILabelT.mjs";

import UILabelProducerAbstractT from "../../../Interface/UI/Label/_/UILabelProducerAbstractT.mjs";


const UILabelProducerT = class UILabelProducerT extends UILabelProducerAbstractT {
	
	constructor(
		
	){
		
		console.log( "UILabelProducerT.constructor" );
		
	
		super( );
		
	}
	
	Produce(
		...Props
	){
		
		console.log( "UILabelProducerT.Produce", Props );
		
		
		return new UILabelT(
			...Props
		);
		
	}
	
};


export default UILabelProducerT;