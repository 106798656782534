import { useState, forwardRef, useImperativeHandle } from "react";

export default forwardRef((props, ref) => {
  console.log({ props });
  const orig_value = props.value;

  const [value, setValue] = useState(orig_value || "");

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: async () => {
        setValue(orig_value);
      },

      isPopup: () => {
        return true;
      },
    };
  });
  return (
    <textarea
      rows="8"
      cols="50"
      value={value}
      onChange={({ target: { value } }) =>
        setValue(value === "" ? null : value)
      }
    />
  );
});
