export default function strComparator (a, b) {
    if (a === null) {
        return -1;
    }
    if (b === null) {
        return 1;
    }
    a = a.toString();
    b = b.toString();
    let num1 = a.match(/\d+/g);
    let num2 = b.match(/\d+/g);
    if (num1) num1 = parseInt(num1[0]);
    if (num2) num2 = parseInt(num2[0]);

    let text1 = a.match(/[a-zA-Z]+/g);
    let text2 = b.match(/[a-zA-Z]+/g);
    if (text1) text1 = text1[0];
    if (text2) text2 = text2[0];

    if (text1 < text2) {
        return -1;
    } else if (text1 > text2) {
        return 1;
    } else {
        if (num1 && num2) {
            return num1 - num2;
        } else {
            return a < b ? -1 : a > b ? 1 : 0;
        }
    }
}