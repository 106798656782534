import UrlService from "../../services/UrlService";
import {updateDataInArray} from "../../utils/localStorageUtills";

const at = localStorage.getItem("accessToken");

export default function updateField(model,
                                    change,
                                    url,
                                    handleResult,
                                    handleUnsuccessful = (message, response) => {
                                        alert(message);
                                        console.error("handleUnsuccessful: ", response);
                                    },
                                    handleError = (error) => {
                                        alert("Chyba serveru");
                                        console.error("handleError: ", error);
                                    }) {
    let form_data = new FormData();
    form_data.append("id", change.id);
    form_data.append("field", change.field);
    form_data.append("value", change.value);

    if (url.startsWith("/")) {
        url = url.substr(1);
    }

    fetch(UrlService.apiDomain() + `api/` + url, {
        method: 'post',
        body: form_data,
        headers: {
            Authorization: "Bearer " + at,
        },
    })
        .then(r => r.json())
        .then(response => {
            if (!response.success) {
                handleUnsuccessful(response.error, response);
                return;
            }
            if(model){
                updateDataInArray(model, response.item);
            }
            handleResult(response.item, response);
        })
        .catch((error) => {
            handleError(error);
        });
}