"use strict";


import React from "react";

import MaterialUI from "@material-ui/core";


const UIInputReactT = class UIInputReactT extends React.Component {
	
	#Ref = null;
	
	constructor( Props ){
		
		console.log( "UIInputReactT.constructor", Props );
		
		
		super( Props );
		
		
		this.state = {
			OnChange: ( ( Props.OnChange ) ? Props.OnChange : null ),
			Hidden: ( Props.Hidden ? Props.Hidden : true ),
			Type: ( Props.Type ? Props.Type : "text" ),
			IsMultiple: ( Props.IsMultiple ? Props.IsMultiple : false ),
			Key: ( Props.Key ? Props.Key : null ) 
		};
		
	}
	
	PropSet(
		Name,
		Value
	){

		console.log( "UIInputReactT.PropSet", Name, Value );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.setState( {
			[ Name ]: Value
		} );
		
	}
	
	PropGet(
		Name
	){
		
		console.log( "UIInputReactT.PropGet", Name );
		
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		

		return this.state[ Name ];
		
	}
	
	Click( ){
		
		this.#Ref.click( );
		
	}
	
	ValueSet( Value ){
		
		console.log( this.#Ref, this.#Ref.files );
		
		this.#Ref.value = Value;
		
	}
	
	render(
	
	){
		
		console.log( "UIInputReactT.render", this.state );
		
		return React.createElement(
			"input",
			{
				ref: function( Ref ){
					this.#Ref = Ref;
				}.bind( this ),
				key: this.state.Key,
				onChange: this.state.OnChange,
				hidden: this.state.Hidden,
				type: this.state.Type,
				multiple: this.state.IsMultiple
			}
		);
		
	}
	
};


export default UIInputReactT;