"use strict";


import UIPageContentAbstractT from "./UIPageContentAbstractT.mjs";


const UIPageContentProducerAbstractT = class UIPageContentProducerAbstractT {
	
	constructor(
		
	){
		
	}
	
	Produce(
		...Props
	){
		
		throw new Error( "Abstract" );
		
	}
	
};


export default UIPageContentProducerAbstractT;