import InputWithValidationOld from "../../../inputs/text/InputWithValidationOld";

export const IbanRegex = /^([A-Z]{2})( ?)(\d{2})( ?)(\d{6})( ?)(\d{5,19})$/;

export default function InputIBAN(props) {
    return (
        <InputWithValidationOld regex={IbanRegex}
                                onChange={props.onChange}
                                name="iban"
                                label="IBAN"
                                value={props.value}
                                processValue={(val) => val.toUpperCase()}/>
    );
}