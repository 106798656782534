import { useState } from "react";
import { IconButton, Dialog, Box, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import SendMessage from "./SendMessage";

const Body = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: 1, display: "flex", justifyContent: "space-between" }}>
        <Typography color="textPrimary" variant="h5">
          {t("Podpora databáze")}
        </Typography>
        <Button variant="outlined" onClick={onClose}>
          {t("Zavřít")}
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, pt: 2 }}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/03mmaxhrGW8"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
      <SendMessage />
    </Box>
  );
};

export default Body;
